<template>
  <div class="addFile">
    <div class="uploadWord">请上传DOC格式的附件（文件大小2M以内）</div>
    <div class="betweenFlex firstLine">
      <div class="name">上传文件</div>
      <div type="text" class="projectName secondInput commonFlex">
        <el-upload
          class="upload-demo"
          action=""
          :multiple="false"
          accept=".doc"
          :before-upload="handlerUpload"
        >
          <div class="startFlex" v-if="!hasFile">
            <img :src="require('@as/zhaopin/上传.png')" alt="" />
            <span
              style="
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #afafaf;
                margin-left: 10px;
              "
              >点击上传文件</span
            >
          </div>
          <img
            class="file-icon"
            :src="require('@as/zhaopin/DOC.png')"
            alt=""
            v-else
          />
        </el-upload>
      </div>
    </div>
    <div class="firstLine botTwoChoice">
      <el-checkbox v-model="isAutoRefresh">三天内帮我自动刷新简历</el-checkbox>
      <el-checkbox v-model="isAutoSend">三天内有好工作帮我自动投递</el-checkbox>
    </div>
    <div class="firstLine botTwoChoice betweenFlex">
      <botChoice @confirm="handlerConfirm" @cancel="handlerCancel" />
      <div class="yulanResume commonFlex">简历预览</div>
    </div>
  </div>
</template>

<script>
import botChoice from './botChoice'
import { Resume, Basic } from '@api'
export default {
  props: ['id'],
  data() {
    return {
      file: {},
      hasFile: false,
      isAutoRefresh: false,
      isAutoSend: false,
    }
  },
  components: {
    botChoice,
  },
  watch: {
    id: {
      handler(val) {
        if (!val) return
        Resume.getFile({ userId: this.$store.state.uid }).then((res) => {
          Object.assign(this, { ...res })
        })
      },
      immediate: true,
    },
  },
  methods: {
    async handlerConfirm() {
      const data = {
        isAutoRefresh: this.isAutoRefresh ? 1 : 2,
        isAutoSend: this.isAutoSend ? 1 : 2,
      }
      if (this.hasFile) {
        data.file = await Basic.uploadImg({ file: this.file })
      }
      if (this.id) data.id = this.id
      data.userId = this.$store.state.uid
      Resume.changeFile(data)
    },
    handlerCancel() {},
    handlerUpload(file) {
      if (file.size / 1024 / 1024 > 2) {
        this.$message.error('请上传大小2M以内的文件')
        return false
      }
      this.file = file
      this.hasFile = true
      return false
    },
  },
}
</script>

<style lang="less">
.addFile {
  padding-left: 20px;
  padding-bottom: 40px;
  .botTwoChoice {
    padding-left: 140px;
    // margin-bottom: 30px;
  }
  .yulanResume {
    width: 94px;
    height: 30px;
    border: 1px solid #1c9ab1;
    border-radius: 15px;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
  }
  .uploadWord {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
    margin: 20px 0;
  }
  .secondInput {
    width: 721px;
    height: 92px;
    padding: 20px;
    border: 1px solid #eeeeee;
    .upload-demo .file-icon {
      width: 50px;
    }
  }
  .firstLine {
    margin-top: 25px;
    align-items: flex-start !important;
    //     flex-direction: column;
    //   }
  }
}
</style>
