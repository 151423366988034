<template>
  <div class="publishJob contentWidth">
    <div class="startFlex jobHeadLine">
      <img :src="require('@as/zhaopin/laba.png')" alt="" />
      <span class="publishWord">发布职位</span>
    </div>
    <div class="wrapAllInputJob">
      <div class="detailInfoLine startFlex">
        <div class="commonFlex">
          <span class="leftTitle">职位名称</span>
          <span class="requireFlag">*</span>
        </div>
        <input
          type="text"
          class="detailInput"
          placeholder="如：人力资源经理，请勿超过30个字"
          maxlength="30"
          v-model="name"
        />
      </div>
      <div class="detailInfoLine startFlex">
        <div class="commonFlex">
          <span class="leftTitle">职位类别</span>
          <span class="requireFlag">*</span>
        </div>
        <!-- <input
          type="text"
          class="detailInput"
          placeholder="请输入职位类别"
          v-model="type"
        /> -->
        <el-cascader
          v-model="type"
          :options="typeList"
          :props="{
            expandTrigger: 'hover',
            label: 'value',
          }"
          style="width: 903px; margin-left: 23px"
        ></el-cascader>
      </div>
      <div class="detailInfoLine startFlex">
        <div class="commonFlex">
          <span class="leftTitle">工作性质</span>
          <span class="requireFlag">*</span>
        </div>
        <div class="commonFlex wrapXingzhi">
          <div
            v-for="(item, index) in xingzhiList"
            :key="index"
            class="xingzhiItem commonFlex canClick"
            @click="method = index"
            :class="{ selectedXingzhi: method == index }"
          >
            <img
              :src="require('@as/zhaopin/xingzhixuan.png')"
              alt=""
              class="xingzhiImg"
              v-if="method == index"
            />
            {{ item }}
          </div>
        </div>
      </div>
      <div class="detailInfoLine startFlex">
        <div class="commonFlex">
          <span class="leftTitle">工作地址</span>
          <span class="requireFlag">*</span>
        </div>
        <div class="commonFlex">
          <el-cascader
            v-model="area"
            placeholder="请选择"
            style="margin-left: 23px"
            :props="props"
          ></el-cascader>
          <!-- <el-select>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <div class="wrapImgMap startFlex">
            <input
              type="text"
              placeholder="请先选择省市，然后输入详细地址"
              class="provinceDist"
              v-model="detailAddress"
            />
            <img
              :src="require('@as/zhaopin/jobMap.png')"
              alt=""
              class="mapImg"
            />
          </div>
        </div>
      </div>
      <div class="detailInfoLine startFlex">
        <div class="commonFlex">
          <span class="leftTitle">月薪范围</span>
          <span class="requireFlag">*</span>
        </div>
        <div class="commonFlex">
          <el-select
            v-model="salary"
            placeholder="请选择月薪范围"
            style="margin-left: 23px; width: 347px"
          >
            <el-option
              v-for="item in salaryList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <!-- <div class="zidingyi canClick">自定义</div> -->
        </div>
        <div class="commonFlex">
          <span class="leftTitle" style="margin-left: 77px">学历要求</span>
          <span class="requireFlag">*</span>
        </div>
        <div class="commonFlex">
          <el-select
            v-model="education"
            placeholder="请选择最低学历"
            style="margin-left: 23px; width: 393px"
          >
            <el-option
              v-for="item in educationList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="detailInfoLine startFlex">
        <div class="commonFlex">
          <span class="leftTitle">工作年限</span>
          <span class="requireFlag">*</span>
        </div>
        <div class="commonFlex">
          <el-select
            v-model="year"
            placeholder="请选择工作年限"
            style="margin-left: 23px; width: 347px"
          >
            <el-option
              v-for="item in yearList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="commonFlex">
          <span class="leftTitle" style="margin-left: 77px">资格证书</span>
          <span class="requireFlag">*</span>
        </div>
        <div class="commonFlex">
          <el-select
            v-model="credentials"
            placeholder="请选择资格证书"
            style="margin-left: 23px; width: 393px"
          >
            <el-option
              v-for="item in credentialsList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="detailInfoLine">
        <div class="startFlex">
          <span class="leftTitle">职位描述</span>
          <span class="requireFlag">*</span>
          <span class="rule"
            >请勿输入公司邮箱、联系电话、外链，请不要包含性别歧视语</span
          >
          <span class="fabuguifan canClick">职位发布规范</span>
        </div>
        <div>
          <textarea class="jobDesp" v-model="describe"></textarea>
        </div>
      </div>
      <div class="detailInfoLine">
        <div class="startFlex">
          <span class="leftTitle">职位亮点</span>
          <span class="rule"
            >选择职位亮点，提升职位吸引力，有效增加职位投递！（最多可选择8个）</span
          >
        </div>
        <div class="startFlex wrapLiangdian commonBorder">
          <div
            v-for="(item, index) in selectedLiangdian"
            :key="index"
            class="liangdinaItem commonFlex canClick"
          >
            <div class="startFlex" @click="handlerRemoveLiangdian(item)">
              {{ item.name }} x
            </div>
          </div>
        </div>
        <div class="startFlex wrapAllLiangdian">
          <div
            v-for="(item, index) in liangdianList"
            :key="index"
            class="liangdianAll commonFlex canClick"
            @click="handlerSelectLiangdian(item)"
            :class="{ selectedliang: item.selected }"
          >
            {{ item.name }}
            <img
              :src="require('@as/zhaopin/xingzhixuan.png')"
              alt=""
              class="xingzhiImg"
              v-if="item.selected"
            />
          </div>
          <!-- <div class="zhankai canClick">
            <div>展开</div>
            <img :src="require('@as/zhaopin/icon_1.png')" alt="" />
          </div> -->
        </div>
      </div>
      <div class="detailInfoLine startFlex">
        <div class="commonFlex">
          <span class="leftTitle">发布时间</span>
          <span class="requireFlag">*</span>
        </div>
        <div class="startFlex">
          <input type="number" class="fabuTime" v-model="sendday" />
          <span class="timeDay">天</span>
        </div>
      </div>
      <div class="commonFlex" style="margin-top: 67px">
        <div class="saveJob commonFlex canClick" @click="handlerAddDone(1)">
          保存
        </div>
        <div class="lijifabu commonFlex canClick" @click="handlerAddDone(2)">
          立即发布
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Basic, Job } from '@api'
export default {
  data() {
    return {
      selectedLiangdian: [],
      method: 0,
      typeList: [],
      xingzhiList: ['全职', '兼职', '实习', '校园'],
      salaryList: [],
      educationList: [],
      yearList: [
        '不限',
        '在读',
        '应届',
        '1-3年',
        '3-5年',
        '5-10年',
        '10年以上',
      ],
      credentialsList: [
        '中国法律执业资格证',
        '专利代理人资格证',
        '证券从业资格证',
        '注册会计师',
        '企业法律顾问从业资格证',
        '香港执业资格',
      ],
      liangdianList: [],
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { data, level } = node
          const levelDir = {
            0: () => {
              Basic.getProvince().then((res) => {
                resolve(
                  res.map((item) => {
                    return {
                      value: item.province,
                      label: item.province,
                      id: item.provinceID,
                    }
                  })
                )
              })
            },
            1: (value) => {
              Basic.getCity({
                provinceID: value,
              }).then((res) => {
                resolve(
                  res.map((item) => {
                    return {
                      value: item.city,
                      label: item.city,
                      leaf: true,
                    }
                  })
                )
              })
            },
          }
          levelDir[level](data?.id)
        },
      },
      credentials: '',
      describe: '',
      education: '',
      name: '',
      salary: '',
      sendday: '',
      status: 1,
      type: '',
      year: '',
      area: '',
      detailAddress: '',
    }
  },
  created() {
    // 获取职业分类
    Job.getTypeList().then((res) => {
      this.typeList = res
    })
    // 获取职位亮点
    Job.getLiangdianList().then((res) => {
      this.liangdianList = res
    })
    // 获取薪资待遇
    Job.getSalaryList().then((res) => {
      this.salaryList = res
    })
    // 获取学历列表
    Job.getEducationList().then((res) => {
      this.educationList = res
    })
  },
  methods: {
    handlerSelectLiangdian(item) {
      if (item.selected) return
      else {
        if (this.selectedLiangdian.length === 8) return
        item.selected = true
        this.selectedLiangdian.push(item)
      }
    },
    handlerRemoveLiangdian(item) {
      const idx = this.selectedLiangdian.findIndex((e) => e.name === item.name)
      this.selectedLiangdian.splice(idx, 1)
      item.selected = false
    },
    handlerAddDone(status) {
      const data = {
        name: this.name,
        type: this.type[1],
        method: this.method,
        area: this.area,
        detailAddress: this.detailAddress,
        salary: this.salary,
        education: this.education,
        year: this.year,
        credentials: this.credentials,
        describe: this.describe,
        sendday: this.sendday,
        remark: this.selectedLiangdian.map((item) => item.name).join(','),
      }
      for (let i in data) {
        const checkResult = this.checkVal(i, data[i])
        if (!checkResult.pass) {
          this.$message.error(checkResult.msg + '不能为空')
          return
        }
      }
      if (this.$route.query.id) data.id = this.$route.query.id
      data.address = this.area.join('') + this.detailAddress
      data.status = status
      delete data.area
      delete data.detailAddress
      Job.changeJob(data)
    },
    checkVal(keys, val) {
      const checkDir = {
        name: '职位名称',
        type: '职位类别',
        area: '省市',
        detailAddress: '详细地址',
        salary: '薪资范围',
        education: '学历要求',
        year: '工作年限',
        credentials: '资格证书',
        describe: '职位描述',
        sendday: '发布时间',
      }
      if (!val) {
        return {
          pass: false,
          msg: checkDir[keys],
        }
      } else {
        return {
          pass: true,
        }
      }
    },
  },
}
</script>

<style lang="less">
.publishJob {
  width: 1200px;
  height: 1300px;
  background: white;
  margin-bottom: 65px;
  margin-top: -5px;
  .lijifabu {
    width: 132px;
    height: 34px;

    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
    background: #1c9ab1;
    margin-left: 31px;
  }
  .saveJob {
    width: 131px;
    height: 34px;
    border: 1px solid #dedede;

    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #808080;
  }
  .fabuTime {
    width: 218px;
    height: 41px;
    background: #ebf1f5;
    padding-left: 20px;
    margin-left: 23px;
  }
  .timeDay {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
    margin-left: -22px;
  }
  .zhankai {
    margin-left: 16px;
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
    img {
      margin-left: 6px;
      width: 13px;
      height: 7px;
    }
  }
  .wrapLiangdian {
    flex-wrap: wrap;
    height: 65px;
  }
  .wrapAllLiangdian {
    flex-wrap: wrap;
    padding-top: 12px;
  }
  .selectedliang {
    background: #ebf1f5 !important;
    border: 1px solid #8ccbd7 !important;
  }
  .liangdianAll {
    padding: 10px 20px;
    margin: 7px 7px 0;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    border: 1px solid #eeeeee;
    position: relative;
  }
  .liangdinaItem {
    padding: 10px 13px;
    background: #ebf1f5;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    margin-right: 7px;
  }
  .rule {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
    margin-left: 23px;
  }
  .jobDesp {
    width: 903px;
    height: 197px;
    border: 1px solid #f2f2f2;
    margin-left: 84px;
    margin-top: 28px;
  }
  .fabuguifan {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
    margin-left: 29px;
  }
  .zidingyi {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
    margin-left: 13px;
    margin-right: 28px;
  }
  .mapImg {
    margin-left: -21px;
  }
  .provinceDist {
    width: 675px;
    height: 41px;
    background: #ebf1f5;
    margin-left: 9px;
    padding-left: 16px;
  }
  .xingzhiImg {
    position: absolute;
    right: 0;
    bottom: -1px;
  }
  .el-input__inner {
    background: #ebf1f5;
    border-radius: 0 !important;
  }
  .selectedXingzhi {
    border: 1px solid #1c9ab1;
  }
  .xingzhiItem {
    width: 220px;
    height: 41px;
    background: #ebf1f5;
    margin-right: 9px;
    position: relative;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
  }
  .wrapAllInputJob {
    padding: 0px 105px 60px 105px;
  }
  //
  .jobHeadLine {
    padding-left: 109px;
    height: 97px;
    border-bottom: 2px solid #f7f9f9;
  }
  .detailInfoLine {
    margin-top: 38px;
  }
  .wrapXingzhi {
    margin-left: 21px;
  }
  .xingzhiItem:last-child {
    margin-right: 0 !important;
  }
  .detailInput {
    padding-left: 21px;
    margin-left: 23px;
  }
  ::-webkit-input-placeholder {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
  }
  .fabuLine {
    width: 1200px;
    height: 2px;
    background: #dedede;
    margin-top: 40px;
  }
  .leftTitle {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .publishWord {
    margin-left: 9px;

    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
  }
  .requireFlag {
    color: #c40012;
    margin-left: 3px;
    margin-bottom: 2px;
  }
  .detailInput {
    width: 903px;
    height: 41px;
    background: #ebf1f5;
  }
}
</style>
