// 生产 axios 请求实例，默认请求格式 form-urlencoded
// 如果不知道代码具体含义，不建议直接修改
import axios from "axios";
import Qs from "qs";
import { Message } from "element-ui";
import isPlainObject from "lodash/isPlainObject";
import isSame from "./looseEqual";

/**
 * 错误提示信息
 * @param {string} message 错误信息
 * @param {function?} cb 错误回调函数
 * @param {string?} type waring 错误类型
 */
export const tip = (message, cb, type = "error") => {
  Message({
    message,
    type,
    duration: 3000,
    onClose() {
      cb && cb();
    },
  });
};
/**
 * 请求失败处理
 * @param {number?} status 错误状态码
 */
export const errorHandle = (status) => {
  // 状态码判断
  switch (Number(status)) {
    // 401: 未登录状态，跳转登录页
    case 401:
      tip("您尚未登录，请先登录");
      break;
    // 403 token过期
    case 403:
      tip("登录过期，请重新登录");
      break;
    // 404请求不存在
    case 404:
      tip("请求的资源不存在");
      break;
    case 405:
      tip("Method Not Allowed");
      break;
    case 500:
      tip("服务器错误！");
      break;
    default:
      tip(status);
      break;
  }
};
const { CancelToken } = axios;

export default class AxiosInstance {
  /**
   * @param {Object} [options] 请求参数
   * @param {string}[options.baseURL] 请求URL
   * @param {number} [options.timeout] 超时时间
   * @param {boolean | 'sameUrl' | 'sameAll'} [options.cancelSameRequest] 是否删除相同请求
   * @param {function} [options.interceptorsRequest] 请求拦截器
   * @param {function} [options.interceptorsResponse] 响应拦截 返回Promise实例
   */
  constructor(options) {
    const defaultOption = {
      baseURL: "",
      timeout: 300000,
      headers: {},
      cancelSameRequest: false, // 同时发起相同请求，是否取消前一个
      interceptorsRequest() {},
      interceptorsResponse(data) {
        return data;
      },
    };
    this.currentRequest = {};
    this.options = Object.assign(defaultOption, options);
    this.instance = axios.create({
      baseURL: this.options.baseURL,
      timeout: this.options.timeout,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        ...this.options.headers,
      },
    });
    this._interceptorsRequest();
    this._interceptorsResponse();
  }

  // 请求拦截器
  _interceptorsRequest() {
    this.instance.interceptors.request.use(
      async (config) => {
        await this.options.interceptorsRequest(config);
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  // 响应拦截
  _interceptorsResponse() {
    this.instance.interceptors.response.use(
      (response) => {
        this._removeCancel(response.config); // 请求成功，清除缓存
        if (response.status.toString()[0] !== "2") {
          // errorHandle(response.data?.message);
          throw new Error(response.data?.message);
          return Promise.reject(response);
        } else {
          const { data } = response;
          return this.options.interceptorsResponse(data, response);
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
          console.warn("Request canceled");
        } else if (error) {
          this._removeCancel(error.config);
          const { response, message } = error;
          if (response.data.constructor !== Blob) {
            if (response) {
              // errorHandle(response.data?.message);
              throw new Error(response.data?.message);
            } else {
              message && console.warn(message);
            }
          }
        } else {
          // errorHandle();
          throw new Error();
        }
        return Promise.reject(error);
      }
    );
  }

  /**
   * 封装 axios 请求
   * @param {object} obj 请求
   * @return {Promise<any>}
   * @constructor
   */
  request(obj) {
    const DEFAULT = {
      method: "get",
      url: "",
      headers: {},
      type: "json",
    };
    const req = Object.assign(DEFAULT, obj);
    this._setCancel(req);
    if (req.method === "post") {
      if (req.type === "json") {
        req.headers["Content-Type"] = "application/json;charset=UTF-8";
      } else if (req.type === "form-data") {
        req.headers["Content-Type"] =
          "multipart/form-data; boundary=FormBoundary";
      } else {
        req.data = Qs.stringify(req.data);
      }
    }
    Reflect.deleteProperty(req, "type");
    return this.instance(req);
  }

  // 配置取消请求规则
  _setCancel(req) {
    if (req.cancelToken) return; // 如果请求自己添加了cancelToken 不再进行处理
    if (req.headers["Content-Type"] === "multipart/form-data") return; // 如果是表单数据类型，不进行比较 -- 常用于文件上传
    let cancelSameRequest;
    if (req.cancelSameRequest === undefined) {
      // eslint-disable-next-line prefer-destructuring
      cancelSameRequest = this.options.cancelSameRequest;
    } else if (req.cancelSameRequest) {
      // eslint-disable-next-line prefer-destructuring
      cancelSameRequest = req.cancelSameRequest;
    } else {
      return;
    }
    if (!cancelSameRequest) return;

    if (typeof cancelSameRequest !== "string") {
      cancelSameRequest = !!cancelSameRequest;
    }
    // 到此处 cancelSameRequest 可能值只能为 true string
    // true 与 非 sameAll 值 全部处理成 sameUrl
    if (cancelSameRequest === true) {
      cancelSameRequest = "sameUrl";
    } else if (cancelSameRequest.toLowerCase() === "sameurl") {
      cancelSameRequest = "sameUrl";
    } else if (cancelSameRequest.toLowerCase() === "sameall") {
      cancelSameRequest = "sameAll";
    } else {
      cancelSameRequest = "sameUrl";
    }
    const currentSource = CancelToken.source();
    if (cancelSameRequest === "sameUrl") {
      const key = `${req.url}sameUrl`;
      if (this.currentRequest[key]) {
        this.currentRequest[key].cancel("Request canceled");
      }
      this.currentRequest[key] = currentSource;
      req.cancelToken = currentSource.token;
      req.currentCacheKey = key; // 配置key, 用于成功能清除缓存
    } else if (cancelSameRequest === "sameAll") {
      const key = `${req.url}sameAll`;
      const old = this.currentRequest[key];
      const curData = AxiosInstance._getReqData(req);
      if (Array.isArray(old)) {
        for (let i = 0; i < old.length; i++) {
          const t = old[i];
          if (isSame(curData, t.data)) {
            t.source.cancel("Request canceled");
            t.source = currentSource;
            req.cancelToken = currentSource.token;
            return;
          }
        }
        old.push({
          source: currentSource,
          data: curData,
        });
        req.cancelToken = currentSource.token;
      } else {
        // 不存在请求队列，创建并加入队列
        this.currentRequest[key] = [
          {
            source: currentSource,
            data: curData,
          },
        ];
        req.cancelToken = currentSource.token;
      }
      req.currentCacheKey = key; // 配置key, 用于成功能清除缓存
    }
  }

  _removeCancel(config) {
    if (!config) return;
    const { cancelToken, currentCacheKey } = config;
    if (!currentCacheKey) return;
    const cacheValue = this.currentRequest[currentCacheKey];
    if (Array.isArray(cacheValue)) {
      for (let i = 0; i < cacheValue.length; i++) {
        if (cacheValue[i].source.token === cancelToken) {
          if (cacheValue.length === 1) {
            Reflect.deleteProperty(this.currentRequest, currentCacheKey);
          } else {
            cacheValue.splice(i, 1);
          }
          return;
        }
      }
    } else if (isPlainObject(cacheValue)) {
      if (this.currentRequest[currentCacheKey].token === cancelToken) {
        Reflect.deleteProperty(this.currentRequest, currentCacheKey);
      }
    }
  }

  static _getReqData(req) {
    if (req.method.toLowerCase() === "get") {
      try {
        return JSON.parse(JSON.stringify(req.params));
      } catch (e) {
        console.error(e);
      }
    } else if (req.method.toLowerCase() === "post") {
      try {
        return JSON.parse(JSON.stringify(req.data));
      } catch (e) {
        console.error(e);
      }
    }
  }

  /**
   * get 请求
   * @param {string | object} url 请求路径
   * @param {object?} params 请求参数
   * @return {Promise<any>}
   */
  get(url, params) {
    let obj;
    if (isPlainObject(url)) {
      obj = `${url}?${Qs.stringify(params)}`;
      obj.method = "get";
    } else {
      obj = {
        method: "get",
        url: `${url}?${Qs.stringify(params)}`,
      };
    }
    return this.request(obj);
  }

  /**
   * post 请求
   * @param {string} url
   * @param {Object?} data
   * @param {string?} type 数据类型
   * @return {Promise<any>}
   */
  post(url, data, type = "json") {
    let obj;
    if (isPlainObject(url)) {
      obj = url;
      obj.method = "post";
    } else {
      obj = {
        method: "post",
        url,
        data,
        type,
      };
    }
    return this.request(obj);
  }

  /**
   * put 请求
   * @param {string} url
   * @param {Object?} data
   * @param {string?} type 数据类型
   * @return {Promise<any>}
   */
  put(url, data) {
    let obj;
    if (isPlainObject(url)) {
      obj = url;
      obj.method = "put";
    } else {
      obj = {
        method: "put",
        url,
        data,
      };
    }
    obj.headers = {
      "Content-Type": "multipart/form-data; boundary=FormBoundary",
    };
    return this.request(obj);
  }

  /**
   * 文件下载
   * @param {string} url
   * @return {Promise<any>}
   */
  download(url) {
    let obj;
    if (isPlainObject(url)) {
      obj = url;
      obj.method = "get";
    } else {
      obj = {
        method: "get",
        url,
      };
    }
    obj.responseType = "blob";
    return this.request(obj);
  }
}
