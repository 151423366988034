<template>
  <div class="zhuanlan contentWidth">
    <sidebar></sidebar>
    <div class="midContent">
      <img
        :src="require('@as/reading/guanchaMid.png')"
        alt=""
        class="topImg"
        style="width: 1070px"
      />
      <div class="zhuanlanSelect betweenFlex commonBorder">
        <div class="left betweenFlex">
          <div
            v-for="(item, index) in options"
            :key="index"
            class="leftItem commonFlex"
            :class="{ selected: activeIndex == index }"
            @click="changeIndex(index)"
          >
            {{ item }}
          </div>
        </div>
        <div class="right betweenFlex">
          <img
            :src="require('@as/reading/questioin.png')"
            alt=""
            style="margin-right: 10px"
          />
          <div>如何创建专栏</div>
        </div>
      </div>
      <ul class="onlyFlex">
        <li
          v-for="(item, index) in articleList"
          :key="index"
          class="articleItem borderContent"
          :class="{ noDistance: (index + 1) % 4 == 0 }"
          @click="toDetail()"
        >
          <div class="wrapImg">
            <img
              :src="require('@as/reading/listImg.png')"
              alt=""
              class="listImg"
            />
            <div class="wrapPersonImg commonFlex">
              <img :src="require('@as/reading/personFlag.png')" />
            </div>
          </div>

          <div class="articleRight">
            <div class="betweenFlex">
              <div class="title">{{ item.title }}</div>
              <div class="dingyue commonFlex">订阅</div>
            </div>
            <div class="desp">{{ item.desp }}</div>
            <div class="wenzhangCount">
              {{ item.wenzhangCount }}篇文章 | {{ item.dingyueCount }}人订阅
            </div>
          </div>
        </li>
      </ul>
      <div class="lookmore">查看更多</div>
    </div>
  </div>
</template>

<script>
import sidebar from '@c/reading/sidebar'
export default {
  data() {
    return {
      activeIndex: 0,
      options: ['全部', '组织机构', '团队个人'],
      articleList: [
        {
          title: '从黑石集团与',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          wenzhangCount: 20,
          dingyueCount: 123,
        },
        {
          title: '从黑石集团与',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          wenzhangCount: 20,
          dingyueCount: 123,
        },
        {
          title: '从黑石集团与',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          wenzhangCount: 20,
          dingyueCount: 123,
        },
        {
          title: '从黑石集团与',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          wenzhangCount: 20,
          dingyueCount: 123,
        },
        {
          title: '从黑石集团与',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          wenzhangCount: 20,
          dingyueCount: 123,
        },
        {
          title: '从黑石集团与',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          wenzhangCount: 20,
          dingyueCount: 123,
        },

        {
          title: '从黑石集团与',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          wenzhangCount: 20,
          dingyueCount: 123,
        },
        {
          title: '从黑石集团与',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          wenzhangCount: 20,
          dingyueCount: 123,
        },
        {
          title: '从黑石集团与',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          wenzhangCount: 20,
          dingyueCount: 123,
        },
        {
          title: '从黑石集团与',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          wenzhangCount: 20,
          dingyueCount: 123,
        },
        {
          title: '从黑石集团与',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          wenzhangCount: 20,
          dingyueCount: 123,
        },
      ],
    }
  },
  components: {
    sidebar,
  },
  methods: {
    changeIndex(index) {
      this.activeIndex = index
    },
    toDetail() {
      this.$router.push({ name: 'zhuanlanIndex' })
    },
  },
  created() {
    console.log('首页')
  },
}
</script>
<style lang="less">
.zhuanlan {
  display: flex;
  justify-content: space-between;

  .midContent {
    width: 1070px;
    .zhuanlanSelect {
      // height: 66px;

      width: 1070px;
      .right {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: hsl(0, 0%, 60%);
        // width: 119px;
        &:hover {
          cursor: pointer;
        }
      }
      .left {
        width: 292px;
        .selected {
          border-bottom: 2px solid #1c99b0 !important;
        }
        .leftItem {
          cursor: pointer;
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
          border-bottom: 2px solid transparent;
          // height: 100%;
          padding: 14px 0;
          width: 64px;
        }
        .selected {
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #1c99b0;
          border-bottom: 2px solid #1c99b0;
        }
      }
    }
    .topImg {
      width: 1070px;
      height: 200px;
      background: #cacedf;
    }
    .lookmore {
      width: 1070px;
    }
    .noDistance {
      margin-right: 0 !important;
    }
    .articleItem:hover {
      background: #ffffff;
      border: 1px solid #e5e8ed;
      box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
      opacity: 0.98;
    }
    .articleItem {
      cursor: pointer;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      width: 260px;
      // height: 150px;
      background: rgba(255, 255, 255, 0.4);
      border: 1px solid #dedede;
      // opacity: 0.4;
      margin-top: 10px;
      margin-right: 10px;
      .wrapImg {
        width: 40px;
        height: 40px;
        position: relative;
        .wrapPersonImg {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 14px;
          height: 14px;
          background: #ffffff;
          border: 1px solid #dedede;
          border-radius: 50%;
        }
      }
      .listImg {
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #dedede;
        border-radius: 50%;
      }
      .articleRight {
        margin-left: 12px;
        .wenzhangCount {
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #999999;
          margin-top: 20px;
        }
        .title {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #4a4b4f;
        }
        .desp {
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
          margin-top: 14px;
        }
        .dingyue {
          width: 40px;
          height: 20px;
          border: 1px solid #1c9ab1;
          cursor: pointer;
          font-size: 10px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #1c9ab1;
          justify-content: center !important;
        }
        .articleRightBot {
          margin-top: 35px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          div {
            display: flex;
            align-items: center;
          }
          span {
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
            margin-right: 5px;
          }
          .signItem {
            padding: 2px 5px;

            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
            // background: #A6A7A7;
          }
        }
      }
    }
  }
  .rightContent {
    width: 330px;
  }
}
</style>