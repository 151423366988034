<template>
  <div class="wendaManage">
    <div class="top betweenFlex commonBorder">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="64"
        :height="56"
        size="16"
        :distance="44"
      />
      <div class="xiewenzhang commonFlex canClick">提问题</div>
    </div>
    <ul style="padding: 0 20px">
      <li
        v-for="item in questionList"
        class="questionItem commonBorder"
        :key="item"
      >
        <div class="questionTop">
          <div class="countNum commonFlex">
            <span class="huidaCount">{{ item.count }}</span>
            <span class="huidaWord">回答</span>
          </div>
          <div class="questionRight">
            <div class="title">{{ item.title }}</div>
            <div class="wrapDetail betweenFlex">
              <div class="detailLeft betweenFlex">
                <div
                  v-for="signItem in item.sign"
                  :key="signItem"
                  class="signItem commonFlex"
                >
                  {{ signItem }}
                </div>
                <div class="wrapTime commonFlex">
                  <img :src="require('@as/reading/yanjing.png')" alt="" />
                  <span class="time">{{ item.time }}</span>
                </div>
              </div>
              <div class="detailright betweenFlex">
                <div class="commonFlex">
                  <img :src="require('@as/reading/laiAnswer.png')" alt="" />
                  <span class="laiAnswer">我来回答</span>
                </div>
                <div class="commonFlex">
                  <img :src="require('@as/reading/guanzhuQues.png')" alt="" />
                  <span class="guanzhuQuestion">关注问题</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="questionBot">
          <!-- <span class="questionBotAns">{{ item.answer }}:</span> -->
          <span class="questionBotDesp">{{ item.answerDesp }}</span>
        </div>
      </li>
      <div class="commonFlex" style="height: 150px">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
  </div>
</template>

<script>
import headerChoice from '../headerChoice'
export default {
  data() {
    return {
      activeIndex: 0,
      choiceList: ['我的提问', '我的回答'],
      articleLlist: [
        '好哈哈哈哈哈哈',
        '好哈哈哈哈哈哈',
        '好哈哈哈哈哈哈',
        '好哈哈哈哈哈哈',
      ],
      questionList: [
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复',
        },
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复',
        },
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复',
        },
      ],
    }
  },
  methods: {
    change(index) {
      this.activeIndex = index
    },
  },
  components: {
    headerChoice,
  },
}
</script>

<style lang="less">
.wendaManage {
  .selected {
    border-bottom: 2px solid #0899ca !important;
  }
  .questionItem:hover {
    background: linear-gradient(90deg, #ffffff 0%, #ecfaff 50%, #ffffff 100%);
  }
  .questionItem {
    cursor: pointer;
    padding: 23px 0;
    .questionBot {
      width: 940px;
      //height: 70px;
      margin-top: 21px;

      background: #f5f5f7;
      padding: 15px 24px;
      box-sizing: border-box;
      .questionBotAns {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
      .questionBotDesp {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #808080;
      }
    }
    .questionTop {
      display: flex;
      .questionRight {
        margin-left: 16px;
        .guanzhuImg {
          margin-right: 3px;
        }
        .laiAnswer {
          margin-right: 25px;
          margin-left: 7px;

          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #babbbb;
        }
        .guanzhuQuestion {
          margin-left: 7px;
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #babbbb;
        }
        .huidaImg {
          margin-right: 3px;
        }
        .detailLeft {
          display: flex;
          .signItem {
            margin-right: 8px;
            width: 52px;
            height: 19px;
            background: #e9eced;

            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #999999;
          }
          .wrapTime {
            margin-left: 48px;
            span {
              margin-left: 2px;

              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #afafaf;
            }
          }
        }
        .wrapDetail {
          width: 875px;
          margin-top: 9px;
        }
        .title {
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 500;
          color: #333333;
        }
      }
      .countNum {
        flex-direction: column;
        width: 44px;
        height: 44px;
        background: rgba(28, 154, 177, 0.1);
        .huidaCount {
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #1c99b0;
        }
        .huidaWord {
          margin-top: 2px;

          font-size: 10px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #1c99b0;
        }
      }
    }
  }
  .wrapRightTwobtn {
    display: flex;
    justify-content: flex-end;
    width: 355px;
  }
  .wrapWhiteCircle {
    width: 36px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 50%;
  }
  .articleItem {
    height: 132px;
  }
  .articleIMG {
    width: 144px;
    height: 90px;
  }
  .wrapBotBtn {
    margin-right: 30px;
  }
  .btnCount {
    margin-left: 7px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .wenzhangName {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .allchoiceBtn {
    margin-top: 37px;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .count {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .xiewenzhang {
    width: 80px;
    height: 30px;
    background: #4395cd;
    border-radius: 2px;
    margin-left: 30px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .top {
    padding: 0 20px;
  }
}
</style>