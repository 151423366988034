<template>
  <ul>
    <li
      v-for="(item, index) in articleList"
      :key="index"
      class="articleItem canClick"
      @mouseover="item.articleHover = true"
      @mouseleave="item.articleHover = false"
    >
      <div class="tag">案例分析</div>
      <img
        :src="require('@as/reading/listImg.png')"
        alt=""
        style="width: 218px; height: 136px"
      />
      <div class="articleRight">
        <div class="title" :class="{ currentHover: item.articleHover }">
          {{ item.title }}
        </div>
        <div class="desp">{{ item.desp }}</div>
        <div class="articleRightBot">
          <div>
            <img
              :src="require('@as/reading/company.png')"
              alt=""
              style="margin-right: 4px; border-radius: 50%"
            />
            <span>{{ item.company }}</span>
            <img
              :src="require('@as/reading/person.png')"
              alt=""
              style="margin-left: 14px; margin-right: 8px"
            />
            <span v-for="authorItem in item.author" :key="authorItem">{{
              authorItem
            }}</span>
            <div style="margin-left: 16px">
              <span
                v-for="signItem in item.sign"
                :key="signItem"
                class="signItem"
                >{{ signItem }}</span
              >
            </div>
          </div>
          <div>
            <img :src="require('@as/reading/time.png')" alt="" />
            <span style="margin-left: 4px">{{ item.time }}</span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      articleList: [
        {
          title: '从黑石集团与Alnylam 20亿美元交易案例深度评析药',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
          articleHover: false,
        },
        {
          title: '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
          articleHover: false,
        },
        {
          title: '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
          articleHover: false,
        },
        {
          title: '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
          articleHover: false,
        },
        {
          title: '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
          articleHover: false,
        },
      ],
    }
  },
}
</script>

<style lang="less">
.articleItem {
  // height: 136px;
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid #dedede;
  display: flex;
  // justify-content: space-between;
  &:last-child {
    border-bottom: none;
  }
  .tag {
    position: absolute;
    top: 26px;
    left: 3px;
    padding: 3px 7px;
    background: rgba(28, 154, 177, 0.5);
    color: #fff;
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
  }
  .currentHover {
    color: #1c9ab1 !important;
  }
  .title:hover {
    color: #1c9ab1 !important;
  }
  .articleRight {
    margin-left: 24px;
    .title {
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #595757;
      cursor: pointer;
      line-height: 28px;
    }
    .desp {
      font-size: 13px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #a6a7a7;
      margin-top: 17px;
    }
    .articleRightBot {
      margin-top: 54px;
      display: flex;
      width: 560px;
      align-items: center;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
      }
      span {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        margin-right: 5px;
      }
      .signItem {
        padding: 2px 5px;
        background: #e9eced;
        font-size: 10px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        // background: #A6A7A7;
      }
    }
  }
}
</style>