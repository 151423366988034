<template>
  <div class="mainGuanzhu">
    <div class="betweenFlex">
      <div></div>
      <ul class="startFlex">
        <li
          v-for="(item, index) in guanzhuFlagList"
          :key="index"
          :class="{ selectedGuanzhu: activeIndex == index }"
          class="guanzhuItem commonFlex"
          @click="activeIndex = index"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <ul style="padding: 20px">
      <li
        v-for="(item, index) in guanzhuList"
        :key="index"
        class="guanzhuListItem betweenFlex commonBorder"
      >
        <div class="startFlex">
          <img
            :src="require('@as/reading/test.png')"
            alt=""
            class="guanzhuImg"
          />
          <div style="margin-left: 15px">
            <div class="name">{{ item.name }}</div>
            <div class="guanzhuDetail">19篇文章 · 123 问答 · 1234活动</div>
          </div>
        </div>
        <div class="guanzhu commonFlex">关注</div>
      </li>
    </ul>
    <div class="wrapPage">
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      guanzhuFlagList: ['关注', '粉丝', '订阅的专栏', '关注的标签'],
      guanzhuList: [
        { name: '去瞧瞧瞧瞧瞧瞧瞧瞧' },
        { name: '去瞧瞧瞧瞧瞧瞧瞧瞧' },
        { name: '去瞧瞧瞧瞧瞧瞧瞧瞧' },
      ],
    }
  },
}
</script>

<style lang="less">
.mainGuanzhu {
  .name {
    font-size: 20px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .guanzhuListItem {
    padding: 20px 0;
  }
  .wrapPage {
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .guanzhu {
    width: 71px;
    height: 30px;
    border: 1px solid #089acb;
    cursor: pointer;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #089acb;
  }
  .guanzhuImg {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }
  .guanzhuDetail {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 500;
    color: #999999;
    margin-top: 15px;
  }
  .selectedGuanzhu {
    color: #1c9ab1 !important;

    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    // color: #666666;
  }
  .guanzhuItem {
    cursor: pointer;
    margin-right: 30px;
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
    height: 50px;
  }
}
</style>