<template>
  <div class="zhuanti contentWidth">
    <sidebar></sidebar>
    <div class="midContent">
      <img
        :src="require('@as/reading/guanchaMid.png')"
        alt=""
        style="width: 1070px; height: 200px"
      />
      <div class="topSelect commonBorder">
        <div class="betweenFlex" style="width: 285px; margin: 0 auto">
          <div
            :class="{ selected: jijin }"
            class="selectKind commonFlex"
            @click="selectJijin"
          >
            最新集锦
          </div>
          <div
            :class="{ selected: zhuanti }"
            class="selectKind commonFlex"
            @click="selectZhuanti"
          >
            最热专题
          </div>
        </div>
      </div>
      <ul class="wrapZhuantiitem">
        <li
          v-for="(item, index) in articleList"
          :key="index"
          class="articleItemZhuanti"
          @click="toDetail()"
        >
          <div style="position: relative">
            <img
              :src="require('@as/reading/test.png')"
              alt=""
              style="width: 100%; height: 150px"
            />
            <div class="imgBot betweenFlex">
              <div>{{ item.readCount }}人看过</div>
              <div>{{ item.articleCount }}篇文章</div>
            </div>
          </div>
          <div class="title">交易案例深度评析</div>
          <div class="desp">
            债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治纠纷解决
          </div>
          <div class="lookZhuanti commonFlex">查看专题</div>
        </li>
      </ul>
      <div class="lookMore commonFlex">查看更多</div>
    </div>
  </div>
</template>

<script>
import sidebar from '@c/reading/sidebar'
export default {
  data() {
    return {
      activeIndex: 1,
      jijin: false,
      zhuanti: true,
      articleList: [
        {
          readCount: 11123,
          articleCount: 123,
          title: '交易案例深度评析',
          desp:
            '债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治纠纷解决',
        },
        {
          readCount: 11123,
          articleCount: 123,
          title: '交易案例深度评析',
          desp:
            '债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治纠纷解决',
        },
        {
          readCount: 11123,
          articleCount: 123,
          title: '交易案例深度评析',
          desp:
            '债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治纠纷解决',
        },
        {
          readCount: 11123,
          articleCount: 123,
          title: '交易案例深度评析',
          desp:
            '债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治纠纷解决',
        },
        {
          readCount: 11123,
          articleCount: 123,
          title: '交易案例深度评析',
          desp:
            '债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治纠纷解决',
        },
        {
          readCount: 11123,
          articleCount: 123,
          title: '交易案例深度评析',
          desp:
            '债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治纠纷解决',
        },
        {
          readCount: 11123,
          articleCount: 123,
          title: '交易案例深度评析',
          desp:
            '债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治纠纷解决',
        },
        {
          readCount: 11123,
          articleCount: 123,
          title: '交易案例深度评析',
          desp:
            '债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治纠纷解决',
        },
      ],
    }
  },
  components: {
    sidebar,
  },
  created() {
    console.log('首页')
  },
  methods: {
    selectJijin() {
      this.jijin = true
      this.zhuanti = false
    },
    selectZhuanti() {
      this.jijin = false
      this.zhuanti = true
    },
    toDetail() {
      this.$router.push({ name: 'zhuantiDetail' })
    },
  },
}
</script>
<style lang="less">
.zhuanti {
  display: flex;
  .midContent {
    width: 1070px;
    margin-left: 20px;
    .lookMore {
      width: 1066px;
      height: 44px;
      background: #ebecec;
      margin-top: 50px;
      margin-bottom: 50px;
      cursor: pointer;
    }
    .wrapZhuantiitem {
      // margin-top: 25px;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      width: 1116px;
    }
    .articleItemZhuanti:hover {
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
    }
    .articleItemZhuanti {
      width: 330px;
      height: 340px;
      background: #ffffff;
      border: 1px solid #f1f2f2;
      margin-top: 30px;
      margin-right: 40px;
      cursor: pointer;

      .title {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        padding-left: 17px;
        border-left: 4px solid #1c9ab1;
        margin-top: 20px;
      }
      .desp {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #a8a9a9;
        margin-top: 14px;
        padding-left: 20px;

        line-height: 24px;
      }
      .lookZhuanti {
        cursor: pointer;
        width: 92px;
        height: 30px;
        border: 1px solid #dedede;
        margin: 0 auto;
        margin-top: 24px;
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .imgBot {
        position: absolute;
        bottom: 11px;
        padding: 0 20px;
        width: 100%;
        box-sizing: border-box;

        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .topSelect {
      .selectKind {
        padding: 15px 0;
        height: 60px;
        border-bottom: 2px solid transparent;

        cursor: pointer;
      }
      div {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      .selected {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c99b0;
        border-bottom: 2px solid #1c99b0 !important;
      }
    }
    .lookmore {
      cursor: pointer;
      width: 718px;
      height: 41px;
      margin-bottom: 37px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 17px;
      background: #afafaf;
    }
    .articleItem {
      height: 136px;
      padding: 20px 0;
      border-bottom: 2px solid #dedede;
      display: flex;
      justify-content: space-between;
      &:last-child {
        border-bottom: none;
      }
      .articleRight {
        margin-left: 24px;
        .title {
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 500;
          color: #1c9ab1;

          line-height: 28px;
        }
        .desp {
          font-size: 13px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #a6a7a7;
          margin-top: 17px;
        }
        .articleRightBot {
          margin-top: 35px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          div {
            display: flex;
            align-items: center;
          }
          span {
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
            margin-right: 5px;
          }
          .signItem {
            padding: 2px 5px;

            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
            // background: #A6A7A7;
          }
        }
      }
    }
  }
  .rightContent {
    width: 330px;
  }
}
</style>