<template>
  <div class="addInfo">
    <div class="betweenFlex firstLine">
      <div class="name">请填写</div>
      <textarea
        type="text"
        class="projectName secondInput"
        placeholder="如：获奖项、技能资格证书、专利等，填写文字1000字以内"
        maxlength="1000"
        v-model="desc"
      />
    </div>
    <div class="stillInput">你还可以输入{{ 1000 - desc.length }}字</div>
    <div class="firstLine botTwoChoice">
      <botChoice @confirm="handlerConfirm" @cancel="handlerCancel" />
    </div>
  </div>
</template>

<script>
import botChoice from './botChoice'
import { Resume } from '@api'
export default {
  props: ['id'],
  data() {
    return {
      desc: '',
    }
  },
  components: {
    botChoice,
  },
  watch: {
    id: {
      handler(val) {
        if (!val) return
        Resume.getOther({ userId: this.$store.state.uid }).then((res) => {
          Object.assign(this, { ...res })
        })
      },
      immediate: true,
    },
  },
  methods: {
    handlerConfirm() {
      const data = {
        desc: this.desc,
      }
      if (this.id) data.id = this.id
      data.userId = this.$store.state.uid
      Resume.changeOther(data)
    },
    handlerCancel() {},
  },
}
</script>

<style lang="less">
.addInfo {
  padding-left: 20px;
  padding-bottom: 40px;
  flex-direction: column;
  .botTwoChoice {
    padding-left: 140px;
    margin-bottom: 30px;
  }
  .stillInput {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .firstLine {
    margin-top: 25px;
    align-items: flex-start !important;
    //     flex-direction: column;
    //   }
  }
  .secondInput {
    width: 721px;
    height: 92px;
    padding: 12px;
    border: 1px solid #eeeeee;
  }
  .name {
    height: 35px;
    line-height: 35px;
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
}
</style>
