<template>
  <div class="biaoqianList">
    <div class="top">
      <!-- <img src="../../assets/reading/test.png" alt="" class="topImg"> -->
      <div class="wrapContent contentWidth">
        <div class="biaoqianList">
          <span class="Jing">#</span>
          <span class="biaoqian">融资并购</span>
        </div>
        <div class="biaoqianDesp">
          拥有可信赖的 DDoS 防护体系，可为不同行业提供多种安全解决方案
        </div>
        <div class="guanzhu commonFlex">关注</div>
      </div>
    </div>
    <div class="contentWidth betweenFlex wrapBiaoMid">
      <ul class="biaoItemLeft">
        <div class="commonBorder">
          <div class="wrapRealItem startFlex">
            <div
              v-for="(item, index) in choice"
              :key="index"
              :class="{ selected: activeIndex == index }"
              @click="activeIndex = index"
              class="choiceItem commonFlex"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="wrapBiaoqianArticle">
          <articleList></articleList>
        </div>

        <div class="lookmore">查看更多</div>
      </ul>
      <div style="margin-top: 20px">
        <tuijianZhuanlanVue></tuijianZhuanlanVue>
        <hotSign />
      </div>
    </div>
  </div>
</template>

<script>
import tuijianZhuanlanVue from '@c/reading/tuijianZhuanlan.vue'
import hotSign from '@c/reading/hotSign.vue'
import articleList from '@c/reading/articleList'
export default {
  data() {
    return {
      choice: ['文章', '问答', '活动'],
      activeIndex: 0,
      articleList: [
        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
      ],
      list: [
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },

        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },

        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
      ],
    }
  },
  components: {
    tuijianZhuanlanVue,
    hotSign,
    articleList,
  },
  methods: {
    toDetail() {
      this.$router.push({ name: 'contentDetail' })
    },
  },
}
</script>

<style lang="less">
.biaoqianList {
  .wrapBiaoqianArticle {
    padding: 0 20px;
  }
  .wrapBiaoMid {
    align-items: flex-start !important;
    margin-bottom: 40px;
  }
  .lookmore {
    width: 810px;
    height: 41px;
    margin-bottom: 20px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 17px;
    background: #ebecec;
  }

  .guanzhu {
    width: 80px;
    height: 30px;
    background: #1c9bb2;
    margin-top: 36px;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 500;
    color: #ffffff;
  }
  .jing {
    font-size: 24px;
    font-family: PingFangSC;
    font-weight: 500;
    color: #1c9bb2;
  }
  .biaoqian {
    font-size: 24px;
    font-family: PingFangSC;
    font-weight: 500;
    color: #ffffff;
  }
  .biaoItemLeft {
    background: white;
    // padding: 0 20px;
    margin-top: 20px;
    .wrapRealItem {
      // width: 294px;
      padding-left: 20px;
      div {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
    }
    .choiceItem {
      height: 57px;
      width: 64px;
      margin-right: 54px;
    }
  }
  .top {
    background: lightgray;
    margin-top: -20px;

    // background: url("../../assets/reading/test.png") no-repeat;
    // background-size: 100% 280px;
    width: 100%;
    height: 280px;
    .topImg {
      width: 100%;
      height: 100%;
    }
    .wrapContent {
      flex-direction: column;
      padding-top: 70px;
      padding-left: 50px;
      display: flex;
      justify-content: flex-start !important;
      .biaoqianList {
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #ffffff;
      }
      .biaoqianDesp {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #999999;
        margin-top: 27px;
      }
    }
  }
}
</style>