<template>
  <div class="hotSign">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <img :src="require('@as/reading/hotSign.png')" alt="" />
        <span class="hotArticleWord">热门标签</span>
      </div>
      <span class="moreWord">MORE</span>
    </div>
    <div class="wrapSign">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="signItem commonFlex"
        :class="{
          selectedSign: item.id == 2,
          noDistance: (index + 1) % 3 == 0,
        }"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isActive: false,
      list: [
        { name: '婚姻法', id: 2 },
        { name: '婚姻法', id: 1 },
        { name: '婚姻法', id: 1 },
        { name: '婚姻法', id: 1 },
        { name: '婚姻法', id: 1 },
        { name: '婚姻法', id: 1 },
        { name: '婚姻法', id: 1 },
        { name: '婚姻法', id: 1 },
        { name: '婚姻法', id: 1 },
      ],
    }
  },
  methods: {},
}
</script>
<style lang="less">
.hotSign {
  padding: 20px;
  background: white;
  margin-top: 20px;
  width: 330px;
  .wrapSign {
    display: flex;
    flex-wrap: wrap;

    .selectedSign {
      width: 90px;
      height: 26px;
      background: rgba(28, 155, 178, 0.1);
      border: 1px solid #1c9bb2 !important;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9bb2 !important;
    }
    .signItem {
      margin-right: 10px;
      margin-top: 14px;
      width: 90px;
      height: 26px;
      border: 1px solid #dedede;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #afafaf;
    }
    .noDistance {
      margin-right: 0 !important;
    }
  }
  .hotArticleItem {
    display: flex;
    padding: 24px 0 15px 0;
    align-items: center;
    justify-content: space-between;
    .hotleft {
      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }

      .title {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #595757;
      }
      .leftBot {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
        margin-top: 12px;
      }
    }
    .right {
      width: 51px;
      height: 23px;
      border: 1px solid #1c9ab1;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    &:last-child {
      border-bottom: none !important;
    }
    .hotArticleItemTitle {
      margin-left: 12px;
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    .moreWord {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a7a8a8;
    }
    .wraphotArticleWord {
      border-bottom: 3px solid #1c9ab1;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .hotArticleWord {
        margin-left: 9px;

        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
    }
  }
}
</style>