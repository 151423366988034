<template>
  <div class="wendaDetail">
    <div class="top betweenFlex">
      <div class="left">
        <div class="title">{{ question.title }}</div>
        <div class="desp">{{ question.desp }}</div>
        <div class="wrapBotDetail">
          <img
            :src="require('@as/reading/test.png')"
            alt=""
            class="personNameImg"
          />
          <span class="personName">{{ question.personName }}</span>
          <span class="date">{{ question.date }}</span>
          <span class="time">{{ question.time }}</span>
          <div
            class="signItem commonFlex"
            v-for="(item, index) in question.sign"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="first betweenFlex">
          <div class="commonFlex wrapGuanzhu">
            <div class="guanzhuCount">{{ question.guanzhuCount }}</div>
            <div class="guanzhuWord">关注者</div>
          </div>
          <div class="commonFlex wrapGuanzhu">
            <div class="guanzhuCount">{{ question.liulanCount }}</div>
            <div class="guanzhuWord">被浏览</div>
          </div>
        </div>
        <div class="second betweenFlex">
          <div class="guanzhuQues commonFlex">关注问题</div>
          <div class="laiAnswer commonFlex" @click="huidadialog = true">
            我要解答
          </div>
        </div>
        <div class="third">
          <span class="share">分享</span>
          <img
            :src="require('@as/reading/wechat.png')"
            alt=""
            class="canClick"
          />
          <img
            :src="require('@as/reading/weibo.png')"
            alt=""
            class="canClick"
          />
        </div>
      </div>
    </div>
    <div class="midAnswerDetail betweenFlex">
      <div class="leftAnswerList">
        <div class="answeroption">
          <div class="allHuida">
            <div
              :class="{ selected: activeIndex == 0 }"
              @click="activeIndex = 0"
              class="commonFlex canClick"
            >
              全部回答
            </div>
            <div
              class="huidaCount commonFlex"
              :class="{ selectedCount: activeIndex == 0 }"
            >
              (36)
            </div>
          </div>
          <div
            :class="{ selected: activeIndex == 1 }"
            @click="activeIndex = 1"
            class="commonFlex newAnswer canClick"
          >
            最新回答
          </div>
        </div>
        <div v-if="question.answers.length == 0" class="noAnswer commonFlex">
          <div class="qiangda">我有靠谱回答，我来抢答</div>
          <div class="wrapTohuida commonFlex">
            <img :src="require('@as/reading/nohuida.png')" alt="" />
            <span class="toHUIDA">回答</span>
          </div>
        </div>
        <ul class="hotQues" v-if="question.answers.length > 0">
          <li
            v-for="(item, index) in question.answers"
            :key="index"
            class="answerItem betweenFlex"
          >
            <img
              :src="require('@as/reading/test.png')"
              alt=""
              class="huidaPersonImg"
            />
            <div class="hotQuesList">
              <div class="betweenFlex">
                <div>
                  <div class="huidaName">{{ item.name }}</div>
                  <div class="wraphuidaDate">
                    <span class="huidaDate">{{ item.date }}</span>
                    <span class="huidaDate">{{ item.time }}</span>
                  </div>
                </div>
                <div>
                  <img :src="require('@as/reading/moreshu.png')" alt="" />
                </div>
              </div>
              <div class="huidaContent">{{ item.content }}</div>
              <div class="betweenFlex commentNum">
                <div>
                  <img :src="require('@as/reading/pinglunDetail.png')" alt="" />
                  {{ item.commentNum }}条评论
                </div>
                <div>
                  <img :src="require('@as/reading/detailShare.png')" />分享
                </div>
                <div
                  @click="item.alreadyShou = !item.alreadyShou"
                  class="wrapShouimg"
                >
                  <img
                    :src="require('@as/reading/shou.png')"
                    v-show="!item.alreadyShou"
                  /><img
                    :src="require('@as/reading/contentShou.png')"
                    v-show="item.alreadyShou"
                  />收藏
                </div>
                <div
                  @click="item.alreadyZan = !item.alreadyZan"
                  class="wrapShouimg"
                >
                  <img
                    :src="require('@as/reading/zan.png')"
                    v-show="!item.alreadyZan"
                  /><img
                    :src="require('@as/reading/contentZan.png')"
                    v-show="item.alreadyZan"
                  />点赞
                </div>
              </div>
            </div>
          </li>
          <div class="jiazaiMore commonFlex">加载更多</div>
        </ul>
      </div>
      <div class="rightHot">
        <hotQues />
      </div>
    </div>

    <el-dialog title="回答问题" :visible.sync="huidadialog">
      <textarea
        v-model="form.huidaContent"
        placeholder="请阐述您对问题的观点..."
        class="huidaQues"
      ></textarea>
      <div style="width: 100%; text-align: center; padding-top: 20px">
        <el-button @click="dialogFormVisible = false">提交回答</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import hotQues from '@c/reading/hotQues'
export default {
  data() {
    return {
      form: {
        title: '',
        desc: '',
        sign: '',
        huidaContent: '',
      },
      formLabelWidth: '120px',
      activeIndex: 0,
      huidadialog: false,
      question: {
        title: '啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊',
        desp: '去瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反复反复反复',
        personName: '汪汪汪汪汪',
        date: '2020,05,243',
        time: 'xxxxxx',
        guanzhuCount: 1234,
        liulanCount: 1234,
        sign: ['啊啊', '全球'],
        answers: [
          {
            name: '爱爱爱',
            date: '20102020202',
            time: ' zxxxx',
            commentNum: 12,
            content: '啊啊啊啊啊啊啊啊啊啊啊啊啊啊等等等等等等等等等等等等',
            alreadyShou: false,
            alreadyZan: false,
          },
          {
            name: '爱爱爱',
            date: '20102020202',
            time: ' zxxxx',
            commentNum: 12,
            content: '啊啊啊啊啊啊啊啊啊啊啊啊啊啊等等等等等等等等等等等等',
            alreadyShou: false,
            alreadyZan: false,
          },
        ],
      },
    }
  },
  components: {
    hotQues,
  },
}
</script>

<style lang="less" scoped>
.wendaDetail {
  width: 1200px;
  margin: 0 auto;

  .wrapShouimg {
    img {
      width: 14px;
      height: 14px;
    }
  }
  & /deep/ .el-dialog__header {
    padding: 30px;
  }
  & /deep/ .el-dialog__title {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
  }
  & /deep/ .el-dialog__body {
    padding: 0px 20px 20px;
    text-align: center;
  }
  & /deep/ .el-button {
    color: #fff;
    background: #1c9ab1;
  }
  .huidaQues {
    padding: 20px;
    width: 100%;
    height: 235px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 3px;
  }
  .noAnswer {
    width: 850px;
    height: 432px;
    background: #ffffff;
    flex-direction: column;
  }
  .qiangda {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .wrapTohuida {
    width: 93px;
    height: 33px;
    background: #1c9ab1;
    margin-top: 16px;
    .toHUIDA {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      margin-left: 6px;
    }
  }
  .midAnswerDetail {
    width: 1200px;
    margin-bottom: 40px;
    // background: white;
    align-items: flex-start !important;
    margin-top: 20px;
    .rightHot {
      margin-bottom: 20px;
    }
    .jiazaiMore {
      cursor: pointer;
      margin: 0 auto;
      margin-top: 25px;
      width: 810px;
      background: rgba(28, 154, 177, 0.1);
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #1c9ab1;
      margin-bottom: 25px;
    }
    .answerItem {
      padding: 35px 25px 0px 25px;
      align-items: flex-start !important;
      .huidaPersonImg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .hotQuesList {
        border-bottom: 1px solid #dedede;
        padding-bottom: 20px;
        width: 745px;
        .commentNum {
          margin-top: 24px;
          width: 235px;
          div {
            cursor: pointer;
            font-size: 10px;
            font-weight: 400;
            color: #bbbcbc;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              margin-right: 3px;
            }
          }
        }
        .huidaContent {
          margin-top: 24px;
        }
        .huidaName {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #333333;
        }
        .wraphuidaDate {
          margin-top: 7px;
          .huidaDate {
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 300;
            color: #999999;
          }
        }
      }
    }
    .leftAnswerList {
      width: 850px;
      background: white;
      margin-bottom: 20px;
      .answeroption {
        height: 50px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dedede;
        .newAnswer {
          height: 100%;
          margin-left: 50px;
          border-bottom: 2px solid transparent;
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
        }
        .selected {
          border-bottom: 1px solid #1c99b0;

          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #1c99b0;
        }
        .allHuida {
          display: flex;
          align-items: center;
          height: 100%;
          .huidaCount {
            margin-left: 3px;
          }
          .selectedCount {
            color: #1c99b0;
          }
          div {
            height: 100%;
          }
          .selected {
            border-bottom: 1px solid #1c99b0;
          }
        }
      }
    }
  }
  .top {
    width: 1200px;
    height: 200px;
    background: white;
    padding: 30px 25px;
    box-sizing: border-box;
  }
  .right {
    width: 247px;
    height: 140px;
    margin-top: 20px;
    .third {
      margin-top: 35px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
      .share {
        margin-right: 20px;

        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #a7a8a8;
      }
    }
    .second {
      margin-top: 18px;
      .guanzhuQues {
        width: 111px;
        height: 30px;
        border: 1px solid #089acb;
        cursor: pointer;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #089acb;
      }
      .laiAnswer {
        width: 111px;
        height: 30px;
        background: #089acb;
        cursor: pointer;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .wrapGuanzhu {
      width: 110px;
      flex-direction: column;
      .guanzhuCount {
        font-size: 15px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      .guanzhuWord {
        margin-top: 15px;

        font-size: 10px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #bbbcbc;
      }
    }
  }
  .left {
    width: 805px;
    .title {
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #333333;
    }
    .desp {
      margin-top: 23px;

      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #808080;
    }
    .wrapBotDetail {
      display: flex;
      align-items: center;
      margin-top: 35px;
      .personNameImg {
        height: 24px;
        width: 24px;
        border-radius: 50%;
      }
      .personName {
        margin-left: 17px;

        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .date {
        margin-left: 32px;

        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #bbbcbc;
      }
      .time {
        margin-left: 12px;
        margin-right: 44px;

        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #bbbcbc;
      }
      .signItem {
        width: 52px;
        height: 19px;
        background: #e9eced;
        margin-right: 8px;

        font-size: 10px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
</style>