<template>
  <div class="hotZhuanlanReading">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <img :src="require('@as/reading/write.png')" alt="" />
        <span class="hotArticleWord">热门专栏</span>
      </div>
      <span class="moreWord">more ></span>
    </div>
    <ul>
      <li
        v-for="(item, index) in list"
        :key="index"
        class="hotArticleItem commonBorder"
      >
        <div class="hotleft betweenFlex">
          <img :src="require('@as/reading/test.png')" alt="" class="" />
          <div style="margin-left: 12px">
            <div class="title">{{ item.title }}</div>
            <div class="leftBot">
              {{ item.wenzhangCount }}篇文章 | {{ item.seeCount }}人看过
            </div>
          </div>
        </div>
        <div class="right commonFlex">已订阅</div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dayRank: true,
      monthRank: false,
      weekRank: false,
      list: [
        { title: '大健康投', wenzhangCount: 12, seeCount: 12345 },
        { title: '大健康投', wenzhangCount: 12, seeCount: 12345 },
        { title: '大健康投', wenzhangCount: 12, seeCount: 12345 },
        { title: '大健康投', wenzhangCount: 12, seeCount: 12345 },
        { title: '大健康投', wenzhangCount: 12, seeCount: 12345 },
        { title: '大健康投', wenzhangCount: 12, seeCount: 12345 },
      ],
    }
  },
  methods: {
    dayClick() {
      this.dayRank = true
      this.monthRank = false
      this.weekRank = false
    },
    weekClick() {
      this.dayRank = false
      this.monthRank = false
      this.weekRank = true
    },
    monthClick() {
      this.dayRank = false
      this.monthRank = true
      this.weekRank = false
    },
  },
}
</script>
<style lang="less">
.hotZhuanlanReading {
  padding: 20px;
  background: white;
  padding-top: 10px;
  .hotArticleItem {
    display: flex;
    padding: 24px 0 15px 0;
    align-items: center;
    justify-content: space-between;
    .hotleft {
      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }

      .title {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #595757;
      }
      .leftBot {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
        margin-top: 12px;
      }
    }
    .right {
      width: 51px;
      height: 23px;
      border: 1px solid #1c9ab1;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    &:last-child {
      border-bottom: none !important;
    }
    .hotArticleItemTitle {
      margin-left: 12px;
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    .moreWord {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a7a8a8;
    }
    .wraphotArticleWord {
      border-bottom: 3px solid #1c9ab1;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .hotArticleWord {
        margin-left: 9px;

        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
    }
  }
}
</style>