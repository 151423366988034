<template>
  <div class="basicInfo">
    <div class="basicUploadPhtoto">
      <div class="upperFile">
        <el-upload
          class="upload-demo"
          action=""
          accept="image/png,image/jpg,image/jpeg,image/bmp"
          :before-upload="beforeAvatarUpload"
          :multiple="false"
          :show-file-list="false"
        >
          <span class="xiugai">选择文件</span>
        </el-upload>

        <div class="wrapUploadPhoto commonFlex">
          <img
            :src="fileImg || require('@as/zhaopin/uploadPhoto.png')"
            alt=""
          />
        </div>
      </div>
      <div class="wrapphotoformat">
        照片为1寸证件照，大小不超过1M<br />
        尺寸为295*413像素 <br />格式支持png、jpg、jpeg、bmp
      </div>
    </div>
    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">姓名</span>
        <span class="requireFlag">*</span>
      </div>
      <input type="text" class="basicWidth" v-model="name" />
    </div>
    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">性别</span>
        <span class="requireFlag">*</span>
      </div>
      <div class="startFlex">
        <el-radio v-model="sex" label="1">男</el-radio>
        <el-radio v-model="sex" label="2">女</el-radio>
        <span class="selectBoy">请选择性别，用于投递简历</span>
      </div>
    </div>
    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">出生日期</span>
        <span class="requireFlag">*</span>
      </div>
      <el-date-picker v-model="birthday" type="date" placeholder="选择日期">
      </el-date-picker>
    </div>
    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">参加工作时间</span>
        <span class="requireFlag">*</span>
      </div>
      <el-date-picker
        v-model="workStartTime"
        type="date"
        placeholder="参加工作时间"
        v-if="isWorked === false"
      >
      </el-date-picker>
      <el-checkbox
        v-model="isWorked"
        style="margin-left: 10px"
        class="selectBoy"
        >暂无工作经历</el-checkbox
      >
    </div>
    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">户口所在地</span>
        <span class="requireFlag">*</span>
      </div>
      <el-select
        v-model="bornAddressProvince"
        placeholder="请选择"
        class="province"
      >
        <el-option
          v-for="item in provinceList"
          :key="item.provinceID"
          :label="item.province"
          :value="item.provinceID"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="bornAddressCity"
        placeholder="请选择"
        class="province"
      >
        <el-option
          v-for="item in hukouCityList"
          :key="item.cityID"
          :label="item.city"
          :value="item.cityID"
        >
        </el-option>
      </el-select>
    </div>
    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">现居住城市</span>
        <span class="requireFlag">*</span>
      </div>
      <el-select
        v-model="liveAddressProvince"
        placeholder="请选择"
        class="nowprovince"
        v-if="!isForeign"
      >
        <el-option
          v-for="item in provinceList"
          :key="item.provinceID"
          :label="item.province"
          :value="item.provinceID"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="liveAddressCity"
        placeholder="请选择"
        class="nowprovince"
        v-if="!isForeign"
      >
        <el-option
          v-for="item in liveCityList"
          :key="item.cityID"
          :label="item.city"
          :value="item.cityID"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="liveAddressArea"
        placeholder="请选择"
        class="nowprovince"
        v-if="!isForeign"
      >
        <el-option
          v-for="item in liveAreaList"
          :key="item.areaID"
          :label="item.area"
          :value="item.areaID"
        >
        </el-option>
      </el-select>
      <el-checkbox
        v-model="isForeign"
        style="margin-left: 10px"
        class="selectBoy"
        >国外</el-checkbox
      >
    </div>
    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">联系方式</span>
        <span class="requireFlag">*</span>
      </div>
      <div class="startFlex">
        <span class="selectBoy">{{ phone }}</span>
        <router-link tag="span" to="/personal/zhanghaoSet" class="xiugai"
          >修改</router-link
        >
        <span class="selectBoy">用于登录和找回密码</span>
      </div>
    </div>
    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">电子邮箱</span>
        <span class="requireFlag">*</span>
      </div>
      <input
        type="text"
        class="basicWidth"
        placeholder="请选择"
        v-model="email"
      />
    </div>
    <botChoice @confirm="handlerSave" @cancel="handlerCancel" />
  </div>
</template>

<script>
import { Basic, Resume } from '@api'
import botChoice from './botChoice'

export default {
  props: ['id'],
  data() {
    return {
      fileList: [],
      name: '',
      sex: '',
      birthday: '',
      workStartTime: '',
      isWorked: false,
      bornAddressProvince: '',
      bornAddressCity: '',
      provinceList: [],
      hukouCityList: [],
      liveAddressProvince: '',
      liveAddressCity: '',
      liveAddressArea: '',
      liveCityList: [],
      liveAreaList: [],
      isForeign: false,
      email: '',
      file: {},
      fileImg: '',
    }
  },
  components: { botChoice },
  created() {
    Basic.getProvince().then((res) => (this.provinceList = res))
  },
  computed: {
    phone() {
      const { phone } = this.$store.state.user.userInfo
      if (phone) return `${phone.slice(0, 3)}****${phone.slice(-4)}`
      else return ''
    },
  },
  watch: {
    bornAddressProvince(val) {
      Basic.getCity({
        provinceID: val,
      }).then((res) => (this.hukouCityList = res))
    },
    liveAddressProvince(val) {
      Basic.getCity({
        provinceID: val,
      }).then((res) => (this.liveCityList = res))
    },
    liveAddressCity(val) {
      Basic.getArea({
        cityID: val,
      }).then((res) => (this.liveAreaList = res))
    },
    id: {
      handler(val) {
        if (!val) return
        Resume.getBasic({ userId: this.$store.state.uid }).then((res) => {
          Object.assign(this, { ...res })
        })
      },
      immediate: true,
    },
  },
  methods: {
    beforeAvatarUpload(file) {
      const isFit = ['png', 'jpg', 'jpeg', 'bmp'].includes(
        file.type.split('/')[1].toLowerCase()
      )
      const isLt1M = file.size / 1024 / 1024 < 1

      if (!isFit) {
        this.$message.error('头像图片上传格式不正确!')
        return false
      }
      if (!isLt1M) {
        this.$message.error('上传头像图片大小错误!')
        return false
      }
      this.file = file
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = (e) => {
        this.fileImg = e.target.result
      }
      return false
    },
    async handlerSave() {
      const data = {
        name: this.name,
        sex: this.sex,
        birthday: this.birthday,
        bornAddressProvince: this.bornAddressProvince,
        bornAddressCity: this.bornAddressCity,
        phone: this.$store.state.user.userInfo.phone,
        email: this.email,
      }
      if (!this.isWorked) data.workStartTime = this.workStartTime
      else data.isWorked = 1
      if (!this.isForeign) {
        data.liveAddressProvince = this.liveAddressProvince
        data.liveAddressCity = this.liveAddressCity
        data.liveAddressArea = this.liveAddressArea
      } else data.isForeign = 2
      for (let i in data) {
        if (!this.isVaild(i, data[i])) return
      }
      if (this.file.name) {
        data.photoUrl = await Basic.uploadImg({ file: this.file })
      } else data.photoUrl = this.fileImg
      if (this.id) data.id = id
      data.userId = this.$store.state.uid
      Resume.changeResumeBasic(data)
    },
    handlerCancel() {},
    isVaild(keys, val) {
      const checkDir = {
        name: '姓名',
        sex: '性别',
        birthday: '出生日期',
        workStartTime: '参加工作时间',
        bornAddressProvince: '户口所在地',
        bornAddressCity: '户口所在地',
        liveAddressProvince: '现居住城市',
        liveAddressCity: '现居住城市',
        liveAddressArea: '现居住城市',
        phone: '联系方式',
        email: '电子邮箱',
      }
      if (!val) {
        this.$message.error(`${checkDir[keys]}不能为空`)
        return false
      }
      return true
    },
  },
}
</script>

<style lang="less">
.basicInfo {
  margin: 40px 0;
  position: relative;
  .basicUploadPhtoto {
    position: absolute;
    right: 0;
    top: 0;
  }
  .upperFile {
    display: flex;
    align-items: flex-end;
    width: 183px;
  }
  .wrapphotoformat {
    margin-top: 10px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
    line-height: 18px;
    text-align: right;
  }
  .wrapUploadPhoto {
    width: 114px;
    height: 149px;
    border: 1px solid #dedede;
    img {
      width: 112px;
      height: 147px;
    }
  }
  .nowprovince {
    width: 197px;
    margin-right: 5px;
  }
  .xiugai {
    color: #1c9ab1;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    margin: 0 10px;
  }
  .province {
    width: 96px;
    margin-right: 10px;
    .el-input__suffix {
      top: 4px;
    }
  }
  .detailWordInfo {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .selectBoy {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
  }
  .everyline {
    margin-top: 27px;
  }
  input {
    border: 1px solid #eaebeb;
  }
  .basicWidth {
    width: 197px;
    height: 34px;
    padding-left: 10px;
  }
  .basicWidth::-webkit-input-placeholder {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
  }
  .leftBasicInfo {
    width: 105px;
  }
}
</style>