<template>
  <div class="jieyou">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <img :src="require('@as/reading/jieyou.png')" alt="" />
        <span class="hotArticleWord">解忧达人</span>
      </div>
      <span class="moreWord">MORE</span>
    </div>
    <el-carousel height="113px" style="margin-top: 23px">
      <el-carousel-item v-for="item in cardList" :key="item">
        <img :src="item" alt="" style="height: 113px; width: 100%" />
      </el-carousel-item>
    </el-carousel>
    <div class="commonFlex">
      <div
        class="paihangkind commonFlex"
        :class="{ selected: activeIndex == 1 }"
        @click="activeIndex = 1"
      >
        周排行
      </div>
      <div
        class="paihangkind commonFlex"
        :class="{ selected: activeIndex == 0 }"
        @click="activeIndex = 0"
      >
        月排行
      </div>
    </div>
    <ul style="margin-top: 10px; padding-bottom: 13px">
      <li
        v-for="(item, index) in list"
        :key="index"
        class="hotArticleItem commonBorder"
      >
        <div class="commonFlex">
          <img
            :src="require('@as/reading/listImg.png')"
            alt=""
            class="authorImg"
          />
          <div class="wrapHotTitle">
            <span class="hotArticleItemTitle">{{ item.title }}</span>
            <img
              :src="require('@as/reading/personFlag1.png')"
              alt=""
              class="personFlag"
            />
          </div>
        </div>

        <div style="display: flex; align-items: center">
          <span class="huidaCount">{{ item.huidaCount }}</span>
          <span class="huidaCountWord">次回答</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: 0,
      cardList: [
        require('@as/reading/listImg.png'),
        require('@as/reading/test.png'),
        require('@as/reading/listImg.png'),
      ],
      list: [
        { title: '李欣欣', huidaCount: 123 },
        { title: '李欣欣', huidaCount: 123 },
        { title: '李欣欣', huidaCount: 123 },
        { title: '李欣欣', huidaCount: 123 },
        { title: '李欣欣', huidaCount: 123 },
        { title: '李欣欣', huidaCount: 123 },
      ],
    }
  },
  methods: {},
}
</script>
<style lang="less">
.jieyou {
  padding: 20px;
  background: white;
  margin-top: 20px;
  width: 330px;
  margin-bottom: 20px;
  .selected {
    color: #55b3c5 !important;
    border-bottom: 2px solid #55b3c5 !important;
  }
  .paihangkind {
    cursor: pointer;
    width: 90px;
    height: 45px;
    border-bottom: 2px solid #f5f5f7;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .hotArticleItem {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .huidaCount {
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    .huidaCountWord {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #bbbcbc;
      margin-left: 5px;
    }
    .hasGuanzhu {
      width: 51px;
      height: 23px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      border: 1px solid #1c9ab1;
    }
    .noGuanzhu {
      width: 51px;
      height: 23px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #babbbb;
      border: 1px solid #babbbb;
    }
    .follow {
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    .authorImg {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .wrapHotTitle {
      position: relative;
      margin-left: 13px;
      .personFlag {
        position: absolute;
        right: -17px;
        top: 0px;
      }
    }
    .hotArticleItemTitle {
      font-size: 13px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #595757;
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    .moreWord {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a7a8a8;
    }
    .wraphotArticleWord {
      border-bottom: 3px solid #1c9ab1;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .hotArticleWord {
        margin-left: 9px;

        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
    }
  }
}
</style>