<template>
  <div class="index">
    <div class="menu">
      <el-menu
        :default-active="activeIndex2"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#fff"
        text-color="#1C9BB2"
        active-text-color="#1C9BB2"
      >
        <el-menu-item
          :index="index + 1"
          v-for="(item, index) in menuList"
          v-bind:key="item"
          >{{ item }}</el-menu-item
        >
      </el-menu>
    </div>
    <div class="main">
      <el-image
        class="banner"
        :src="require('@as/index/banner.png')"
      ></el-image>
      <div class="content">
        <div class="left">
          <el-carousel height="390px" :autoplay="false">
            <el-carousel-item v-for="item in swiperList" :key="item">
              <el-image :src="item" class="swiper"></el-image>
            </el-carousel-item>
          </el-carousel>
          <div class="color_title">阅读</div>
          <div class="list">
            <div class="list_item" v-for="item in 4" v-bind:key="item">
              <div class="buoy">观点</div>
              <el-image
                class="list_item_img"
                :src="require('@as/index/banner.png')"
              ></el-image>
              <div class="list_item_content">
                <div class="title">
                  债券虚假陈述诉讼纠纷解决的“求同存异”之道——兼评《全国法院审理债券纠纷案件座谈会纪要》
                </div>
                <div class="subtitle">
                  债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治
                </div>
                <div class="info">
                  <el-image
                    class="info_img"
                    :src="require('@as/index/icon_18.png')"
                  ></el-image>
                  <div class="info_name" :class="{ active: item == 1 }">
                    中伦律师事务所
                  </div>
                  <el-image
                    class="info_icon"
                    :src="require('@as/index/icon_1.png')"
                  ></el-image>
                  <div class="info_name">张保生 周伟 朱媛媛</div>
                  <div class="info_tag_container">
                    <div class="info_tag" :class="{ active: item == 1 }">
                      九民纪要
                    </div>
                  </div>
                  <div class="info_tag">九民纪要</div>
                  <el-image
                    class="info_icon_time"
                    :src="require('@as/index/icon_2.png')"
                  ></el-image>
                  <div class="info_name">10分钟前</div>
                </div>
              </div>
            </div>
            <div class="middle_banner">
              <div class="item">
                <el-image
                  class="banner_img"
                  :src="require('@as/index/banner.png')"
                >
                </el-image>
                <div class="tag">还要16天</div>
              </div>
              <div class="item">
                <el-image
                  class="banner_img"
                  :src="require('@as/index/banner.png')"
                >
                </el-image>
                <div class="tag">还要16天</div>
              </div>
              <div class="item">
                <el-image
                  class="banner_img"
                  :src="require('@as/index/banner.png')"
                >
                </el-image>
                <div class="tag">还要16天</div>
              </div>
            </div>
            <div class="list_item" v-for="item in 4" v-bind:key="item">
              <div class="buoy">观点</div>
              <el-image
                class="list_item_img"
                :src="require('@as/index/banner.png')"
              ></el-image>
              <div class="list_item_content">
                <div class="title">
                  债券虚假陈述诉讼纠纷解决的“求同存异”之道——兼评《全国法院审理债券纠纷案件座谈会纪要》
                </div>
                <div class="subtitle">
                  债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治
                </div>
                <div class="info">
                  <el-image
                    class="info_img"
                    :src="require('@as/index/icon_18.png')"
                  ></el-image>
                  <div class="info_name" :class="{ active: item == 1 }">
                    中伦律师事务所
                  </div>
                  <el-image
                    class="info_icon"
                    :src="require('@as/index/icon_1.png')"
                  ></el-image>
                  <div class="info_name">张保生 周伟 朱媛媛</div>
                  <div class="info_tag_container">
                    <div class="info_tag" :class="{ active: item == 1 }">
                      九民纪要
                    </div>
                  </div>
                  <div class="info_tag">九民纪要</div>
                  <el-image
                    class="info_icon_time"
                    :src="require('@as/index/icon_2.png')"
                  ></el-image>
                  <div class="info_name">10分钟前</div>
                </div>
              </div>
            </div>
            <el-image
              class="list_banner"
              :src="require('@as/index/banner.png')"
            ></el-image>
            <div class="list_item" v-for="item in 4" v-bind:key="item">
              <div class="buoy">观点</div>
              <el-image
                class="list_item_img"
                :src="require('@as/index/banner.png')"
              ></el-image>
              <div class="list_item_content">
                <div class="title">
                  债券虚假陈述诉讼纠纷解决的“求同存异”之道——兼评《全国法院审理债券纠纷案件座谈会纪要》
                </div>
                <div class="subtitle">
                  债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治
                </div>
                <div class="info">
                  <el-image
                    class="info_img"
                    :src="require('@as/index/icon_18.png')"
                  ></el-image>
                  <div class="info_name" :class="{ active: item == 1 }">
                    中伦律师事务所
                  </div>
                  <el-image
                    class="info_icon"
                    :src="require('@as/index/icon_1.png')"
                  ></el-image>
                  <div class="info_name">张保生 周伟 朱媛媛</div>
                  <div class="info_tag_container">
                    <div class="info_tag" :class="{ active: item == 1 }">
                      九民纪要
                    </div>
                  </div>
                  <div class="info_tag">九民纪要</div>
                  <el-image
                    class="info_icon_time"
                    :src="require('@as/index/icon_2.png')"
                  ></el-image>
                  <div class="info_name">10分钟前</div>
                </div>
              </div>
            </div>
            <el-image
              class="list_banner"
              :src="require('@as/index/banner.png')"
            ></el-image>
            <div class="list_item" v-for="item in 4" v-bind:key="item">
              <div class="buoy">观点</div>
              <el-image
                class="list_item_img"
                :src="require('@as/index/banner.png')"
              ></el-image>
              <div class="list_item_content">
                <div class="title">
                  债券虚假陈述诉讼纠纷解决的“求同存异”之道——兼评《全国法院审理债券纠纷案件座谈会纪要》
                </div>
                <div class="subtitle">
                  债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治
                </div>
                <div class="info">
                  <el-image
                    class="info_img"
                    :src="require('@as/index/icon_18.png')"
                  ></el-image>
                  <div class="info_name" :class="{ active: item == 1 }">
                    中伦律师事务所
                  </div>
                  <el-image
                    class="info_icon"
                    :src="require('@as/index/icon_1.png')"
                  ></el-image>
                  <div class="info_name">张保生 周伟 朱媛媛</div>
                  <div class="info_tag_container">
                    <div class="info_tag" :class="{ active: item == 1 }">
                      九民纪要
                    </div>
                  </div>
                  <div class="info_tag">九民纪要</div>
                  <el-image
                    class="info_icon_time"
                    :src="require('@as/index/icon_2.png')"
                  ></el-image>
                  <div class="info_name">10分钟前</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pull_more">查看更多</div>
        </div>
        <div class="right">
          <div class="section">
            <div class="head">
              <div class="title">
                <el-image
                  class="title_icon"
                  :src="require('@as/index/title_icon1.png')"
                ></el-image>
                <div>法律招标</div>
              </div>
              <div class="title_more">MORE</div>
            </div>
            <div class="plan">
              <el-timeline>
                <el-timeline-item
                  :timestamp="2018 + '/' + 4 + '/' + item"
                  :color="item == 1 ? '#1C9AB1' : ''"
                  placement="top"
                  v-for="item in 4"
                  v-bind:key="item"
                >
                  <div class="info">
                    <div class="info_title" :class="{ active: item == 1 }">
                      中国进出口银行湖南省分行五凌电力孟 加拉风电法律服务项目
                    </div>
                    <div class="info_subtitle">
                      <el-image
                        class="info_icon"
                        :src="require('@as/index/icon_2.png')"
                      ></el-image>
                      <div>2020.07.28</div>
                      <el-image
                        class="info_icon"
                        style="margin-left: 16px"
                        :src="require('@as/index/icon_3.png')"
                      ></el-image>
                      <div>北京</div>
                    </div>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
          <div class="section">
            <div class="head">
              <div class="title">
                <el-image
                  class="title_icon"
                  :src="require('@as/index/title_icon2.png')"
                ></el-image>
                <div>热门活动</div>
              </div>
              <div class="title_more">MORE</div>
            </div>
            <el-carousel height="175px">
              <el-carousel-item v-for="item in 4" :key="item">
                <div class="swiper_item">
                  <el-image
                    class="swiper_item_img"
                    :src="require('@as/index/banner.png')"
                  ></el-image>
                  <div class="item_text">法律金融一起同行</div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="list">
              <div class="item" v-for="item in 3" v-bind:key="item">
                <div class="left">
                  <div class="day">30</div>
                  <div class="month">7月</div>
                  <div class="line"></div>
                </div>
                <div class="right">
                  <div class="title">
                    掌握国际并购规则，为中英资本跨 境投资护航
                  </div>
                  <div class="subtitle">
                    <el-image
                      class="item_icon"
                      :src="require('@as/index/icon_3.png')"
                    ></el-image>
                    <div>北京</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="head">
              <div class="title">
                <el-image
                  class="title_icon"
                  :src="require('@as/index/title_icon3.png')"
                ></el-image>
                <div>最新招聘</div>
              </div>
              <div class="title_more">MORE</div>
            </div>
            <div class="second_list">
              <div class="item" v-for="item in 4" v-bind:key="item">
                <div class="head">
                  <div class="item_title">国际争端解决领域专业律师</div>
                  <div class="price">10-15K</div>
                </div>
                <div class="info">
                  <el-image
                    class="info_icon icon_cirle"
                    :src="require('@as/index/icon_18.png')"
                  ></el-image>
                  <div class="info_name">德邦物流股份有限公司</div>
                  <el-image
                    class="info_icon"
                    style="margin-left: 16px"
                    :src="require('@as/index/icon_3.png')"
                  ></el-image>
                  <div class="info_name">北京</div>
                  <div class="right_info">/月</div>
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="head">
              <div class="title">
                <el-image
                  class="title_icon"
                  :src="require('@as/index/title_icon4.png')"
                ></el-image>
                <div>法律黄页</div>
              </div>
              <div class="title_more">MORE</div>
            </div>
            <el-carousel height="175px">
              <el-carousel-item v-for="item in 4" :key="item">
                <div class="swiper_item">
                  <el-image
                    class="swiper_item_img"
                    :src="require('@as/index/banner.png')"
                  ></el-image>
                  <div class="item_text">法律金融一起同行</div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
              text-color="#595757"
              active-text-color="#1C9BB2"
            >
              <el-menu-item index="1">推荐黄页</el-menu-item>
              <el-menu-item index="2">热门黄页</el-menu-item>
            </el-menu>
            <div class="third_list">
              <div
                class="item"
                :class="{ active: index == 0 }"
                v-for="(item, index) in 4"
                v-bind:key="item"
              >
                <el-image
                  class="item_img"
                  :src="require('@as/index/title_icon4.png')"
                ></el-image>
                <div class="info">
                  <div class="title">君合律师事务所</div>
                  <div class="subtitle">1603文章 | 2426粉丝</div>
                </div>
                <div class="btn">{{ index == 0 ? '已关注' : '+关注' }}</div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="head">
              <div class="title">
                <el-image
                  class="title_icon"
                  :src="require('@as/index/title_icon5.png')"
                ></el-image>
                <div>We言堂</div>
              </div>
              <div class="title_more">MORE</div>
            </div>
            <div class="fourth_list">
              <div class="item" v-for="item in 3" v-bind:key="item">
                <div class="item_title">
                  <el-image
                    class="title_img"
                    :src="require('@as/index/title_icon4.png')"
                  ></el-image>
                  <div class="name">审判研究</div>
                  <div class="read">2464 人阅读</div>
                </div>
                <div class="card">
                  <div class="detail">
                    24小时随时响应，法律咨询，北京韦德律师在线平台，丰富的处理经验，实时在线回复擅长处理各类案件。
                  </div>
                  <div class="btn">
                    <el-image
                      class="btn_icon"
                      :src="require('@as/index/icon_4.png')"
                    ></el-image>
                    <div>我要解答</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="color_title" style="margin-bottom: 13px">服务</div>
    </div>
    <div class="card_container">
      <div class="item">
        <div class="title">
          <span>法律</span>
          <span>服务</span>
        </div>
        <div class="line"></div>
        <div class="subtitle">对的人，真正的问题解决者</div>
        <div class="detail">
          通过与数名行业专家学者合作，已成为法律行业互相学习、资源共享的法律经验集聚平台
        </div>
        <div class="btn">查看详情</div>
      </div>
      <div class="item">
        <div class="title">
          <span>品牌</span>
          <span>服务</span>
        </div>
        <div class="line"></div>
        <div class="subtitle">一站式品牌服务</div>
        <div class="detail">
          通过与数名行业专家学者合作，已成为法律行业互相学习、资源共享的法律经验集聚平台
        </div>
        <div class="btn">查看详情</div>
      </div>
      <div class="item">
        <div class="title">
          <span>知识</span>
          <span>服务</span>
        </div>
        <div class="line"></div>
        <div class="subtitle">一站式品牌服务</div>
        <div class="detail">
          通过与数名行业专家学者合作，已成为法律行业互相学习、资源共享的法律经验集聚平台
        </div>
        <div class="btn">查看详情</div>
      </div>
      <div class="item">
        <div class="title">
          <span>严选</span>
          <span>服务</span>
        </div>
        <div class="line"></div>
        <div class="subtitle">百里挑一的品质</div>
        <div class="detail">
          通过与数名行业专家学者合作，已成为法律行业互相学习、资源共享的法律经验集聚平台
        </div>
        <div class="btn">查看详情</div>
      </div>
      <div class="item">
        <div class="title">
          <span>招标</span>
          <span>服务</span>
        </div>
        <div class="line"></div>
        <div class="subtitle">法律服务招标信息 垂直门户平台</div>
        <div class="detail">
          通过与数名行业专家学者合作，已成为法律行业互相学习、资源共享的法律经验集聚平台
        </div>
        <div class="btn">查看详情</div>
      </div>
    </div>
    <div class="float_btn">
      <div class="left">
        <div class="item">
          <el-image
            class="item_img"
            :src="require('@as/index/icon_4.png')"
          ></el-image>
          <div class="text">创作中心</div>
        </div>
        <div class="item">
          <el-image
            class="item_img"
            :src="require('@as/index/icon_5.png')"
          ></el-image>
          <div class="text">反馈意见</div>
        </div>
        <div class="item">
          <el-image
            class="item_img"
            :src="require('@as/index/icon_6.png')"
          ></el-image>
          <el-image
            class="code"
            :src="require('@as/index/footer_code.png')"
          ></el-image>
        </div>
        <div class="item">
          <el-image
            class="item_img"
            :src="require('@as/index/icon_7.png')"
          ></el-image>
          <div class="text">回到顶部</div>
        </div>
      </div>
      <div class="right">
        <div class="item">
          <div class="one">写</div>
          <div class="two">文章</div>
        </div>
        <div class="item">
          <div class="one">去</div>
          <div class="two">提问</div>
        </div>
        <div class="item">
          <div class="one">发</div>
          <div class="two">活动</div>
        </div>
        <div class="item">
          <div class="one">发</div>
          <div class="two">职位</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex2: 1,

      menuList: [
        '观点实务',
        '法律资讯',
        '律途职场',
        '专题专栏',
        '互动问答',
        '法律活动',
        '作者矩阵',
        '法律服务',
        '知识服务',
        '品牌服务',
        '严选服务',
        '招标服务',
      ],
      swiperList: [
        require('@as/event/banner.png'),
        require('@as/event/banner.png'),
        require('@as/event/banner.png'),
        require('@as/event/banner.png'),
      ],
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
  },
}
</script>
<style lang="less" scoped>
.index {
  position: relative;
  .float_btn {
    position: fixed;
    right: 10px;
    top: 70%;
    z-index: 10;
    display: flex;
    .left {
      .item {
        margin-bottom: 2px;
        padding: 13px;
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        background: #fff;
        cursor: pointer;
        &:nth-child(3) {
          &:hover {
            padding: 1px;
            .code {
              display: block;
            }
          }
        }
        &:hover {
          background: #1c9bb2;
          .item_img {
            display: none;
          }
          .text {
            display: block;
          }
        }
        .item_img {
          width: 24px;
          height: 24px;
        }
        .code {
          display: none;
        }
        .text {
          display: none;
          line-height: 1.2;
          font-size: 10px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .right {
      margin-left: 10px;
      .item {
        margin-bottom: 2px;
        padding: 10px 13px;
        background: #fff;
        color: #1c9bb2;
        text-align: center;
        cursor: default;
        .one {
          font-size: 16px;
        }
        .two {
          margin-top: 2px;
          font-size: 10px;
        }
      }
    }
  }
  & /deep/ .el-timeline-item__content {
    cursor: pointer;
  }
}
.menu {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(000, 000, 000, 0.2);
  & /deep/ .el-menu {
    margin: -20px auto 0;
    width: 1200px;
  }
  & /deep/ .el-menu--horizontal > .el-menu-item {
    height: 42px;
    line-height: 42px;
  }
}
.main {
  margin: 0 auto;
  width: 1200px;
  .banner {
    margin-top: 20px;
    width: 1200px;
    height: 60px;
  }
  .content {
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .left {
      width: 835px;
      .swiper {
        width: 100%;
        height: 100%;
      }
      & /deep/ .el-carousel__indicators--horizontal {
        left: 11%;
      }
      & /deep/ .el-carousel__button {
        opacity: 1;
      }
      & /deep/ .el-carousel__indicator.is-active button {
        background-color: #1c9bb2;
      }

      .list_item {
        position: relative;
        padding: 20px 0;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #e0e1e2;
        &:hover {
          padding: 20px 12px;
          background: #fff;
          box-shadow: 0 0 2px 2px rgba(224, 225, 226, 0.7);
          .buoy {
            top: 24px;
            left: 16px;
          }
        }
        .buoy {
          z-index: 2;
          position: absolute;
          top: 26px;
          left: 6px;
          padding: 3px 6px;
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
          background: rgba(28, 155, 178, 0.5);
        }
        .list_item_img {
          width: 218px;
          height: 136px;
        }
        .list_item_content {
          margin-left: 20px;
          .title {
            font-size: 18px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #1c9bb2;
            line-height: 28px;
            cursor: pointer;
          }
          .subtitle {
            margin-top: 18px;
            font-size: 13px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #a8a9a9;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .info {
            margin-top: 27px;
            display: flex;
            align-items: center;
            .info_img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
            }
            .info_name {
              margin-left: 5px;
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #afafaf;
              cursor: pointer;
              &.active {
                color: #1c9bb2;
              }
            }
            .info_icon {
              margin-left: 16px;
              width: 11px;
              height: 10px;
            }
            .info_tag_container {
              margin-left: 15px;
              display: flex;
            }
            .info_tag {
              margin-left: 4px;
              padding: 5px 16px;
              background: #eaedee;
              font-size: 10px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #afafaf;
              cursor: pointer;
              &.active {
                color: #fff;
                background: #1c9bb2;
              }
            }
            .info_icon_time {
              margin-left: auto;
              width: 11px;
              height: 11px;
            }
          }
        }
      }
      .middle_banner {
        margin: 10px 0;
        padding: 16px 14px;
        display: flex;
        justify-content: space-between;
        background: #fff;
        .item {
          position: relative;
        }
        .banner_img {
          width: 260px;
          height: 130px;
        }
        .tag {
          position: absolute;
          bottom: 5px;
          right: 5px;
          padding: 5px;
          font-size: 12px;
          color: #fff;
          background: #1c9bb2;
          border-radius: 10px;
        }
      }
      .list_banner {
        margin-top: 10px;
        width: 836px;
        height: 68px;
      }
      .pull_more {
        margin-top: 20px;
        width: 836px;
        height: 41px;
        line-height: 41px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
        text-align: center;
        background: #eceded;
        cursor: pointer;
      }
    }
    .right {
      width: 330px;
      .section {
        margin-bottom: 20px;
        padding: 20px 24px;
        background: #fff;
        .head {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          .title {
            padding-bottom: 6px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #595757;
            border-bottom: 2px solid #4a97ad;
            .title_icon {
              margin-right: 5px;
              width: 22px;
              height: 22px;
            }
          }
          .title_more {
            padding-bottom: 6px;
            flex: 1;
            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            line-height: 22px;
            color: #a6a7a7;
            text-align: right;
            border-bottom: 1px solid #dedede;
          }
        }
        .plan {
          margin-top: 22px;
          & /deep/ .el-timeline-item__timestamp.is-top {
            display: none;
          }
          & /deep/ .el-timeline {
            padding-left: 0;
          }
          & /deep/ .el-timeline-item__wrapper {
            padding-left: 22px;
          }
          & /deep/ .el-timeline-item__tail {
            top: 4px;
            border-left-width: 1px;
          }
          & /deep/ .el-timeline-item__node--normal {
            top: 4px;
            width: 5px;
            height: 5px;
            left: 2.5px;
          }
          .info {
            .info_title {
              font-size: 13px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #595757;
              line-height: 18px;
              &.active {
                color: #1c9ab1;
              }
            }
            .info_subtitle {
              margin-top: 15px;
              display: flex;
              align-items: center;
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #afafaf;
              .info_icon {
                margin-right: 5px;
                width: 9px;
                height: 11px;
              }
            }
          }
        }
        .swiper_item {
          position: relative;
          width: 280px;
          height: 175px;
          .swiper_item_img {
            width: 100%;
            height: 100%;
          }
          .item_text {
            position: absolute;
            left: 14px;
            bottom: 10px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #ffffff;
          }
        }
        & /deep/ .el-carousel__indicators--horizontal {
          display: flex;
          left: 235px;
          bottom: 15px;
        }
        & /deep/ .el-carousel__indicator--horizontal {
          padding: 0 4px;
        }
        & /deep/ .el-carousel__button {
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }
        & /deep/ .el-carousel {
          margin-top: 15px;
        }
        .list {
          margin-top: 12px;
          .item {
            margin-top: 10px;
            padding-bottom: 8px;
            border-bottom: 2px solid #e7e7e7;
            display: flex;
            align-items: flex-start;
            cursor: pointer;
            &:last-child {
              border-bottom: none;
            }
            &:hover {
              .right {
                .title {
                  color: #1c9bb2;
                }
              }
            }
            .left {
              flex-shrink: 0;
              width: 50px;
              height: 40px;
              position: relative;
              font-family: PingFangSC;
              font-weight: 400;
              color: #1c9bb2;
              .day {
                position: absolute;
                top: 2px;
                left: 4px;
                font-size: 21px;
              }
              .month {
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 12px;
              }
              .line {
                width: 40px;
                height: 1px;
                background: #1c9bb2;
                transform: rotate(130deg);
                position: absolute;
                top: 25px;
                left: 8px;
              }
            }
            .right {
              margin-left: 34px;
              .title {
                font-size: 13px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #595757;
                line-height: 18px;
              }
              .subtitle {
                margin-top: 10px;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #afafaf;
                .item_icon {
                  margin-right: 5px;
                  width: 9px;
                  height: 11px;
                }
              }
            }
          }
        }
        .second_list {
          margin: 10px -14px 0;
          .item {
            padding: 15px;
            border-bottom: 1px solid #e7e7e7;
            cursor: pointer;
            &:last-child {
              border-bottom: none;
            }
            &:hover {
              background: #ecf2f6;
              .head {
                .item_title {
                  color: #1c9bb2;
                }
              }
            }
            .head {
              display: flex;
              justify-content: space-between;
              .item_title {
                font-size: 16px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #595757;
              }
              .price {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #1c9bb2;
              }
            }
            .info {
              margin-top: 16px;
              display: flex;
              align-items: center;
              .info_img {
                margin-right: 3px;
                width: 17px;
                height: 17px;
              }
              .info_icon {
                margin-right: 3px;
                width: 9px;
                height: 11px;
                &.icon_cirle {
                  width: 17px;
                  height: 17px;
                  border-radius: 50%;
                }
              }
              .info_name {
                margin-right: 12px;
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #afafaf;
              }
              .right_info {
                margin-left: auto;
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #afafaf;
              }
            }
          }
        }
        & /deep/ .el-menu {
          display: flex;
          justify-content: center;
        }
        & /deep/ .el-menu--horizontal > .el-menu-item {
          height: 40px;
          line-height: 40px;
        }
        & /deep/ .el-menu.el-menu--horizontal {
          border-bottom: none;
        }
        & /deep/ .el-menu-item {
          margin: 0 10px;
          padding: 0;
        }
        .third_list {
          .item {
            padding: 10px 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:first-child {
              margin-top: 6px;
            }
            &:hover {
              background: #ecf2f6;
            }
            &.active {
              .info {
                .title {
                  color: #1c9bb2;
                }
              }
              .btn {
                color: #1c9bb2;
                border-color: #1c9bb2;
              }
            }
            .item_img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
            }
            .info {
              margin-left: 13px;
              .title {
                font-size: 13px;
                font-family: PingFangSC;
                font-weight: 500;
                color: #595757;
              }
              .subtitle {
                margin-top: 6px;
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #afafaf;
              }
            }
            .btn {
              padding: 5px 8px;
              margin-left: auto;
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #afafaf;
              border: 1px solid #ebebeb;
            }
          }
        }
        .fourth_list {
          .item {
            margin-top: 20px;
            .item_title {
              display: flex;
              align-items: center;
              .title_img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
              }
              .name {
                margin-left: 8px;
                font-size: 13px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #afafaf;
              }
              .read {
                margin-left: auto;
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #afafaf;
              }
            }
            .card {
              margin-top: 10px;
              padding: 17px;
              background: #ecf2f6;
              .detail {
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #a8a9a9;
                line-height: 20px;
              }
              .btn {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #1c9bb2;
                .btn_icon {
                  margin-right: 2px;
                  width: 11px;
                  height: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  .color_title {
    margin-top: 30px;
    padding: 0 5px;
    width: 99px;
    height: 24px;
    line-height: 24px;
    font-size: 19px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
    text-align: right;
    background: center/ 100% 100% url('~@as/index/title_bg.png');
  }
}
.card_container {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  background: #e6e7e9;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    width: 240px;
    height: 350px;
    box-sizing: border-box;
    &:nth-child(1) {
      background: center/100% 100% url('~@as/index/card_bg1.png') no-repeat;
      &:hover {
        background: linear-gradient(
            to right,
            rgba(28, 155, 178, 0.75),
            rgba(28, 155, 178, 0.75)
          ),
          center/100% 100% url('~@as/index/card_bg1.png') no-repeat;
      }
    }
    &:nth-child(2) {
      background: center/100% 100% url('~@as/index/card_bg2.png') no-repeat;
      &:hover {
        background: linear-gradient(
            to right,
            rgba(28, 155, 178, 0.75),
            rgba(28, 155, 178, 0.75)
          ),
          center/100% 100% url('~@as/index/card_bg2.png') no-repeat;
      }
    }
    &:nth-child(3) {
      background: center/100% 100% url('~@as/index/card_bg3.png') no-repeat;
      &:hover {
        background: linear-gradient(
            to right,
            rgba(28, 155, 178, 0.75),
            rgba(28, 155, 178, 0.75)
          ),
          center/100% 100% url('~@as/index/card_bg3.png') no-repeat;
      }
    }
    &:nth-child(4) {
      background: center/100% 100% url('~@as/index/card_bg4.png') no-repeat;
      &:hover {
        background: linear-gradient(
            to right,
            rgba(28, 155, 178, 0.75),
            rgba(28, 155, 178, 0.75)
          ),
          center/100% 100% url('~@as/index/card_bg4.png') no-repeat;
      }
    }
    &:nth-child(5) {
      background: center/100% 100% url('~@as/index/card_bg5.png') no-repeat;
      &:hover {
        background: linear-gradient(
            to right,
            rgba(28, 155, 178, 0.75),
            rgba(28, 155, 178, 0.75)
          ),
          center/100% 100% url('~@as/index/card_bg5.png') no-repeat;
      }
    }
    &:hover {
      .line {
        display: none;
      }
      .subtitle {
        display: none;
      }
      .detail {
        display: block;
      }
      .btn {
        display: block;
      }
    }
    .title {
      font-size: 20px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      span {
        &:first-child {
          padding: 0 2px;
          font-size: 17px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
          background: #1c9ab1;
        }
        &:last-child {
          padding-left: 5px;
        }
      }
    }
    .line {
      margin-top: 20px;
      width: 29px;
      height: 2px;
      background: rgba(255, 255, 255, 0.4);
    }
    .subtitle {
      margin-top: 17px;
      max-width: 144px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
    }
    .detail {
      margin-top: 24px;
      display: none;
      line-height: 20px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
    }
    .btn {
      margin-top: 63px;
      display: none;
      padding: 4px 24px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      border: 1px solid #46a2b4;
    }
  }
}
</style>
