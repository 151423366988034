<template>
  <div class="Renqiauthour contentWidth">
    <sidebar></sidebar>
    <div class="midContent">
      <div class="topAuthor canClick">榜单规则 I 作者福利</div>
      <div class="commonFlex wrapRank">
        <img :src="require('@as//reading/authorStar.png')" alt="" />
        <div class="paihanghuilv">汇率阅读人气作者排行</div>
      </div>
      <div class="warpRankList">
        <div class="topWrapRankKind commonBorder">
          <div class="betweenFlex wrapMidRank">
            <div
              class="zhoubang commonFlex"
              :class="{ selected: activeIndex == 0 }"
              @click="activeIndex = 0"
            >
              周榜
            </div>
            <div
              class="zongbang commonFlex"
              :class="{ selected: activeIndex == 1 }"
              @click="activeIndex = 1"
            >
              总榜
            </div>
          </div>
        </div>
        <el-table
          :data="tableData"
          style="width: 1030px; margin: 0 auto; margin-top: 15px"
        >
          <el-table-column label="排名" type="index" width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.index == 1">
                <img :src="require('@as/reading/1.png')" alt="" />
              </div>
              <div v-if="scope.row.index == 2">
                <img :src="require('@as/reading/2.png')" alt="" />
              </div>
              <div v-if="scope.row.index == 3">
                <img :src="require('@as/reading/3.png')" alt="" />
              </div>
              <div v-if="scope.row.index > 3" style="position: relative">
                <img :src="require('@as/reading/authorSign.png')" alt="" />
                <div style="position: absolute; top: 0; left: 35px">
                  {{ scope.row.index }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="zuozhe" label="作者">
            <template slot-scope="scope">
              <div class="commonFlex">
                <img
                  :src="require('@as/reading/test.png')"
                  alt=""
                  style="width: 36px; height: 36px; border-radius: 50%"
                />
                <span style="margin-left: 20px">{{ scope.row.zuozhe }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="wenzhangCount"
            label="文章数"
            width="140"
            class="commonFlex"
          >
          </el-table-column>
          <el-table-column prop="yueduCount" label="阅读数" width="140">
          </el-table-column>
          <el-table-column prop="zanCount" label="获赞数" width="140">
          </el-table-column>
          <el-table-column prop="yingxiang" label="影响力" width="150">
          </el-table-column>
          <el-table-column label="" width="100">
            <template slot-scope="scope">
              <div
                @click="handleClick(scope.row)"
                class="guanzhuTable commonFlex"
              >
                关注
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center; padding: 34px 0">
          <el-pagination background layout="prev, pager, next" :total="1000">
          </el-pagination>
        </div>
      </div>
      <div class="guizeWord">
        <div class="bangdanRule">
          <div class="heng"></div>
          榜单规则
          <div class="heng"></div>
        </div>
        <div
          style="
            margin-top: 62px;
            font-size: 16px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #333333;
          "
        >
          一、云+社区作者影响力排行榜单说明
        </div>
        <div
          style="
            margin-top: 23px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #666666;
          "
        >
          1）周榜是以自然周为统计周期的榜单，总榜是累计榜单，每周一18:00刷新。
          2）云+社区影响力评测指标主要包括发文量、阅读数、官方推荐次数以及文章互动量（点赞、评论、收藏）。
          3）原创比例提升、社区活跃提升、个人信息完善度高等有助于提升排名，长时间无更新、文章删除过多、被举报成功等会对排名产生负面影响。
          4）有任何疑问请邮件至“yunjia_community@tencent.com”。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from '@c/reading/sidebar'
export default {
  data() {
    return {
      activeIndex: 1,
      jijin: false,
      zhuanti: true,
      tableData: [
        {
          wenzhangCount: 12,
          zanCount: 23,
          yueduCount: 343,
          yingxiang: 231,
          zuozhe: '啊啊啊啊啊',
          index: 1,
        },
        {
          wenzhangCount: 12,
          zanCount: 23,
          yueduCount: 343,
          yingxiang: 231,
          zuozhe: '啊啊啊啊啊',
          index: 2,
        },
        {
          wenzhangCount: 12,
          zanCount: 23,
          yueduCount: 343,
          yingxiang: 231,
          zuozhe: '啊啊啊啊啊',
          index: 3,
        },
        {
          wenzhangCount: 12,
          zanCount: 23,
          yueduCount: 343,
          yingxiang: 231,
          zuozhe: '啊啊啊啊啊',
          index: 4,
        },
      ],
    }
  },
  components: {
    sidebar,
  },
  created() {
    console.log('首页')
  },
  methods: {
    selectJijin() {
      this.jijin = true
      this.zhuanti = false
    },
    selectZhuanti() {
      this.jijin = false
      this.zhuanti = true
    },
  },
}
</script>
<style>
.cell {
  text-align: center;
}
</style>
<style lang="less" scoped>
.Renqiauthour {
  display: flex;
  justify-content: space-between;

  .midContent {
    width: 1070px;
    .el-table {
      .el-table__header-wrapper {
        height: 50px;
        background: #f5f7f8;
      }
    }
    .topAuthor {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
    .guizeWord {
      width: 1070px;
      margin-bottom: 35px;
      // height: 770px;
      background: #ffffff;
      padding: 40px 30px;
      margin-top: 20px;
      .heng {
        width: 30px;
        height: 1px;
        color: #dedede;
        margin: 0 30px;
      }
      .bangdanRule {
        display: flex;
        text-align: center;
        width: 260px;
        align-items: center;
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        margin: 0 auto;
      }
    }
    .guanzhuTable {
      width: 72px;
      height: 32px;
      border: 1px solid #1c9ab1;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    .wrapRank {
      widows: 100%;
      flex-direction: column;
      .paihanghuilv {
        margin-top: 19px;

        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 300;
        color: #333333;
      }
    }
    .warpRankList {
      width: 1070px;
      margin-top: 36px;
      background: #ffffff;
      .topWrapRankKind {
        height: 60px;
        width: 1070px;
        margin: 0 auto;
        .wrapMidRank {
          width: 200px;
          margin: 0 auto;
          height: 100%;
        }
        .zhoubang,
        .zongbang {
          height: 100%;
          width: 100px;
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #333333;
          cursor: pointer;
          border-bottom: 2px solid transparent;
        }
        .selected {
          color: #1c9ab1;
          border-bottom: 2px solid #1c9ab1 !important;
        }
      }
    }
  }
}
</style>