<template>
  <div class="shimingRenzheng">
    <div class="top commonBorder">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="80"
        :height="56"
        :size="16"
        :distance="44"
      />
    </div>
    <div v-show="renzhengChoice">
      <div class="startFlex" style="padding: 20px">
        <div class="jigouRenzheng startFlex">
          <img :src="require('@as/personal/企业.png')" alt="" />
          <div class="right" style="margin-left: 20px">
            <div class="betweenFlex">
              <div
                style="
                  font-size: 18px;
                  font-family: PingFangSC;
                  font-weight: 500;
                  color: #ffffff;
                "
              >
                机构认证
              </div>
              <img :src="require('@as/personal/矩形 17.png')" alt="" />
            </div>
            <div class="renzhengword" style="margin-top: 10px">
              享受专属标识
            </div>
            <div class="renzhengword">
              适合企业/公司/机构/团队，企业相关品牌及服务等。
            </div>
            <div
              class="instantRenzheng commonFlex canClick"
              @click="
                jigourenzheng = true
                renzhengChoice = false
              "
            >
              立即认证
            </div>
          </div>
        </div>
        <div class="gerenRenzheng startFlex">
          <img :src="require('@as/personal/个人认证 (1).png')" alt="" />
          <div class="right" style="margin-left: 20px">
            <div class="betweenFlex">
              <div
                style="
                  font-size: 18px;
                  font-family: PingFangSC;
                  font-weight: 500;
                  color: #ffffff;
                "
              >
                个人认证
              </div>
              <img :src="require('@as/personal/矩形 17.png')" alt="" />
            </div>
            <div class="renzhengword" style="margin-top: 10px">
              享受专属标识
            </div>
            <div class="renzhengword">
              适合垂直领域专家/大人/爱好者及其他自然人注册和申请。
            </div>
            <div
              class="instantRenzheng commonFlex canClick"
              @click="
                gerenrenzheng = true
                renzhengChoice = false
              "
            >
              立即认证
            </div>
          </div>
        </div>
      </div>
      <div class="quanxiTitle commonFlex">实名认证后您将获得以下权益</div>
      <div class="onlyFlex WrapAllQunaYI">
        <div
          class="quanyiitem commonFlex"
          v-for="(item, index) in quanyilist"
          :key="index"
        >
          <img :src="item.imgsrc" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="desp">{{ item.desp }}</div>
        </div>
      </div>
      <div class="quanxiTitle commonFlex">实名认证审核及费用</div>
      <div class="renzhengPay">
        <div class="mainTitle">实名认证审核需要多久？</div>
        <div class="answer">
          资料提交后，3~7个工作日即可完成审核；届时可能会有电话回访，请留意来电
        </div>
        <div class="mainTitle">实名认证审核需要缴费吗？</div>
        <div class="answer">
          实名认证的审核服务费为 300元/次，1年有效；到期后需重新认证。
        </div>
        <div class="mainTitle">1个公司或个人可以认证多个主办方账号吗？</div>
        <div class="answer">
          可以，只要主办方账号信息不同，均可进行实名认证；
        </div>
        <div class="mainTitle">认证后，可以开发票吗？</div>
        <div class="answer">可以，提交认证后次日可至财务中心申请开具发票。</div>
      </div>
    </div>
    <apartmentRenzheng
      v-show="jigourenzheng"
      @toBack="change"
      @toCheck="toCheck"
    />
    <gerenRenzheng v-show="gerenrenzheng" @toBack="change" @toCheck="toCheck" />
    <div class="renzhengResult commonFlex" v-show="renzhengSuccess">
      <img :src="require('@as/personal/success.png')" alt="" />
      <div class="renzhengSucces">认证信息提交成功！</div>
      <div class="wanshai">我们将尽快完成信息审核，请耐心等待</div>
    </div>
  </div>
</template>

<script>
import headerChoice from '../headerChoice'
import apartmentRenzheng from './apartmentRenzheng'
import gerenRenzheng from './gerenRenzheng'
export default {
  data() {
    return {
      activeIndex: 0,
      jigourenzheng: false,
      gerenrenzheng: false,
      renzhengSuccess: false,
      renzhengChoice: true,
      choiceList: ['实名认证'],
      quanyilist: [
        {
          imgsrc: require('@as/personal/个人认证.png'),
          title: '专属标识',
          desp: '加V标识，提高辨识度，增加 可信度',
        },
        {
          imgsrc: require('@as/personal/个性化.png'),
          title: '个性化主页',
          desp: '更好的展示品牌形象，深度宣 传品牌理念',
        },
        {
          imgsrc: require('@as/personal/推广.png'),
          title: '推广特权',
          desp: '优先推荐，提升粉丝数和品牌',
        },
        {
          imgsrc: require('@as/personal/运营.png'),
          title: '运营优势 ',
          desp: '优先审核内容，工作时间4小时 完成审核 (非认证用户为24小时) ',
        },
        {
          imgsrc: require('@as/personal/特权.png'),
          title: '黄页特权',
          desp: '更全面的介绍 ',
        },
        {
          imgsrc: require('@as/personal/招标.png'),
          title: '招标特权 ',
          desp: '无限制查看VIP招标内容 （非认证用户为免费内容）',
        },
        {
          imgsrc: require('@as/personal/模块.png'),
          title: '板块更全 ',
          desp: '多栏目集于一身 （招聘仅适用于已认证企业用户）',
        },
        {
          imgsrc: require('@as/personal/权益.png'),
          title: '独享权益 ',
          desp: '专属客服，针对性服务',
        },
      ],
    }
  },
  methods: {
    change(index) {
      this.activeIndex = index
      this.jigourenzheng = false
      this.gerenrenzheng = false
      this.renzhengChoice = true
      this.renzhengSuccess = false
    },
    toCheck() {
      this.jigourenzheng = false
      this.gerenrenzheng = false
      this.renzhengChoice = false
      this.renzhengSuccess = true
    },
  },
  components: {
    headerChoice,
    apartmentRenzheng,
    gerenRenzheng,
  },
}
</script>

<style lang="less">
.shimingRenzheng {
  .renzhengSucces {
    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
    margin-top: 10px;
  }
  .wanshai {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    margin-top: 10px;
  }
  .renzhengResult {
    width: 100%;
    height: 400px;
    flex-direction: column;
  }
  .mainTitle {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
    margin-top: 32px;
  }
  .answer {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
    margin-top: 20px;
  }
  .renzhengPay {
    padding: 20px;
  }
  .WrapAllQunaYI {
    flex-wrap: wrap;
    padding-left: 80px;
    padding-top: 30px;
  }
  .quanyiitem {
    width: 150px;
    flex-direction: column;
    margin-right: 70px;
    justify-content: flex-start;
    margin-bottom: 30px;
    .title {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
      margin-top: 10px;
    }
    .desp {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-top: 10px;
    }
  }
  .quanxiTitle {
    width: 920px;
    height: 50px;
    background: #f8f8f8;
    border-left: 2px solid #1c9ab1;
    padding-left: 30px;
    margin-top: 30px;
    margin-left: 20px;

    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }

  .top {
    padding-left: 20px;
  }
  .instantRenzheng {
    width: 128px;
    height: 30px;
    background: #ffffff;
    box-shadow: 7px 7px 15px 0px rgba(1, 31, 37, 0.05);
    border-radius: 15px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
    margin-top: 15px;
  }
  .renzhengword {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
    // margin-top:10px;
  }
  .jigouRenzheng {
    align-items: flex-start;
    padding: 20px;
    width: 440px;
    height: 190px;
    background: #1c9ab1;
    border-radius: 3px;
  }
  .gerenRenzheng {
    width: 440px;
    align-items: flex-start;
    padding: 20px;
    height: 190px;
    background: #f18e1f;
    border-radius: 3px;
    margin-left: 30px;
  }
}
</style>