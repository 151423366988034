const state = {
  footerShow: sessionStorage.getItem("footerShow") || 1,
};
const mutations = {
  change(state, res) {
    state.footerShow = res.boole;
    sessionStorage.setItem("footerShow", res.boole);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
};
