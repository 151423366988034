import { render, staticRenderFns } from "./renqiAuthor.vue?vue&type=template&id=79d26041&scoped=true&"
import script from "./renqiAuthor.vue?vue&type=script&lang=js&"
export * from "./renqiAuthor.vue?vue&type=script&lang=js&"
import style0 from "./renqiAuthor.vue?vue&type=style&index=0&lang=css&"
import style1 from "./renqiAuthor.vue?vue&type=style&index=1&id=79d26041&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d26041",
  null
  
)

export default component.exports