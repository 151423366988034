<template>
  <div class="gerenRenzhengWrap">
    <div class="companyInfo">个人信息认证</div>
    <div>
      <div class="startFlex">
        <span class="title">姓名</span>
        <span style="color: red">*</span>
      </div>
      <input type="text" placeholder="请填写实际姓名" class="companyRenzheng" />
    </div>
    <div style="margin-top: 20px">
      <div class="betweenFlex wrapMust">
        <div class="startFlex">
          <span class="title">运营者手机号</span>
          <span style="color: red">*</span>
        </div>
        <div class="startFlex">
          <span class="title"> 短信验证码 </span>
          <span style="color: red">*</span>
        </div>
      </div>

      <input
        type="text"
        placeholder="请输入运营人手机号"
        class="yunyingphone"
      />
      <input type="text" placeholder="获取验证码" class="yanzhengCode" />
      <input
        type="text"
        placeholder="请输入短信验证码"
        class="yanzhengCodeInput"
      />
    </div>
    <div style="margin-top: 25px">
      <div class="startFlex">
        <span class="title">身份证号码 </span>
        <span style="color: red">*</span>
      </div>
      <input
        type="text"
        placeholder="请填写您的身份证号"
        class="companyRenzheng"
      />
    </div>
    <div style="margin-top: 25px" class="startFlex">
      <div>
        <div class="startFlex">
          <span class="title">身份证正面照片</span>
          <span style="color: red">*</span>
        </div>
        <div class="startFlex wrapbeizhu">
          <div class="wrapUpload commonFlex">
            <div class="limit">不超过1MBjpg，png</div>
            <div class="shangchuan commonFlex">上传</div>
          </div>
        </div>
      </div>
      <div style="margin-left: 30px">
        <div class="startFlex">
          <span class="title">身份证背面照片</span>
          <span style="color: red">*</span>
        </div>
        <div class="startFlex wrapbeizhu">
          <div class="wrapUpload commonFlex">
            <div class="limit">不超过1MBjpg，png</div>
            <div class="shangchuan commonFlex">上传</div>
          </div>
          <div class="beizhu">
            请上传中国大陆工商局或市场监督管理局颁发的营业执照。
          </div>
        </div>
      </div>
    </div>
    <div class="startFlex wrapStep commonFlex">
      <div class="lastStep commonFlex canClick" @click="toBack()">上一步</div>
      <div class="nextStep commonFlex canClick" @click="toCheck()">下一步</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
   methods:{
  toBack(){
    this.$emit('toBack')
  },
   toCheck(){
    this.$emit('toCheck')
  }
  }
};
</script>

<style lang="less">
.gerenRenzhengWrap {
  .wrapStep {
    text-align: center;

    margin: 0 auto;
    margin-top: 50px;
  }
  .nextStep {
    width: 100px;
    height: 32px;
    background: #1c9ab1;
    border-radius: 2px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
    margin-left: 25px;
  }
  .lastStep {
    width: 100px;
    height: 32px;
    border: 1px solid #1c9ab1;
    border-radius: 2px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
  }
  .wrapbeiyongphone {
    width: 543px;
  }
  .beiyongdianhua {
    width: 450px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cccccc;
  }
  .youxiaoEmail {
    width: 450px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cccccc;
    margin-left: 30px;
  }
  .wrapMust {
    width: 684px;
  }
  input {
    padding-left: 20px;
  }
  padding: 20px;
  .yunyingphone {
    width: 440px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cccccc;
  }
  .yanzhengCodeInput {
    width: 320px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cccccc;
    margin-left: 40px;
  }
  .yanzhengCode {
    width: 110px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cccccc;
    margin-left: 10px;
  }
  .wrapbeizhu {
    align-items: flex-end;
  }
  .yunying {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
    margin: 20px 0;
  }
  .wrapUpload {
    flex-direction: column;

    padding: 0 10px;
    width: 120px;
    height: 120px;
    background: #ffffff;
    border: 1px solid #cccccc;
  }
  .limit {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
        width: 63px;
    word-break: break-all;
    line-height: 24px;
    text-align: center;
  }
  .shangchuan {
    width: 64px;
    height: 24px;
    background: #1c9ab1;
    margin-top: 15px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .beizhu {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    margin-left: 20px;
  }
  .companyInfo {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
    margin: 20px 0;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
    margin-bottom: 5px;
  }
  .companyRenzheng {
    width: 920px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cccccc;
  }
}
</style>