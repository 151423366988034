<template>
  <div class="hotjob">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <img :src="require('@as/reading/tashan.png')" alt="" />
        <span class="hotArticleWord">他山之石</span>
      </div>
      <span class="moreWord">MORE</span>
    </div>
    <el-carousel height="113px" style="margin-top: 23px">
      <el-carousel-item v-for="item in cardList" :key="item">
        <img :src="item" alt="" style="height: 113px; width: 100%" />
      </el-carousel-item>
    </el-carousel>
    <el-menu
      :default-active="activeName"
      mode="horizontal"
      @select="handleClick"
    >
      <el-menu-item index="first">技 能</el-menu-item>
      <el-menu-item index="second">经 验</el-menu-item>
      <el-menu-item index="third">访 谈</el-menu-item>
    </el-menu>
    <ul>
      <li v-for="(item, index) in list" :key="index" class="hotArticleItemJob">
        <div class="hotArticleItemTitle">
          <div class="circle"></div>
          <div class="title">{{ item.title }}</div>
        </div>
        <div
          class="betweenFlex zhuantiBot"
          style="
            margin-top: 17px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #a6a7a7;
          "
        >
          <div>
            {{ item.time }}
          </div>
          <div>
            <span>{{ item.readCount }} </span>
            <span>人阅读</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: 'second',
      cardList: [
        require('@as/reading/test.png'),
        require('@as/reading/company.png'),
        require('@as/reading/detailShare.png'),
      ],
      list: [
        {
          title: '中国进出口银行湖南省分行五凌电力孟加拉风电力孟加拉风',
          time: '2020.08.04',
          readCount: 3434,
        },
        {
          title: '中国进出口银行湖南省分行五凌电力孟加拉风电力孟加拉风',
          time: '2020.08.04',
          readCount: 3434,
        },
        {
          title: '中国进出口银行湖南省分行五凌电力孟加拉风电力孟加拉风',
          time: '2020.08.04',
          readCount: 3434,
        },
        {
          title: '中国进出口银行湖南省分行五凌电力孟加拉风电力孟加拉风',
          time: '2020.08.04',
          readCount: 3434,
        },
        {
          title: '中国进出口银行湖南省分行五凌电力孟加拉风电力孟加拉风',
          time: '2020.08.04',
          readCount: 3434,
        },
      ],
    }
  },
  methods: {
    handleClick() {},
  },
}
</script>

<style lang="less" scoped >
.hotjob {
  padding: 20px;
  padding-top: 10px;
  background: white;
  margin-top: 20px;
  & /deep/ .el-menu {
    display: flex;
    justify-content: center;
  }
  & /deep/ .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  & /deep/ .el-menu-item {
    padding: 8px 4px;

    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    &:not(:first-child) {
      margin-left: 16px;
    }
  }
  & /deep/ .el-menu--horizontal > .el-menu-item.is-active {
    color: #1c99b0;
    border-bottom: 2px solid #1c99b0;
  }
  .hotArticleItemJob {
    margin: 0 -20px;
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    line-height: 18px;
    padding: 18px 20px 0;
    // display: flex;
    // align-items: flex-start;
    &:hover {
      cursor: pointer;
      background: #f2fdff;
    }
    .zhuantiBot {
      padding: 0 0 18px 13px;
      border-bottom: 1px solid #dedede;
      span {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #babbbb;
      }
    }
    &:last-child {
      .zhuantiBot {
        border-bottom: none !important;
      }
    }
    .hotArticleItemTitle {
      display: flex;
      align-items: baseline;
      font-size: 13px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
      margin-top: 15px;

      .circle {
        margin-right: 9px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #1c9ab1;
      }
      .title {
        width: 276px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    .moreWord {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a7a8a8;
      cursor: pointer;
    }
    .wraphotArticleWord {
      border-bottom: 3px solid #1c9ab1;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .hotArticleWord {
        margin-left: 9px;

        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
    }
  }
}
</style>