<template>
  <div class="zhuantiArticle">
    <div class="titleFirst commonBorder">专题文章</div>
    <articleList></articleList>
    <div class="lookmore commonFlex">查看更多</div>
  </div>
</template>
<script>
import articleList from './articleList'
export default {
  data() {
    return {}
  },
  components: {
    articleList,
  },
}
</script>
<style lang="less">
.zhuantiArticle {
  width: 850px;
  background: #ffffff;
  border: 1px solid #dedede;
  padding: 0 20px;
  .titleFirst {
    height: 65px;
    // margin-left: 20px;
    display: flex;
    align-items: center;

    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .lookmore {
    width: 810px !important;
  }
}
</style>