<template>
  <div class="zhaopinDepartWrap contentWidth">
    <div class="wrapTopImg commonFlex">
      <div class="topImg"></div>
      <div class="huilvTitle">汇律招·惠律人</div>
      <div class="zhaopinLook canClick">查看更多 ></div>
      <div class="wrapBotJob commonFlex">
        <div class="betweenFlex choiceWidth">
          <div
            class="commonFlex danweiItem"
            :class="{ selectedjob: activeIndex == 0 }"
            @click="toJob(0)"
          >
            职位
          </div>
          <div
            class="commonFlex danweiItem"
            :class="{ selectedjob: activeIndex == 1 }"
          >
            单位
          </div>
        </div>
      </div>
    </div>
    <div class="wrapDestination">
      <div class="wrapDIdianLine">
        <div class="jobDest">工作地点:</div>
        <div
          v-for="(item, index) in destination"
          :key="index"
          :class="{ selectedXingzhi: activeDes == index }"
          class="destinationitem"
          @click="activeDes = index"
        >
          {{ item }}
        </div>
        <div class="more">
          <div>更多</div>
          <img
            :src="require('@as/zhaopin/jiagengduo.png')"
            alt=""
            style="margin-left: 7px"
            class="canClick"
          />
        </div>
      </div>
      <div class="wrapDIdianLine secondLine">
        <div class="jobDest">工作性质:</div>
        <div
          v-for="(item, index) in destination"
          :key="index"
          :class="{ selectedXingzhi: activeXingzhi == index }"
          class="destinationitem"
          @click="activeXingzhi = index"
        >
          {{ item }}
        </div>
        <div class="more">
          <div>更多</div>
          <img
            :src="require('@as/zhaopin/jiagengduo.png')"
            alt=""
            style="margin-left: 7px"
            class="canClick"
          />
        </div>
      </div>
    </div>
    <ul class="wrapJobList">
      <li
        v-for="(item, index) in jobList"
        :key="index"
        class="jobListItem canClick"
        :class="{ noDistance: (index + 1) % 4 == 0 }"
      >
        <div class="jobItemTop commonBorder">
          <img
            :src="require('@as/reading/test.png')"
            alt=""
            style="width: 48px; height: 48px"
          />
          <div style="text-align: right">
            <div style="display: flex">
              <img :src="require('@as/zhaopin/renzheng.png')" alt="" />
              <span class="name">{{ item.name }}</span>
            </div>
            <div class="wrapCount">
              <span class="count">{{ item.count }}</span
              >在招职位 | 北京
            </div>
          </div>
        </div>
        <div class="jobItemBot">
          <span class="rezhao">热招</span>
          <span class="jobTitle">{{ item.jobTitle }}</span>
          <span class="mianyi">面议</span>
        </div>
      </li>
    </ul>
    <div
      class="jiazaiMore commonFlex canClick"
      @click="getCompany"
      v-if="hasMore"
    >
      加载更多
    </div>
  </div>
</template>

<script>
import { Job } from '@api'
export default {
  data() {
    return {
      hasMore: true,
      pageNum: 1,
      companyList: [],
      activeIndex: 1,
      activeDes: 0,
      activeXingzhi: 0,
      destination: [
        '全国',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
      ],
      xingzhi: ['不限', '销售', '销售', '销售', '销售', '销售', '销售', '销售'],
      jobList: [
        {
          name: '轻轻巧巧',
          count: 22,
          destination: '北京',
          jobTitle: '某某主力',
        },
        {
          name: '轻轻巧巧',
          count: 22,
          destination: '北京',
          jobTitle: '某某主力',
        },
        {
          name: '轻轻巧巧',
          count: 22,
          destination: '北京',
          jobTitle: '某某主力',
        },
        {
          name: '轻轻巧巧',
          count: 22,
          destination: '北京',
          jobTitle: '某某主力',
        },
        {
          name: '轻轻巧巧',
          count: 22,
          destination: '北京',
          jobTitle: '某某主力',
        },
        {
          name: '轻轻巧巧',
          count: 22,
          destination: '北京',
          jobTitle: '某某主力',
        },
      ],
    }
  },
  created() {
    this.getCompany()
  },
  methods: {
    toJob(num) {
      this.$router.push('/zhaopinMain')
    },
    getCompany() {
      Job.getAllCompany({
        pageNum: this.pageNum,
        pageSize: 24,
      }).then((res) => {
        this.companyList = this.companyList.concat(res)
        this.pageNum += 1
      })
    },
  },
}
</script>

<style lang="less" >
.zhaopinDepartWrap {
  .danweiItem {
    cursor: pointer;
  }
  .selectedjob {
    border-bottom: 3px solid #1c9ab1;
    color: #1c9ab1 !important;
  }
  .selectedDes {
    color: #1c9ab1 !important;
  }
  .selectedXingzhi {
    border: 1px solid #c3e4eb !important;
    padding: 2px 7px;
    color: #1c9ab1 !important;
  }
  .wrapJobList {
    display: flex;
    flex-wrap: wrap;
  }
  .jiazaiMore {
    width: 1200px;
    height: 50px;
    background: #ffffff;

    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .jobListItem:hover {
    box-shadow: 7px 7px 15px 0px rgba(1, 31, 37, 0.05);
  }

  .jobListItem {
    width: 280px;
    height: 140px;
    background: #ffffff;
    margin-right: 26px;
    margin-top: 20px;
    padding: 30px;
    .wrapCount {
      margin-top: 17px;
      color: #afafaf;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      .count {
        margin-right: 4px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
    }
    .jobItemBot {
      margin-top: 17px;
      .mianyi {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
        margin-left: 15px;
      }
      .rezhao {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #666666;
      }
      .jobTitle {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        margin-left: 15px;
      }
    }
    .jobItemTop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;

      .name {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        margin-left: 8px;
      }
    }
  }
  .wrapTopImg {
    position: relative;
    color: white;
    flex-direction: column;
    width: 100%;
    height: 300px;
    .topImg {
      width: 100%;
      height: 300px;
      position: absolute;
      background: url('~@as/zhaopin/banner.png');
      top: 0;
      left: 0;
    }
    .huilvTitle {
      font-size: 24px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #ffffff;
      z-index: 200;
    }
    .zhaopinLook {
      margin-top: 14px;

      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      z-index: 200;
    }
    .wrapBotJob {
      position: absolute;
      background: rgba(255, 255, 255, 0.5);
      bottom: 0;
      width: 100%;
      .choiceWidth {
        width: 150px;
        .whiteSlected {
          border-bottom: 2px solid white;
        }
        div {
          width: 64px;
          height: 100%;

          height: 50px;
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: black;
        }
      }
    }
  }
  .wrapDestination {
    width: 1200px;
    height: 160px;
    background: white;
    margin-top: 25px;
    padding: 39px 24px 46px 0px;
    border: 1px solid #dedede;
    .jobDest {
      margin-right: 26px;
    }
    .secondLine {
      margin-top: 40px;
    }
    .wrapDIdianLine {
      display: flex;
      align-items: center;
      padding-left: 37px;
      .destinationitem {
        margin-right: 37px;
        padding: 2px 8px;
        min-width: 44px;
        border: 1px solid transparent;

        box-sizing: border-box;
        &:hover {
          color: #1c9ab1;
          cursor: pointer;
        }
      }
      .el-select {
        margin-left: 7px;

        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      div {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
    }
  }
  .more {
    display: flex;
    align-items: center;
    &:hover {
      div {
        color: #1c9ab1 !important;
      }
      cursor: pointer;
    }
  }
}
</style>