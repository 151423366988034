<template>
  <div class="hotAuthor">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <img :src="require('@as/reading/hotAuthor.png')" alt="" />
        <span class="hotArticleWord">热门作者</span>
      </div>
      <span class="moreWord canClick">MORE</span>
    </div>
    <ul>
      <li
        v-for="(item, index) in list"
        :key="index"
        class="hotArticleItem"
        :class="{ hasBack: index % 2 != 0 }"
      >
        <img
          :src="require('@as/reading/listImg.png')"
          alt=""
          class="authorImg"
        />
        <div class="startFlex" style="flex: 1">
          <span class="hotArticleItemTitle">{{ item.title }}</span>
          <img :src="require('@as/reading/personFlag1.png')" alt="" />
        </div>

        <span class="follow">{{ item.follow }}</span>
        <div v-if="!item.isguanzhu" class="noGuanzhu commonFlex">+关注</div>
        <div v-if="item.isguanzhu" class="hasGuanzhu commonFlex">已关注</div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        { title: '李欣欣', follow: 123, isguanzhu: true },
        { title: '李欣欣', follow: 123, isguanzhu: false },
        { title: '李欣欣', follow: 123, isguanzhu: false },
        { title: '李欣欣', follow: 123, isguanzhu: false },
        { title: '李欣欣', follow: 123, isguanzhu: false },
      ],
    }
  },
  methods: {},
}
</script>
<style lang="less">
.hotAuthor {
  padding: 20px 10px;
  background: white;
  margin-top: 20px;
  padding-top: 10px;
  .hasGuanzhu,
  .noGuanzhu {
    cursor: pointer;
  }
  .hasBack {
    background: #f5f7f8;
  }
  .hotArticleItem {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;

    line-height: 18px;
    padding: 18px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    .hasGuanzhu {
      width: 51px;
      height: 23px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      border: 1px solid #1c9ab1;
    }
    .noGuanzhu {
      width: 51px;
      height: 23px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #babbbb;
      border: 1px solid #babbbb;
    }
    .follow {
      margin-right: 28px;
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    .authorImg {
      margin-right: 15px;
      width: 37px;
      height: 37px;
      border-radius: 50%;
    }
    &:last-child {
      border-bottom: none !important;
    }
    .hotArticleItemTitle {
      font-size: 13px;
      margin-right: 8px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #595757;
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    margin: 0 10px;
    width: 280px;
    .moreWord {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a7a8a8;
    }
    .wraphotArticleWord {
      border-bottom: 3px solid #1c9ab1;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .hotArticleWord {
        margin-left: 8px;

        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
    }
  }
}
</style>