import req from "@/utils/request";
import { Message } from "element-ui";

function tips(hasId, title, status) {
  const msg = [
    "简历",
    title,
    hasId ? "更新" : "创建",
    status === "success" ? "成功" : "失败",
  ];
  Message[status](msg.join(""));
  return Promise[status === "success" ? "resolve" : "reject"]();
}

// 保存或更新
const change = {
  // 用户简历信息
  changeBasic: (data) =>
    req.post("/resume/basis/saveOrUpdateUserResumeBasis", data).then(
      () => tips(!!data.id, "基本信息", "success"),
      () => tips(!!data.id, "基本信息", "error")
    ),

  // 用户职业意向
  changeIntention: (data) =>
    req.post("/resume/intention/saveOrUpdateUserResumeIntention", data).then(
      () => tips(!!data.id, "职业意向", "success"),
      () => tips(!!data.id, "职业意向", "error")
    ),

  // 用户实习经历
  changeExperience: (data) =>
    req.post("/resume/experience/saveOrUpdateUserResumeExperience", data).then(
      () => tips(!!data.id, "实习经历", "success"),
      () => tips(!!data.id, "实习经历", "error")
    ),

  // 用户教育经历
  changeEdu: (data) =>
    req.post("/resume/edu/saveOrUpdateUserResumeEdu", data).then(
      () => tips(!!data.id, "教育经历", "success"),
      () => tips(!!data.id, "教育经历", "error")
    ),

  // 用户项目经验
  changeProject: (data) =>
    req.post("/resume/project/saveOrUpdateUserResumeProject", data).then(
      () => tips(!!data.id, "项目经验", "success"),
      () => tips(!!data.id, "项目经验", "error")
    ),

  // 用户资格证书
  changeCert: (data) =>
    req.post("/resume/cert/saveOrUpdateUserResumeCert", data).then(
      () => tips(!!data.id, "资格证书", "success"),
      () => tips(!!data.id, "资格证书", "error")
    ),

  // 用户添加信息
  changeOther: (data) =>
    req.post("/resume/other/saveOrUpdateUserResumeOther", data).then(
      () => tips(!!data.id, "添加信息", "success"),
      () => tips(!!data.id, "添加信息", "error")
    ),

  // 用户招聘的附件
  changeFile: (data) =>
    req.post("/resume/file/saveOrUpdateUserResumeFile", data).then(
      () => tips(!!data.id, "附件", "success"),
      () => tips(!!data.id, "附件", "error")
    ),
};

// 获取信息
const getVal = {
  // 用户简历基础信息
  getBasic: (data) => req.get("/resume/basis/getUserResumeBasis", data),

  // 用户求职意向
  getIntention: (data) =>
    req.get("/resume/intention/getUserResumeIntention", data),

  // 用户工作/实习经历
  getExperience: (data) =>
    req.get("/resume/experience/getUserResumeExperience", data),

  // 用户教育经历
  getEdu: (data) => req.get("/resume/edu/getUserResumeEdu", data),

  // 用户项目经验
  getProject: (data) => req.get("/resume/project/getUserResumeProject", data),

  // 用户项目经验
  getOther: (data) => req.get("/resume/other/getUserResumeOther", data),

  // 用户上传的附件
  getFile: (data) => req.get("/resume/file/getUserResumeFile", data),
};

export default {
  ...change,
  ...getVal,
};
