<template>
  <div class="dizhiManage">
    <div class="startFlex topHead">
      <div class="shouhuo">收货人</div>
      <div class="dizhi">地址</div>
      <div class="phone">联系方式</div>
      <div class="opration">操作</div>
    </div>
    <ul>
      <li
        v-for="(item, index) in addresslist"
        :key="index"
        class="startFlex addressItem commonBorder"
      >
        <div class="nameAddress">{{ item.name }}</div>
        <div class="address">{{ item.address }}</div>
        <div class="phonelist">{{ item.phone }}</div>
        <div class="startFlex lastColume">
          <div @click="handlerEditor(index)">编辑</div>
          <div @click="handlerRemove(item.id)">删除</div>
          <div @click="handlerSetDefault(item.id)">设为默认</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { User } from '@api'
export default {
  data() {
    return {
      addresslist: [],
    }
  },
  methods: {
    getList() {
      User.getUserAddressList({
        userId: this.$store.state.uid,
      }).then((res) => {
        this.addresslist = res
      })
    },
    handlerEditor(idx) {
      this.$emit('editor', this.addresslist[idx])
    },
    handlerRemove(id) {
      this.$confirm('确认是否删除此地址信息！', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        User.delUserAddress({ id }).then(() => {
          this.getList()
        })
      })
    },
    handlerSetDefault(id) {
      User.changeDefaultAddress({ id }).then(() => {
        this.getList()
      })
    },
  },
}
</script>

<style lang="less">
.dizhiManage {
  padding: 20px;
  .topHead {
    width: 940px;
    height: 40px;
    background: #f5f6fa;
    border-radius: 2px 2px 0px 0px;
    padding-left: 20px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .lastColume {
    div {
      color: #1c9ab1 !important;
      margin: 0 10px;
      cursor: pointer;
    }
  }
  .addressItem {
    height: 80px;
    padding-left: 20px;
    div {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
  }
  .nameAddress,
  .shouhuo {
    width: 100px;
  }
  .address,
  .dizhi {
    width: 380px;
  }
  .phonelist,
  .phone {
    width: 190px;
  }
}
</style>