<template>
  <div class="tuijianZhuanlanRading">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <img :src="require('@as/reading/tuijianZhuanlan.png')" alt="" />
        <span class="hotArticleWord">推荐专栏</span>
      </div>
      <span class="moreWord" @click="toList()">MORE</span>
    </div>
    <ul v-if="list.length > 0" class="hotArticleList">
      <li v-for="(item, index) in list" :key="index" class="hotArticleItem">
        <div class="hotleft betweenFlex">
          <img :src="require('@as/reading/test.png')" alt="" class="" />
          <div style="margin-left: 12px">
            <div class="title" :class="{ alreadySub: item.dingyue == 1 }">
              {{ item.title }}
            </div>
            <div class="leftBot">
              {{ item.wenzhangCount }}篇文章 | {{ item.seeCount }}人看过
            </div>
          </div>
        </div>
        <div class="right">
          <span class="yidingyue commonFlex" v-if="item.dingyue == 1"
            >已订阅</span
          >
          <span class="dingyueBtn commonFlex" v-if="item.dingyue == 0"
            >订阅</span
          >
        </div>
      </li>
      <div
        class="wrapQiehuan commonFlex"
        @mouseover="isActive = true"
        @mouseout="isActive = false"
        :class="{ WraphuanActive: isActive }"
      >
        <img :src="require('@as/reading/copy.png')" alt="" v-if="!isActive" />
        <img :src="require('@as/reading/qiehuan.png')" alt="" v-if="isActive" />
        <div class="huanyipi" :class="{ huanActive: isActive }">换一批</div>
      </div>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dayRank: true,
      monthRank: false,
      weekRank: false,
      isActive: false,
      list: [
        { title: '大健康投', wenzhangCount: 12, seeCount: 12345, dingyue: 1 },
        { title: '大健康投', wenzhangCount: 12, seeCount: 12345, dingyue: 1 },
        { title: '大健康投', wenzhangCount: 12, seeCount: 12345, dingyue: 0 },
        { title: '大健康投', wenzhangCount: 12, seeCount: 12345, dingyue: 1 },
      ],
    }
  },
  methods: {
    dayClick() {
      this.dayRank = true
      this.monthRank = false
      this.weekRank = false
    },
    weekClick() {
      this.dayRank = false
      this.monthRank = false
      this.weekRank = true
    },
    monthClick() {
      this.dayRank = false
      this.monthRank = true
      this.weekRank = false
    },
    toList() {
      this.$router.push({ name: 'biaoqianList' })
    },
  },
}
</script>
<style lang="less">
.tuijianZhuanlanRading {
  padding: 20px;
  background: #ffffff;
  width: 330px;
  .alreadySub {
    color: #1c9bb2 !important;
  }
  .hotArticleList {
    margin: 0 -20px;
  }
  .hotArticleItem {
    display: flex;
    padding: 24px 20px 15px 20px;
    align-items: center;
    justify-content: space-between;
    .hotleft {
      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }

      .title {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #595757;
      }
      .leftBot {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;

        color: #afafaf;
        margin-top: 6px;
      }
    }
    .right {
      .yidingyue {
        width: 51px;
        height: 23px;
        // border:1px solid #1C9AB1;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
        border: 1px solid #d4ecf1;
      }
      .dingyueBtn {
        width: 50px;
        height: 23px;
        cursor: pointer;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        border: 1px solid #ebebeb;
      }
    }
    &:last-child {
      border-bottom: none !important;
    }
    .hotArticleItemTitle {
      margin-left: 12px;
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .WraphuanActive {
    border: 1px solid #1c9bb2 !important;
  }
  .huanActive {
    color: #1c9bb2 !important;
  }
  .wrapQiehuan {
    width: 84px;
    height: 24px;
    border: 1px solid #cccccc;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 25px;
    .huanyipi {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #cccccc;
      margin-left: 5px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    .moreWord {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a7a8a8;
      cursor: pointer;
    }
    .wraphotArticleWord {
      border-bottom: 3px solid #1c9ab1;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .hotArticleWord {
        margin-left: 9px;

        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
    }
  }
}
</style>