<template>
  <div class="hotNewsReading">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <img :src="require('@as/reading/phone.png')" alt="" />
        <span class="hotArticleWord">热门资讯</span>
      </div>
      <span class="moreWord">MORE</span>
    </div>
    <ul style="margin: 0 -20px">
      <li
        v-for="(item, index) in list"
        :key="index"
        class="hotArticleItem commonBorder"
      >
        <span class="hotArticleItemTitle">{{ item.title }}</span>
        <div class="betweenFlex botNews">
          <div class="wrapTimeImg">
            <img
              :src="require('@as/reading/time.png')"
              alt=""
              style="margin-right: 3px"
            />
            <span>{{ item.time }}</span>
          </div>
          <div class="wrapTimeImg" @click="item.like = !item.like">
            <img
              :src="
                item.like
                  ? require('@as/reading/contentZan.png')
                  : require('@as/reading/zan.png')
              "
              alt=""
              style="margin-right: 3px:width:12px;height:12px"
            />
            <span :class="{ active: item.like }">{{ item.zanCount }}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          title:
            '中国进出口银行湖南省分行五凌电力孟加拉风电法律服务项目-招标公告',
          time: '44分钟前',
          zanCount: 8,
          like: false,
        },
        {
          title:
            '中国进出口银行湖南省分行五凌电力孟加拉风电法律服务项目-招标公告',
          time: '44分钟前',
          zanCount: 8,
          like: false,
        },
        {
          title:
            '中国进出口银行湖南省分行五凌电力孟加拉风电法律服务项目-招标公告',
          time: '44分钟前',
          zanCount: 8,
          like: false,
        },
        {
          title:
            '中国进出口银行湖南省分行五凌电力孟加拉风电法律服务项目-招标公告',
          time: '44分钟前',
          zanCount: 8,
          like: false,
        },
        {
          title:
            '中国进出口银行湖南省分行五凌电力孟加拉风电法律服务项目-招标公告',
          time: '44分钟前',
          zanCount: 8,
          like: false,
        },
        {
          title:
            '中国进出口银行湖南省分行五凌电力孟加拉风电法律服务项目-招标公告',
          time: '44分钟前',
          zanCount: 8,
          like: false,
        },
      ],
    }
  },
  methods: {},
}
</script>
<style lang="less">
.hotNewsReading {
  padding: 20px;
  background: white;
  margin-top: 20px;
  padding-top: 10px;
  .hasBack {
    background: lightgray;
  }
  .hotArticleItem {
    flex-direction: column;
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    line-height: 18px;
    padding: 18px 20px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    &:hover {
      background: #ecf2f6;
    }
    .active {
      color: #1c9ab1 !important;
    }
    .botNews {
      margin-top: 20px;
      width: 100%;
      .wrapTimeImg {
        display: flex;
        align-items: center;
      }
      div {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #babbbb;
      }
    }
    .hasGuanzhu {
      width: 51px;
      height: 23px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      border: 1px solid #1c9ab1;
    }
    .noGuanzhu {
      width: 51px;
      height: 23px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #babbbb;
      border: 1px solid #babbbb;
    }
    .follow {
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    .authorImg {
      width: 37px;
      height: 37px;
      border-radius: 50%;
    }
    &:last-child {
      border-bottom: none !important;
    }
    .hotArticleItemTitle {
      font-size: 13px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    .moreWord {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a7a8a8;
    }
    .wraphotArticleWord {
      border-bottom: 3px solid #1c9ab1;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .hotArticleWord {
        margin-left: 9px;

        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
    }
  }
}
</style>