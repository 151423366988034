<template>
  <div class="kuaixun contentWidth">
    <sidebar></sidebar>
    <div class="midContent">
      <img
        :src="require('@as/reading/guanchaMid.png')"
        alt=""
        style="width: 100%"
      />
      <ul>
        <li v-for="(item, index) in kuaixunList" :key="index">
          <div class="commonBorder kuaixunDate">
            <div class="kuaixunDateImg">
              <img :src="require('@as/reading/date.png')" alt="" />
            </div>
            <div>
              <div
                style="
                  font-size: 16px;
                  font-family: PingFangSC;
                  font-weight: 400;
                  color: #333333;
                  margin-top: 3px;
                "
              >
                {{ item.weekday }}
              </div>
              <div
                style="
                  font-size: 12px;
                  font-family: PingFangSC;
                  font-weight: 400;
                  color: #666666;
                  margin-top: 3px;
                "
              >
                {{ item.date }}
              </div>
            </div>
          </div>
          <ul>
            <li
              v-for="(listitem, index) in item.articleList"
              :key="index"
              class="articleItemKuaixun"
            >
              <div class="articleRight" style="width: 100%">
                <div class="title">{{ listitem.title }}</div>
                <div class="desp">{{ listitem.desp }}</div>
                <div class="articleRightBot betweenFlex">
                  <div>
                    <img
                      :src="require('@as/reading/time.png')"
                      alt=""
                      style="margin-right: 5px"
                    />
                    <span>{{ listitem.time }}</span>
                  </div>
                  <div class="betweenFlex botChoice" style="width: 42%">
                    <div>
                      <img :src="require('@as/reading/share.png')" alt="" />分享
                    </div>
                    <div
                      @click="listitem.collect = !listitem.collect"
                      :class="{ active: listitem.collect }"
                    >
                      <img
                        style="width: 14px; height: 14px"
                        :src="
                          listitem.collect
                            ? require('@as/reading/contentShou.png')
                            : require('@as/reading/shou.png')
                        "
                        alt=""
                      />收藏
                    </div>
                    <div>
                      <img
                        :src="require('@as/reading/comment.png')"
                        alt=""
                      />评论
                    </div>
                    <div
                      @click="listitem.like = !listitem.like"
                      :class="{ active: listitem.like }"
                    >
                      <img
                        style="width: 12px; height: 12px"
                        :src="
                          listitem.like
                            ? require('@as/reading/contentZan.png')
                            : require('@as/reading/zan.png')
                        "
                        alt=""
                      />点赞
                    </div>
                    <div class="lookALL">查看原文</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>

      <!-- <div class="lookmore">查看更多</div> -->
    </div>
    <div class="rightContent">
      <writeArticle />
      <hotNews />
    </div>
  </div>
</template>

<script>
import sidebar from '@c/reading/sidebar'
import writeArticle from '@c/reading/writeArticle'
import hotNews from '@c/reading/hotNews'
export default {
  data() {
    return {
      kuaixunList: [
        {
          weekday: '周五',
          date: '2020-10-14',
          articleList: [
            {
              title:
                '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
              desp:
                '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
              time: '2020.08.04',
              collect: false,
              like: false,
            },
            {
              title:
                '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
              desp:
                '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
              time: '2020.08.04',
              collect: false,
              like: false,
            },
            {
              title:
                '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
              desp:
                '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
              time: '2020.08.04',
              collect: false,
              like: false,
            },
          ],
        },
        {
          weekday: '周四',
          date: '2011-10-14',
          articleList: [
            {
              title:
                '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
              desp:
                '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
              time: '2020.08.04',
              collect: false,
              like: false,
            },
            {
              title:
                '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
              desp:
                '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
              time: '2020.08.04',
              collect: false,
              like: false,
            },
            {
              title:
                '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
              desp:
                '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
              time: '2020.08.04',
              collect: false,
              like: false,
            },
          ],
        },
      ],
    }
  },
  components: {
    sidebar,
    hotNews,
    writeArticle,
  },
  created() {
    console.log('首页')
  },
}
</script>
<style lang="less">
.kuaixun {
  display: flex;
  justify-content: space-between;
  .midContent {
    width: 720px;
    margin-bottom: 40px;
    .kuaixunDate {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 13px;
      .kuaixunDateImg {
        border-bottom: 2px solid #1c99b0;
        margin-right: 5px;
        height: 46px;
      }
    }
    .lookmore {
      width: 718px;
      height: 41px;
      margin-bottom: 37px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 17px;
      background: #afafaf;
    }
    .articleItemKuaixun {
      padding: 20px 0;
      border-bottom: 1px solid #dedede;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      &:last-child {
        margin-bottom: 37px;
      }
      .articleRight {
        .title:hover {
          color: #1c9ab1;
        }
        .title {
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 500;
          cursor: pointer;
          color: #333333;

          line-height: 28px;
        }
        .desp {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #999999;
          margin-top: 21px;
        }
        .articleRightBot {
          margin-top: 29px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .active {
            color: #1c9ab1 !important;
          }
          .botChoice {
            img {
              margin-right: 5px;
            }
            div {
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #afafaf;
              cursor: pointer;
            }

            .lookALL {
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #7db7ca;
            }
          }
          div {
            display: flex;
            align-items: center;
          }
          span {
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
            margin-right: 5px;
          }
          .signItem {
            padding: 2px 5px;

            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
            // background: #A6A7A7;
          }
        }
      }
    }
  }
  .rightContent {
    width: 330px;
  }
}
</style>