<template>
  <div class="toudiManage">
    <div class="top betweenFlex commonBorder">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="64"
        :height="56"
        size="16"
        :distance="44"
      />
      <div class="commonFlex" v-show="activeIndex == 0">
        <div class="title">我投递的职位</div>
        <div class="count">23</div>
      </div>
      <div class="commonFlex" v-show="activeIndex == 1">
        <div class="title">我收藏的职位</div>
        <div class="count">23</div>
      </div>
    </div>
    <div class="wrapZhaopinBetweenn" v-show="activeIndex == 0">
      <ul class="wrapJobList">
        <li
          v-for="(item, index) in jobList1"
          :key="index"
          class="jobListItem commonBorder"
        >
          <div class="jobItemLeft">
            <div class="title">{{ item.jobTitle }}</div>
            <div class="wrapSecond">
              <span class="count">{{ item.salary }}</span
              ><span class="yueSalary">/月</span>|<span class="fengeLine">{{
                item.didian
              }}</span
              >|<span class="fengeLine">{{ item.major }}</span
              >|<span class="fengeLine">{{ item.exp }}</span>
            </div>
            <div class="wrapThree startFlex">
              <img :src="require('@as/reading/time.png')" alt="" />
              <span style="margin-left: 5px">投递于: 20100909</span>
            </div>
          </div>
          <div class="jobItemRight">
            <div class="wrapTop">
              <img
                :src="require('@as/reading/test.png')"
                alt=""
                style="width: 40px; height: 40px; border-radius: 50%"
              />
              <div style="margin-left: 10px">
                <div style="display: flex; align-items: center">
                  <span class="company">{{ item.company }}</span>
                  <img
                    :src="require('@as/reading/personFlag1.png')"
                    alt=""
                    style="margin-left: 5px"
                  />
                </div>

                <div class="desp">{{ item.desp }}</div>
              </div>
            </div>
            <div class="wrapbot startFlex">
              <div
                v-for="(itemSign, index) in item.sign"
                :key="itemSign"
                class="commonFlex"
              >
                <div class="commonFlex signItem">{{ itemSign }}</div>
                <div
                  class="lineItem"
                  v-if="index != item.sign.length - 1"
                ></div>
              </div>
            </div>
          </div>
        </li>
        <div class="wrapTotalPage commonFlex" style="height: 100px">
          <el-pagination background layout="prev, pager, next" :total="1000">
          </el-pagination>
        </div>
      </ul>
    </div>
    <div class="wrapZhaopinBetweenn" v-show="activeIndex == 1">
      <ul class="wrapJobList">
        <li
          v-for="(item, index) in jobList1"
          :key="index"
          class="jobListItem commonBorder myHide"
        >
          <div class="jobItemLeft">
            <div class="title">{{ item.jobTitle }}</div>
            <div class="wrapSecond">
              <span class="count">{{ item.salary }}</span
              ><span class="yueSalary">/月</span>|<span class="fengeLine">{{
                item.didian
              }}</span
              >|<span class="fengeLine">{{ item.major }}</span
              >|<span class="fengeLine">{{ item.exp }}</span>
            </div>
            <!-- <div class="wrapThree startFlex">
              <img :src="require('@as/reading/time.png')" alt="" />
              <span style="margin-left: 5px">投递于: 20100909</span>
            </div> -->
          </div>
          <div class="jobItemRight betweenFlex">
            <div class="wrapTop">
              <img
                :src="require('@as/reading/test.png')"
                alt=""
                style="width: 40px; height: 40px; border-radius: 50%"
              />
              <div style="margin-left: 10px">
                <div style="display: flex; align-items: center">
                  <span class="company">{{ item.company }}</span>
                  <img
                    :src="require('@as/reading/personFlag1.png')"
                    alt=""
                    style="margin-left: 5px"
                  />
                </div>

                <div class="desp">{{ item.desp }}</div>
              </div>
            </div>
            <div class="choiceJobide commonFlex">
              <div class="lijiApply commonFlex canClick">立即申请</div>
              <div class="quitHide commonFlex canClick">取消收藏</div>
            </div>
          </div>
        </li>
        <div class="wrapTotalPage commonFlex" style="height: 100px">
          <el-pagination background layout="prev, pager, next" :total="1000">
          </el-pagination>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import headerChoice from '../headerChoice'
export default {
  data() {
    return {
      activeIndex: 1,
      choiceList: ['职位投递', '职位收藏'],
      articleLlist: [
        '好哈哈哈哈哈哈',
        '好哈哈哈哈哈哈',
        '好哈哈哈哈哈哈',
        '好哈哈哈哈哈哈',
      ],
      jobList1: [
        {
          jobTitle: '国际争端解决领域专业律师',
          salary: '10-15k',
          didian: '北京',
          exp: '1-2年',
          major: '法学',
          time: '30分钟',
          company: '某某事务所',
          desp: '哈哈哈哈哈哈',
          sign: ['已投递', '已产看', '不适合'],
        },
        {
          jobTitle: '国际争端解决领域专业律师',
          salary: '10-15k',
          didian: '北京',
          exp: '1-2年',
          major: '法学',
          time: '30分钟',
          company: '某某事务所',
          desp: '哈哈哈哈哈哈',
          sign: ['已投递', '已产看', '不适合'],
        },
        {
          jobTitle: '国际争端解决领域专业律师',
          salary: '10k',
          didian: '北京',
          exp: '1-2年',
          major: '法学',
          time: '30分钟',
          company: '某某事务所',
          desp: '哈哈哈哈哈哈',
          sign: ['已投递', '已产看', '不适合'],
        },
        {
          jobTitle: '国际争端解决领域专业律师',
          salary: '10k',
          didian: '北京',
          exp: '1-2年',
          major: '法学',
          time: '30分钟',
          company: '某某事务所',
          desp: '哈哈哈哈哈哈',
          sign: ['已投递', '已产看', '不适合'],
        },
        {
          jobTitle: '国际争端解决领域专业律师',
          salary: '10k',
          didian: '北京',
          exp: '1-2年',
          major: '法学',
          time: '30分钟',
          company: '某某事务所',
          desp: '哈哈哈哈哈哈',
          sign: ['已投递', '已产看', '不适合'],
        },
      ],
    }
  },
  methods: {
    change(index) {
      this.activeIndex = index
    },
  },
  components: {
    headerChoice,
  },
}
</script>

<style lang="less">
.toudiManage {
  .selected {
    border-bottom: 2px solid #1c9ab1 !important;
  }
  .lijiApply {
    padding: 5px 10px;
    background: #1c9ab1;
    border-radius: 3px;

    font-size: 10px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .quitHide {
    padding: 5px 10px;
    background: #d8e5e7;
    border-radius: 3px;

    font-size: 10px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    margin-left: 20px;
  }
  .lineItem {
    width: 15px;
    height: 2px;
    background: #1c9ab1;
  }
  .wrapZhaopinBetweenn {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .wrapTotalPage {
    margin: 0 auto;
    margin-top: 20px;
  }
  .selectedDes {
    color: #1c9ab1 !important;
  }
  .selectedXingzhi {
    border: 1px solid #c3e4eb;
    padding: 2px 7px;
    color: #1c9ab1 !important;
  }
  .wrapJobList {
    display: flex;
    flex-wrap: wrap;
    // width: 850px;
    padding: 0 20px;
  }
  .jiazaiMore {
    width: 1200px;
    height: 50px;
    background: #ffffff;

    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .myHide {
    height: 102px !important;
  }
  .jobListItem:hover {
    background: linear-gradient(90deg, #ffffff 0%, #ecfaff 50%, #ffffff 100%);
  }
  .jobListItem {
    cursor: pointer;
    width: 940px;
    height: 131px;
    background: white;
    padding: 16px 0px;
    display: flex;
    align-items: center;
    // margin-bottom: 18px;
    .jobItemRight {
      padding-left: 53px;
      .wrapTop {
        display: flex;
        align-items: center;
        width: 234px;
        .company {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 500;
          color: #595757;
        }
        .desp {
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #afafaf;
          margin-top: 7px;
        }
      }
      .wrapbot {
        margin-top: 30px;
        display: flex;
        align-items: center;
        .signItem {
          // margin-right: 10px;
          width: 63px;
          background: #1c9ab1;
          height: 19px;

          font-size: 10px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .jobItemLeft {
      width: 496px;
      border-right: 1px solid #dedede;
      .wrapThree {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        margin-top: 19px;
        display: flex;
        align-items: center;
      }
      .wrapSecond {
        margin-top: 17px;
        .yueSalary {
          margin-right: 7px;
        }
        .fengeLine {
          margin: 0 10px;
        }
        .count {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1c9bb2;
          margin-right: 5px;
        }
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      .title {
        font-size: 17px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #595757;
      }
    }
    .wrapCount {
      margin-top: 17px;
      color: #afafaf;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      .count {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
    }
    .jobItemBot {
      margin-top: 17px;
      .mianyi {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
        margin-left: 15px;
      }
      .rezhao {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #666666;
      }
      .jobTitle {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        margin-left: 15px;
      }
    }
    .jobItemTop {
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      .name {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        margin-left: 8px;
      }
    }
  }
  .wrapRightTwobtn {
    display: flex;
    justify-content: flex-end;
    width: 355px;
  }
  .wrapWhiteCircle {
    width: 36px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 50%;
  }
  .articleItem {
    height: 132px;
  }
  .articleIMG {
    width: 144px;
    height: 90px;
  }
  .wrapBotBtn {
    margin-right: 30px;
  }
  .btnCount {
    margin-left: 7px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .wenzhangName {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .allchoiceBtn {
    margin-top: 37px;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .count {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #0899ca;
    margin-left: 3px;
  }
  .xiewenzhang {
    width: 80px;
    height: 30px;
    background: #4395cd;
    border-radius: 2px;
    margin-left: 30px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .top {
    padding: 0 20px;
  }
}
</style>