const state = {
  userInfo: JSON.parse(sessionStorage.getItem("userInfo")) || {},
  isLogin: !!sessionStorage.getItem("token"),
};

const mutations = {
  setUserInfo(state, res) {
    state.userInfo = res;
    sessionStorage.setItem("userInfo", JSON.stringify(res));
  },
  setIsLogin(state, payload) {
    state.isLogin = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
