<template>
  <div class="wendaList contentWidth betweenFlex">
    <sidebar></sidebar>
    <div class="wendaWrap">
      <img :src="require('@as/reading/test.png')" alt="" class="topImg" />
      <div class="topOptions">
        <div class="leftArrow commonFlex" @click="leftSelect">
          <img :src="require('@as/reading/leftArrow.png')" alt="" />
        </div>
        <div class="rightArrow commonFlex" @click="rightSelect">
          <img :src="require('@as/reading/rightArrow.png')" alt="" />
        </div>
        <div class="wrapOptionsItem commonBorder">
          <div
            v-for="(item, index) in topList"
            :key="index"
            class="OptionsItem"
          >
            <div
              class="topOptionsItem commonFlex"
              :class="{ active: selectIdx == index }"
              @click="selectIdx = index"
            >
              <img
                :src="require('@as/reading/test.png')"
                alt=""
                style="width;25px;height:24px"
              />
              <div class="name">运服务器</div>
              <div class="questionCount">共4.9K个问题 ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapAnswer">
        <div class="midAnswer commonBorder">
          <div class="">
            <div class="wrapAnswer1 commonBorder">
              <div
                v-for="(item, index) in answerOptions"
                :key="index"
                class="answerItem"
                :class="{ selected: activeIndex == index }"
                @click="activeIndex = index"
              >
                {{ item.name }}
              </div>
            </div>

            <ul>
              <li
                v-for="(item, index) in questionList"
                class="questionItem commonBorder canClick"
                :key="index"
                @click="toDetail()"
              >
                <div class="questionTop">
                  <div class="countNum commonFlex">
                    <span class="huidaCount">{{ item.count }}</span>
                    <span class="huidaWord">回答</span>
                  </div>
                  <div class="questionRight">
                    <div class="title">{{ item.title }}</div>
                    <div class="wrapDetail betweenFlex">
                      <div class="detailLeft betweenFlex">
                        <div
                          v-for="(signItem, index) in item.sign"
                          :key="index"
                          class="signItem commonFlex"
                        >
                          {{ signItem }}
                        </div>
                        <div class="wrapTime commonFlex">
                          <img
                            :src="require('@as/reading/yanjing.png')"
                            alt=""
                          />
                          <span class="time">{{ item.time }}</span>
                        </div>
                      </div>
                      <div class="detailright betweenFlex">
                        <div class="commonFlex">
                          <img
                            :src="require('@as/reading/laiAnswer.png')"
                            alt=""
                          />
                          <span
                            class="laiAnswer"
                            @click.stop="huidadialog = true"
                            >我来回答</span
                          >
                        </div>
                        <div
                          class="commonFlex"
                          @click.stop="
                            item.alreadyHideQues = !item.alreadyHideQues
                          "
                        >
                          <img
                            :src="require('@as/reading/guanzhuQues.png')"
                            alt=""
                            v-show="!item.alreadyHideQues"
                            class="twoStateImg"
                          />
                          <img
                            class="twoStateImg"
                            :src="require('@as/reading/contentShou.png')"
                            v-show="item.alreadyHideQues"
                          />
                          <span class="guanzhuQuestion">关注问题</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="questionBot">
                  <span class="questionBotAns">{{ item.answer }}:</span>
                  <span class="questionBotDesp">{{ item.answerDesp }}</span>
                </div>
              </li>
            </ul>
            <div class="chakanMore commonFlex">查看更多</div>
          </div>
        </div>
        <div>
          <div class="commonFlex wrapToWen" @click="tiwendialog = true">
            <img
              :src="require('@as/reading/goTiwen.png')"
              alt=""
              class="tiwenImg"
            />
            <span class="gotiwen">去提问</span>
          </div>
          <jieyou />
        </div>
      </div>
    </div>
    <el-dialog title="回答问题" :visible.sync="huidadialog" class="huidaDialog">
      <textarea
        v-model="form.huidaContent"
        placeholder="请阐述您对问题的观点..."
        class="huidaQues"
      ></textarea>
      <div style="width: 100%; text-align: center; padding-top: 20px">
        <el-button @click="dialogFormVisible = false">提交回答</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提问题" :visible.sync="tiwendialog">
      <el-form :model="form">
        <el-input
          v-model="form.title"
          autocomplete="off"
          placeholder="问题标题：一句话描述问题，请以问号结尾。"
        ></el-input>
        <el-input
          style="margin-top: 20px"
          v-model="form.sign"
          autocomplete="off"
          placeholder="添加标签，能够帮助你更快获得回答"
        ></el-input>
        <div
          style="
            font-size: 13px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #808080;
            margin-top: 20px;
          "
        >
          问题描述（可选）
        </div>
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          class="el-textarea"
        ></textarea>
      </el-form>
      <div
        style="
          width: 100%;
          text-align: center;
          padding-top: 20px;
          margin-bottom: 40px;
        "
      >
        <el-button @click="dialogFormVisible = false" type="primary"
          >提交问题</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import sidebar from '@c/reading/sidebar'
import jieyou from '@c/reading/jieyou'
export default {
  data() {
    return {
      activeIndex: 1,
      tiwendialog: false,
      huidadialog: false,
      form: {
        title: '',
        desc: '',
        sign: '',
        huidaContent: '',
      },
      topList: [
        { id: 1 },
        { id: 2 },
        { id: 3 },

        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
      ],
      activeId: 1,
      selectIdx: 0,
      answerOptions: [
        { name: '最新回答' },
        { name: '最新提问' },
        { name: '尚未回答' },
      ],
      questionList: [
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          alreadyHideQues: false,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复',
        },
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          alreadyHideQues: false,
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复',
        },
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          alreadyHideQues: false,
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复',
        },
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          alreadyHideQues: false,
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复',
        },
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          alreadyHideQues: false,
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复',
        },
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          alreadyHideQues: false,
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复',
        },
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          alreadyHideQues: false,
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复',
        },
      ],
    }
  },
  components: {
    sidebar,
    jieyou,
  },

  methods: {
    toDetail() {
      this.$router.push({ name: 'wendaDetail' })
    },
    leftSelect() {
      this.selectIdx -= 1
    },
    rightSelect() {
      this.selectIdx += 1
    },
  },
}
</script>

<style lang="less">
.wendaList {
  margin-top: 20px;
  align-items: flex-start !important;
  .twoStateImg {
    width: 13px;
    height: 13px;
  }
  .huidaQues {
    padding: 20px;
    width: 100%;
    height: 235px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 3px;
  }
  .el-dialog {
    // height: 550px;
    z-index: 9999;
  }
  .el-textarea {
    margin-top: 10px;
    height: 235px;
    background: #ffffff;
    border: none;
    resize: none;
    border: 1px solid #dedede;
    border-radius: 3px;
  }
  .el-dialog__body {
    padding-top: 5px !important;
  }
  .el-dialog__title {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
  }
  .gotiwen {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .wrapToWen {
    width: 330px;
    cursor: pointer;
    height: 50px;
    margin-top: 48px;
    background: #1c9ab1;
    position: relative;
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
    .tiwenImg {
      position: absolute;
      left: 40px;
    }
  }
  .wendaWrap {
    width: 1070px;
    .wrapAnswer {
      align-items: flex-start !important;
      display: flex;
      justify-content: space-between;
    }
    .topImg {
      width: 1070px;
      height: 200px;
    }
    .midAnswer {
      width: 720px;
      .chakanMore {
        cursor: pointer;
        width: 720px;
        margin-top: 20px;
        height: 41px;
        margin-bottom: 79px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
        background: #ebecec;
      }
      // margin-top: 46px;
      .questionItem {
        padding: 23px 0;
        .questionBot {
          width: 720px;
          //height: 70px;
          margin-top: 21px;
          background: rgba(28, 154, 177, 0.05);
          padding: 15px 24px;
          box-sizing: border-box;
          .questionBotAns {
            font-size: 13px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #1c9ab1;
          }
          .questionBotDesp {
            font-size: 13px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #808080;
          }
        }
        .questionTop {
          display: flex;
          .questionRight {
            margin-left: 16px;
            .guanzhuImg {
              margin-right: 3px;
            }
            .laiAnswer {
              margin-right: 25px;
              margin-left: 7px;
              cursor: pointer;
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #babbbb;
            }
            .guanzhuQuestion {
              margin-left: 7px;
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #babbbb;
              cursor: pointer;
            }
            .huidaImg {
              margin-right: 3px;
            }
            .detailLeft {
              display: flex;
              .signItem {
                margin-right: 8px;
                width: 52px;
                height: 19px;
                background: #e9eced;

                font-size: 10px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #999999;
              }
              .wrapTime {
                margin-left: 48px;
                span {
                  margin-left: 2px;

                  font-size: 12px;
                  font-family: PingFangSC;
                  font-weight: 400;
                  color: #afafaf;
                }
              }
            }
            .wrapDetail {
              width: 660px;
              margin-top: 9px;
            }
            .title {
              font-size: 18px;
              font-family: PingFangSC;
              font-weight: 500;
              color: #333333;
            }
          }
          .countNum {
            flex-direction: column;
            width: 44px;
            height: 44px;
            background: rgba(28, 154, 177, 0.1);
            .huidaCount {
              font-size: 16px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #1c99b0;
            }
            .huidaWord {
              margin-top: 2px;

              font-size: 10px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #1c99b0;
            }
          }
        }
      }
      .wrapAnswer1 {
        width: 720px;
        margin-top: 40px;
        display: flex;
        padding-left: 20px;
        .selected {
          border-bottom: 2px solid #1c99b0 !important;
          color: #1c99b0 !important;
        }
        .answerItem {
          cursor: pointer;
          padding-bottom: 15px;
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
          margin-right: 54px;
        }
      }
    }
    .topOptions {
      display: flex;
      width: 1070px;
      flex-wrap: nowrap;
      overflow-x: hidden;
      position: relative;
      .leftArrow {
        height: 130px;
        position: absolute;
        width: 32px;
        left: 0;
        z-index: 2;
        cursor: pointer;

        div {
          width: 32px;
          height: 32px;
          background: black;
          opacity: 0.1;
        }
      }
      .rightArrow {
        height: 130px;
        position: absolute;
        width: 32px;
        right: 0;
        z-index: 2;
        cursor: pointer;
        div {
          width: 32px;
          height: 32px;
          background: black;
          opacity: 0.1;
        }
      }
      .wrapOptionsItem {
        display: flex;
        flex-wrap: nowrap;
        .OptionsItem {
          position: relative;
          cursor: pointer;
          .zhezhao {
            position: absolute;
            top: 0;
            left: 0;
            width: 155px;
            height: 130px;
            background: #1c9ab1;
            opacity: 0.1;
          }
        }
      }
      .topOptionsItem {
        flex-direction: column;
        width: 155px;
        height: 130px;
        background: white;
        &.active {
          background: #dfeef1;
        }
        .name {
          margin-top: 15px;

          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #333333;
        }
        .questionCount {
          margin-top: 7px;

          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
</style>