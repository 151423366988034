<template>
  <div class="wrapAllEditor">
    <div class="blank"></div>
    <div class="leftArticle">
      <input
        type="text"
        placeholder="请在这里输入标题"
        class="titleInput"
        v-model="articleobj.title"
      />
      <div class="wrapZuozhe">
        <input
          type="text"
          placeholder="请输入作者"
          class="zuozheInput"
          v-model="articleobj.author"
        />
      </div>
      <!-- <div style="height: 600px"></div> -->
      <quill-editor
        style="height: 600px"
        v-model="content"
        ref="myQuillEditor"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @change="onEditorChange($event)"
      >
      </quill-editor>
      <div class="wrapFengmian">
        <div class="fengmian">封面及概要</div>
        <div class="wrapUpload">
          <div class="uploadWrap commonFlex">
            <el-upload
              class="uploadDemo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :on-exceed="handleExceed"
              :show-file-list="false"
            >
              <div class="wrapInside">
                <img :src="require('@as/reading/uploadImg.png')" alt="" />
                <div class="uploadBiaozhu">请上传尺寸为220px*140px</div>
                <div class="uploadBiaoSec">的png、jpg图片</div>
              </div>
            </el-upload>
          </div>
          <div class="wrapZhaiyaoContent">
            <textarea
              class="zhaiyaoContent"
              placeholder="选填，摘要会在文章列表中显示"
              v-model="articleobj.zhaiyao"
            ></textarea>
            <div class="textCount">{{ count }}/120</div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightInput">
      <div class="publishArticle">发布文章</div>
      <div class="fabuzhuanlan">请选择要发布的专栏</div>
      <el-select
        v-model="articleobj.value"
        placeholder="请选择"
        style="margin-top: 11px"
      >
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="fengeLine"></div>
      <div class="laiyuan">文章来源</div>
      <el-radio v-model="articleobj.radio" label="1" style="margin-bottom: 24px"
        >原创</el-radio
      ><br />
      <el-radio v-model="articleobj.radio" label="2">转载</el-radio>
      <input
        type="text"
        placeholder="文章原作者"
        class="editorRightInput"
        v-model="articleobj.originAuthor"
      />
      <textarea
        placeholder="输入文章"
        class="editorRightText"
        v-model="articleobj.originAuthor1"
      ></textarea>
      <div style="margin-top: 16px">翻译论文</div>
      <input
        type="text"
        placeholder="文章原作者"
        class="editorRightInput"
        v-model="articleobj.originAuthor"
      />
      <textarea placeholder="输入文章" class="editorRightText"></textarea>
      <div class="fengeLine"></div>
      <div style="margin-top: 16px">文章标签</div>
      <input type="text" placeholder="文章原作者" class="editorRightInput" />
      <div style="margin-top: 16px">文章类型</div>
      <input type="text" placeholder="文章原作者" class="editorRightInput" />
      <div class="confirmPublish commonFlex canClick">确认发布</div>
      <div class="saveCaogao commonFlex canClick">存草稿</div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  data() {
    return {
      fileList: [
        {
          name: 'food.jpeg',
          url:
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
        },
        {
          name: 'food2.jpeg',
          url:
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
        },
      ],
      desc: '',
      count: 0,
      content: '',
      articleobj: {
        value: '',
        radio: 1,
        sign: '',
        kind: '',
        originAuthor: '',
        originAuthor1: '',
        originUrl: '',
        originUrl1: '',
      },
      options: [
        {
          value: '选项1',
          label: '黄金糕',
        },
        {
          value: '选项2',
          label: '双皮奶',
        },
        {
          value: '选项3',
          label: '蚵仔煎',
        },
        {
          value: '选项4',
          label: '龙须面',
        },
        {
          value: '选项5',
          label: '北京烤鸭',
        },
      ],
      value: '',
      editorOption: {
        placeholder: '请在这里输入',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
            ['blockquote', 'code-block'], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: 'ordered' }, { list: 'bullet' }], //列表
            [{ script: 'sub' }, { script: 'super' }], // 上下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向
            [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], //对齐方式
            ['clean'], //清除字体样式
            ['image', 'video'], //上传图片、上传视频
          ],
        },
      },
    }
  },
  components: {
    quillEditor,
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    },
  },
  methods: {
    onEditorReady(editor) {
      // 准备编辑器
    },
    onEditorBlur() {}, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    onEditorChange() {}, // 内容改变事件
    descInput() {
      if (this.desc.length > 120) {
        this.desc = this.desc.slice(0, 120)
      }
      this.count = this.desc.length
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
  },
}
</script>

<style lang="less">
.wrapAllEditor {
  display: flex;
  // align-items: flex-start;
  justify-content: flex-end;
  margin-top: -20px;
  .rightInput {
    // position: absolute;
    // right: 0;
    // bottom:0;
    width: 480px;
    // height: 1010px;
    background: #ffffff;
    margin-left: 20px;
    padding: 30px;
    .confirmPublish {
      width: 420px;
      height: 40px;
      background: #1c9ab1;

      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      margin-top: 120px;
    }
    .saveCaogao {
      width: 420px;
      height: 40px;
      border: 1px solid #1c9ab1;

      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      margin-top: 20px;
    }
    div {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
    .editorRightText {
      margin-top: 16px;
      ::placeholder {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
    }
    .editorRightInput {
      margin-top: 16px;
    }
    .laiyuan {
      margin-bottom: 24px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
    }
    .fengeLine {
      width: 420px;
      height: 1px;
      background: #dedede;
      margin: 24px 0;
    }
    .publishArticle {
      font-size: 20px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #333333;
    }
    .fabuzhuanlan {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
      margin-top: 25px;
    }
    textarea {
      width: 420px;
      height: 72px;
      border: 1px solid #dedede;
      padding: 10px;
      outline: none;
    }
    input {
      width: 420px;
      height: 40px;
      border: 1px solid #dedede;
      padding-left: 11px;
      outline: none;
      ::placeholder {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .wrapZuozhe {
    margin-bottom: 20px;
  }
  .leftArticle {
    // margin-right: 400px;
    width: 1084px;
    // height: 1010px;
    background: #ffffff;
    padding: 50px 30px 30px 30px;
    .wrapFengmian {
      margin-top: 120px;
    }
    .fengmian {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
    .uploadWrap {
      width: 312px;
      height: 137px;
      background: #f6f7f7;
      flex-direction: column;
      margin-right: 26px;
      position: relative;
      .uploadDemo {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
      }
      .wrapInside {
        width: 302px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 137px;
        background: #f6f7f7;
        flex-direction: column;
        //   margin-right: 26px;
        position: relative;
      }
    }
    .textCount {
      position: absolute;
      right: 10px;
      bottom: 10px;

      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
    }
    .wrapZhaiyaoContent {
      position: relative;
      // width: 63%;
    }
    .uploadBiaoSec {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
    }
    .uploadBiaozhu {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
      margin-top: 15px;
      // width: 70%;
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }
    .wrapUpload {
      margin-top: 22px;
      display: flex;
      align-items: center;
    }
    .zhaiyaoContent {
      width: 697px;
      height: 137px;
      border: 1px solid #dedede;
      padding: 10px;
    }
    .zhaiyaoContent::placeholder {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
    }
    .titleInput {
      border: none;
      font-size: 24px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #666666;
      outline: none;
    }
    .titleInput::placeholder {
      font-size: 24px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #666666;
    }
    .zuozheInput::placeholder {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
    }
    .zuozheInput {
      border: none;
      outline: none;
      margin-top: 31px;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>