<template>
  <div class="socialManage">
    <div class="top commonBorder">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="64"
        :height="56"
        size="16"
        :distance="44"
      />
    </div>
    <ul>
      <li
        v-for="item in socialList"
        class="socialItem commonBorder betweenFlex"
        :key="item"
      >
        <div class="commonFlex">
          <img
            :src="require('@as/reading/test.png')"
            alt=""
            class="socailImg"
          />
          <div style="margin-left: 17px">
            <div class="title">{{ item.title }}</div>
            <div class="desp">
              关注 <span class="guanzhuFnege">·</span> 123 粉丝
            </div>
          </div>
        </div>
        <div>
          <div class="guanzhu commonFlex" v-if="!item.isguanzhu">关注</div>
          <div class="quitGuanzhu commonFlex" v-if="item.isguanzhu">
            取消关注
          </div>
        </div>
      </li>
      <div class="commonFlex" style="height: 75px">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
  </div>
</template>

<script>
import headerChoice from '../headerChoice'
export default {
  data() {
    return {
      choiceList: ['关注', '粉丝', '关注机构'],
      activeIndex: 0,
      socialList: [
        { title: '还好还好', isguanzhu: false },
        { title: '还好还好', isguanzhu: false },

        { title: '还好还好', isguanzhu: false },

        { title: '还好还好', isguanzhu: true },

        { title: '还好还好', isguanzhu: true },
      ],
    }
  },
  components: {
    headerChoice,
  },
  methods: {
    change(index) {
      this.activeIndex = index
    },
  },
}
</script>

<style lang="less">
.socialManage {
  .guanzhuFnege {
    margin: 0 10px;
  }
  .top {
    padding-left: 20px;
  }
  .selected {
    border-bottom: 2px solid #0899ca !important;
  }
  .socialItem {
    padding: 20px;
    &:hover {
      background: #f6feff;
      box-shadow: 2px 2px 6px 0 rgba(000, 000, 000, 0.1);
    }
    .guanzhu {
      width: 71px;
      height: 30px;
      background: #1c9ab1;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
    .quitGuanzhu:hover {
      background: #ecfaff;

      color: #089acb;

      border: 1px solid #089acb;
    }
    .quitGuanzhu {
      width: 71px;
      height: 30px;
      border: 1px solid #999999;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }

    .socailImg {
      width: 46px;
      height: 46px;
      border-radius: 50%;
    }
    .title {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
    }
    .desp {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #999999;
      margin-top: 10px;
    }
  }
}
</style>