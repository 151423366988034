import CreateRequest, { tip } from "./genAxiosInstance";

export default new CreateRequest({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {},
  // 请求拦截
  interceptorsRequest(config) {
    // 请求拦截处理 可在此加入
    if (sessionStorage.getItem("token"))
      config.headers.Authorization = sessionStorage.getItem("token");
    // console.log(config);
  },
  // 响应拦截
  interceptorsResponse(res) {
    // 接口响应逻辑处理
    if (!res) {
      tip("接口请求失败，请稍后再试！");
      return Promise.reject(res);
    }
    if (res.code === 0 || res.constructor === Blob) {
      return Promise.resolve(res.data);
    } else if (res.code === 100001) {
      sessionStorage.removeItem("token");
      this.$store.commit("setUserInfo", {});
      this.$store.commit("setToken", "");
      window.location.hash = "/login";
    } else if (res.code !== -1) {
      if (res.msg) {
        tip(res.msg);
      }
      return Promise.reject(res);
    } else return Promise.reject(res);
  },
});
