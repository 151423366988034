<template>
  <div class="hotArticleGuancha">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <img :src="require('@as/reading/hotArticle.png')" alt="" />
        <span class="hotArticleWord">热门文章</span>
      </div>
      <span class="moreWord canClick">MORE</span>
    </div>
    <div class="hotArticleMid">
      <div class="wrapPaihang">
        <div :class="{ selected: dayRank }" @click="dayClick">日排行</div>
        <div :class="{ selected: weekRank }" @click="weekClick">周排行</div>
        <div :class="{ selected: monthRank }" @click="monthClick">月排行</div>
      </div>
    </div>
    <ul>
      <li
        v-for="(item, index) in list"
        :key="index"
        class="hotArticleItem commonBorder"
        @click="toDetail"
      >
        <span v-if="index + 1 == 1">
          <img :src="require('@as/reading/gold.png')" alt="" />
        </span>
        <span v-if="index + 1 == 2">
          <img :src="require('@as/reading/silver.png')" alt="" />
        </span>
        <span v-if="index + 1 == 3">
          <img :src="require('@as/reading/tong.png')" alt="" />
        </span>
        <span v-if="index + 1 != 1 && index + 1 != 2 && index + 1 != 3">
          {{ index + 1 }}
        </span>
        <span class="hotArticleItemTitle">{{ item.title }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dayRank: true,
      monthRank: false,
      weekRank: false,
      list: [
        { title: '大健康投融资及合规发展论坛规发展论发展论坛规发展论' },
        { title: '大健康投融资及合规发展论坛规' },
        { title: '大健康投融资及合规发展论坛规' },
        { title: '大健康投融资及合规发展论坛规' },
        { title: '大健康投融资及合规发展论坛规' },
        { title: '大健康投融资及合规发展论坛规' },
      ],
    }
  },
  methods: {
    dayClick() {
      this.dayRank = true
      this.monthRank = false
      this.weekRank = false
    },
    weekClick() {
      this.dayRank = false
      this.monthRank = false
      this.weekRank = true
    },
    monthClick() {
      this.dayRank = false
      this.monthRank = true
      this.weekRank = false
    },
    toDetail() {
      this.$router.push({ name: 'contentDetail' })
    },
  },
}
</script>
<style lang="less">
.hotArticleGuancha {
  padding: 20px;
  background: white;
  padding-top: 11px;
  margin-top: 20px;
  .hotArticleItem {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    line-height: 18px;
    padding: 18px 0;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    &:last-child {
      border-bottom: none !important;
    }
    &:hover {
      color: #1c9ab1;
    }
    .hotArticleItemTitle {
      margin-left: 12px;
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    .moreWord {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a7a8a8;
    }
    .wraphotArticleWord {
      border-bottom: 3px solid #1c9ab1;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .hotArticleWord {
        margin-left: 9px;

        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
    }
  }
}
</style>