<template>
  <div class="writeArticle">
    <div class="writeArticleHead">
      <div class="writeArticleLeft">
        <div class="articleHead commonFlex">
          <img :src="require('@as/reading/慧.png')" alt="" />
        </div>
        <div style="margin-left: 10px">
          <img :src="require('@as/reading/word.png')" alt="" />
          <div class="articleDesp">Reading enriches the mind</div>
        </div>
      </div>
      <div class="tixiRead">体系阅读</div>
    </div>
    <div class="articleBot">
      <div class="articleLeft commonFlex" @click="toEditor()">
        <img :src="require('@as/reading/wenzhang.png')" alt="" />
        <div style="margin-top: 15px">写文章</div>
      </div>
      <div class="articleRight commonFlex" @click="toMainIndex()">
        <img :src="require('@as/reading/zhuanlan.png')" alt="" />
        <div style="margin-top: 15px">我的专栏</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    toEditor() {
      this.$router.push({ name: 'editorContent' })
    },
    toMainIndex() {
      this.$router.push('/mainIndex/mainZhuanlan')
    },
  },
}
</script>
<style lang="less">
.writeArticle {
  width: 330px;
  height: 200px;
  background: white;
  .articleBot {
    width: 300px;
    // height: 110px;
    border: 1px solid #dedede;
    margin: 0 auto;
    margin-top: 20px;
    padding: 15px 0;
    display: flex;
    .articleLeft {
      width: 49%;
      height: 80px;
      flex-direction: column;
      border-right: 1px solid #dedede;
    }
    .articleRight {
      width: 49%;
      height: 80px;
      flex-direction: column;
      cursor: pointer;
    }
  }
  .writeArticleHead {
    width: 100%;
    height: 50px;
    background: #1c9ab1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tixiRead {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #ffffff;
    }
    .writeArticleLeft {
      display: flex;
      align-items: center;
      justify-content: stretch;
      .articleHead {
        font-size: 24px;
        font-family: iSlide;
        font-weight: normal;
        color: #ffffff;
        width: 33px;
        height: 33px;
        border: 1px solid white;
        border-radius: 50%;
      }
      .articleDesp {
        font-size: 10px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>