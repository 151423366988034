<template>
  <div class="wendaManage">
    <div class="top betweenFlex commonBorder">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="80"
        :height="56"
        size="16"
        :distance="44"
      />
      <div class="commonFlex"></div>
    </div>
    <ul style="padding: 0 20px">
      <li
        v-for="item in articleLlist"
        class="questionItem commonBorder betweenFlex"
        :key="item"
      >
        <div class="left commonFlex">
          <img
            :src="require('@as/reading/test.png')"
            alt=""
            class="dingyuePerson"
          />
          <div style="margin-left: 16px">
            <div class="dingyueTitle">{{ item }}</div>
            <div class="dingyueCount">
              18 篇文章 <span class="wenzhangDingyue">·</span> 1234 订阅者
            </div>
          </div>
        </div>

        <div class="quitDingyue commonFlex canClick">取消订阅</div>
      </li>
      <div class="commonFlex" style="height: 150px">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
  </div>
</template>

<script>
import headerChoice from '../headerChoice'
export default {
  data() {
    return {
      activeIndex: 0,
      choiceList: ['收藏的文章', '收藏的问答', '订阅的专栏'],
      articleLlist: [
        '好哈哈哈哈哈哈',
        '好哈哈哈哈哈哈',
        '好哈哈哈哈哈哈',
        '好哈哈哈哈哈哈',
      ],
    }
  },
  methods: {
    change(index) {
      this.activeIndex = index
    },
  },
  components: {
    headerChoice,
  },
}
</script>

<style lang="less">
.wendaManage {
  .wenzhangDingyue {
    margin: 0 10px;
  }
  .dingyuePerson {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }
  .quitDingyue {
    width: 71px;
    height: 30px;
    border: 1px solid #089acb;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #089acb;
  }
  .dingyueTitle {
    font-size: 20px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .dingyueCount {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 500;
    color: #999999;
    margin-top: 14px;
  }
  .wrapRightTwobtn {
    display: flex;
    justify-content: flex-end;
    width: 355px;
  }
  .wrapWhiteCircle {
    width: 36px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 50%;
  }
  .articleItem {
    height: 132px;
  }
  .articleIMG {
    width: 144px;
    height: 90px;
  }
  .wrapBotBtn {
    margin-right: 30px;
  }
  .btnCount {
    margin-left: 7px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .wenzhangName {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .allchoiceBtn {
    margin-top: 37px;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .count {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .xiewenzhang {
    width: 80px;
    height: 30px;
    background: #4395cd;
    border-radius: 2px;
    margin-left: 30px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .top {
    padding: 0 20px;
  }
}
</style>