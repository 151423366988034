<template>
  <div class="mainZhuanlan">
    <div class="commonBorder topZhuanlan betweenFlex">
      <div>
        <div class="zhuanlanName">法律专栏</div>
        <div class="commonFlex" style="margin-bottom: 31px">
          <img
            :src="require('@as/reading/test.png')"
            alt=""
            class="zhuanlanImg"
          />
          <div style="margin-left: 15px">
            <div class="commonFlex">
              <span class="title">达人秀</span>
              <div class="createPerson commonFlex">TA创建的</div>
            </div>
            <div class="wenzhangcount">19 篇文章 · 1234 订阅者</div>
          </div>
        </div>
      </div>
      <div class="writeArticle commonFlex">写文章</div>
    </div>
    <articleList />
    <div class="commonBorder"></div>
    <div class="wrapPage">
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import articleList from '@c/reading/articleList'
export default {
  data() {
    return {}
  },
  components: {
    articleList,
  },
}
</script>

<style lang="less">
.mainZhuanlan {
  padding: 20px;
  padding-bottom: 0;
  .wrapPage {
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .topZhuanlan {
    // height: 186px;
    // padding:20px;
    .zhuanlanName {
      // margin-top:-10px;
      margin-bottom: 28px;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
    }
    .writeArticle {
      cursor: pointer;
      width: 71px;
      height: 30px;
      background: #ecfaff;
      border: 1px solid #089acb;
      color: #089acb;
    }
    .topZhuanlan {
      padding: 20px;
    }
    .zhuanlanImg {
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }
    .title {
      font-size: 20px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
    }
    .wenzhangcount {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #999999;
      margin-top: 10px;
    }
    .createPerson {
      width: 72px;
      height: 20px;
      background: #f2f2f2;
      border-radius: 10px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
      margin-left: 20px;
    }
  }
}
</style>