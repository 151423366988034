<template>
  <div class="loginWrap">
    <div class="midLoginWrap">
      <div class="leftLoginDetail">
        <div class="zhuceWord">
          <span
            :class="{
              zhanghaoLogin: active == 1,
              yanzhengCodeLogin: active == 2,
            }"
            @click="active = 1"
            >账号登陆</span
          >
          <span class="huozhe">or</span>
          <span
            :class="{
              yanzhengCodeLogin: active == 1,
              zhanghaoLogin: active == 2,
            }"
            @click="active = 2"
            >验证码登陆</span
          >
        </div>
        <template v-if="active == 1">
          <div class="wrapPassword commonBorder">
            <input
              type="text"
              placeholder="用户名/邮箱/手机号"
              class="password"
              v-model="username"
            />
          </div>
          <div class="wrapPassword commonBorder">
            <input
              type="password"
              placeholder="密码"
              class="password"
              v-model="password"
            />
          </div>
          <div class="wrapLstline betweenFlex">
            <el-checkbox v-model="checked">记住我</el-checkbox>
            <span class="forgetPASS" @click="handlerGoFindPassword"
              >忘记密码?</span
            >
          </div>
          <div class="betweenFlex wrapOtherlogin">
            <span class="otherLogin">其他登录方式</span>
            <div class="commonFlex">
              <img
                class="login-logo"
                :src="require('@as/login/weixin.png')"
                alt=""
                style="margin-right: 29px; cursor: pointer"
              />
              <img
                class="login-logo"
                :src="require('@as/login/weibo.png')"
                alt=""
                style="cursor: pointer"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="wrapInputPhone commonFlex commonBorder"
            style="margin-top: 80px; justify-content: flex-start"
          >
            <div class="leftPhone commonFlex">
              <el-select v-model="areaCode" placeholder="+86">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <input
              type="number"
              placeholder="手机号码"
              class="phoneInput"
              maxlength="11"
              v-model="mobile"
            />
          </div>
          <div class="wrapmsgCode betweenFlex" style="margin-top: 30px">
            <div class="wrapLeftInputCode commonBorder commonFlex">
              <input
                type="number"
                placeholder="验证码"
                class="msgCode"
                v-model="code"
              />
            </div>
            <div
              class="sendMsgCode commonFlex"
              :class="countdown > -1 ? 'disable' : ''"
              @click="handlerGetCode"
            >
              {{ countdown > -1 ? `${countdown}秒` : '发送验证码' }}
            </div>
          </div>
        </template>
        <div
          class="wrapTozhuce betweenFlex"
          :style="active === 2 ? 'margin-top: 98px;' : ''"
        >
          <div class="register commonFlex" @click="handlerLogin">登陆</div>
          <div>
            <span class="hasCode">没有账号?</span>
            <span class="toLogin" @click="handlerGoRegister">去注册</span>
          </div>
        </div>
      </div>
      <rightBk />
    </div>
  </div>
</template>

<script>
import rightBk from '@c/login/rightBk'
import { User, Basic } from '@api/index'

export default {
  data() {
    return {
      checked: false,
      active: 1,
      username: '',
      password: '',
      mobile: '',
      options: [
        {
          value: '+86',
          label: '+86',
        },
      ],
      areaCode: '+86',
      code: '',
      countdown: undefined,
    }
  },
  components: {
    rightBk,
  },
  methods: {
    handlerGoRegister() {
      this.$router.push('/register')
    },
    handlerLogin() {
      const { active, username, password, mobile, code } = this
      let login = undefined
      if (active === 1) {
        if (!username) {
          this.$message.error({
            message: '账号不能为空',
          })
          return
        }
        if (!password) {
          this.$message.error({
            message: '密码不能为空',
          })
          return
        }
        login = User.login({ username, password })
      } else {
        if (!mobile) {
          this.$message.error({
            message: '请填写手机号',
          })
          return
        }
        if (!code) {
          this.$message.error({
            message: '请填写验证码',
          })
          return
        }
        login = User.codeLogin({ loginBean: { code, mobile } })
      }
      login.then((res) => {
        sessionStorage.setItem('token', res.token)
        User.getUserInfo({ userId: res.userId }).then((userInfo) => {
          this.$store.commit('footer/change', { boole: 1 })
          this.$store.commit('user/setUserInfo', userInfo)
          this.$store.commit('user/setIsLogin', true)
          this.$router.replace({ name: 'index' })
        })
      })
    },
    handlerGoFindPassword() {
      this.$router.push({ name: 'findPassword' })
    },
    handlerGetCode() {
      if (!this.mobile) {
        this.$message.error({
          message: '请填写手机号',
        })
        return
      }
      if (this.countdown > -1) return
      Basic.getSmsCode({
        mobile: `${this.mobile}`,
        type: 2,
      }).then(() => {
        this.countdown = 60
        this.setCountdown()
      })
    },
    setCountdown() {
      setTimeout(() => {
        if (this.countdown === 0) this.countdown = undefined
        else {
          this.countdown -= 1
          this.setCountdown()
        }
      }, 1000)
    },
  },
}
</script>

<style lang="less">
.loginWrap {
  background: wheat;
  width: 100%;
  height: 100%;
  background: url('~@as/login/loginBg.png') no-repeat;
  padding: 100px 0 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    border: none;
    outline: none;
  }
  ::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .midLoginWrap {
    width: 1150px;
    height: 560px;
    // height: 760px;
    // margin: 0 auto;
    // margin-top: 200px;
    //  margin-bottom: 200px;
    display: flex;
    justify-content: center;
  }
  .loginTestImg {
    width: 384px;
    height: 560px;
  }
  .forgetPASS {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
  }
  .wrapOtherlogin {
    height: 100px;
    width: 520px;
    .otherLogin {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
    }
    .login-logo {
      width: 42px;
      height: 42px;
    }
  }
  .leftLoginDetail {
    width: 660px;
    height: 560px;
    background: white;
    padding-left: 70px;
    padding-top: 74px;
    .zhuceWord {
      .yanzhengCodeLogin {
        cursor: pointer;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        text-decoration: underline;
        color: #1c9ab1;
        text-decoration: underline;
      }
      .zhanghaoLogin {
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .huozhe {
        margin: 0 17px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
    }
    .tongyi {
      margin: 0 10px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
    }
    .protocal {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      text-decoration: underline;
    }
    .wrapTozhuce {
      // height: 120px;
      width: 520px;
      .hasCode {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
      .toLogin {
        color: #1c9ab1;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        cursor: pointer;
        border-bottom: 1px solid;
      }
      .register {
        width: 160px;
        height: 48px;
        background: #1c9ab1;
        cursor: pointer;
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .wrapLstline {
      display: flex;
      align-items: center;
      height: 79px;
      width: 520px;
      & /deep/ .el-checkbox__label {
        color: #999999;
      }
      & /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #dedede;
      }
      & /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #fff;
        border-color: #dedede;
      }
      & /deep/ .el-checkbox__inner::after {
        border-color: #999;
      }
    }
    .wrapyanzhengCode,
    .wrapmsgCode,
    .wrapPassword {
      width: 520px;
      height: 99px;
      display: flex;
      align-items: flex-end;
      padding-bottom: 20px;
      // margin-top:20px

      input {
        width: 100%;
      }
    }
    .msgCode {
      width: 100%;
    }
    .wrapLeftInputCode {
      height: 59px;
      width: 360px;
    }
    .sendMsgCode {
      width: 140px;
      height: 46px;
      border: 1px solid #1c9ab1;

      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    .wrapInputPhone {
      width: 520px;
      height: 70px;
      .phoneInput {
        padding-left: 23px;
        width: 421px;
        font-size: 16px;
        border-left: 1px solid #dedede;
      }
      .leftPhone {
        width: 80px;
        justify-content: flex-start !important;

        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
        & /deep/ .el-input__inner {
          padding-left: 0;
        }
        & /deep/ .el-input__suffix {
          right: 20px;
        }
      }
    }
  }
}
</style>