<template>
  <div>
    <ul class="readingSide">
      <li v-for="(item, index) in list" :key="index">
        <router-link :to="item.path" class="readingMid">{{
          item.name
        }}</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import "./../../css/reading.css";
export default {
  data() {
    return {
      list: [
        { id: 1, name: " 专业观察", path: "/reading" },
        { id: 2, name: " 法律快讯", path: "/reading/kuaixun" },
        { id: 3, name: " 律途职场", path: "/reading/zhichang" },
        { id: 4, name: " 专题", path: "/reading/zhuanti" },
        { id: 5, name: " 专栏", path: "/reading/zhuanlan" },
        { id: 6, name: " 问答", path: "/reading/wendaList" },
        { id: 7, name: " 人气作者", path: "/reading/renqiAuthor" },
        { id: 8, name: " 我的订阅", path: "/reading/dingyue" },
      ],
    };
  },
};
</script>
<style lang="less">
.readingSide {
  border: 1px solid #dedede;
  border-bottom: none;
  width: 108px;
  background: white;
  li {
    a {
      // width: 108px;
      height: 40px;
      color: #595757;

      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 600;

      border-bottom: 1px solid #dedede;
      &:hover {
        color: #1c9ab1;

        background: #ebf1f5;
      }
    }
    .router-link-exact-active {
      background: #1c9ab1 !important;
      color: white !important;
    }
  }
}
</style>