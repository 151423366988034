<template>
  <div class="resumeManage">
    <div class="top commonBorder betweenFlex">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="80"
        :height="56"
        size="16"
        :distance="44"
      />
      <div class="publishNew commonFlex">
        <span class="shaixuanResume">简历筛选</span>
        <img :src="require('@as/personal/resume/shaixuan.png')" alt="" />
      </div>
    </div>
    <div class="wrapDestination" v-show="activeIndex == 0">
      <div class="wrapDIdianLine">
        <div>工作地点:</div>
        <div
          v-for="(item, index) in destination"
          :key="index"
          :class="{ selectedXingzhi: activeDes == index }"
          class="destinationitem"
          @click="activeDes = index"
        >
          {{ item }}
        </div>
        <div class="destinationitem">更多</div>
        <img
          :src="require('@as/zhaopin/jiagengduo.png')"
          alt=""
          style="margin-left: 7px"
        />
      </div>
    </div>
    <div class="wrapDIdianLine secondLine" v-show="activeIndex == 0">
      <div style="width: 86px">更多筛选:</div>
      <el-select v-model="value" placeholder="月薪范围">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select v-model="value" placeholder="最低学历">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select v-model="value" placeholder="工作年限">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select v-model="value" placeholder="工作性质">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="shaixuanWord commonFlex">筛选</div>
    </div>
    <ul style="padding: 0 20px">
      <li
        v-for="(item, index) in fankuiList"
        :key="index"
        class="articleItem commonBorder"
      >
        <div class="betweenFlex wrapFirstJob">
          <div class="title">国际争端解决领域专业律师</div>
          <div class="time">2020.10.24</div>
        </div>
        <div class="botJobManage betweenFlex">
          <div class="onlyFlex">
            <img
              :src="require('@as/reading/test.png')"
              alt=""
              class="fankuiIMG"
            />
            <div style="margin-left: 10px">
              <div class="title">{{ item.title }}</div>
              <div class="personDetail">
                26岁 <span class="fengeLine">|</span> 法学硕士<span
                  class="fengeLine"
                  >|</span
                >1-2年
              </div>
            </div>
          </div>
          <div class="betweenFlex" v-show="activeIndex == 0">
            <div class="wrapIcon">
              <div class="commonFlex whiteBorder">
                <img :src="require('@as/personal/partner.png')" alt="" />
              </div>

              <span class="refresh">邀面试</span>
            </div>
            <div class="wrapIcon">
              <div class="commonFlex whiteBorder">
                <img :src="require('@as/personal/forbidden.png')" alt="" />
              </div>
              <span class="refresh">不合适</span>
            </div>
            <div class="wrapIcon">
              <div class="commonFlex whiteBorder">
                <img :src="require('@as/personal/lanview.png')" alt="" />
              </div>
              <span class="refresh">查看</span>
            </div>
            <div class="wrapIcon">
              <div class="commonFlex whiteBorder">
                <img :src="require('@as/personal/xiazai.png')" alt="" />
              </div>
              <span class="refresh">下载</span>
            </div>
          </div>
          <div class="betweenFlex" v-show="activeIndex == 1">
            <div class="wrapIcon">
              <div class="commonFlex whiteBorder">
                <img :src="require('@as/personal/lanlike.png')" alt="" />
              </div>
              <span class="refresh">取消收藏</span>
            </div>
            <div class="wrapIcon">
              <div class="commonFlex whiteBorder">
                <img :src="require('@as/personal/lanview.png')" alt="" />
              </div>
              <span class="refresh">查看</span>
            </div>
          </div>
        </div>
      </li>
      <div class="wrapTotalPage commonFlex">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
  </div>
</template>

<script>
import headerChoice from '../headerChoice'
export default {
  data() {
    return {
      choiceList: ['已收到简历', '收藏简历', '已下载简历'],
      getResume: true,
      activeIndex: 0,
      activeDes: 0,
      fankuiList: [
        { title: '去我的师傅的师傅说的' },
        { title: '去我的师傅的师傅说的' },
        { title: '去我的师傅的师傅说的' },
        { title: '去我的师傅的师傅说的' },
      ],
      destination: [
        '全国',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
      ],
    }
  },
  methods: {
    change(index) {
      this.activeIndex = index
    },
  },
  components: {
    headerChoice,
  },
}
</script>

<style lang="less">
.resumeManage {
  .selectedXingzhi {
    border: 1px solid #c3e4eb !important;
    // padding: 2px 7px;
    color: #1c9ab1 !important;
  }
  .fengeLine {
    margin: 0 10px;
  }
  .articleItem:hover {
    background: linear-gradient(90deg, #ffffff 0%, #ecfaff 50%, #ffffff 100%);
  }
  .whiteBorder {
    width: 28px;
    height: 28px;
    background: #ffffff;
    border: 1px solid #a3c6cc;
    border-radius: 50%;
  }
  .wrapTotalPage {
    margin: 0 auto;
    height: 100px;
    text-align: center;
  }
  .personDetail {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    margin-top: 10px;
  }
  .fankuiIMG {
    width: 40px;
    height: 40px;
    background: #9fded5;
    border-radius: 50%;
  }
  .articleItem {
    padding: 20px 0;
    flex-direction: column;
    .wrapIcon {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-left: 16px;
      cursor: pointer;
    }
    .refresh {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
      margin-top: 10px;
    }
    .wrapFankui {
      width: 90px;
      height: 26px;
      border: 1px solid #1c9ab1;
      border-radius: 2px;

      .fankuiCount {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
        margin-left: 5px;
      }
      .lookFankui {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #666666;
      }
    }
    .xiaxian {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
    }
    .zaixian {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    .jobtime {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #afafaf;
      margin-left: 10px;
    }
    .title {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
  }
  .wrapFirstJob {
    margin-bottom: 10px;
    .title {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #0899ca;
    }
    .time {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
    }
  }
  .shaixuanWord {
    width: 90px;
    height: 36px;
    background: #1c9ab1;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff !important;
    margin-left: 15px;
  }
  .el-input__inner {
    background: #ebf1f5;
    border-radius: 0 !important;
    width: 180px;
    height: 36px;
  }
  .wrapDIdianLine {
    display: flex;
    align-items: center;
    //   padding-left: 37px;
    .destinationitem {
      margin-left: 37px;
      border: 1px solid transparent;
      padding: 2px 5px;
      cursor: pointer;
    }
    .el-select {
      margin-left: 7px;

      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
    }
    div {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
    }
  }
  .secondLine {
    margin-top: 27px;
    padding-left: 20px;
    border-bottom: 1px solid #dedede;
    padding-bottom: 15px;
  }
  .wrapDestination {
    // width: 1200px;
    // height: 220px;
    background: white;
    // margin-top: 25px;
    padding-top: 30px;
    padding-left: 20px;

    .wrapDIdianLine {
      display: flex;
      align-items: center;
      //   padding-left: 20px;
      .el-select {
        margin-left: 7px;

        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      div {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
    }
  }
  .top {
    padding: 0px 20px;
    .shaixuanResume {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #4395cd;
      margin-right: 5px;
    }
  }
}
</style>