<template>
  <div class="huangye contentWidth">
    <div style="position: relative">
      <img :src="require('@as/reading/test.png')" alt="" class="topImg" />
      <div class="huangyeWord commonFlex">汇律黄页</div>
    </div>
    <div class="wrapFive betweenFlex">
      <div
        class="fiveItem commonFlex"
        v-for="(item, index) in fiveList"
        :key="index"
      >
        <div class="fiveItemWord">{{ item.name }}</div>
        <img :src="item.imgSrc" alt="" style="margin-top: 20px" />
        <div style="margin-top: 18px">
          <span class="fiveItemCount">{{ item.count }}</span>
          <span class="fiveItemDanwei">{{ item.danwei }}</span>
        </div>
      </div>
    </div>

    <div class="betweenFlex commonBorder">
      <headerChoice
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="80"
        :height="80"
        @changeIndex="changeIndex"
      ></headerChoice>
      <div class="gongtongChoice">10万+法律从业者的共同选择</div>
    </div>
    <ul class="wrapLawyerItem">
      <li
        v-for="(item, index) in lawyerList"
        :key="index"
        class="lawyerItem"
        :class="{
          noDistance: (index + 1) % 3 == 0,
        }"
      >
        <div class="betweenFlex wrapTopGuanzhu">
          <div class="commonFlex">
            <div class="leftImg">
              <div class="lawyerImg commonFlex">
                <img :src="require('@as/reading/test.png')" alt="" />
              </div>

              <div class="commonFlex wrapFlag">
                <img :src="require('@as/reading/personFlag.png')" alt="" />
              </div>
            </div>
            <div class="rightName">
              <div class="name">{{ item.name }}</div>
              <div class="wrapJigouname">
                <img :src="require('@as/huangye/juxing.png')" alt="" style="" />
                <span class="jigouName">{{ item.jigou }}</span>
              </div>
            </div>
          </div>
          <div class="jiaguanzhu betweenFlex">
            <span>关注</span><span class="commonFlex">+</span>
          </div>
        </div>
        <div class="introduce">{{ item.introduce }}</div>
        <div class="commonFlex wrapFourCount">
          <div class="hasRightBorder">文章:{{ item.wenzhangCount }}</div>
          <div class="hasRightBorder">活动:{{ item.huodongCount }}</div>
          <div class="hasRightBorder">招聘:{{ item.zhaopinCount }}</div>
          <div class="hasRightBorder">关注:{{ item.guanzhuCount }}</div>
        </div>
      </li>
    </ul>
    <div class="betweenFlex" style="margin-top: 30px; margin-bottom: 70px">
      <div class="commonFlex createHuangye canClick" @click="toCreate">
        创建黄页
      </div>
      <div class="wrapForward betweenFlex">
        <div class="commonFlex forward canClick">上一页</div>
        <div class="commonFlex next canClick">下一页</div>
      </div>
    </div>
  </div>
</template>

<script>
import headerChoice from '@c/headerChoice'
export default {
  data() {
    return {
      fiveList: [
        {
          imgSrc: require('@as/huangye/zuzhi.png'),
          name: '组织结构',
          count: 1234,
          danwei: '家',
        },
        {
          imgSrc: require('@as/huangye/renzheng.png'),
          name: '认证作者',
          count: 1234,
          danwei: '位',
        },
        {
          imgSrc: require('@as/huangye/fabiao.png'),
          name: '发表文章',
          count: 1234,
          danwei: '篇',
        },
        {
          imgSrc: require('@as/huangye/fabu.png'),
          name: '发布活动',
          count: 1234,
          danwei: '场',
        },
        {
          imgSrc: require('@as/huangye/zhaopin.png'),
          name: '招聘职位',
          count: 1234,
          danwei: '个',
        },
      ],
      choiceList: ['全部', '组织机构', '团队个人'],
      activeIndex: 1,
      lawyerList: [
        {
          name: '恒庆律师事务所',
          jigou: '恒庆律师',
          introduce:
            '中伦律师事务所于1989年创立于北京，是中国最早的合伙制律师事务所之一...',
          wenzhangCount: 12,
          huodongCount: 23,
          zhaopinCount: 33,
          guanzhuCount: 54,
          selected: false,
        },
        {
          name: '恒庆律师事务所',
          jigou: '恒庆律师',
          introduce:
            '中伦律师事务所于1989年创立于北京，是中国最早的合伙制律师事务所之一...',
          wenzhangCount: 12,
          huodongCount: 23,
          zhaopinCount: 33,
          guanzhuCount: 54,
          selected: false,
        },
        {
          name: '恒庆律师事务所',
          jigou: '恒庆律师',
          introduce:
            '中伦律师事务所于1989年创立于北京，是中国最早的合伙制律师事务所之一...',
          wenzhangCount: 12,
          huodongCount: 23,
          zhaopinCount: 33,
          guanzhuCount: 54,
          selected: false,
        },
        {
          name: '恒庆律师事务所',
          jigou: '恒庆律师',
          introduce:
            '中伦律师事务所于1989年创立于北京，是中国最早的合伙制律师事务所之一...',
          wenzhangCount: 12,
          huodongCount: 23,
          zhaopinCount: 33,
          guanzhuCount: 54,
          selected: false,
        },
        {
          name: '恒庆律师事务所',
          jigou: '恒庆律师',
          introduce:
            '中伦律师事务所于1989年创立于北京，是中国最早的合伙制律师事务所之一...',
          wenzhangCount: 12,
          huodongCount: 23,
          zhaopinCount: 33,
          guanzhuCount: 54,
        },
        {
          name: '恒庆律师事务所',
          jigou: '恒庆律师',
          introduce:
            '中伦律师事务所于1989年创立于北京，是中国最早的合伙制律师事务所之一立于北京，是中国最早的合伙制律师事务所之一',
          wenzhangCount: 12,
          huodongCount: 23,
          zhaopinCount: 33,
          guanzhuCount: 54,
        },
      ],
    }
  },
  components: {
    headerChoice,
  },
  methods: {
    changeIndex(index) {
      this.activeIndex = index
    },
    toCreate() {
      this.$router.push('/huangye/createHuangye')
    },
  },
}
</script>

<style lang="less">
.huangye {
  .createHuangye {
    width: 118px;
    height: 34px;
    background: #1c9ab1;
    border-radius: 17px;

    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .wrapForward {
    width: 144px;
  }
  .forward {
    width: 64px;
    height: 34px;
    border: 1px solid #999999;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .next {
    width: 64px;
    height: 34px;
    border: 1px solid #1c9ab1;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
  }
  .gongtongChoice {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .topImg {
    width: 1200px;
    height: 300px;
    // background: #000000;
    // opacity: 0.4;
  }
  .wrapFive {
    justify-content: space-around !important;
  }
  .wrapLawyerItem {
    flex-wrap: wrap;
    display: flex;
  }
  .jigouName {
    font-size: 10px;
    font-family: PingFangSC;
    font-weight: 300;
    color: #1c9ab1;
  }
  .noDistance {
    margin-right: 0 !important;
  }
  .selectedLawyer {
    //     width: 385px;
    // height: 220px;
    background: rgba(255, 255, 255, 0.6) !important;
    border: 1px solid #dedede !important;
    border-radius: 2px;
  }
  .lawyerItem:hover {
    background: white;
  }
  .lawyerItem {
    padding: 20px;
    padding-bottom: 0;
    width: 385px;
    cursor: pointer;

    // height: 220px;
    background: rgba(255, 255, 255, 0);
    border: 1px solid #dedede;
    border-radius: 2px;
    margin-right: 22px;
    margin-top: 23px;
    &.selectedLawyer {
      //     width: 385px;
      // height: 220px;
      background: rgba(255, 255, 255, 0.6) !important;
      border: 1px solid #dedede !important;
      border-radius: 2px;

      .rightName {
        .name {
          color: #1c9ab1;
        }
      }
    }
    .introduce {
      margin-top: 31px;
      // padding-bottom: 22px;

      line-height: 24px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .wrapFourCount {
      margin-top: 22px;
      padding: 14px 0;
      border-top: 1px solid #dedede;
      .hasRightBorder {
        padding: 8px 23px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #666666;
        border-right: 1px solid #dedede;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          border-right: none;
        }
      }
    }
    .wrapTopGuanzhu {
      align-items: flex-start !important;
    }
    .jiaguanzhu {
      // width: 56px;
      padding: 5px 12px;
      // height: 22px;
      background: #1c9ab1;
      border-radius: 11px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
    }
    .rightName {
      margin-left: 20px;
      .name {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .wrapJigouname {
        position: relative;
        margin-top: 10px;
      }

      .jigouName {
        position: absolute;
        top: 50%;
        left: 7px;
        transform: translateY(-50%);
      }
    }
    .leftImg {
      position: relative;
    }
    .wrapFlag {
      width: 14px;
      height: 14px;
      background: #ffffff;
      border: 1px solid #dedede;
      border-radius: 50%;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .lawyerImg {
      width: 56px;
      height: 56px;
      background: #ffffff;
      border: 1px solid #dedede;
      border-radius: 50%;
      img {
        width: 32px;
        height: 36px;
      }
    }
  }
  .fiveItem {
    flex-direction: column;
    .fiveItemDanwei {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #bbbcbc;
    }
    .fiveItemCount {
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    .fiveItemWord {
      margin-top: 30px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
    }
  }
  .huangyeWord {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);

    font-size: 32px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>