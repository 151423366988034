<template>
  <div class="zhuantiDetail">
    <img :src="require('@as/reading/test.png')" alt="" class="topImg" />
    <div class="first">
      <div>
        <img
          :src="require('@as/reading/test.png')"
          alt=""
          style="width: 398px; height: 166px"
        />
        <div class="botShare">
          <span style="margin-right: 15px">分享</span>
          <img
            :src="require('@as/reading/wechat.png')"
            alt=""
            style="margin-right: 5px; cursor: pointer"
          />
          <img
            :src="require('@as/reading/weibo.png')"
            alt=""
            style="cursor: pointer"
          />
        </div>
      </div>
      <div style="margin-left: 39px">
        <div class="title">{{ obj.detailTitle }}</div>
        <div style="margin-top: 19px" class="tongji commonBorder">
          <div class="commonFlex">
            <span class="readingCount">{{ obj.readingCount }}</span
            ><span style="margin-left: 5px">篇文章</span>
          </div>
          <div style="margin-left: 40px" class="commonFlex">
            <span class="readingCount">{{ obj.people }}</span
            ><span style="margin-left: 10px">位观众</span>
          </div>
        </div>
        <div class="desc">{{ obj.desc }}</div>
        <div class="shouDetail commonFlex canClick">
          <img :src="require('@as/reading/star.png')" alt="" />
          <span style="margin-left: 5px">收藏</span>
          <span>309</span>
        </div>
      </div>
    </div>
    <div class="second betweenFlex">
      <div>
        <zhuantiArticle />
        <div class="commentList">
          <div class="topCommentCount betweenFlex">
            <div class="wrapCommentNum">
              <span class="commentNum">{{ comment.count }}</span
              ><span class="commentWordCount">条评论</span>
            </div>
            <div class="betweenFlex">
              <img :src="require('@as/reading/contentping.png')" alt="" />
              <span class="laiSaySome">我来说两句</span>
            </div>
          </div>
          <textarea class="fabiaoComment" placeholder="说点什么把"></textarea>
          <div class="rightWrapComment commonBorder">
            <div class="commentWord commonFlex canClick">评论</div>
          </div>
          <ul>
            <li
              v-for="(item, index) in comment.list"
              :key="index"
              class="commentItem commonBorder"
            >
              <img
                :src="require('@as/reading/test.png')"
                alt=""
                style="width: 40px; height: 40px; border-radius: 50%"
              />
              <div class="wrapRightComment">
                <div>
                  <span class="commentPerson">{{ item.name }}</span>
                  <span class="commentTime">{{ item.time }}</span>
                </div>
                <div class="betweenFlex wrapSecondBot">
                  <span class="commentContent">{{ item.content }}</span>
                  <div class="huifu commonFlex canClick">
                    <img :src="require('@as/reading/zhuantiMsg.png')" alt="" />
                    <span class="">回复</span>
                  </div>
                </div>
              </div>
            </li>
            <div class="jiazaiMore commonFlex">加载更多</div>
          </ul>
        </div>
      </div>

      <hotZhuanti />
    </div>
  </div>
</template>
<script>
import zhuantiArticle from '@c/reading/zhuantiArticle'
import hotZhuanti from '@c/reading/hotZhuanti'
export default {
  data() {
    return {
      recommendList: [
        {
          title: '悄悄瞧瞧瞧瞧瞧瞧瞧瞧',
          desp: '去悄悄瞧瞧瞧瞧瞧瞧瞧瞧',
          choice: '立即入住',
        },
        {
          title: '悄悄瞧瞧瞧瞧瞧瞧瞧瞧',
          desp: '去悄悄瞧瞧瞧瞧瞧瞧瞧瞧',
          choice: '立即入住',
        },
      ],
      content: {
        title: '悄悄去悄悄瞧瞧瞧瞧瞧瞧瞧瞧',
        zuozhe: ['全球', '此次'],
        time: '20203030',
        count: '123',
        relativeSign: ['全球', '全球', '全球'],
      },
      comment: {
        count: 8,
        list: [
          { name: '去去去', time: '几天', content: 'zzzz啊啊啊啊啊啊啊' },
          { name: '去去去', time: '几天', content: 'zzzz啊啊啊啊啊啊啊' },
          { name: '去去去', time: '几天', content: 'zzzz啊啊啊啊啊啊啊' },

          { name: '去去去', time: '几天', content: 'zzzz啊啊啊啊啊啊啊' },
        ],
      },
      commentObj: {
        count: 9,
        list: [
          {
            time: '一天前',
            name: '小人物',
            content: '感觉每年的设计趋势都有那几个大条～',
          },
          {
            time: '一天前',
            name: '小人物',
            content: '感觉每年的设计趋势都有那几个大条～',
          },
          {
            time: '一天前',
            name: '小人物',
            content: '感觉每年的设计趋势都有那几个大条～',
          },

          {
            time: '一天前',
            name: '小人物',
            content: '感觉每年的设计趋势都有那几个大条～',
          },
          {
            time: '一天前',
            name: '小人物',
            content: '感觉每年的设计趋势都有那几个大条～',
          },

          {
            time: '一天前',
            name: '小人物',
            content: '感觉每年的设计趋势都有那几个大条～',
          },
        ],
      },
      obj: {
        detailTitle: '交易案例深度评析《一个专题囊括所有的评析》',
        readingCount: 19,
        people: 1234455,
        desc:
          '债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治纠纷解决债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则，简单适用股票模型下的司只是治纠纷解决',
        shou: 370,
      },
    }
  },
  components: {
    zhuantiArticle,
    hotZhuanti,
  },
}
</script>

<style lang="less" scoped>
.zhuantiDetail {
  width: 100%;
  //   .commentArea {
  //     width: 1200px;
  //     background: #ffffff;
  //     opacity: 0.98;
  //     border-radius: 4px;
  //     // border:1px solid #DEDEDE;
  //     padding: 0 30px;
  //     margin: 0 auto;
  .first {
    width: 1200px;
    height: 301px;
    background: white;
    margin: 0 auto;
    margin-top: 36px;
    z-index: 2000;
    position: relative;
    padding: 30px;
    display: flex;
    align-items: center;
  }
  #wordInput {
    width: 790px;
    height: 60px;
    background: #f7f7f7;
    border: 1px solid #ededed;
    border-radius: 4px;
  }
  .wrapCommentWord {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
    div {
      width: 80px;
      height: 32px;
      background: #089acb;
      border-radius: 4px;

      font-size: 14px;
      font-family: SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .commentCount {
    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9bb2;
  }
  .comentWord {
    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 400;
    color: black;
  }
  .say {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    margin-left: 9px;
  }
  .second {
    width: 1200px;
    margin: 0 auto;
    margin-top: 19px;
    align-items: flex-start !important;
  }
  .tongji {
    display: flex;
    align-items: center;
    width: 664px;
    padding-bottom: 19px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #a7a8a8;
  }
  .readingCount {
    font-size: 24px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c99b0;
  }
  .title {
    font-size: 24px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .shouDetail {
    width: 116px;
    height: 31px;
    background: #1c99b0;
    color: white;
    margin-top: 37px;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .desc {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #a7a8a8;
    line-height: 24px;
    margin-top: 31px;
  }

  .botShare {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
  }
  .topImg {
    width: 100%;
    height: 236px;
    position: absolute;
    top: 67px;
  }
  .commentList {
    margin-top: 16px;
    padding: 30px;
    background: white;
    width: 850px;
    background: #ffffff;
    border: 1px solid #e5e8ed;
    box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
    opacity: 0.98;
    border-radius: 4px;
    padding-bottom: 20px;
    margin-bottom: 46px;
    .commentItem {
      padding: 20px 0;
      display: flex;
      align-items: flex-start;
      .wrapRightComment {
        margin-left: 20px;
        .wrapSecondBot {
          margin-top: 10px;
          width: 722px;
        }
        .commentPerson {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #333333;
        }
        .commentTime {
          margin-left: 19px;

          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #999999;
        }
        .commentContent {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #666666;
        }
        .huifu {
          span {
            margin-left: 3px;

            font-size: 12px;
            font-family: SourceHanSansCN;
            font-weight: 400;
            color: #9c9c9c;
          }
        }
      }
    }
  }
  .laiSaySome {
    margin-left: 9px;

    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .topCommentCount {
    padding-bottom: 24px;
    align-items: flex-start !important;

    .wrapCommentNum {
      .commentWordCount {
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: black;
        margin-left: 10px;
      }
      .commentNum {
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9bb2;
      }
    }
  }
  .fabiaoComment {
    padding: 10px;
    resize: none;
    width: 790px;
    height: 60px;
    background: #f7f7f7;
    border: 1px solid #ededed;
    border-radius: 4px;
  }
  .fabiaoComment::placeholder {
    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #666666;
  }
  .rightWrapComment {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .commentWord {
      width: 80px;
      height: 32px;
      background: #089acb;
      border-radius: 4px;

      font-size: 14px;
      font-family: SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .jiazaiMore {
    width: 786px;
    height: 30px;
    background: rgba(28, 154, 177, 0.08);
    margin-top: 20px;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
  }
}
</style>