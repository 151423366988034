<template>
  <div class="educationExp">
    <div v-for="(item, index) in eduList" :key="index">
      <div class="startFlex everyline">
        <div class="startFlex leftBasicInfo">
          <span class="detailWordInfo">学校名称</span>
          <span class="requireFlag">*</span>
        </div>
        <input type="text" class="jingliInput" v-model="item.schoolName" />
      </div>
      <div class="startFlex everyline">
        <div class="startFlex leftBasicInfo">
          <span class="detailWordInfo">专业名称</span>
          <span class="requireFlag">*</span>
        </div>
        <input
          type="text"
          class="jingliInput"
          v-model="item.professionalName"
        />
      </div>

      <div class="startFlex everyline">
        <div class="startFlex leftBasicInfo">
          <span class="detailWordInfo">就读时间</span>
          <span class="requireFlag">*</span>
        </div>
        <div class="betweenFlex" style="width: 722px">
          <el-date-picker
            v-model="item.startTime"
            type="date"
            placeholder="开始时间"
            class="ruzhiTime"
            style="width: 351px"
          >
          </el-date-picker>
          <el-date-picker
            v-model="item.endTime"
            type="date"
            placeholder="结束时间"
            class="ruzhiTime"
            style="width: 351px"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="startFlex everyline">
        <div class="startFlex leftBasicInfo">
          <span class="detailWordInfo">学历</span>
          <span class="requireFlag">*</span>
        </div>
        <div>
          <el-select
            v-model="item.education"
            placeholder="本科"
            class="yxiangSelect"
          >
            <el-option
              v-for="i in educationList"
              :key="i"
              :label="i"
              :value="i"
            >
            </el-option>
          </el-select>
          <el-checkbox v-model="item.isRecruitment" style="margin-left: 20px"
            >是否统招</el-checkbox
          >
        </div>
      </div>
      <botChoice
        :isLast="index === eduList.length - 1"
        @confirm="handlerConfirm"
        @cancel="handlerCancel(index)"
      />
    </div>
  </div>
</template>

<script>
import botChoice from './botChoice'
import { Resume, Job } from '@api'
export default {
  props: ['id'],
  data() {
    return {
      eduList: [
        {
          schoolName: '',
          professionalName: '',
          startTime: '',
          endTime: '',
          education: '',
          isRecruitment: false,
        },
      ],
      educationList: [],
    }
  },
  components: {
    botChoice,
  },
  watch: {
    id: {
      handler(val) {
        if (!val) return
        Resume.getEdu({ userId: this.$store.state.uid }).then((res) => {
          this.eduList = res
        })
      },
      immediate: true,
    },
  },
  created() {
    Job.getEducationList().then((res) => {
      this.educationList = res
    })
  },
  methods: {
    handlerConfirm() {
      const data = this.eduList.map((item) => {
        const obj = Object.assign({}, item)
        obj.userId = this.$store.state.uid
        if (this.id) obj.id = this.id
        return obj
      })
      for (let i in data) {
        for (let j in data[i]) {
          if (!this.isValid(j, data[i][j])) return
        }
      }
      Resume.changeEdu(data)
    },
    handlerCancel(idx) {
      if (this.eduList.length > 1) {
        this.eduList.splice(idx, 1)
      }
    },
    isValid(keys, val) {
      const keysDir = {
        schoolName: '学校名称',
        professionalName: '专业名称',
        startTime: '就读开始时间',
        endTime: '就读结束时间',
        education: '学历',
        isRecruitment: '是否统招',
      }
      if (keysDir[keys] && !val) {
        this.$message.error(`${keysDir[keys]}不能为空`)
        return false
      }
      return true
    },
    addItem() {
      this.eduList.push({
        schoolName: '',
        professionalName: '',
        startTime: '',
        endTime: '',
        education: '',
        isRecruitment: false,
      })
    },
  },
}
</script>

<style lang="less">
.educationExp {
  .jobDesp {
    width: 722px;
    height: 84px;
    padding: 20px;
    border: 1px solid #eaebeb;
  }
  ::-webkit-input-placeholder {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
  }
  .zhijin {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .ruzhiTime {
    width: 354px;
    height: 34px;
    .el-input__prefix {
      top: -3px;
    }
  }
  input {
    border: 1px solid #eaebeb;
    padding-left: 20px;
  }
  padding-left: 20px;
  padding-bottom: 40px;
  .jingliInput {
    width: 721px;
    height: 34px;
  }
  .cancel {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .saveUpdate {
    width: 94px;
    height: 31px;
    border-radius: 32px;
    background: #1c9ab1;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .leftBasicInfo {
    width: 105px;
  }
  .yxiangSelect {
    width: 351px;
    height: 34px;
  }
  .yxiangSelect::-webkit-input-placeholder {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
  }
  .detailWordInfo {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .everyline {
    margin-top: 27px;
  }
}
</style>