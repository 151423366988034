<template>
  <div class="zhanghaoSet">
    <div class="top betweenFlex commonBorder">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="64"
        :height="56"
        :size="16"
        :distance="44"
      />
      <div
        class="addAddress commonFlex canClick"
        v-show="activeIndex == 2"
        @click="dialogVisible = true"
      >
        新增地址
      </div>
    </div>
    <div v-show="activeIndex == 0">
      <div class="topImgWrap commonFlex">
        <img :src="require('@as/personal/personSet.png')" alt="" />
        <div class="uploadWord">
          从本地上传一张 JPG/PNG 格式图片， 大小 5M 以内
        </div>
        <div class="uploadPhoto commonFlex">上传图片</div>
      </div>
      <div class="wrapAllInput">
        <div class="wrapInput">
          <div class="title">昵称</div>
          <input type="text" class="setInput" />
        </div>
        <div class="wrapInput">
          <div class="title">职业</div>
          <div class="startFlex">
            <input type="text" class="setInputhalf" placeholder="所在公司" />
            <input
              type="text"
              class="setInputhalf"
              placeholder="就职职位"
              style="margin-left: 10px"
            />
          </div>
        </div>
        <div class="wrapInput" style="position: relative">
          <div class="title">简介</div>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder="个人简介"
            class="jianjie"
          ></textarea>
          <div class="wordNum">30字</div>
        </div>
        <div class="wrapInput">
          <div class="title">技能专长</div>
          <input
            type="text"
            class="setInput"
            placeholder="请输入或选择您擅长的领域，可多选"
          />
        </div>
        <div class="wrapInput">
          <div class="title">个人网址</div>
          <input type="text" class="setInput" placeholder="www.huilvnet.com" />
        </div>
        <div class="wrapInput">
          <div class="title">所在地</div>
          <el-select
            v-model="value"
            placeholder="请选择"
            class="setInputOption"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="querenSub commonFlex">确认提交</div>
      </div>
    </div>
    <div v-show="activeIndex == 1 && setoptions" class="wrapSecurity">
      <div class="anquanLine betweenFlex">
        <div class="startFlex">
          <div class="wrapImg commonFlex">
            <img :src="require('@as/personal/mima.png')" alt="" />
          </div>

          <div class="midWord">账号密码</div>
        </div>

        <div
          class="rightWord commonFlex canClick"
          @click="
            changePass = true
            setoptions = false
          "
        >
          修改
        </div>
      </div>
      <div class="anquanLine betweenFlex">
        <div class="startFlex">
          <div class="wrapImg commonFlex">
            <img :src="require('@as/personal/xinxiang.png')" alt="" />
          </div>
          <div class="midWord">已绑定邮箱 aaaa@mail,com</div>
        </div>
        <div
          class="rightWord commonFlex canClick"
          @click="
            bindEmai = true
            setoptions = false
          "
        >
          更换
        </div>
      </div>
      <div class="anquanLine betweenFlex">
        <div class="startFlex">
          <div class="wrapImg commonFlex">
            <img :src="require('@as/personal/dianhua.png')" alt="" />
          </div>
          <div class="midWord">已绑定手机号 18711174856</div>
        </div>
        <div
          class="rightWord commonFlex canClick"
          @click="
            changePhone = true
            setoptions = false
          "
        >
          更换
        </div>
      </div>
      <div class="anquanLine betweenFlex">
        <div class="startFlex">
          <div class="wrapImgWEixin commonFlex">
            <img :src="require('@as/personal/weixinSet.png')" alt="" />
          </div>
          <div class="weixinbo">未绑定微信</div>
        </div>
        <div class="rightWord commonFlex">绑定</div>
      </div>
      <div class="anquanLine betweenFlex">
        <div class="startFlex">
          <div class="wrapImgWEixin commonFlex">
            <img :src="require('@as/personal/weibobang.png')" alt="" />
          </div>
          <div class="weixinbo">未绑定微博</div>
        </div>
        <div class="rightWord commonFlex">绑定</div>
      </div>
    </div>
    <div class="bindEmai" v-show="activeIndex == 1 && bindEmai">
      <div class="warpEmailWord">
        ·
        为开发者提供账号认证流程、类型、资料完善、资质证明材料等说明，个人开发者认证/企业开发者认证通过后即可申请应用上线。<br />
        · 认证信息及资料提交审核后，可在此查看认证情况；<br />
        ·
        认证不通过时根据不通过原因对认证资料进行修改，再次进入认证审核阶段。<br />
      </div>
      <div class="bindEmailWord">绑定邮箱</div>
      <div>
        <div class="emailWord">邮箱</div>
        <input type="text" placeholder="请输入邮箱地址" class="emailInput" />
      </div>
      <div style="margin-top: 20px">
        <span class="emailWord">验证码</span>
        <div class="startFlex">
          <input type="text" placeholder="请输入验证码" class="yanzhengInput" />
          <div>xxxxx</div>
        </div>
      </div>
      <div class="startFlex" style="margin-top: 20px">
        <div class="queren commonFlex">确认</div>
        <div class="quxiao commonFlex">取消</div>
      </div>
    </div>
    <div class="changePhone" v-show="activeIndex == 1 && changePhone">
      <div>
        <div class="betweenFlex" style="width: 400px">
          <div class="wrapNumber commonFlex">1</div>
          <div class="lianjie"></div>
          <div class="wrapNumber commonFlex">2</div>
          <div class="lianjie"></div>
          <div class="wrapNumber commonFlex">3</div>
        </div>
        <div
          class="betweenFlex shenfenyanzheng"
          style="width: 400px; margin-top: 10px"
        >
          <div>身份验证</div>
          <div>更换新的手机号码</div>
          <div>完成</div>
        </div>
        <div class="xiugaiSuccess">恭喜您！手机号码修改成功！</div>
        <div class="quedingXiugai commonFlex">确定</div>
      </div>
    </div>
    <div class="modifyPass" v-show="activeIndex == 1 && changePass">
      <div class="title">修改密码</div>
      <div class="threePass">
        <div class="password">原密码</div>
        <input type="text" placeholder="请输入原密码" class="passwodInput" />
      </div>
      <div class="threePass">
        <div class="password">新密码</div>
        <input type="text" placeholder="请输入密码" class="passwodInput" />
      </div>
      <div class="threePass">
        <div class="password">重复密码</div>
        <input type="text" placeholder="请重复密码" class="passwodInput" />
      </div>
      <div class="startFlex" style="margin-top: 20px">
        <div class="queren commonFlex">确认</div>
        <div class="quxiao commonFlex">取消</div>
      </div>
    </div>
    <dizhiManage v-show="activeIndex == 2" />
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      custom-class="pop_container"
    >
      <div class="pop_address">
        <div class="pop_title">新增地址</div>
        <div class="content">
          <div class="row">
            <div class="label">所在地区：</div>
            <div class="value">
              <div class="select_container">
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-image
                  class="select_icon"
                  :src="require('@as/personal/icon_11.png')"
                ></el-image>
              </div>
              <div class="select_container">
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-image
                  class="select_icon"
                  :src="require('@as/personal/icon_11.png')"
                ></el-image>
              </div>
              <div class="select_container">
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-image
                  class="select_icon"
                  :src="require('@as/personal/icon_11.png')"
                ></el-image>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="label">详细地址：</div>
            <div class="value">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="请输入内容"
                v-model="textarea"
              >
              </el-input>
            </div>
          </div>
          <div class="row">
            <div class="label">收货人：</div>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
            <div class="label margin_left">手机号码：</div>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <div class="btn_container">
            <el-checkbox v-model="checked">设为默认</el-checkbox>
            <div class="btn_group">
              <div class="btn">确定</div>
              <div class="btn">取消</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import headerChoice from '../headerChoice'
import dizhiManage from './dizhiManage'
export default {
  data() {
    return {
      dialogVisible: false,
      choiceList: ['我的信息', '账号安全', '地址管理'],
      activeIndex: 0,
      bindEmai: false,
      changePhone: false,
      setoptions: true,
      changePass: false,
      addressManage: true,
      options: [
        {
          value: '选项1',
          label: '黄金糕',
        },
        {
          value: '选项2',
          label: '双皮奶',
        },
        {
          value: '选项3',
          label: '蚵仔煎',
        },
        {
          value: '选项4',
          label: '龙须面',
        },
        {
          value: '选项5',
          label: '北京烤鸭',
        },
      ],
      value: '',
    }
  },
  components: {
    headerChoice,
    dizhiManage,
  },
  methods: {
    change(index) {
      this.activeIndex = index
      this.setoptions = true
      this.bindEmai = false
      this.changePhone = false
      this.changePass = false
    },
    getAddressList() {},
  },
}
</script>

<style lang="less">
.zhanghaoSet {
  .wrapImgWEixin {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #cccccc;
  }
  .pop_container {
    & /deep/ .el-dialog__header {
      padding: 33px 45px 5px;
      & /deep/ .el-dialog__headerbtn .el-dialog__close {
        font-size: 20px;
        font-weight: bold;
        color: #cccccc;
      }
    }
    & /deep/ .el-dialog__body {
      padding: 30px 128px 58px;
    }
    .pop_address {
      .title {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }

      .pop_title {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .content {
        margin-top: 46px;
        padding: 0 6px;
        .row {
          display: flex;
          align-items: baseline;
          &:not(:first-child) {
            margin-top: 23px;
          }
          .label {
            margin-right: 23px;
            flex-shrink: 0;
            min-width: 80px;
            font-size: 16px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #999999;
            &.margin_left {
              margin-left: 47px;
            }
          }
          .value {
            display: flex;
            width: 530px;

            .select_container {
              position: relative;
              margin-right: 10px;
              &:last-of-type {
                margin-right: 0;
              }
              & /deep/ .el-input {
                width: 170px;
              }
              & /deep/ .el-input__inner {
                width: 170px;
                height: 28px;
                line-height: 28px;
              }
              & /deep/ .el-input--suffix .el-input__inner {
                padding-left: 7px;
              }
              & /deep/ .el-input__suffix {
                display: none;
              }
              .select_icon {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
              }
            }
          }
          & /deep/ .el-input {
            width: 190px;
          }
          & /deep/ .el-input__inner {
            width: 190px;

            height: 32px;
            line-height: 32px;
          }
        }
        .btn_container {
          margin-top: 48px;
          display: flex;
          align-items: center;
          & /deep/ .el-checkbox {
            color: #666666;
          }
          .btn_group {
            margin-left: 215px;
            display: flex;
            .btn {
              padding: 12px 64px;
              font-size: 16px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #009db4;
              border: 1px solid #009db4;
              border-radius: 2px;
              &:first-child {
                margin-right: 13px;
              }
            }
          }
        }
      }
    }
  }
  .el-input__inner {
    width: 910px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 0 !important;
  }
  .addAddress {
    width: 80px;
    height: 30px;
    background: #4395cd;
    border-radius: 2px;
    margin-right: 20px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .threePass {
    margin-top: 20px;
  }
  .passwodInput {
    width: 480px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cccccc;
  }
  .password {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
  .modifyPass {
    padding: 50px;
    .title {
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
  }
  .quedingXiugai {
    width: 290px;
    height: 40px;
    background: #1c9ab1;
    border-radius: 2px;
    margin-top: 84px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
    margin-left: 50px;
    margin-bottom: 50px;
  }
  .xiugaiSuccess {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
    margin-top: 80px;
    margin-left: 100px;
  }
  .shenfenyanzheng {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .changePhone {
    margin-top: 100px;
    margin-left: 250px;
  }
  .lianjie {
    width: 170px;
    height: 6px;
    background: #1c9ab1;
    border-radius: 0px 3px 3px 0px;
  }
  .wrapNumber {
    width: 24px;
    height: 24px;
    background: #ffffff;
    border-radius: 50%;
    border: 1px solid #b1dce4;
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #415499;
  }
  .queren {
    width: 100px;
    height: 32px;
    background: #1c9ab1;
    border-radius: 2px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .quxiao {
    margin-left: 20px;
    width: 100px;
    height: 32px;
    border: 1px solid #1c9ab1;
    border-radius: 2px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
  }
  .emailWord {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .yanzhengInput {
    width: 480px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cccccc;
  }
  .warpEmailWord {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
  }
  .bindEmai {
    padding: 25px;
  }
  .bindEmailWord {
    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
    margin: 57px 0 27px 0;
  }
  .emailInput {
    width: 480px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cccccc;
  }
  .wrapImg {
    width: 39px;
    height: 39px;
    background: #1c9ab1;
    border-radius: 50%;
  }
  .weixinbo {
    margin-left: 35px;
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .rightWord {
    width: 100px;
    height: 29px;
    background: #1c9ab1;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .midWord {
    margin-left: 35px;
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .anquanLine {
    margin-bottom: 40px;
    width: 500px;
  }
  .wrapSecurity {
    padding: 40px;
  }
  .querenSub {
    width: 200px;
    height: 36px;
    background: #1c9ab1;

    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;

    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    cursor: pointer;
  }
  .setInputOption {
    width: 910px;
    height: 44px;
    background: #ffffff;
  }
  .jianjie {
    width: 920px;
    height: 84px;
    background: #ffffff;
    border: 1px solid #cccccc;
  }
  .wordNum {
    position: absolute;
    right: 47px;
    bottom: 10px;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .wrapInput {
    margin-top: 26px;
  }
  .wrapAllInput,
  .top {
    padding-left: 20px;
  }
  .topImgWrap {
    flex-direction: column;
    margin-top: 43px;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
    margin-bottom: 3px;
  }
  .uploadWord {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    margin: 15px 0;
  }
  .uploadPhoto {
    width: 200px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #cccccc;

    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
  }
  input {
    border: 1px solid #eaebeb;
    padding-left: 20px;
  }
  .setInputhalf {
    width: 450px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cccccc;
  }
  .setInput {
    width: 920px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cccccc;
  }
}
</style>