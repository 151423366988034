<template>
  <div class="createCenter">
    <div class="writeArticleHead">
      <div class="writeArticleLeft">
        <div class="articleHead commonFlex">慧</div>
        <div style="margin-left: 10px">
          <img :src="require('@as/reading/word.png')" alt="" />
          <div class="articleDesp">Reading enriches the</div>
        </div>
      </div>
      <div class="tixiRead canClick">创作中心 ></div>
    </div>
    <div class="articleBot commonFlex">
      <div class="commonFlex direction dataItem">
        <div class="count">{{ userInfo.attention || 0 }}</div>
        <div class="guanzhu">关注</div>
      </div>
      <div class="commonFlex direction dataItem">
        <div class="count">{{ userInfo.fans || 0 }}</div>
        <div class="guanzhu">粉丝</div>
      </div>
      <div class="commonFlex direction dataItem">
        <div class="count">{{ userInfo.collect || 0 }}</div>
        <div class="guanzhu">收藏</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    },
  },
}
</script>
<style lang="less">
.createCenter {
  width: 330px;
  background: white;
  .count {
    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 300;
    color: #333333;
  }
  .dataItem {
    border-right: 1px solid #dedede;
    width: 133px;
    height: 40px;
  }
  .guanzhu {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    margin-top: 20px;
  }
  .articleBot {
    width: 300px;
    // height: 110px;
    // border: 1px solid #DEDEDE;
    margin: 0 auto;
    margin-top: 20px;
    padding: 15px 0;
    display: flex;
    .articleLeft {
      width: 49%;
      height: 80px;
      flex-direction: column;
      border-right: 1px solid #dedede;
    }
    .articleRight {
      width: 49%;
      height: 80px;
      flex-direction: column;
    }
  }
  .writeArticleHead {
    width: 100%;
    height: 50px;
    background: #1c9ab1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tixiRead {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #ffffff;
    }
    .writeArticleLeft {
      display: flex;
      align-items: center;
      justify-content: stretch;
      .articleHead {
        font-size: 24px;
        font-family: iSlide;
        font-weight: normal;
        color: #ffffff;
        width: 33px;
        height: 33px;
        border: 1px solid white;
        border-radius: 50%;
      }
      .articleDesp {
        font-size: 10px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>
