<template>
  <div class="midMenu">
    <div class="topMenu">
      <div class="topMenuLeft">
        <div @click="ifmore" :class="{ moreBorder: ifMore }" class="hideBorder">
          <img :src="require('@as/reading/more.png')" alt="" v-show="ifMore" />
          <img
            :src="require('@as/reading/morehui.png')"
            alt=""
            v-show="!ifMore"
          />
          <span :class="{ ifMore: ifMore }" class="nomore">全部</span>
        </div>
        <div @click="ifhot" :class="{ hotBorder: ifHot }" class="hideBorder">
          <img :src="require('@as/reading/hot1.png')" alt="" v-show="ifHot" />
          <img :src="require('@as/reading/hot.png')" alt="" v-show="!ifHot" />
          <span :class="{ ifHot: ifHot }" class="nomore">热文</span>
        </div>
        <div
          @click="ifjingXuan"
          :class="{ jingXuanBorder: ifJingXuan }"
          class="hideBorder"
        >
          <img
            :src="require('@as/reading/jingxuan.png')"
            alt=""
            v-show="ifJingXuan"
          />
          <img
            :src="require('@as/reading/jingxuanhui.png')"
            alt=""
            v-show="!ifJingXuan"
          />
          <span :class="{ ifJingXuan: ifJingXuan }" class="nomore">精选</span>
        </div>
      </div>
      <div class="topMenRight">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            业务领域<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" placement="bottom">
            <el-dropdown-item command="1">黄金糕</el-dropdown-item>
            <el-dropdown-item command="2">狮子头</el-dropdown-item>
            <el-dropdown-item command="3">螺蛳粉</el-dropdown-item>
            <el-dropdown-item command="4" disabled>双皮奶</el-dropdown-item>
            <el-dropdown-item command="5" divided>蚵仔煎</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command="handleAuthor">
          <span class="el-dropdown-link">
            作者属性<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">黄金糕</el-dropdown-item>
            <el-dropdown-item command="b">狮子头</el-dropdown-item>
            <el-dropdown-item command="c">螺蛳粉</el-dropdown-item>
            <el-dropdown-item command="d" disabled>双皮奶</el-dropdown-item>
            <el-dropdown-item command="e" divided>蚵仔煎</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="botMenu">
      <div
        v-for="(item, index) in choice"
        :key="index"
        class="choiceItem commonFlex"
        @click="activeIndex = index"
        :class="{ selected: activeIndex == index }"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ifMore: true,
      ifHot: false,
      ifJingXuan: false,
      activeIndex: '1',
      choice: ['近期最多阅读', '近期最多收藏', '近期最多赞'],
    }
  },
  methods: {
    ifmore() {
      this.ifMore = true
      this.ifHot = false
      this.ifJingXuan = false
    },
    ifjingXuan() {
      this.ifMore = false
      this.ifHot = false
      this.ifJingXuan = true
    },
    ifhot() {
      this.ifMore = false
      this.ifHot = true
      this.ifJingXuan = false
    },
    handleCommand() {},
    handleAuthor() {},
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
  },
}
</script>
<style lang="less">
.midMenu {
  .topMenu {
    width: 100%;
    height: 46px;
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
    .topMenuLeft {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      padding-left: 16px;
      div {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
      }
      .moreBorder,
      .hotBorder,
      .jingXuanBorder {
        border-bottom: 2px solid #1c99b0 !important;
      }
      .hideBorder {
        border-bottom: 2px solid transparent;
      }
    }
    .topMenRight {
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .ifMore {
      color: #1c99b0 !important;
    }
    .ifHot {
      color: #1c99b0 !important;
    }
    .ifJingXuan {
      color: #1c99b0 !important;
    }
    .nomore {
      color: #595757;
      margin-left: 5px;
    }
  }
  .botMenu {
    width: 100%;
    padding-left: 34px;
    display: flex;
    .choiceItem {
      width: 79px;
      margin-right: 48px;
      height: 38px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
      border-bottom: 2px solid transparent;
    }
    .el-menu-demo {
      height: 38px;
      border-bottom: none !important;
      .el-menu-item {
        display: flex;
        align-items: center;
        height: 38px;
        padding: 0 2px !important;
        margin-right: 45px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      .is-active {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c99b0 !important;
        height: 38px;
      }
    }
  }
}
</style>