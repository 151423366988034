<template>
  <div class="biaoqianList">
    <div class="top">
      <!-- <img :src="require('@as/reading/test.png')" alt="" class="topImg"> -->
      <div class="wrapContent contentWidth">
        <div class="biaoqianList">标签列表</div>
        <div class="biaoqianDesp">
          拥有可信赖的 DDoS 防护体系，可为不同行业提供多种安全解决方案
        </div>
      </div>
    </div>
    <div class="contentWidth wrapBiaoqianList">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="biaoqianItem"
        @click="toDetail()"
      >
        <img
          :src="require('@as/reading/test.png')"
          alt=""
          class="biaoqianImg"
        />
        <div class="biaoqianInfo">
          <div class="title">{{ item.title }}</div>
          <div class="liulanCount">{{ item.liulanCount }}人浏览</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },

        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },

        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
        { title: '瞧瞧瞧瞧瞧瞧', liulanCount: 123 },
      ],
    }
  },
  methods: {
    toDetail() {
      this.$router.push({ name: 'biaoqianItem' })
    },
  },
}
</script>

<style lang="less">
.biaoqianList {
  .wrapBiaoqianList {
    margin-bottom: 40px;
    width: 1200px;
    background: #ffffff;
    padding-left: 70px;
    flex-wrap: wrap;
    display: flex;
    padding-bottom: 62px;
    .biaoqianItem {
      width: 320px;
      height: 88px;
      border: 1px solid #dedede;
      margin-right: 50px;
      padding: 25px 20px;
      display: flex;
      align-items: center;
      margin-top: 32px;
      .biaoqianInfo {
        margin-left: 17px;
        padding-left: 17px;
        border-left: 1px solid #dedede;
        height: 47px;
        display: flex;
        // align-items: center;
        justify-content: center;
        flex-direction: column;
        .title {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #333333;
        }
        .liulanCount {
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #999999;
          margin-top: 10px;
        }
      }
      .biaoqianImg {
        width: 48px;
        height: 48px;
        background: #fffeff;
        border: 1px solid #dedede;
        border-radius: 4px;
      }
    }
  }
  .top {
    background: url('~@as/reading/test.png') no-repeat;
    background-size: 100% 280px;
    width: 100%;
    height: 280px;
    .topImg {
      width: 100%;
      height: 100%;
    }
    .wrapContent {
      flex-direction: column;
      padding-top: 90px;
      display: flex;
      justify-content: flex-start !important;
      .biaoqianList {
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #ffffff;
      }
      .biaoqianDesp {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #999999;
        margin-top: 27px;
      }
    }
  }
}
</style>