<template>
  <div class="guanchaJobZhichang contentWidth">
    <sidebar></sidebar>
    <div class="midContent">
      <img
        :src="require('@as/reading/guanchaMid.png')"
        alt=""
        style="width: 100%"
      />
      <topMenu></topMenu>
      <articleList />
      <div class="lookmore">查看更多</div>
    </div>
    <div class="rightContent">
      <writeArticle />
      <job />
    </div>
  </div>
</template>

<script>
import sidebar from '@c/reading/sidebar'
import topMenu from '@c/reading/topMenu'
import job from '@c/reading/job'
import writeArticle from '@c/reading/writeArticle'
import articleList from '@c/reading/articleList'
export default {
  data() {
    return {}
  },
  components: {
    sidebar,
    topMenu,
    writeArticle,
    articleList,
    job,
  },
  created() {
    console.log('首页')
  },
}
</script>
<style lang="less">
.guanchaJobZhichang {
  display: flex;
  justify-content: space-between;
  .midContent {
    width: 720px;
  }
  .rightContent {
    width: 330px;
  }
  .articleRightBot {
    width: 532px !important;
  }
}
</style>