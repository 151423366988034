<template>
  <div class="fabuManage">
    <div class="top betweenFlex commonBorder" v-show="!showFankui">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="64"
        :height="56"
        size="16"
        :distance="44"
      />

      <div class="xiewenzhang commonFlex canClick">创建活动</div>
    </div>
    <ul style="padding: 0 20px" v-show="!showFankui">
      <li
        v-for="item in articleLlist"
        :key="item"
        class="startFlex articleItemFabu commonBorder canClick"
        @mouseover="item.showTwoBtn = true"
        @mouseleave="item.showTwoBtn = false"
      >
        <img :src="require('@as/reading/test.png')" alt="" class="articleIMG" />
        <div style="margin-left: 16px; width: 680px">
          <div class="wenzhangName">{{ item.title }}</div>
          <div class="startFlex allchoiceBtn">
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/region.png')" alt="" />
              <span class="btnCount"> 北京</span>
            </div>
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/rili.png')" alt="" />
              <span class="btnCount"> 20100989</span>
            </div>
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/view.png')" alt="" />
              <span class="btnCount"> 1234</span>
            </div>
          </div>
        </div>
        <div class="onlyFlex wrapRightFankui">
          <div class="onlyFlex" style="margin-bottom: 20px; height: 36px">
            <div
              class="wrapWhiteCircle commonFlex"
              style="margin-right: 26px"
              v-show="item.showTwoBtn"
            >
              <img :src="require('@as/personal/edit.png')" alt="" />
            </div>
            <div class="wrapWhiteCircle commonFlex" v-show="item.showTwoBtn">
              <img :src="require('@as/personal/delete.png')" alt="" />
            </div>
          </div>
          <div class="wrapFankui commonFlex canClick">
            <span class="fankui" @click="showFankui = true">查看反馈</span>
            <span class="fankuiCount">36</span>
          </div>
        </div>
      </li>
      <div class="commonFlex" style="height: 150px">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
    <fankui v-show="showFankui" @showFankuiChild="showFankui = false"></fankui>
  </div>
</template>

<script>
import headerChoice from '../headerChoice'
import fankui from './fabuManageFankui'
export default {
  data() {
    return {
      showFankui: false,
      activeIndex: 0,
      choiceList: ['已发布', '草稿箱', '待审核'],
      articleLlist: [
        { title: '好哈哈哈哈哈哈', showTwoBtn: false },
        { title: '好哈哈哈哈哈哈', showTwoBtn: false },
        { title: '好哈哈哈哈哈哈', showTwoBtn: false },
        { title: '好哈哈哈哈哈哈', showTwoBtn: false },
      ],
    }
  },
  methods: {
    change(index) {
      this.activeIndex = index
    },
  },
  components: {
    headerChoice,
    fankui,
  },
}
</script>

<style lang="less">
.fabuManage {
  .selected {
    border-bottom: 2px solid #0899ca !important;
  }
  .wrapRightFankui {
    flex-direction: column;
  }
  .wrapFankui {
    width: 90px;
    height: 26px;
    border: 1px solid #1c9ab1;
    border-radius: 2px;
    // margin-top: 20px;
    .fankui {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
    }
    .fankuiCount {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      margin-left: 8px;
    }
  }
  .wrapRightTwobtn {
    display: flex;
    justify-content: flex-end;
    width: 355px;
  }
  .wrapWhiteCircle {
    width: 36px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 50%;
  }
  .articleItemFabu {
    height: 132px;
  }
  .articleItemFabu:hover {
    background: linear-gradient(90deg, #ffffff 0%, #ecfaff 50%, #ffffff 100%);
  }
  .articleIMG {
    width: 144px;
    height: 90px;
  }
  .wrapBotBtn {
    margin-right: 30px;
  }
  .btnCount {
    margin-left: 7px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .wenzhangName {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .allchoiceBtn {
    margin-top: 37px;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .count {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .xiewenzhang {
    width: 80px;
    height: 30px;
    background: #4395cd;
    border-radius: 2px;
    margin-left: 30px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .top {
    padding: 0 20px;
  }
}
</style>