<template>
  <div class="shixijingli">
    <div v-for="(item, index) in shixiList" :key="index">
      <div class="shixi-content">
        <div class="startFlex everyline">
          <div class="startFlex leftBasicInfo">
            <span class="detailWordInfo">公司名称</span>
            <span class="requireFlag">*</span>
          </div>
          <input
            type="text"
            class="jingliInput"
            placeholder="如：智联招聘"
            v-model="item.companyName"
          />
        </div>
        <div class="startFlex everyline">
          <div class="startFlex leftBasicInfo">
            <span class="detailWordInfo">所属行业</span>
            <span class="requireFlag">*</span>
          </div>
          <el-select
            v-model="item.industry"
            placeholder="如：互联网/电子商务"
            class="yxiangSelect"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="startFlex everyline">
          <div class="startFlex leftBasicInfo">
            <span class="detailWordInfo">职业名称</span>
            <span class="requireFlag">*</span>
          </div>
          <input
            type="text"
            class="jingliInput"
            placeholder="如：销售经理"
            v-model="item.postName"
          />
        </div>
        <div class="startFlex everyline">
          <div class="startFlex leftBasicInfo">
            <span class="detailWordInfo">薪资要求</span>
            <span class="requireFlag">*</span>
          </div>
          <el-select
            v-model="item.salary"
            placeholder="请选择薪资要求，如：1万-2万"
            class="yxiangSelect"
          >
            <el-option
              v-for="item in salaryList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="startFlex everyline">
          <div class="startFlex leftBasicInfo">
            <span class="detailWordInfo">在职时间</span>
            <span class="requireFlag">*</span>
          </div>
          <div class="betweenFlex" style="width: 719px">
            <el-date-picker
              v-model="item.workStartTime"
              type="date"
              placeholder="入职时间"
              class="ruzhiTime"
              style="width: 322px"
            >
            </el-date-picker>
            <el-date-picker
              v-model="item.workEndTime"
              type="date"
              placeholder="离职时间"
              class="ruzhiTime"
              style="width: 322px"
            >
            </el-date-picker>
            <el-checkbox v-model="item.isToNow" class="zhijin"
              >至今</el-checkbox
            >
          </div>
        </div>
        <div class="startFlex everyline">
          <div class="startFlex leftBasicInfo">
            <span class="detailWordInfo">税前月薪</span>
            <span class="requireFlag">*</span>
          </div>
          <div>
            <input
              type="text"
              class="salaryInput"
              placeholder="元/月"
              v-model="item.salary"
            />
            <span class="zhijin" style="padding-left: 11px">元/月</span>
          </div>
        </div>
        <div class="startFlex everyline">
          <div class="startFlex leftBasicInfo">
            <span class="detailWordInfo">工作描述</span>
            <span class="requireFlag">*</span>
          </div>
          <div>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              class="jobDesp"
              v-model="item.workDesc"
            ></textarea>
          </div>
        </div>
      </div>
      <botChoice
        :isLast="index === shixiList.length - 1"
        @confirm="handlerConfirm"
        @cancel="handlerCancel(index)"
      />
    </div>
  </div>
</template>

<script>
import botChoice from './botChoice'
import { Resume, Job } from '@api'
export default {
  props: ['id'],
  data() {
    return {
      options: [],
      typeList: [],
      salaryList: [],
      shixiList: [
        {
          companyName: '',
          industry: '',
          postName: '',
          salary: '',
          workStartTime: '',
          workEndTime: '',
          isToNow: false,
          salary: '',
          workDesc: '',
        },
      ],
    }
  },
  components: {
    botChoice,
  },
  watch: {
    id: {
      handler(val) {
        if (!val) return
        Resume.getExperience({ userId: this.$store.state.uid }).then((res) => {
          this.shixiList = res
        })
      },
      immediate: true,
    },
  },
  created() {
    Job.getTypeList().then((res) => {
      this.typeList = res
    })
    Job.getSalaryList().then((res) => {
      this.salaryList = res
    })
  },
  methods: {
    handlerConfirm() {
      const data = this.shixiList.map((item) => {
        const obj = Object.assign({}, item)
        obj.workEndTime = item.isToNow ? new Date() : item.workEndTime
        obj.userId = this.$store.state.uid
        if (this.id) obj.id = this.id
        return obj
      })
      for (let i in data) {
        for (let j in data[i]) {
          if (!this.isValid(j, data[i][j])) return
        }
      }
      Resume.changeExperience(data)
    },
    handlerCancel(idx) {
      if (this.shixiList.length > 1) {
        this.shixiList.splice(idx, 1)
      }
    },
    isValid(keys, val) {
      const keysDir = {
        companyName: '公司名称',
        industry: '所属行业',
        postName: '职业名称',
        salary: '薪资要求',
        workStartTime: '入职时间',
        workEndTime: '离职时间',
        salary: '税前月薪',
        workDesc: '工作描述',
      }
      if (keysDir[keys] && !val) {
        this.$message.error(`${keysDir[keys]}不能为空`)
        return false
      }
      return true
    },
    addItem() {
      this.shixiList.push({
        companyName: '',
        industry: '',
        postName: '',
        salary: '',
        workStartTime: '',
        workEndTime: '',
        isToNow: false,
        salary: '',
        workDesc: '',
      })
    },
  },
}
</script>

<style lang="less">
.shixijingli {
  .jobDesp {
    width: 722px;
    height: 84px;
    padding: 20px;
    border: 1px solid #eaebeb;
  }
  .salaryInput {
    width: 322px;
    height: 34px;
    .el-input__prefix {
      top: 3px;
    }
  }
  ::-webkit-input-placeholder {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
  }
  .zhijin {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .ruzhiTime {
    width: 312px;
    height: 34px;
    .el-input__prefix {
      top: -3px;
    }
  }
  input {
    border: 1px solid #eaebeb;
    padding-left: 20px;
  }
  padding-left: 20px;
  padding-bottom: 40px;
  .jingliInput {
    width: 721px;
    height: 34px;
  }
  .cancel {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .saveUpdate {
    width: 94px;
    height: 31px;
    border-radius: 32px;
    background: #1c9ab1;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .leftBasicInfo {
    width: 105px;
  }
  .yxiangSelect {
    width: 720px;
    height: 34px;
  }
  .yxiangSelect::-webkit-input-placeholder {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
  }
  .detailWordInfo {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .everyline {
    margin-top: 27px;
  }
}
</style>