const state = {
  hide: 1,
  headShow: sessionStorage.getItem("headShow") || false,
};
const mutations = {
  change(state, res) {
    state.hide = res.boole;
  },
  headShow(state, res) {
    state.headShow = res.headShow;
    sessionStorage.setItem("headShow", res.headShow);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
};
