<template>
  <div class="fankuiManage">
    <div class="top betweenFlex">
      <div class="commonFlex">
        <div class="wrapLinkImg">
          <div class="whiteLINK commonFlex" @click="showFankui">
            <img :src="require('@as/personal/go-to-link 拷贝.png')" alt="" />
          </div>
        </div>
        <div class="commonFlex topTitle">
          从黑石集团与Alnylam 20亿美元交易案例深度评析药 品特许权组合融资
        </div>
      </div>
      <div class="commonFlex wrapXiazai canClick">
        <img :src="require('@as/personal/download.png')" alt="" />
        <span class="xiazaiWord">导出下载</span>
      </div>
    </div>
    <div style="padding: 0 20px; margin-top: 20px">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; border: 1px solid #dedede"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="姓名" width="80"> </el-table-column>
        <el-table-column prop="company" label="公司" width="240">
        </el-table-column>
        <el-table-column
          width="80"
          prop="job"
          label="职位"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="phone" label="手机" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="email" label="邮箱" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="date" label="提交时间" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div class="commonFlex" style="height: 100px">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          date: '2016-05-03',
          name: '王小虎',
          company: '北京时代创信科技有限公司',
          job: '总经理',
          phone: '13456787656',
          email: '1234185@mail.com',
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          company: '北京时代创信科技有限公司',
          job: '总经理',
          phone: '13456787656',
          email: '1234185@mail.com',
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          company: '北京时代创信科技有限公司',
          job: '总经理',
          phone: '13456787656',
          email: '1234185@mail.com',
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          company: '北京时代创信科技有限公司',
          job: '总经理',
          phone: '13456787656',
          email: '1234185@mail.com',
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          company: '北京时代创信科技有限公司',
          job: '总经理',
          phone: '13456787656',
          email: '1234185@mail.com',
        },
      ],
    }
  },
  methods: {
    showFankui() {
      this.$emit('showFankuiChild')
    },
  },
}
</script>

<style lang="less">
.fankuiManage {
  background: white;
  width: 980px;
  // height: 1210px;
  background: #ffffff;
  border-radius: 4px;
  .el-table__header-wrapper th {
    background: #f5f5f5;
  }
  th.el-table-column--selection {
    padding-left: 4px;
  }
  .top {
    height: 57px;
    padding: 0 20px;
    border-bottom: 1px solid rgba(8, 153, 202, 0.1);
    .wrapXiazai {
      width: 100px;
      height: 30px;
      background: #4395cd;
      border-radius: 2px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
    }
    .xiazaiWord {
      margin-left: 4px;
    }
    .wrapLinkImg {
      width: 64px;
      border-bottom: 2px solid #0899ca;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 57px;
    }
    .topTitle {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
      margin-left: -25px;
    }
    .whiteLINK {
      width: 24px;
      height: 24px;
      background: #f5f5f5;
      border: 1px solid #dedede;
      border-radius: 50%;
    }
  }
}
</style>