<template>
  <div class="mainWenda">
    <ul style="padding: 0 20px">
      <li
        v-for="(item, index) in questionList"
        class="questionItem commonBorder"
        :key="index"
      >
        <div class="betweenFlex" style="margin-bottom: 20px">
          <div class="onlyFlex">
            <img :src="require('@as/personal/参与.png')" alt="" />
            <span class="time">我提问的</span>
          </div>
          <span class="time">2020-2090</span>
        </div>
        <div class="questionTop">
          <div class="countNum commonFlex">
            <span class="huidaCount">{{ item.count }}</span>
            <span class="huidaWord">回答</span>
          </div>
          <div class="questionRight">
            <div class="title">{{ item.title }}</div>
            <div class="wrapDetail betweenFlex">
              <div class="detailLeft betweenFlex">
                <div
                  v-for="(signItem, index) in item.sign"
                  :key="index"
                  class="signItem commonFlex"
                >
                  {{ signItem }}
                </div>
                <div class="wrapTime commonFlex">
                  <img :src="require('@as/reading/heiyan.png')" alt="" />
                  <span class="time">{{ item.time }}</span>
                </div>
              </div>
              <div class="detailright betweenFlex">
                <div class="commonFlex">
                  <img :src="require('@as/reading/laiAnswer.png')" alt="" />
                  <span class="laiAnswer">我来回答</span>
                </div>
                <div class="commonFlex">
                  <img :src="require('@as/reading/guanzhuQues.png')" alt="" />
                  <span class="guanzhuQuestion">关注问题</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="questionBot">
          <div class="">
            <img
              :src="require('@as/reading/test.png')"
              alt=""
              class="answerPeople"
            /><span class="questionBotAns">{{ item.answer }}:</span
            ><span class="questionBotDesp">{{ item.answerDesp }}</span>
          </div>
        </div>
      </li>
      <div class="everyPage">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questionList: [
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复大师傅师傅师傅说的第三方的说法是东方时代',
        },
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复',
        },
        {
          title: '机器学习的发展前景如何？',
          sign: ['谷歌', '探讨'],
          time: '5分钟前',
          count: 23,
          answer: '瞧瞧瞧瞧瞧瞧瞧瞧',
          answerDesp:
            'q瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧反反复反反复反复反复反复',
        },
      ],
    }
  },
}
</script>

<style lang="less">
.mainWenda {
  .detailright {
    cursor: pointer;
  }
  .everyPage {
    text-align: center;
    margin: 30px 0;
  }
  .answerPeople {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
  }
  .time {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
    margin-left: 4px;
  }
  .questionItem {
    padding: 23px 0;
    .questionBot {
      width: 805px;
      //height: 70px;
      margin-top: 21px;

      background: #f5f5f7;
      padding: 15px 24px;
      box-sizing: border-box;
      .questionBotAns {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
      .questionBotDesp {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #808080;
        margin-left: 15px;
        line-height: 24px;
      }
    }
    .questionTop {
      display: flex;
      .questionRight {
        margin-left: 16px;
        .guanzhuImg {
          margin-right: 3px;
        }
        .laiAnswer {
          margin-right: 25px;
          margin-left: 7px;

          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #babbbb;
        }
        .guanzhuQuestion {
          margin-left: 7px;
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #babbbb;
        }
        .huidaImg {
          margin-right: 3px;
        }
        .detailLeft {
          display: flex;
          .signItem {
            margin-right: 8px;
            width: 52px;
            height: 19px;
            background: #e9eced;

            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #999999;
          }
          .wrapTime {
            margin-left: 48px;
            span {
              margin-left: 2px;

              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #afafaf;
            }
          }
        }
        .wrapDetail {
          width: 745px;
          margin-top: 9px;
        }
        .title {
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 500;
          color: #333333;
        }
      }
      .countNum {
        flex-direction: column;
        width: 44px;
        height: 44px;
        background: rgba(28, 154, 177, 0.1);
        .huidaCount {
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #1c99b0;
        }
        .huidaWord {
          margin-top: 2px;

          font-size: 10px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #1c99b0;
        }
      }
    }
  }
}
</style>