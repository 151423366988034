<template>
  <div class="orderManage">
    <div class="top betweenFlex commonBorder">
      <div class="startFlex">
        <div class="wrapLinkImg" v-show="Collection">
          <div class="whiteLINK commonFlex" @click="Collection = false">
            <img :src="require('@as/personal/go-to-link 拷贝.png')" alt="" />
          </div>
        </div>
        <headerChoice
          @changeIndex="change"
          :choice="choiceList"
          :activeIndex="activeIndex"
          :width="64"
          :height="56"
          size="16"
          :distance="44"
        />
      </div>
      <div class="betweenFlex" v-show="!Collection">
        <div class="xiewenzhang commonFlex canClick" @click="Collection = true">
          我的收藏
        </div>
        <div class="xiewenzhang commonFlex canClick" @click="toCart">
          购物车
        </div>
      </div>
    </div>
    <div
      class="startFlex commonBorder"
      style="margin-left: 20px; margin-right: 20px"
      v-show="service == 0 && !Collection"
    >
      <div
        v-for="(item, index) in fukaunState"
        :key="index"
        :class="{ selctedPay: activePay == index }"
        @click="activePay = index"
        class="payStateItem commonFlex"
      >
        {{ item }}
      </div>
    </div>
    <ul style="padding: 0 20px" v-show="service == 0">
      <li
        v-for="item in articleLlist"
        :key="item"
        class="startFlex articleItem commonBorder"
      >
        <div class="left">
          <div class="betweenFlex wrapXiadanTime" v-show="!Collection">
            <div>
              <span class="xiadanTime">下单时间：</span
              ><span class="timeCount">2019-03-12 </span>
            </div>
            <div>
              <span class="xiadanTime">订单号：</span
              ><span class="timeCount">>20190312144556</span>
            </div>
          </div>
          <div class="startFlex">
            <img
              :src="require('@as/reading/test.png')"
              alt=""
              class="orderPersonImg"
            />
            <div class="outerTitle">
              <div class="title">{{ item.title }}</div>
              <div class="desp">{{ item.desp }}</div>
            </div>
          </div>
        </div>
        <div class="mid commonFlex">¥ 580.00</div>
        <div class="right" v-show="!Collection">
          <div class="daifukuan">待付款</div>
          <div class="yizhifu commonFlex">立即付款</div>
          <div class="qitapay">取消订单</div>
        </div>
        <div class="rightCollect betweenFlex" v-show="Collection">
          <div class="instantJoin commonFlex">立即参加</div>
          <div class="quitCollect commonFlex">取消收藏</div>
        </div>
      </li>
      <div class="commonFlex" style="height: 150px">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>

    <div
      class="startFlex commonBorder"
      style="margin-left: 20px; margin-right: 20px"
      v-show="service == 1 && !Collection"
    >
      <div
        v-for="(item, index) in yanxuanState"
        :key="index"
        :class="{ selctedPay: activePay == index }"
        @click="activePay = index"
        class="payStateItem commonFlex"
      >
        {{ item }}
      </div>
    </div>
    <ul style="padding: 0 20px" v-show="service == 1">
      <li
        v-for="item in yanxuanService"
        :key="item"
        class="startFlex articleItem commonBorder yanxuanSpecial"
      >
        <div
          class="startFlex upperITEM"
          :class="{ specialHieght: !Collection }"
        >
          <div class="left">
            <div class="betweenFlex wrapXiadanTime" v-show="!Collection">
              <div>
                <span class="xiadanTime">下单时间：</span
                ><span class="timeCount">2019-03-12 </span>
              </div>
              <div>
                <span class="xiadanTime">订单号：</span
                ><span class="timeCount">>20190312144556</span>
              </div>
            </div>
            <div class="startFlex">
              <img
                :src="require('@as/reading/test.png')"
                alt=""
                class="orderPersonImg"
              />
              <div class="outerTitle">
                <div class="title">{{ item.title }}</div>
                <div class="desp">
                  <div>数量：1</div>
                  <div>快递：包邮</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mid commonFlex">¥ 580.00</div>
          <div class="rightYnxuan betweenFlex" v-show="!Collection">
            <div class="daifukuan yanxuanPay">待付款</div>
            <div class="lookDetail commonFlex">查看详情</div>
          </div>
          <div class="rightCollect betweenFlex" v-show="Collection">
            <div class="instantJoin commonFlex">立即参加</div>
            <div class="quitCollect commonFlex">取消收藏</div>
          </div>
        </div>
        <div class="payDetail onlyFlex" v-show="!Collection">
          <div class="leftDetail">
            <div class="orderDetailWord">订单详情</div>
            <div class="betweenFlex detailLine">
              <span>订单编号</span>
              <span>1234444555</span>
            </div>
            <div class="betweenFlex detailLine">
              <span>时间</span>
              <span>1234444555</span>
            </div>
            <div class="betweenFlex detailLine">
              <span>数量</span>
              <span>1234444555</span>
            </div>
            <div class="betweenFlex detailLine">
              <span>快递</span>
              <span>1234444555</span>
            </div>
            <div class="betweenFlex detailLine">
              <span>收货地址</span>
              <span>1234444555</span>
            </div>
            <div class="betweenFlex detailLine">
              <span>收货人</span>
              <span>事实上</span>
            </div>
            <div class="detailFengeLine"></div>
            <div class="betweenFlex">
              <div></div>
              <div>
                <span class="sifukuan">实付款：</span>
                <span class="payMoney">¥699.00</span>
              </div>
            </div>
          </div>
          <div class="midDetail">
            <div
              style="
                font-size: 14px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #333333;
                margin: 30px;
              "
            >
              订单状态
            </div>
            <div
              style="
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #333333;
                margin-left: 30px;
              "
            >
              订单已提交，等待付款中
            </div>
          </div>
          <div class="rightDetail commonFlex">
            <div class="instantPay commonFlex">立即付款</div>
            <div class="quitOrder">取消订单</div>
          </div>
        </div>
      </li>
      <div class="commonFlex" style="height: 100px">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
  </div>
</template>

<script>
import headerChoice from '../headerChoice'
export default {
  data() {
    return {
      activeIndex: 0,
      activePay: 0,
      service: 0,
      Collection: false,
      choiceList: ['知识服务', '严选服务'],
      fukaunState: ['全部', '待付款', '已支付'],
      yanxuanState: ['全部', '待付款', '代发货', '代收货', '已完成'],
      yanxuanService: [
        { title: '悄悄瞧瞧瞧瞧瞧瞧瞧瞧' },
        { title: '悄悄瞧瞧瞧瞧瞧瞧瞧瞧' },

        { title: '悄悄瞧瞧瞧瞧瞧瞧瞧瞧' },
        { title: '悄悄瞧瞧瞧瞧瞧瞧瞧瞧' },
      ],
      articleLlist: [
        {
          title: '悄悄瞧瞧瞧瞧瞧瞧瞧瞧',
          desp: '是你丝带大赛等级哦啊是梵蒂冈梵蒂冈',
        },
        {
          title: '悄悄瞧瞧瞧瞧瞧瞧瞧瞧',
          desp: '是你丝带大赛等级哦啊是梵蒂冈梵蒂冈',
        },
        {
          title: '悄悄瞧瞧瞧瞧瞧瞧瞧瞧',
          desp: '是你丝带大赛等级哦啊是梵蒂冈梵蒂冈',
        },
        {
          title: '悄悄瞧瞧瞧瞧瞧瞧瞧瞧',
          desp: '是你丝带大赛等级哦啊是梵蒂冈梵蒂冈',
        },
      ],
    }
  },
  methods: {
    change(index) {
      this.activeIndex = index
      this.service = index
    },
    toCart() {
      this.$router.push('/myCart')
    },
  },
  components: {
    headerChoice,
  },
}
</script>

<style lang="less">
.orderManage {
  .qitapay {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
    margin-top: 23px;
    cursor: pointer;
  }
  .daifukuan {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #0899ca;
  }
  .rightCollect {
    padding-left: 46px;
    padding-top: 20px;
    .instantJoin {
      padding: 3px 5px;
      background: #4395cd;
      border-radius: 3px;

      font-size: 10px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .quitCollect {
    width: 64px;
    height: 20px;
    background: #d8e5e7;
    border-radius: 3px;
    margin-left: 20px;
    font-size: 10px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .yanxuanSpecial {
    flex-direction: column;
  }
  .whiteLINK {
    width: 24px;
    height: 24px;
    background: #f5f5f5;
    border: 1px solid #dedede;
    border-radius: 50%;
  }
  .wrapLinkImg {
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 57px;
  }
  .payDetail {
    width: 920px;
    height: 320px;
    background: #f5f6fa;
    border-radius: 1px;
    // padding:20px;
    margin-bottom: 20px;
    .rightDetail {
      flex-direction: column;
      text-align: center;
      width: 190px;
    }
    .instantPay {
      width: 80px;
      height: 30px;
      background: #1c9ab1;
      border-radius: 2px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
    }
    .quitOrder {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
      margin-top: 35px;
    }
    .midDetail {
      width: 440px;
      border-right: 1px solid #dedede;
      background: #f5f6fa;
    }
    .leftDetail {
      background: #e8ebf6;
      padding: 20px;
    }
    .payMoney {
      color: #db0b22;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
    }
    .sifukuan {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
    .detailFengeLine {
      width: 100px;
      height: 1px;
      background: #ffffff;

      margin: 30px 0px;
      margin-left: 150px;
    }
    .detailLine {
      margin-top: 15px;
      width: 250px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #666666;
    }
    .orderDetailWord {
      margin-bottom: 20px;
      height: 14px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
    }
  }
  .rightYnxuan {
    flex-direction: column;
    height: 100%;
  }
  .lookDetail {
    width: 80px;
    margin-bottom: 56px;
    height: 30px;
    border: 1px solid #1c9ab1;
    border-radius: 2px;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
  }
  .yanxuanPay {
    display: flex;
    justify-content: flex-end;
    width: 190px;

    color: #1c9ab1;
  }
  .payStateItem {
    height: 57px;
    margin-right: 40px;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .selctedPay {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #0899ca !important;
  }
  .wrapRightTwobtn {
    display: flex;
    justify-content: flex-end;
    width: 355px;
  }
  .wrapWhiteCircle {
    width: 36px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 50%;
  }
  .articleItem {
    //
    padding: 20px;
    padding-right: 0px;
    // flex-direction: column;
    &:hover {
      background: #f1fdff;
      box-shadow: 2px 2px 6px 0 rgba(000, 000, 000, 0.1);
    }
    .specialHieght {
      height: 158px;
    }
    .orderPersonImg {
      width: 140px;
      height: 90px;
    }
    .title {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
    .right {
      // padding: 10px 0px;
      margin-left: 42px;
      height: 119px;
      text-align: center;
    }
    .daifukan {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #0899ca;
    }
    .yizhifu {
      width: 80px;
      height: 30px;
      background: #0899ca;
      border-radius: 2px;
      margin-top: 15px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
    .outerTitle {
      margin-left: 20px;
      width: 383px;
    }
    .mid {
      margin-left: 50px;
      margin-top: 20px;
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #ca4242;
      width: 139px;
      height: 60px;
      border-right: 1px solid #dedede;
      border-left: 1px solid #dedede;
    }
    .desp {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-top: 17px;
      width: 360px;
    }
    .right {
      flex-direction: column;
    }
    .wrapXiadanTime {
      margin-bottom: 13px;
      .xiadanTime {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
      .timeCount {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .articleIMG {
    width: 144px;
    height: 90px;
  }
  .wrapBotBtn {
    margin-right: 30px;
  }
  .btnCount {
    margin-left: 7px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .wenzhangName {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .allchoiceBtn {
    margin-top: 37px;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .count {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .xiewenzhang {
    width: 80px;
    height: 30px;
    background: #4395cd;
    border-radius: 2px;
    margin-left: 30px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .top {
    padding: 0 20px;
  }
}
</style>