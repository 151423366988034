<template>
  <div class="findPassWwordWrap">
    <div class="midLoginWrap">
      <div class="leftLoginDetail">
        <div class="zhuceWord">
          <span class="zhanghaoLogin">找回密码</span>
        </div>
        <div class="wrapEmail">
          <div
            class="commonFlex item"
            :class="{ select: active === 1 }"
            @click="() => (this.active = 1)"
          >
            邮箱
          </div>
          <div
            class="commonFlex item"
            :class="{ select: active === 2 }"
            @click="() => (this.active = 2)"
          >
            手机号
          </div>
        </div>
        <template v-if="active === 2">
          <div class="wrapInputPhone betweenFlex commonBorder">
            <div class="leftPhone commonFlex">
              <el-select v-model="areaCode">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <input
              type="number"
              placeholder="手机号码"
              class="phoneInput"
              v-model="mobile"
            />
          </div>
          <div class="wrapmsgCode betweenFlex">
            <div class="wrapLeftInputCode commonBorder commonFlex">
              <input
                type="text"
                placeholder="验证码"
                class="msgCode"
                v-model="code"
              />
            </div>
            <div
              class="sendMsgCode commonFlex"
              :class="{ disable: countdown > -1 }"
              @click="handlerGetCode"
            >
              {{ countdown > -1 ? `${countdown}秒` : '发送验证码' }}
            </div>
          </div>
          <div
            class="findPasswordBtn commonFlex"
            style="margin-top: 119px"
            @click="() => (this.active = 0)"
          >
            找回密码
          </div>
        </template>
        <template v-else-if="active === 1">
          <div class="wrapPassword commonBorder" style="margin-top: 40px">
            <input
              type="text"
              placeholder="请输入您注册时填写的邮箱"
              class="password_email"
            />
          </div>
          <div
            class="findPasswordBtn commonFlex"
            style="margin-top: 196px"
            @click="() => (this.active = 0)"
          >
            确认
          </div>
        </template>
        <template v-else>
          <div class="wrapPassword commonBorder">
            <input
              type="password"
              placeholder="请输入密码"
              class="password"
              v-model="password"
            />
          </div>
          <div class="wrapPassword commonBorder">
            <input
              type="password"
              placeholder="重复密码"
              class="password"
              v-model="passwordRe"
            />
          </div>
          <div
            class="findPasswordBtn commonFlex"
            style="margin-top: 137px"
            @click="handlerResetPassword"
          >
            确认
          </div>
        </template>
      </div>
      <rightBk />
    </div>
  </div>
</template>

<script>
import rightBk from '@c/login/rightBk'
import { User, Basic } from '@api/index'

export default {
  data() {
    return {
      active: 2,
      mobile: '',
      options: [
        {
          value: '+86',
          label: '+86',
        },
      ],
      areaCode: '+86',
      code: '',
      countdown: undefined,
      password: '',
      passwordRe: '',
    }
  },
  components: {
    rightBk,
  },
  methods: {
    handlerGetCode() {
      if (!this.mobile) {
        this.$message.error({
          message: '请填写手机号',
        })
        return
      }
      if (this.countdown > -1) return
      Basic.getSmsCode({
        mobile: `${this.mobile}`,
        type: 2,
      }).then(() => {
        this.countdown = 60
        this.setCountdown()
      })
    },
    setCountdown() {
      setTimeout(() => {
        if (this.countdown === 0) this.countdown = undefined
        else {
          this.countdown -= 1
          this.setCountdown()
        }
      }, 1000)
    },
    handlerResetPassword() {
      const { active, code, mobile, password, passwordRe } = this
      if (!mobile) {
        this.$message.error({
          message: '手机号不能为空',
        })
        return
      }
      if (!code) {
        this.$message.error({
          message: '验证码不能为空',
        })
        return
      }
      if (!password) {
        this.$message.error({
          message: '密码不能为空',
        })
        return
      }
      if (password !== passwordRe) {
        this.$message.error({
          message: '两次输入密码不一致',
        })
        return
      }
      Basic.isSmsCodeVaild({ code })
        .then(() =>
          User.resetPassword({ loginBean: { code, mobile, password } })
        )
        .then(() => {
          this.$message.success({
            message: '密码更改成功，即将跳转登录页',
          })
          this.$router.replace('/login')
        })
    },
  },
}
</script>

<style lang="less">
.findPassWwordWrap {
  background: wheat;
  width: 100%;
  height: 100%;
  background: url('~@as/login/loginBg.png') no-repeat;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    border: none;
    outline: none;
  }
  ::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .findPasswordBtn {
    width: 160px;
    height: 48px;
    background: #1c9ab1;
    cursor: pointer;
    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
    margin-top: 105px;
    margin-bottom: 40px;
  }
  .midLoginWrap {
    width: 1150px;
    // height: 760px;
    // margin: 0 auto;
    // padding-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-bottom: 200px;
  }
  .loginTestImg {
    width: 410px;
    height: 560px;
  }
  .forgetPASS {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .wrapOtherlogin {
    height: 100px;
    width: 520px;

    .otherLogin {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
    }
  }
  .leftLoginDetail {
    width: 660px;
    height: 560px;
    background: white;
    padding-left: 70px;
    padding-top: 70px;
    .wrapEmail {
      width: 256px;
      height: 38px;
      background: rgba(0, 0, 0, 0.05);
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin-top: 48px;
      .commonFlex {
        cursor: pointer;
        &:first-child {
          border-right: 1px solid #dedede;
        }
      }
      .item {
        width: 50%;
        height: 30px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #666666;

        &.select {
          color: #1c9ab1;
        }
      }
    }
    .zhuceWord {
      width: 660px;
      .yanzhengCodeLogin {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        text-decoration: underline;
        color: #1c9ab1;
        text-decoration: underline;
      }
      .zhanghaoLogin {
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .huozhe {
        margin: 0 17px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
    }
    .tongyi {
      margin: 0 10px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
    }
    .protocal {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      text-decoration: underline;
    }
    .wrapTozhuce {
      height: 120px;
      width: 520px;
      margin-top: 100px;
      .hasCode {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
      .toLogin {
        color: #1c9ab1;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        cursor: pointer;
      }
      .register {
        width: 160px;
        height: 48px;
        background: #1c9ab1;
        cursor: pointer;
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .wrapLstline {
      display: flex;
      align-items: center;
      height: 79px;
      width: 520px;
    }
    .wrapyanzhengCode,
    .wrapmsgCode,
    .wrapPassword {
      width: 520px;
      height: 59px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      .password_email {
        width: 100%;
      }
    }
    .msgCode {
      width: 100%;
    }
    .wrapLeftInputCode {
      height: 59px;
      width: 360px;
    }
    .sendMsgCode {
      width: 140px;
      height: 46px;
      border: 1px solid #1c9ab1;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;

      &.disable {
        border-color: #999;
        color: #999;
      }
    }
    .wrapInputPhone {
      width: 520px;
      height: 77px;
      margin-top: 20px;
      .phoneInput {
        width: 421px;
      }
      .leftPhone {
        width: 80px;
        border-right: 1px solid #dedede;
        justify-content: flex-start !important;

        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
        & /deep/ .el-input__inner {
          padding-left: 0;
        }
        & /deep/ .el-input__suffix {
          right: 20px;
        }
      }
    }
  }
}
</style>