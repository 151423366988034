import Vue from "vue";
import Vuex from "vuex";
import header from "./modules/header";
import footer from "./modules/footer";
import user from "./modules/user";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    uid: user.state.userInfo.userId,
  },
  modules: {
    header,
    footer,
    user,
  },
});
