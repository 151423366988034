<template>
  <div class="zhuanlanManage">
    <div class="top betweenFlex commonBorder">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="64"
        :height="56"
        size="16"
        :distance="44"
      />
      <div class="commonFlex">
        <img
          :src="require('@as/reading/test.png')"
          alt=""
          style="width: 32px; height: 32px; border-radius: 50%"
        />
        <div style="margin-left: 10px">
          <div class="title">建站达人秀</div>
          <div class="count">19 篇文章 · 1234 订阅者</div>
        </div>
        <div class="xiewenzhang commonFlex canClick">写文章</div>
      </div>
    </div>
    <ul style="padding: 0 20px">
      <li
        v-for="item in articleLlist"
        :key="item"
        class="startFlex articleItemZhuanlan commonBorder canClick"
        @mouseover="item.showTwoBtn = true"
        @mouseleave="item.showTwoBtn = false"
      >
        <img :src="require('@as/reading/test.png')" alt="" class="articleIMG" />
        <div style="margin-left: 16px">
          <div class="wenzhangName">{{ item.title }}</div>
          <div class="startFlex allchoiceBtn">
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/view.png')" alt="" />
              <span class="btnCount"> 1234</span>
            </div>
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/message.png')" alt="" />
              <span class="btnCount"> 1234</span>
            </div>
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/dianzan.png')" alt="" />
              <span class="btnCount"> 1234</span>
            </div>
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/like.png')" alt="" />
              <span class="btnCount"> 1234</span>
            </div>
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/time.png')" alt="" />
              <span class="btnCount"> 1234</span>
            </div>
          </div>
        </div>
        <div class="wrapRightTwobtn" v-show="item.showTwoBtn">
          <div class="wrapWhiteCircle commonFlex" style="margin-right: 26px">
            <img :src="require('@as/personal/edit.png')" alt="" />
          </div>
          <div class="wrapWhiteCircle commonFlex">
            <img :src="require('@as/personal/delete.png')" alt="" />
          </div>
        </div>
      </li>
      <div class="commonFlex" style="height: 150px">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
  </div>
</template>

<script>
import headerChoice from '../headerChoice'
export default {
  data() {
    return {
      activeIndex: 0,
      showTwoBtn: false,
      choiceList: ['已发布', '草稿箱', '待审核'],
      articleLlist: [
        { title: '好哈哈哈哈哈哈', showTwoBtn: false },
        { title: '好哈哈哈哈哈哈', showTwoBtn: false },
        { title: '好哈哈哈哈哈哈', showTwoBtn: false },
        { title: '好哈哈哈哈哈哈', showTwoBtn: false },
      ],
    }
  },
  methods: {
    change(index) {
      this.activeIndex = index
    },
  },
  components: {
    headerChoice,
  },
}
</script>

<style lang="less">
.zhuanlanManage {
  .wrapRightTwobtn {
    display: flex;
    justify-content: flex-end;
    width: 377px;
  }
  .selected {
    border-bottom: 2px solid #0899ca !important;
  }
  .wrapWhiteCircle {
    width: 36px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 50%;
    cursor: pointer;
  }
  .articleItemZhuanlan {
    height: 132px;
  }
  .articleItemZhuanlan:hover {
    background: linear-gradient(90deg, #ffffff 0%, #ecfaff 50%, #ffffff 100%);
  }
  .articleIMG {
    width: 144px;
    height: 90px;
  }
  .wrapBotBtn {
    margin-right: 30px;
  }
  .btnCount {
    margin-left: 7px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .wenzhangName {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .allchoiceBtn {
    margin-top: 37px;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .count {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .xiewenzhang {
    width: 80px;
    height: 30px;
    background: #4395cd;
    border-radius: 2px;
    margin-left: 30px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .top {
    padding: 0 20px;
  }
}
</style>