<template>
  <div class="setPop">
    <el-dialog title="装饰主页" :visible.sync="visible" width="720px">
      <div class="pop_container">
        <div class="title">个人主页封面图</div>
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="textarea"
        >
        </el-input>
        <div class="upload">
          <el-upload
            action=""
            :multiple="false"
            accept=".jpg,.png"
            :file-list="fileList"
            :before-upload="handlerUpload"
            :before-remove="handlerRemove"
          >
            <div class="btn">上传图片</div>
          </el-upload>
          <div class="tips">建议尺寸1920x300，支持JPG/PNG</div>
        </div>
        <div class="background">
          <div class="label">自定义页面背景</div>
          <div class="color">
            <el-input v-model="color" placeholder="#3DAED3"></el-input>
            <div
              class="show"
              :style="`background: ${color || '#3DAED3'};`"
            ></div>
          </div>
        </div>
        <div class="btn_group" slot="footer">
          <div class="btn" @click="changeHomePage">确定</div>
          <div class="btn" @click="() => (this.visible = false)">取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { User, Basic } from '@api'
export default {
  props: ['dialogVisible'],
  data() {
    return {
      textarea: '',
      input: '',
      fileList: [],
      color: '',
    }
  },
  computed: {
    visible: {
      set(val) {
        this.$emit('update:dialogVisible', val)
      },
      get() {
        return this.dialogVisible
      },
    },
  },
  methods: {
    handlerUpload(file) {
      this.fileList = [file]
      return false
    },
    handlerRemove() {
      this.fileList = []
      return true
    },
    async changeHomePage() {
      const data = {
        userId: this.$store.state.uid,
        url: '',
        color: '#0899CA',
      }
      if (this.fileList.length) {
        data.url = await Basic.uploadImg({
          file: this.fileList[0],
        })
      }
      if (this.color) data.color = this.color
      User.changeHomePage(data).then(() => {
        this.$emit('change')
        this.visible = false
      })
    },
  },
}
</script>
<style lang="less" scoped>
.setPop {
  & /deep/ .el-dialog__header {
    padding: 16px 30px;
    border-bottom: 1px solid #f0f0f0;
  }
  & /deep/ .el-dialog__title {
    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 500;
    color: #333333;
  }
  & /deep/ .el-dialog__headerbtn .el-dialog__close {
    font-size: 16px;
    font-weight: bold;
    color: #4f4f4f;
  }
  & /deep/ .el-dialog__body {
    padding: 24px 40px;
  }
  .pop_container {
    .title {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
    & /deep/ .el-textarea {
      margin-top: 14px;
    }
    & /deep/ .el-textarea__inner {
      background: #f5f5f5;
      border: 1px solid #eeeeee;
    }
    .upload {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn {
        padding: 10px 20px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
        border: 1px solid #999999;
        border-radius: 3px;
      }
      .tips {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
    }
    .background {
      margin-top: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .color {
        display: flex;
        align-items: center;
        & /deep/ .el-input {
          z-index: 4;
          width: auto;
        }
        & /deep/ .el-input__inner {
          width: 96px;
          height: 34px;
          background: #f5f9fa;
          border: 1px solid #b3c4c9;
          border-radius: 3px;
        }
        .show {
          margin-left: -4px;
          width: 54px;
          height: 34px;
          border: 1px solid #b3c4c9;
          border-radius: 3px;
        }
      }
    }
    .btn_group {
      margin-top: 47px;
      padding: 22px 0;
      border-top: 1px solid #f0f0f0;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        padding: 12px 42px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        border: 1px solid #cccccc;
        border-radius: 4px;
        &:first-child {
          margin-right: 20px;
          color: #fff;
          background: #0899ca;
          border-color: #0899ca;
        }
      }
    }
  }
}
</style>