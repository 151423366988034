<template>
  <div class="guancha contentWidth">
    <sidebar></sidebar>
    <div class="midContent">
      <img
        :src="require('@as/reading/guanchaMid.png')"
        alt=""
        style="width: 100%"
      />
      <topMenu></topMenu>
      <ul>
        <li
          v-for="(item, index) in articleList"
          :key="index"
          class="articleItem canClick"
          @click="toDetail()"
        >
          <img
            :src="require('@as/reading/listImg.png')"
            alt=""
            style="width: 218px; height: 136px"
          />
          <div class="articleRight">
            <div class="title">{{ item.title }}</div>
            <div class="desp">{{ item.desp }}</div>
            <div class="articleRightBot">
              <div>
                <img
                  :src="require('@as/reading/company.png')"
                  alt=""
                  style="margin-right: 4px; border-radius: 50%"
                />
                <span>{{ item.company }}</span>
                <img
                  :src="require('@as/reading/person.png')"
                  alt=""
                  style="margin-left: 14px; margin-right: 8px"
                />
                <span v-for="authorItem in item.author" :key="authorItem">{{
                  authorItem
                }}</span>
                <div style="margin-left: 16px">
                  <span
                    v-for="signItem in item.sign"
                    :key="signItem"
                    class="signItem"
                    >{{ signItem }}</span
                  >
                </div>
              </div>
              <div>
                <img :src="require('@as/reading/time.png')" alt="" />
                <span style="margin-left: 4px">{{ item.time }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="lookmore">查看更多</div>
    </div>
    <div class="rightContent">
      <writeArticle />
      <hotArticle />
      <zhuantiSquare />
      <hotAuthor />
    </div>
  </div>
</template>

<script>
import sidebar from '@c/reading/sidebar'
import topMenu from '@c/reading/topMenu'
import writeArticle from '@c/reading/writeArticle'
import hotArticle from '@c/reading/hotArticle'
import zhuantiSquare from '@c/reading/zhuantiSquare'
import hotAuthor from '@c/reading/hotAuthor'
export default {
  data() {
    return {
      articleList: [
        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
          articleselected: true,
        },
        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },

        {
          title:
            '从黑石集团与Alnylam 20亿美元交易案例深度评析药品特许权组合融资',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
      ],
    }
  },
  components: {
    sidebar,
    topMenu,
    writeArticle,
    hotArticle,
    zhuantiSquare,
    hotAuthor,
  },
  created() {
    console.log('首页')
  },
  methods: {
    toDetail() {
      this.$router.push({ name: 'contentDetail' })
    },
  },
}
</script>
<style lang="less" scoped>
.guancha {
  display: flex;
  justify-content: space-between;
  .midContent {
    width: 720px;
    .lookmore {
      cursor: pointer;
      width: 718px;
      height: 41px;
      margin-bottom: 37px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 17px;
      background: #ebecec;
    }
    .articleItem {
      // height: 136px;
      padding: 20px 0;
      border-bottom: 1px solid #dedede;
      display: flex;
      justify-content: space-between;
      &:last-child {
        border-bottom: none;
      }
      .articleRight {
        margin-left: 24px;
        .title {
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 500;
          color: #333333;

          line-height: 28px;
        }
        .title:hover {
          color: #1c9ab1 !important;
        }
        .desp {
          font-size: 13px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #a6a7a7;
          margin-top: 17px;
        }
        .articleRightBot {
          width: 504px !important;
          margin-top: 35px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          div {
            display: flex;
            align-items: center;
          }
          span {
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
            margin-right: 5px;
          }
          .signItem {
            padding: 2px 5px;
            background: #e9eced;
            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
            // background: #A6A7A7;
          }
        }
      }
    }
  }
  .rightContent {
    width: 330px;
    margin-bottom: 30px;
  }
}
</style>