<template>
  <div class="zhaopinDetail contentWidth">
    <div class="left">
      <div class="first startFlex">
        <div class="jobDetail">
          <div class="jobTitle">
            反反复复<img
              :src="require('@as/zhaopin/jobTitle.png')"
              alt=""
              style="margin-left: 15px"
            />
          </div>
          <div class="jobRequire">法律行业　|　1-2年　｜　法学博士</div>
          <div class="fabuTime">20小时前</div>
        </div>
        <img :src="require('@as/zhaopin/topFenge.png')" alt="" />
        <div class="companyDetail commonFlex">
          <div class="companyName">轻轻巧巧</div>
          <div class="wrapJobChoice commonFlex">
            <div class="shoucangJob commonFlex">收藏</div>
            <div class="applyJob commonFlex">立即申请</div>
          </div>
        </div>
      </div>
      <div class="second">
        <div class="jobPoint commonBorder">
          <div class="commonFlex wordPoint">职位亮点</div>
        </div>
        <ul class="wrapJobPointItem">
          <li
            v-for="(item, index) in jobPoint"
            :key="index"
            class="jobPointItem"
          >
            {{ item }}
          </li>
        </ul>
        <div class="jobPoint commonBorder" style="margin-top: 10px">
          <div class="commonFlex wordPoint">职位描述</div>
        </div>
        <div class="mianTitle">哈哈哈哈哈哈</div>
        <div class="content">
          1 哈哈哈哈哈哈哈哈哈<br />
          2 rrrrrrr淡淡的<br />
          3去去去去去
        </div>
        <div class="jobPoint commonBorder">
          <div class="commonFlex teamIntroduce">企业/团队介绍</div>
        </div>
        <div class="wrapContent">
          去去去去去去去去去去去去去去去去去去去去去去去
        </div>
        <div class="chakanMore commonFlex canClick">查看更多</div>
      </div>
      <div class="third">
        <div class="jobPoint commonBorder">
          <div class="commonFlex teamIntroduce">推荐相关职位</div>
        </div>
        <ul>
          <li
            v-for="(item, index) in jobList"
            :key="index"
            class="jobItem betweenFlex commonBorder"
          >
            <div class="betweenFlex wrapThreeItem">
              <div class="title">{{ item.title }}</div>
              <div>
                <span class="salary">{{ item.salary }}</span
                ><span class="yue">/月</span>
              </div>
            </div>
            <div class="betweenFlex wrapThreeItem">
              <div class="sameColor">已通过法律认证</div>
              <div class="sameColor">
                {{ item.didian }} <span class="divide">|</span>
                {{ item.xueli }} <span class="divide">|</span>{{ item.year }}
              </div>
            </div>
            <div class="betweenFlex wrapThreeItem">
              <div class="sameColor">{{ item.company }}</div>
              <div class="pubtime commonFlex">
                <img
                  :src="require('@as/reading/time.png')"
                  alt=""
                  style="margin-right: 4px"
                />{{ item.pubtime }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="right">
      <div class="companyDetail commonFlex">
        <img :src="require('@as/reading/test.png')" alt="" class="companyImg" />
        <div class="commonFlex" style="margin-top: 27px">
          某某事务所<img
            :src="require('@as/reading/personFlag1.png')"
            alt=""
            style="margin-left: 5px"
          />
        </div>
        <div class="betweenFlex" style="margin-top: 30px; width: 198px">
          <div class="guanzhu commonFlex">+关注</div>
          <div class="zhuye commonFlex">主页</div>
        </div>
        <div
          class="commonFlex"
          style="margin-top: 50px; align-items: flex-start; flex-wrap: wrap"
        >
          <img
            :src="require('@as/zhaopin/map.png')"
            alt=""
            style="margin-top: 4px"
          />
          <span class="didianTitle"
            >北京市东城区建国门北大街8号华润大厦 20层</span
          >
        </div>
        <div class="commonFlex" style="margin-top: 33px">
          <div class="line"></div>
          <div class="newstJob">最新职位</div>
          <div class="line"></div>
        </div>
        <ul>
          <li
            v-for="(item, index) in bestJob"
            :key="index"
            class="bestJobItem commonFlex"
            :class="{ commonBorder: index != bestJob.length - 1 }"
          >
            <div class="wrapFirstTitle">
              <div class="titleIndex commonFlex">{{ index }}</div>
              <span class="title">{{ item.title }}</span>
            </div>
            <div style="margin-top: 19px">
              <span class="salary">{{ item.salary }}</span>
              <span class="sameColor"
                >{{ item.didian }}/{{ item.xueli }}/{{ item.time }}</span
              >
            </div>
          </li>
          <div class="more commonFlex canClick">更多</div>
        </ul>
      </div>
      <div class="jobInterview">
        <div class="hotArticleTop commonBorder">
          <div class="wraphotArticleWord">
            <img :src="require('@as/zhaopin/fabuPerson.png')" alt="" />
            <span class="hotArticleWord">职位发布者</span>
          </div>
          <span class="moreWord"></span>
        </div>
        <div class="commonFlex wrapFabuInfo">
          <img :src="require('@as/reading/test.png')" alt="" class="fabuImg" />
          <div class="commonFlex" style="margin-top: 23px">
            <div class="lawyerName">李律师</div>
            <img
              :src="require('@as/reading/personFlag1.png')"
              alt=""
              style="margin: 0 6px 0 4px"
            />
            <span class="shenfen">/人力资源专员</span>
            <div class="idRenzheng commonFlex">身份已认证</div>
          </div>
          <div class="shiwusuo">律师事务所</div>
          <div class="instantChat commonFlex canClick">立即交流</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bestJob: [
        {
          title: '高达上律师',
          salary: '12k',
          didian: '北京',
          xueli: '本科',
          time: '3年以上',
        },
        {
          title: '高达上律师',
          salary: '12k',
          didian: '北京',
          xueli: '本科',
          time: '3年以上',
        },
        {
          title: '高达上律师',
          salary: '12k',
          didian: '北京',
          xueli: '本科',
          time: '3年以上',
        },
      ],
      jobPoint: [
        '钱多1',
        '钱多2',
        '钱多3',
        '钱多4',
        '钱多5',
        '钱多6',
        '钱多7',
        '钱多8',
        '钱多9',
        '钱多45',
        '钱多435',
        '钱多345',
        '钱多35',
      ],
      jobList: [
        {
          title: '方法呢你反反复',
          salary: '12k',
          didian: '北京',
          xueli: '说是',
          year: '1-2',
          company: '大公司',
          pubtime: '30分钟前',
        },
        {
          title: '方法呢你反反复',
          salary: '12k',
          didian: '北京',
          xueli: '说是',
          year: '1-2',
          company: '大公司',
          pubtime: '30分钟前',
        },
        {
          title: '方法呢你反反复',
          salary: '12k',
          didian: '北京',
          xueli: '说是',
          year: '1-2',
          company: '大公司',
          pubtime: '30分钟前',
        },
        {
          title: '方法呢你反反复',
          salary: '12k',
          didian: '北京',
          xueli: '说是',
          year: '1-2',
          company: '大公司',
          pubtime: '30分钟前',
        },
      ],
    }
  },
}
</script>

<style lang="less" >
.zhaopinDetail {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .divide {
    margin: 0 10px;
  }
  .pubtime {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #aeaead;
  }
  .bestJobItem:last-child {
    border-bottom: none !important;
  }
  .left {
    width: 850px;
    .first {
      width: 850px;
      height: 168px;
      background: lightblue;
      .companyDetail {
        flex-direction: column;
        margin-left: 46px;
        .wrapJobChoice {
          margin-top: 50px;
        }
        .companyName {
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
        }
        .shoucangJob {
          width: 79px;
          height: 27px;
          border: 1px solid #f0f7f8;
          cursor: pointer;
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
        }
        .applyJob {
          margin-left: 42px;
          width: 78px;
          height: 26px;
          cursor: pointer;
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
          background: #f08d1d;
        }
      }
      .jobDetail {
        padding: 30px;
        width: 560px;
        .jobTitle {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 500;
          color: #ffffff;
        }
        .jobRequire {
          margin-top: 22px;

          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
        }
        .fabuTime {
          margin-top: 50px;

          font-size: 13px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .second {
      width: 850px;
      margin-top: 17px;
      background: white;
      padding: 30px;
      padding-top: 0px;
      .wrapPoint {
        height: 52px;
        color: #1c9ab1;
        border-bottom: 2px solid #1c9ab1;
        width: 70px;
      }
      .mianTitle {
        margin-top: 33px;
        font-size: 14px;
        font-family: PingFangSC-Medium;
        color: #595757;
        margin-left: 14px;
      }
      .content {
        color: #808080;
        margin-top: 13px;
        font-size: 14px;
        font-family: PingFangSC-Regular;
        margin-bottom: 30px;
        margin-left: 14px;
        line-height: 28px;
      }
      .wrapContent {
        padding: 20px;

        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #808080;
        line-height: 28px;
      }
      .chakanMore {
        margin: 0 auto;
        margin-top: 33px;
        margin-bottom: 33px;
        width: 171px;
        height: 41px;
        background: #eaebeb;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      .teamIntroduce {
        height: 52px;
        color: #1c9ab1;
        border-bottom: 2px solid #1c9ab1;
        width: 111px;
      }
      .wordPoint {
        width: 70px;
        height: 47px;
        font-size: 17px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #1c9ab1;
        border-bottom: 2px solid #1c9ab1;
      }
      .wrapJobPointItem {
        display: flex;
        flex-wrap: wrap;
        .jobPointItem {
          padding: 9px 15px;
          background: #ebf1f5;
          margin-right: 17px;
          margin-top: 17px;

          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
        }
      }
    }
    .third {
      width: 850px;
      margin-top: 17px;
      background: white;
      padding: 23px;
      padding-bottom: 0;
      margin-bottom: 50px;
      .jobItem:hover {
        background: #f2fdff;
      }
      .jobItem {
        padding: 23px 54px 28px 26px;
        cursor: pointer;
      }
      .jobItem:last-child {
        border-bottom: none !important;
      }
      .sameColor {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
      }
      .wrapThreeItem {
        flex-direction: column;
        align-items: flex-start !important;
        height: 50px;

        .title {
          font-size: 17px;
          font-family: PingFangSC;
          font-weight: 500;
          color: #585757;
        }
        .salary {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2092a7;
        }
        .yue {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #585757;
          margin-left: 5px;
        }
      }
      .teamIntroduce {
        height: 52px;
        color: #1c9ab1;
        border-bottom: 2px solid #1c9ab1;
        width: 111px;
      }
    }
  }
  .right {
    .jobInterview {
      width: 331px;
      height: 287px;
      background: white;
      padding: 27px;
      padding-top: 10px;
      margin-top: 20px;
      .wrapFabuInfo {
        flex-direction: column;
      }
      .instantChat {
        width: 83px;
        height: 31px;
        background: #ebf1f5;
        margin-top: 20px;

        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      .shiwusuo {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #808080;
        margin-top: 19px;
      }
      .idRenzheng {
        width: 73px;
        height: 22px;
        margin-left: 5px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #c40012;
        border: 1px solid #f8dfe1;
      }
      .fabuImg {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-top: 30px;
      }
      .shenfen {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #808080;
      }
      .lawyerName {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #595757;
      }
      .hotArticleTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        // margin:0 10px;
        .moreWord {
          font-size: 10px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a7a8a8;
        }
        .wraphotArticleWord {
          border-bottom: 3px solid #1c9ab1;
          height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          .hotArticleWord {
            margin-left: 9px;

            font-size: 18px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #595757;
          }
        }
      }
    }
    .companyDetail {
      flex-direction: column;
      width: 331px;
      background: white;
      .more {
        width: 83px;
        height: 30px;
        background: #ebf1f5;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 45px;
      }
      .guanzhu {
        width: 90px;
        height: 34px;
        border: 1px solid #d4ecf0;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }

      .bestJobItem:last-child {
        border-bottom: none;
      }
      .bestJobItem:hover {
        background: #f2fdff;
      }
      .bestJobItem {
        cursor: pointer;
        height: 85px;
        width: 289px;
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: center;
        display: flex;
        .wrapFirstTitle {
          display: flex;
          align-items: center;
        }
        .titleIndex {
          width: 14px;
          height: 14px;
          background: #1c9ab1;
          color: white;

          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
        }
        .salary {
          margin: 0 7px 0 6px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #1c9bb2;
        }
        .sameColor {
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #afafaf;
        }
        .title {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
        }
      }
      .line {
        width: 110px;
        height: 1px;
        background: #dedede;
      }
      .newstJob {
        margin: 0 2px;
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      .didianTitle {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
        margin-left: 13px;
        display: flex;
        flex-wrap: wrap;
        width: 230px;

        line-height: 24px;
      }
      .zhuye {
        width: 90px;
        height: 34px;
        border: 1px solid #d4ecf0;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
      .companyImg {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-top: 33px;
      }
      .title {
        margin-left: 7px;
        font-size: 15px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #595757;
        // margin-top: 23px;
      }
    }
  }
}
</style>