<template>
  <div class="readingContent contentWidth">
    <div class="wrapLeftShare">
      <div class="commonFlex wrapZan canClick" @click="shouzan = !shouzan">
        <img
          :src="require('@as/reading/contentbaizan.png')"
          alt=""
          v-if="!shouzan"
          style="cursor: pointer"
        />
        <img
          :src="require('@as/reading/contentZan.png')"
          alt=""
          v-if="shouzan"
        />
      </div>
      <div class="commonFlex wrapStar canClick" @click="shouCang = !shouCang">
        <img
          :src="require('@as/reading/contentbaishou.png')"
          alt=""
          v-if="!shouCang"
        />
        <img
          :src="require('@as/reading/contentShou.png')"
          alt=""
          v-if="shouCang"
        />
      </div>
      <div class="betweenFlex wrapTwoShare">
        <span class="shareWord">分享</span>
        <div class="line"></div>
        <div @mouseover="shouWeixin = true" @mouseout="shouWeixin = false">
          <img
            :src="require('@as/reading/heiweixin.png')"
            alt=""
            v-if="!shouWeixin"
            class="canClick"
          />
          <img
            :src="require('@as/reading/wechat.png')"
            alt=""
            v-if="shouWeixin"
            style="width: 24px; height: 24px"
            class="canClick"
          />
        </div>
        <div @mouseover="shouWeibo = true" @mouseout="shouWeibo = false">
          <img
            :src="require('@as/reading/heiweibo.png')"
            alt=""
            v-if="!shouWeibo"
            class="canClick"
          />
          <img
            :src="require('@as/reading/weibo.png')"
            alt=""
            v-if="shouWeibo"
            style="width: 24px; height: 24px"
            class="canClick"
          />
        </div>
      </div>
    </div>
    <div class="wrapcommentList">
      <div class="midContentDetail">
        <div class="title">{{ content.title }}</div>
        <div class="ContentDetailBot betweenFlex commonBorder">
          <div class="wrapzuozhe">
            作者:
            <div
              v-for="(item, index) in content.zuozhe"
              :key="index"
              class="zuozheItem"
            >
              {{ item }}
            </div>
            <div class="contentTime">
              <img :src="require('@as/reading/time.png')" alt="" />
              <span style="margin-left: 4px">{{ content.time }}</span>
            </div>
          </div>
          <div class="commonFlex">
            <img
              :src="require('@as/reading/heiyan.png')"
              alt=""
              style="margin-right: 3px"
            />
            {{ content.count }}
          </div>
        </div>
        <div class="wrapLongContent">
          <div class="wrapTitle commonBorder">
            <div class="mainTitle">导读:</div>
            <div class="content">
              实施上述事实上实施上述事实上述事实反反复反反复反反复反复反复反复给哥哥哥哥哥哥哥哥哥哥哥哥哥哥哥哥
            </div>
            <div class="bottomZhu">去瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧瞧</div>
          </div>
          <div class="wrapZanOrWechat commonFlex">
            <div class="wrapZan betweenFlex canClick">
              <img :src="require('@as/reading/dizan.png')" alt="" />
              <span>点赞</span>
              <span>123</span>
            </div>
            <div class="wrapWechat commonFlex canClick">
              <img
                :src="require('@as/reading/shareWechat.png')"
                alt=""
                style="margin-right: 3px"
              />
              <span class="shareToWechat">分享到威信</span>
            </div>
          </div>
          <div class="wrapRelativeSign">
            <div class="wrapLeftSign">
              <span class="relativeSignWord">相关标签</span>
              <div
                v-for="(item, index) in content.relativeSign"
                :key="index"
                class="relativeSignItem commonFlex"
              >
                {{ item }}
              </div>
            </div>
            <div class="jubao canClick">
              <img
                :src="require('@as/reading/jinggao.png')"
                alt=""
                style="margin-right: 3px"
              />举报
            </div>
          </div>
        </div>
      </div>
      <div class="commentList">
        <div class="topCommentCount betweenFlex">
          <div class="wrapCommentNum">
            <span class="commentNum">{{ comment.count }}</span
            ><span class="commentWordCount">条评论</span>
          </div>
          <div class="betweenFlex">
            <img :src="require('@as/reading/contentping.png')" alt="" />
            <span class="laiSaySome">我来说两句</span>
          </div>
        </div>
        <textarea class="fabiaoComment" placeholder="说点什么把"></textarea>
        <div class="rightWrapComment commonBorder">
          <div class="commentWord commonFlex canClick">评论</div>
        </div>
        <ul>
          <li
            v-for="(item, index) in comment.list"
            :key="index"
            class="commentItem commonBorder"
          >
            <img
              :src="require('@as/reading/test.png')"
              alt=""
              style="width: 40px; height: 40px; border-radius: 50%"
            />
            <div class="wrapRightComment">
              <div>
                <span class="commentPerson">{{ item.name }}</span>
                <span class="commentTime">{{ item.time }}</span>
              </div>
              <div class="betweenFlex wrapSecondBot">
                <span class="commentContent">{{ item.content }}</span>
                <div class="huifu commonFlex canClick">
                  <img :src="require('@as/reading/zhuantiMsg.png')" alt="" />
                  <span class="">回复</span>
                </div>
              </div>
            </div>
          </li>
          <div class="jiazaiMore commonFlex canClick">加载更多</div>
        </ul>
      </div>
    </div>
    <div>
      <div class="zhuanlanIntroduce">
        <div class="zhuanlanWord">专栏介绍</div>
        <div class="commonFlex" style="margin-top: 31px">
          <img
            :src="require('@as/reading/test.png')"
            alt=""
            style="width: 70px; height: 70px; border-radius: 50%"
          />
        </div>
        <div class="lvsuoName commonFlex">
          <span>律师事务所</span>
          <img
            :src="require('@as/reading/personFlag1.png')"
            alt=""
            style="margin-left: 7px"
          />
        </div>
        <div class="wrapDingyueBtn commonFlex commonBorder">
          <div class="dingyue commonFlex canClick">订阅</div>
          <div class="zhuanlan commonFlex canClick">专栏</div>
        </div>
        <div class="betweenFlex fourkind">
          <div class="commonFlex">
            <div class="zhuanlanKind">文章</div>
            <div class="num">2</div>
          </div>
          <div class="commonFlex">
            <div class="zhuanlanKind">阅读量</div>
            <div class="num">2</div>
          </div>
          <div class="commonFlex">
            <div class="zhuanlanKind">获赞</div>
            <div class="num">2</div>
          </div>
          <div class="commonFlex">
            <div class="zhuanlanKind">订阅</div>
            <div class="num">2</div>
          </div>
        </div>
      </div>
      <div class="zhuanlanRightBot">
        <div class="zhuanlanWord">活动推荐</div>
        <ul>
          <li
            v-for="(item, index) in recommendList"
            :key="index"
            class="tuijianItem"
          >
            <div class="title">{{ item.title }}</div>
            <div class="betweenFlex" style="margin-top: 14px">
              <div class="desp">{{ item.desp }}</div>
              <div class="choice commonFlex canClick">{{ item.choice }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shouzan: false,
      shouCang: false,
      shouWeixin: false,
      shouWeibo: false,
      recommendList: [
        {
          title: '律师云自媒体分享计划',
          desp: '入驻云社区，共享百万资 源包。',
          choice: '立即入住',
        },
        {
          title: '邀请作者加入平台计划',
          desp: '每月最高可拿1110元无门 代金券槛',
          choice: '了解更多',
        },
      ],
      content: {
        title: '悄悄去悄悄瞧瞧瞧瞧瞧瞧瞧瞧',
        zuozhe: ['全球', '此次'],
        time: '20203030',
        count: '123',
        relativeSign: ['全球', '全球', '全球'],
      },
      comment: {
        count: 8,
        list: [
          { name: '去去去', time: '几天', content: 'zzzz啊啊啊啊啊啊啊' },
          { name: '去去去', time: '几天', content: 'zzzz啊啊啊啊啊啊啊' },
          { name: '去去去', time: '几天', content: 'zzzz啊啊啊啊啊啊啊' },

          { name: '去去去', time: '几天', content: 'zzzz啊啊啊啊啊啊啊' },
        ],
      },
    }
  },
}
</script>

<style lang="less">
.readingContent {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  .zhuanlanRightBot {
    margin-top: 21px;
    padding: 25px 25px 40px 25px;
    width: 330px;
    background: white;
    border: 1px solid #e5e8ed;

    box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
    opacity: 0.98;
    .tuijianItem {
      margin-top: 39px;
      .title {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      .desp {
        width: 144px;

        line-height: 18px;
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
      .choice {
        width: 91px;
        height: 31px;

        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
        border: 1px solid #1c9ab1;
      }
    }
  }
  .zhuanlanIntroduce {
    padding: 25px;
    width: 330px;
    height: 370px;
    background: #ffffff;
    border: 1px solid #e5e8ed;
    box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
  }
  .zhuanlanWord {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .lvsuoName {
    margin-top: 27px;
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .fourkind {
    width: 100%;
    padding: 0 15px;
    padding-top: 22px;
    div {
      flex-direction: column;
    }
  }
  .wrapDingyueBtn {
    margin-top: 37px;
    padding-bottom: 24px;
    .dingyue {
      width: 88px;
      height: 32px;
      background: #1c9ab1;
      font-size: 15px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #ffffff;
      justify-content: center;
    }
    .zhuanlan {
      width: 88px;
      height: 32px;
      border: 1px solid #1c9ab1;
      margin-left: 8px;

      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #1c9ab1;
    }
  }
  .zhuanlanKind {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #bbbcbc;
  }
  .num {
    margin-top: 10px;

    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #bbbcbc;
  }
  .commentList {
    margin-top: 16px;
    margin-bottom: 57px;
    padding: 30px;
    background: white;
    width: 780px;
    background: #ffffff;
    border: 1px solid #e5e8ed;
    box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
    opacity: 0.98;
    border-radius: 4px;
    .commentItem {
      padding: 20px 0;
      display: flex;
      align-items: flex-start;
      .wrapRightComment {
        margin-left: 20px;
        .wrapSecondBot {
          margin-top: 10px;
          width: 657px;
        }
        .commentPerson {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #333333;
        }
        .commentTime {
          margin-left: 19px;

          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #999999;
        }
        .commentContent {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #666666;
        }
        .huifu {
          span {
            margin-left: 3px;

            font-size: 12px;
            font-family: SourceHanSansCN;
            font-weight: 400;
            color: #9c9c9c;
          }
        }
      }
    }
    .laiSaySome {
      margin-left: 9px;

      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
    }
    .topCommentCount {
      padding-bottom: 24px;
      align-items: flex-start !important;

      .wrapCommentNum {
        .commentWordCount {
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 400;
          color: black;
          margin-left: 10px;
        }
        .commentNum {
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #1c9bb2;
        }
      }
    }
    .fabiaoComment {
      padding: 10px;
      resize: none;
      width: 720px;
      height: 60px;
      background: #f7f7f7;
      border: 1px solid #ededed;
      border-radius: 4px;
    }
    .fabiaoComment::placeholder {
      font-size: 14px;
      font-family: SourceHanSansCN;
      font-weight: 400;
      color: #666666;
    }
    .rightWrapComment {
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .commentWord {
        width: 80px;
        height: 32px;
        background: #089acb;
        border-radius: 4px;

        font-size: 14px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .jiazaiMore {
      width: 726px;
      height: 30px;
      background: rgba(28, 154, 177, 0.1);
      margin-top: 20px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
  }
  .midContentDetail {
    padding: 40px 30px 20px 30px;
    background: white;
    border: 1px solid #e5e8ed;
    box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
    opacity: 0.98;
    .wrapTitle {
      padding-bottom: 28px;
    }
    .mainTitle {
      margin-top: 38px;

      font-size: 17px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #595757;
    }
    .content {
      margin-top: 20px;

      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
    }
    .bottomZhu {
      margin-top: 31px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
    }
    .relativeSignWord {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #babbbb;
    }
    .wrapRelativeSign {
      display: flex;
      flex-wrap: wrap;
      margin-top: 34px;
      justify-content: space-between;
      align-items: center;
      .jubao {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #bbbcbc;
      }
      .wrapLeftSign {
        display: flex;
        align-items: center;
      }
      .relativeSignItem {
        margin-left: 16px;
        width: 64px;
        height: 30px;
        border: 1px solid #cccccc;
        border-radius: 2px;

        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
    }
    .wrapZanOrWechat {
      width: 100%;
      margin-top: 50px;
      .wrapZan {
        padding: 0 49px;
        width: 171px;
        height: 41px;
        background: #1c9bb2;
        border-radius: 25px;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
      }
      .wrapWechat {
        width: 170px;
        margin-left: 33px;
        background: #ebecec;
        border-radius: 25px;
        width: 170px;
        height: 41px;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
        .shareWechat {
          margin-left: 2px;
        }
      }
    }
    .title {
      font-size: 29px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
    }
    .ContentDetailBot {
      padding-bottom: 28px;
      margin-top: 60px;
      .wrapzuozhe {
        display: flex;
      }
      div {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #babbbb;
      }
      .zuozheItem {
        margin-left: 5px;
      }
      .contentTime {
        margin-left: 40px;
        display: flex;
        align-items: center;
        margin-left: 32px;
      }
    }
  }
  .wrapcommentList {
    width: 782px;
    // background: #FFFFFF;
  }
  .wrapLeftShare {
    // width: 66px;
    .wrapZan {
      margin-top: 80px;
      width: 43px;
      height: 43px;
      box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
      opacity: 0.98;
      background: white;
      border-radius: 50%;
    }
    .wrapStar {
      margin-top: 18px;
      width: 43px;
      height: 43px;
      box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
      opacity: 0.98;
      background: white;
      border-radius: 50%;
    }
    .wrapTwoShare {
      margin-top: 21px;
      flex-direction: column;
      padding: 23px 0;
      width: 42px;
      height: 150px;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
      opacity: 0.98;
      border-radius: 21px;
      .line {
        width: 24px;
        height: 1px;
        background: #dedede;
      }
      .shareWord {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
</style>