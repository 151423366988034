<template>
  <div class="zhaopinWrap contentWidth">
    <div class="wrapTopImg commonFlex">
      <div class="topImg"></div>
      <div class="huilvTitle">汇律招·惠律人</div>
      <div class="zhaopinLook canClick">查看更多 ></div>
      <div class="wrapBotJob commonFlex">
        <div class="betweenFlex choiceWidth">
          <div class="commonFlex danweiItem selectedjob">职位</div>
          <div
            class="commonFlex danweiItem"
            style="cursor: pointer"
            @click="toJob()"
          >
            单位
          </div>
        </div>
      </div>
    </div>
    <div class="wrapDestination">
      <div class="wrapDIdianLine">
        <div class="jobDest">工作地点:</div>
        <div
          v-for="(item, index) in workAddressCode"
          :key="index"
          :class="{ selectedXingzhi: searchData.workAddressCode === item }"
          class="destinationitem"
          @click="handlerSelectAddress(item)"
        >
          {{ item }}
        </div>
        <div class="more">
          <div>更多</div>
          <img
            :src="require('@as/zhaopin/jiagengduo.png')"
            alt=""
            style="margin-left: 7px"
            class="canClick"
          />
        </div>
      </div>
      <div class="wrapDIdianLine secondLine">
        <div class="jobDest">工作性质:</div>
        <div
          v-for="(item, index) in orgNature"
          :key="index"
          :class="{ selectedXingzhi: searchData.orgNature === item }"
          class="destinationitem"
          @click="handlerSelectNature(item)"
        >
          {{ item }}
        </div>
        <div class="more">
          <div>更多</div>
          <img
            :src="require('@as/zhaopin/jiagengduo.png')"
            alt=""
            style="margin-left: 7px"
            class="canClick"
          />
        </div>
      </div>
      <div class="wrapDIdianLine secondLine">
        <div style="width: 86px">更多筛选:</div>
        <div class="select_container">
          <el-select v-model="value" placeholder="职位类别">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-image
            class="select_icon"
            :src="require('@as/zhaopin/icon_2.png')"
          />
        </div>
        <div class="select_container">
          <el-select v-model="value" placeholder="行业领域">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-image
            class="select_icon"
            :src="require('@as/zhaopin/icon_2.png')"
          />
        </div>
        <div class="select_container">
          <el-select v-model="value" placeholder="工作经验">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-image
            class="select_icon"
            :src="require('@as/zhaopin/icon_2.png')"
          />
        </div>
        <div class="select_container">
          <el-select v-model="value" placeholder="工作性质">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-image
            class="select_icon"
            :src="require('@as/zhaopin/icon_2.png')"
          />
        </div>
        <div class="select_container">
          <el-select v-model="value" placeholder="发布时间">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-image
            class="select_icon"
            :src="require('@as/zhaopin/icon_2.png')"
          />
        </div>
      </div>
    </div>
    <div class="wrapZhaopinBetweenn" style="margin-top: 20px">
      <ul class="wrapJobList">
        <li
          v-for="(item, index) in list"
          :key="index"
          class="jobListItem"
          @click="toDetail(item.id)"
        >
          <div class="jobItemLeft">
            <div class="title">{{ item.postName }}</div>
            <div class="wrapSecond">
              <span class="count">{{ item.monthSalary }}</span>
              <span> /月</span>

              |<span>{{ item.resumeAddrCode }}</span
              >|<span>{{ item.resumeAddrCode }}</span
              >|<span>{{ getWordYear(item.workYear) }}</span>
            </div>
            <div class="wrapThree">
              <img :src="require('@as/reading/time.png')" alt="" />
              <span style="margin: 0 5px 0 3px">{{ item.monthSalary }}</span>
              <span>|</span>
              <img
                :src="require('@as/zhaopin/baishou.png')"
                alt=""
                style="margin: 0 3px 0 5px"
              />
              <span>收藏</span>
            </div>
          </div>
          <div class="jobItemRight">
            <div class="wrapTop">
              <img
                :src="item.orgPhotoUrl"
                alt=""
                style="width: 40px; height: 40px; border-radius: 50%"
              />
              <div style="margin-left: 10px">
                <div style="display: flex; align-items: center">
                  <span class="company">{{ item.companyName }}</span>
                  <img
                    :src="getVerify(item.isVerify)"
                    alt=""
                    style="margin-left: 5px"
                  />
                </div>

                <div class="desp">{{ item.companyName }}</div>
              </div>
            </div>
            <div class="wrapbot">
              <div
                v-for="itemSign in item.remark"
                :key="itemSign"
                class="signItem commonFlex"
              >
                {{ itemSign }}
              </div>
            </div>
          </div>
        </li>
        <div class="wrapTotalPage">
          <el-pagination
            background
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            :total="total"
            @current-change="handlerChangePage"
          >
          </el-pagination>
        </div>
      </ul>
      <div class="right">
        <tixiReading />
        <hotzhaopin />
      </div>
    </div>
  </div>
</template>

<script>
import tixiReading from '@c/zhaopin/tixiReading'
import hotzhaopin from '@c/zhaopin/hotzhaopin'
import { Job } from '@api'
export default {
  data() {
    return {
      list: [],
      value: '',
      options: [],
      workAddressCode: [
        '全国',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
        '程度',
      ],
      orgNature: [
        '不限',
        '销售',
        '销售',
        '销售',
        '销售',
        '销售',
        '销售',
        '销售',
      ],
      searchData: {
        pageNum: 1,
        pageSize: 10,
        workAddressCode: '全国',
        orgNature: '不限',
      },
      total: 0,
    }
  },
  components: {
    tixiReading,
    hotzhaopin,
  },
  created() {
    this.getPost()
  },
  methods: {
    toDetail(id) {
      this.$router.push({
        name: 'zhaopinDetail',
        query: {
          id,
        },
      })
    },
    toJob() {
      this.$router.push({ name: 'zhaopinDepart' })
    },
    getCompany() {
      Job.getAllCompany({
        pageNum: this.page,
        pageSize: 24,
      }).then((res) => {
        this.list = this.list.concat(res.list)
        this.page += 1
      })
    },
    getPost() {
      Job.getAllPost(this.searchData).then((res) => {
        this.list = res.list
        this.total = res.total
        this.page += 1
      })
    },
    handlerSelectAddress(address) {
      this.searchData.workAddressCode = address
      this.searchData.page = 1
      this.getPost()
    },
    handlerSelectNature(nature) {
      this.searchData.orgNature = nature
      this.searchData.page = 1
      this.getPost()
    },
    handlerChangePage(page) {
      this.searchData.page = page
      this.getPost()
    },
    getWordYear(year) {
      const yearDir = {
        1: '不限',
        2: '在读',
        3: '应届',
        4: '1-3年',
        5: '3-5年',
        6: '10年以上',
      }
      return yearDir[year]
    },
    getVerify(verify) {
      const verifyDir = {
        1: require('@as/reading/personFlag.png'),
        2: require('@as/reading/personFlag1.png'),
        3: '',
      }
      return verifyDir[verify]
    },
  },
}
</script>

<style lang="less">
.zhaopinWrap {
  & /deep/ .el-pager li {
    width: 30px;
    height: 30px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    border: 1px solid #dedede;
    box-sizing: border-box;
  }
  & /deep/ .el-pagination {
    display: flex;
    justify-content: center;
  }
  & /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #1c9ab1;
  }
  & /deep/ .el-pagination .btn-next,
  .el-pagination .btn-prev {
    padding: 0 10px;
    line-height: 28px;
    border: 1px solid #dedede;
  }
  & /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #1c9ab1;
  }
  .jobDest {
    margin-right: 26px;
  }
  .el-input__inner {
    background: #f5f7f8;
    // width: 200px;
    height: 36px;
    border-radius: 0 !important;
  }
  .danweiItem {
    cursor: pointer;
  }
  .selectedjob {
    border-bottom: 3px solid #1c9ab1;
    color: #1c9ab1 !important;
  }
  .wrapZhaopinBetweenn {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    .right {
      margin-bottom: 20px;
    }
  }
  .wrapTotalPage {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 850px;
    text-align: center;
    & /deep/ .el-pager {
      display: flex;
    }
  }
  .selectedXingzhi {
    border: 1px solid #c3e4eb !important;
    padding: 2px 7px;
    color: #1c9ab1 !important;
  }
  .wrapJobList {
    display: flex;
    flex-wrap: wrap;
    width: 850px;
  }
  .jiazaiMore {
    width: 1200px;
    height: 50px;
    background: #ffffff;

    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .jobListItem {
    width: 850px;
    height: 131px;
    background: white;
    padding: 16px 27px;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    border: 1px solid #dedede;
    &:hover {
      box-shadow: 1px 1px 4px 0 rgba(000, 000, 000, 0.2);
    }
    .jobItemRight {
      padding-left: 35px;
      .wrapTop {
        display: flex;
        align-items: center;
        .company {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 500;
          color: #595757;
        }
        .desp {
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #afafaf;
          margin-top: 7px;
        }
      }
      .wrapbot {
        margin-top: 30px;
        display: flex;
        align-items: center;
        .signItem {
          margin-right: 10px;
          width: 63px;
          background: #9cd1d3;
          height: 19px;
          line-height: 19px;
          font-size: 10px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
          border-radius: 3px;
        }
      }
    }
    .jobItemLeft {
      width: 496px;
      border-right: 1px solid #dedede;
      .wrapThree {
        margin-top: 35px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        display: flex;
        align-items: center;
      }
      .wrapSecond {
        margin-top: 24px;
        span {
          margin: 0 3px;

          &:not(:first-child) {
            margin: 0 6px;
          }
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
        }
        .count {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1c9bb2;
        }
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      .title {
        font-size: 17px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #595757;
      }
    }
    .wrapCount {
      margin-top: 17px;
      color: #afafaf;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      .count {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
    }
    .jobItemBot {
      margin-top: 17px;
      .mianyi {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
        margin-left: 15px;
      }
      .rezhao {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #666666;
      }
      .jobTitle {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        margin-left: 15px;
      }
    }
    .jobItemTop {
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      .name {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        margin-left: 8px;
      }
    }
  }
  .wrapTopImg {
    position: relative;
    color: white;
    flex-direction: column;
    width: 100%;
    height: 300px;
    .topImg {
      width: 100%;
      height: 300px;
      position: absolute;
      background: url('~@as/zhaopin/zhaopinMain.png');
      top: 0;
      left: 0;
    }
    .huilvTitle {
      font-size: 24px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #ffffff;
      z-index: 200;
    }
    .zhaopinLook {
      margin-top: 14px;

      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      z-index: 200;
    }
    .wrapBotJob {
      position: absolute;
      background: rgba(255, 255, 255, 0.5);
      bottom: 0;
      width: 100%;
      .choiceWidth {
        width: 150px;
        .whiteSlected {
          border-bottom: 2px solid white;
        }
        div {
          width: 64px;
          height: 100%;

          height: 50px;
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 400;
          color: black;
        }
      }
    }
  }
  .wrapDestination {
    width: 1200px;
    height: 220px;
    background: white;
    margin-top: 25px;
    padding: 39px 24px 46px 0px;
    border: 1px solid #dedede;
    .secondLine {
      margin-top: 40px;
      .select_container {
        position: relative;
        .select_icon {
          position: absolute;
          top: 56%;
          right: 12px;
          transform: translateY(-50%);
        }
      }
      & /deep/ .el-input__inner {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
        border: none;
        background: #f5f7f8;
      }
      & /deep/ .el-input__suffix-inner {
        display: none;
      }
    }
    .wrapDIdianLine {
      display: flex;
      align-items: center;
      padding-left: 37px;
      .destinationitem {
        margin-right: 37px;
        padding: 2px 7px;
        min-width: 44px;
        border: 1px solid transparent;

        box-sizing: border-box;

        &:hover {
          color: #1c9ab1;
          cursor: pointer;
        }
      }
      .el-select {
        margin-left: 7px;

        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      div {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
    }
  }
  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      div {
        color: #1c9ab1 !important;
      }
      cursor: pointer;
    }
  }
}
</style>
