<template>
  <div class="zhuantiSquareReading">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <img :src="require('@as/reading/zhuanti.png')" alt="" />
        <span class="hotArticleWord">专题广场</span>
      </div>
      <span class="moreWord canClick">MORE</span>
    </div>
    <ul>
      <li
        v-for="(item, index) in list"
        :key="index"
        class="hotArticleItem commonBorder canClick"
      >
        <img
          :src="require('@as/reading/zhuanti_banner.png')"
          alt=""
          style="width: 280px; height: 90px; display: block"
        />

        <div class="hotArticleItemTitle">{{ item.title }}</div>
        <div class="betweenFlex zhuantiBot" style="margin-top: 15px">
          <div>
            <span>文章数：</span>
            <span>{{ item.articleCount }}</span>
          </div>
          <div>
            <span>{{ item.readCount }}</span>
            <span>人阅读</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dayRank: true,
      monthRank: false,
      weekRank: false,
      list: [
        {
          title: '大健康投融资及合规发展论',
          articleCount: 123,
          readCount: 3434,
        },
        {
          title: '大健康投融资及合规发展论',
          articleCount: 123,
          readCount: 3434,
        },
        {
          title: '大健康投融资及合规发展论',
          articleCount: 123,
          readCount: 3434,
        },
      ],
    }
  },
  methods: {},
}
</script>
<style lang="less">
.zhuantiSquareReading {
  padding: 25px;
  width: 330px;
  background: white;
  padding-top: 11px;
  margin-top: 20px;
  padding-bottom: 0;
  .hotArticleItem:hover {
    background: linear-gradient(90deg, #ffffff 0%, #ecfaff 50%, #ffffff 100%);
  }
  .hotArticleItem {
    cursor: pointer;
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    line-height: 18px;
    padding: 18px 0;
    width: 280px;

    .zhuantiBot {
      span {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #babbbb;
      }
    }
    &:last-child {
      border-bottom: none !important;
    }
    .hotArticleItemTitle {
      font-size: 15px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
      margin-top: 15px;
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 280px;
    .moreWord {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a7a8a8;
    }
    .wraphotArticleWord {
      border-bottom: 3px solid #1c9ab1;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .hotArticleWord {
        margin-left: 4px;

        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
    }
  }
}
</style>