<template>
  <div class="levelBook">
    <div v-for="(item, index) in bookList" :key="index">
      <div class="betweenFlex firstLine">
        <div class="name">证书名称</div>
        <input
          type="text"
          class="projectName secondInput"
          v-model="item.certName"
        />
      </div>
      <div class="betweenFlex firstLine">
        <div class="name">获得时间</div>
        <el-date-picker
          v-model="item.getTime"
          type="month"
          placeholder="选择月份"
          class="secondInput"
        >
        </el-date-picker>
      </div>
      <div class="firstLine botTwoChoice">
        <botChoice
          :isLast="index === bookList.length - 1"
          @confirm="handlerConfirm"
          @cancel="handlerCancel(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import botChoice from './botChoice'
import { Resume } from '@api'
export default {
  props: ['id'],
  data() {
    return {
      bookList: [
        {
          certName: '',
          getTime: '',
        },
      ],
    }
  },
  components: {
    botChoice,
  },
  watch: {
    id: {
      handler(val) {
        if (!val) return
        Resume.getEdu({ userId: this.$store.state.uid }).then((res) => {
          this.bookList = res
        })
      },
      immediate: true,
    },
  },
  methods: {
    handlerConfirm() {
      const data = this.bookList.map((item) => {
        const obj = Object.assign({}, item)
        obj.userId = this.$store.state.uid
        if (this.id) obj.id = this.id
        return obj
      })
      for (let i in data) {
        for (let j in data[i]) {
          if (!this.isValid(j, data[i][j])) return
        }
      }
      Resume.changeCert(data)
    },
    handlerCancel(idx) {
      if (this.bookList.length > 1) {
        this.bookList.splice(idx, 1)
      }
    },
    isValid(keys, val) {
      const keysDir = {
        certName: '证书名称',
        getTime: '获得时间',
      }
      if (keysDir[keys] && !val) {
        this.$message.error(`${keysDir[keys]}不能为空`)
        return false
      }
      return true
    },
    addItem() {
      this.bookList.push({
        certName: '',
        getTime: '',
      })
    },
  },
}
</script>

<style lang="less">
.levelBook {
  padding-left: 20px;
  padding-bottom: 40px;
  .botTwoChoice {
    padding-left: 140px;
    margin-bottom: 30px;
  }
  .firstLine {
    margin-top: 25px;
  }
  .secondInput {
    width: 722px !important;
    height: 34px;
    border: 1px solid #eeeeee;

    .el-input__prefix {
      top: -3px;
    }
  }
  .name {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
}
</style>
