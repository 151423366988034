<template>
  <div class="canyuManage">
    <div class="top betweenFlex commonBorder" v-show="!showFankui">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="64"
        :height="56"
        size="16"
        :distance="44"
      />

      <!-- <div class="xiewenzhang commonFlex">创建活动</div> -->
    </div>
    <ul style="padding: 0 20px" v-show="!showFankui && activeIndex == 0">
      <li
        v-for="item in articleLlist"
        :key="item"
        class="startFlex articleItem commonBorder canClick"
      >
        <img :src="require('@as/reading/test.png')" alt="" class="articleIMG" />
        <div style="margin-left: 16px; width: 680px">
          <div class="wenzhangName">{{ item }}</div>
          <div class="startFlex allchoiceBtn">
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/region.png')" alt="" />
              <span class="btnCount"> 北京</span>
            </div>
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/rili.png')" alt="" />
              <span class="btnCount"> 20100989</span>
            </div>
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/view.png')" alt="" />
              <span class="btnCount"> 1234</span>
            </div>
          </div>
        </div>
        <div class="commonFlex">
          <div class="baoming commonFlex">报名中</div>
          <div class="jieshu commonFlex" v-show="jieshu">已结束</div>
          <div class="kaishi commonFlex" v-show="jieshu">即将开始</div>
        </div>
      </li>
      <div class="commonFlex" style="height: 150px">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
    <ul style="padding: 0 20px" v-show="!showFankui && activeIndex == 1">
      <li
        v-for="(item, index) in articleLlist"
        :key="index"
        class="startFlex articleItem commonBorder"
        @mouseover="selectedHidew(index)"
        :class="{ hasBack: index == hignLightIndex }"
      >
        <img :src="require('@as/reading/test.png')" alt="" class="articleIMG" />
        <div style="margin-left: 16px; width: 680px">
          <div class="wenzhangName">{{ item }}</div>
          <div class="startFlex allchoiceBtn">
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/region.png')" alt="" />
              <span class="btnCount"> 北京</span>
            </div>
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/rili.png')" alt="" />
              <span class="btnCount"> 20100989</span>
            </div>
            <div class="startFlex wrapBotBtn">
              <img :src="require('@as/personal/view.png')" alt="" />
              <span class="btnCount"> 1234</span>
            </div>
          </div>
        </div>
        <div class="commonFlex">
          <div
            class="quitShou commonFlex canClick"
            v-show="index == hignLightIndex"
          >
            取消收藏
          </div>
        </div>
      </li>
      <div class="commonFlex" style="height: 150px">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
  </div>
</template>

<script>
import headerChoice from '../headerChoice'
import fankui from './fabuManageFankui'
export default {
  data() {
    return {
      hignLightIndex: 999,
      showhide: false,
      showFankui: false,
      activeIndex: 0,
      choiceList: ['我参与的', '我收藏的'],
      articleLlist: [
        '好哈哈哈哈哈哈',
        '好哈哈哈哈哈哈',
        '好哈哈哈哈哈哈',
        '好哈哈哈哈哈哈',
      ],
    }
  },
  methods: {
    change(index) {
      this.activeIndex = index
    },
    selectedHidew(index) {
      this.hignLightIndex = index
      this.showhide = true
    },
  },
  components: {
    headerChoice,
    fankui,
  },
}
</script>

<style lang="less">
.canyuManage {
  .hasBack {
    background: linear-gradient(90deg, #ffffff 0%, #ecfaff 50%, #ffffff 100%);
  }
  .quitShou {
    width: 71px;
    height: 30px;
    background: #ecfaff;
    border: 1px solid #089acb;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
  }
  .baoming {
    width: 90px;
    height: 26px;
    background: #f08d1d;
    border-radius: 2px;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .jieshu {
    width: 90px;
    height: 26px;
    background: #939494;
    border-radius: 2px;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .kaishi {
    width: 90px;
    height: 26px;
    background: #1c9ab1;
    border-radius: 2px;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .wrapRightFankui {
    flex-direction: column;
  }
  .wrapFankui {
    width: 90px;
    height: 26px;
    border: 1px solid #1c9ab1;
    border-radius: 2px;
    margin-top: 20px;
    .fankui {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
    }
    .fankuiCount {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      margin-left: 8px;
    }
  }
  .wrapRightTwobtn {
    display: flex;
    justify-content: flex-end;
    width: 355px;
  }
  .wrapWhiteCircle {
    width: 36px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 50%;
  }
  .articleItem {
    height: 132px;
  }
  .articleItem:hover {
    background: linear-gradient(90deg, #ffffff 0%, #ecfaff 50%, #ffffff 100%);
  }
  .articleIMG {
    width: 144px;
    height: 90px;
  }
  .wrapBotBtn {
    margin-right: 30px;
  }
  .btnCount {
    margin-left: 7px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .wenzhangName {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .allchoiceBtn {
    margin-top: 37px;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .count {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .xiewenzhang {
    width: 80px;
    height: 30px;
    background: #4395cd;
    border-radius: 2px;
    margin-left: 30px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .top {
    padding: 0 20px;
  }
}
</style>