import req from "@/utils/request";
import { Message } from "element-ui";

const typeList = [
  {
    value: "律师类",
    children: [
      {
        value: "合伙人",
      },
      {
        value: "提成律师",
      },
      {
        value: "授薪律师",
      },
      {
        value: "诉讼律师",
      },
      {
        value: "知识产权律师",
      },
      {
        value: "专利律师",
      },
      {
        value: "专利代理人",
      },
      {
        value: "律师助理",
      },
      {
        value: "实习生",
      },
    ],
  },
  {
    value: "法务类",
    children: [
      {
        value: "法务总监",
      },
      {
        value: "法律经理",
      },
      {
        value: "法务专员",
      },
      {
        value: "法务助理",
      },
      {
        value: "合规总监",
      },
      {
        value: "合规经理",
      },
      {
        value: "合规专员",
      },
      {
        value: "合规助理",
      },
      {
        value: "专利顾问",
      },
      {
        value: "专栏专员",
      },
      {
        value: "风控",
      },
    ],
  },
  {
    value: "公务员",
    children: [
      {
        value: "法官",
      },
      {
        value: "检察官",
      },
      {
        value: "警察",
      },
      {
        value: "辅助人员",
      },
      {
        value: "执法类",
      },
      {
        value: "立法类",
      },
      {
        value: "处罚类",
      },
      {
        value: "行政部门",
      },
      {
        value: "综合管理类",
      },
    ],
  },
  {
    value: "财会类",
    children: [
      {
        value: "会计类",
      },
      {
        value: "审计类",
      },
      {
        value: "税务类",
      },
    ],
  },
  {
    value: "辅助类",
    children: [
      {
        value: "运营总监",
      },
      {
        value: "运营主管",
      },
      {
        value: "行政主管",
      },
      {
        value: "人事主管",
      },
      {
        value: "宣传主管",
      },
      {
        value: "市场主管",
      },
      {
        value: "行政专员",
      },
      {
        value: "人事专员",
      },
      {
        value: "宣传专员",
      },
      {
        value: "市场专员",
      },
      {
        value: "IT人员",
      },
      {
        value: "翻译人员",
      },
      {
        value: "知识管理人员",
      },
      {
        value: "设计师",
      },
      {
        value: "秘书",
      },
      {
        value: "编辑",
      },
      {
        value: "销售人员",
      },
      {
        value: "前台",
      },
      {
        value: "档案管理",
      },
    ],
  },
];

const liangdianList = [
  {
    name: "年底双薪",
    selected: false,
  },
  {
    name: "绩效奖金",
    selected: false,
  },
  {
    name: "带薪年假",
    selected: false,
  },
  {
    name: "通讯津贴",
    selected: false,
  },
  {
    name: "出差补贴",
    selected: false,
  },
  {
    name: "午餐补助",
    selected: false,
  },
  {
    name: "定期体检",
    selected: false,
  },
  {
    name: "领导好",
    selected: false,
  },
  {
    name: "管理规范",
    selected: false,
  },
  {
    name: "技能培训",
    selected: false,
  },
  {
    name: "岗位晋升",
    selected: false,
  },
  {
    name: "五险一金",
    selected: false,
  },
  {
    name: "节日礼物",
    selected: false,
  },
  {
    name: "绩效奖金",
    selected: false,
  },
  {
    name: "补充医疗保险",
    selected: false,
  },
  {
    name: "领导好",
    selected: false,
  },
  {
    name: "公司规模大",
    selected: false,
  },
  {
    name: "企业文化好",
    selected: false,
  },
  {
    name: "发展空间大",
    selected: false,
  },
  {
    name: "团队聚餐",
    selected: false,
  },
];

const salaryList = [
  "面议",
  "5k以下",
  "5k-10k",
  "10k-15k",
  "15k-20k",
  "20k-30k",
  "30k-40k",
  "40k-50k",
  "50k-100k",
  "100k以上",
  "自定义",
];

const educationList = ["大专", "本科", "硕士", "博士"];

export default {
  // 保存或更新或发布职位
  changeJob: (data) =>
    req.get("/job/saveOrUpdateOrSendJob", data).then(
      () => {
        if (data.id) Message.success("职位更新成功");
        else Message.success("职位创建成功");
        return Promise.resolve();
      },
      () => {
        if (data.id) Message.error("职位更新失败，请重试");
        else Message.error("职位创建失败，请重试");
        return Promise.reject();
      }
    ),

  // 查询招聘所有信息
  getAll: (data) => req.get("/resume/all/getUserResumeAll", data),

  // 招聘主页：招聘单位
  getAllCompany: (data) => req.get("/resume/all/getResumeHomePageUnit", data),

  // 招聘主页：招聘职位
  getAllPost: (data) => req.get("/resume/all/getResumePostList", data),

  // 获取职位类别
  getTypeList: () => Promise.resolve(typeList),

  // 获取职位亮点
  getLiangdianList: () => Promise.resolve(liangdianList),

  // 获取薪资待遇
  getSalaryList: () => Promise.resolve(salaryList),

  // 获取学历
  getEducationList: () => Promise.resolve(educationList),
};
