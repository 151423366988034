import req from "@/utils/request";
import { Message } from "element-ui";

// 用户相关api

// 基础api
const basic = {
  // 登录
  login: (data) =>
    req.post("/user/login", data).then(
      (res) => Promise.resolve(res),
      () => {
        Message.error("登录失败");
        return Promise.reject();
      }
    ),

  // 验证码登录
  codeLogin: (data) =>
    req.post("/user/login/code", data).then(
      (res) => Promise.resolve(res),
      () => {
        Message.error("登录失败");
        return Promise.reject();
      }
    ),

  // 注册
  register: (data) =>
    req.post("/user/register", data).then(
      (res) => Promise.resolve(res),
      () => {
        Message.error("注册失败");
        return Promise.reject();
      }
    ),

  // 注销
  logout: (data) => req.post("/user/logout", data),

  // 找回密码
  resetPassword: (data) =>
    req.post("/user/login/retrieve", data).then(
      (res) => Promise.resolve(res),
      () => {
        Message.error("找回密码失败");
        return Promise.reject();
      }
    ),

  // 获取用户信息
  getUserInfo: (data) => req.get("/user/info/getUserInfo", data),

  // 获取用户简介
  getUserIntro: (data) =>
    req.get("/user/introduce/getUserIntroduceByUserId", data),

  // 保存或更新用户简介
  changeUserIntro: (data) =>
    req.post("/user/introduce/saveOrUpdateUserIntroduce", data),
};

// 收货地址相关
const address = {
  // 用户删除收货地址
  delUserAddress: (data) =>
    req.post("/user/address/deleteUserAddress", data).then(
      () => {
        Message.success("删除成功");
        Promise.resolve();
      },
      () => {
        Message.error("删除失败");
        return Promise.reject();
      }
    ),

  // 获取用户地址列表
  getUserAddressList: (data) =>
    req.post("/user/address/findUserAddressList", data),

  // 用户新增或更新收货地址
  changeUserAddress: (data) =>
    req.post("/user/address/saveOrUpdateUserAddress", data).then(
      (res) => Promise.resolve(res),
      () => {
        Message.error("更新失败");
        return Promise.reject();
      }
    ),

  // 用户设为默认地址
  changeDefaultAddress: (data) =>
    req.post("/user/address/updateDefaultAddress", data).then(
      () => {
        Message.success("默认地址设置成功");
        Promise.resolve();
      },
      () => {
        Message.error("默认地址设置失败");
        return Promise.reject();
      }
    ),
};

export default {
  ...basic,
  ...address,

  // 获取黄页信息
  getYellowPages: (data) => req.get("/yellowPage/getYellowPageByUserId", data),

  // 新增黄页信息
  addYellowPages: (data) =>
    req.post("/yellowPage/saveOrUpdateYellowPageInfo", data).then(
      (res) => Promise.resolve(res),
      () => {
        Message.error("新增失败");
        return Promise.reject();
      }
    ),

  // 获取用户个人成就
  getAchievement: (data) =>
    req.get("/user/achiever/getUserAchieverByUserId", data),

  // 装饰主页
  changeHomePage: (data) =>
    req.post("/user/info/uploadHomePageImage", data, "other").then(
      () => {
        Message.success("更新成功");
        return Promise.resolve();
      },
      () => {
        Message.error("更新失败");
        return Promise.reject();
      }
    ),
};
