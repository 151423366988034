<template>
  <div class="myCart contentWidth">
    <div class="cardHead startFlex">
      <div class="allCheckbox startFlex commonWidth">
        <el-checkbox v-model="checkedALL" @change="allChecked"
          >全选</el-checkbox
        >
      </div>
      <div class="goodsInfo startFlex shopWidth">商品信息</div>
      <div class="price startFlex commonWidth">单价</div>
      <div class="count startFlex commonWidth">数量</div>
      <div class="count startFlex commonWidth">小计</div>
      <div class="operation startFlex commonWidth">操作</div>
    </div>
    <ul>
      <li v-for="item in cartList" :key="item" class="startFlex cartItem">
        <div class="allCheckbox startFlex commonWidth">
          <el-checkbox v-model="item.isChecked"></el-checkbox
          ><img
            :src="require('@as/reading/test.png')"
            alt=""
            style="width: 90px; height: 90px; margin-left: 25px"
          />
        </div>
        <div class="goodsInfo startFlex shopWidth">{{ item.title }}</div>
        <div class="price startFlex commonWidth">{{ item.price }}</div>
        <div class="count startFlex commonWidth">
          <span class="jian commonFlex" @click="minus(item)">-</span>
          <div class="commonFlex wrapCount">{{ item.count }}</div>
          <span class="jia commonFlex" @click="add(item)">+</span>
        </div>
        <div class="count startFlex commonWidth">
          ¥{{ item.count * item.price }}
        </div>
        <div class="operation startFlex commonWidth canClick">删除</div>
      </li>
    </ul>

    <div class="totalCart startFlex">
      <div>
        <el-checkbox :value="true"></el-checkbox>
        <span class="yixuanCount">已选 ({{ totalCount }})</span>
      </div>
      <div class="piliangDelete">批量删除</div>
      <div class="startFlex">
        <div class="wrapExpress commonFlex">
          <div>
            商品合计:<span style="margin-left: 40px">{{ totalPrice }}</span>
          </div>
          <div style="margin-top: 16px">
            快递费用:<span style="margin-left: 40px; margin-top: 16px">20</span>
          </div>
        </div>
        <div class="wrapAllmoney">
          <span class="yingfuWord">应付总额：</span>
          <span class="yinguCount">¥{{ totalPrice + 20 }}</span>
        </div>
        <div class="lijixiadan commonFlex">立即下单</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cartList: [
        { title: '还好还好', price: 36.0, count: 1, isChecked: false },
        { title: '还好还好', price: 36.0, count: 1, isChecked: false },
        { title: '还好还好', price: 36.0, count: 1, isChecked: false },

        { title: '还好还好', price: 36.0, count: 1, isChecked: false },
      ],
      checkedALL: false,
    }
  },
  computed: {
    totalCount() {
      let count = 0
      this.cartList.map((item) => {
        if (item.isChecked) {
          count += item.count
        }
      })
      return count
    },
    totalPrice() {
      let count = 0
      this.cartList.map((item) => {
        if (item.isChecked) {
          count += item.count * item.price
        }
      })
      return count
    },
  },
  methods: {
    minus(item) {
      if (item.count <= 0) {
        return
      }
      item.count -= 1
    },
    add(item) {
      item.count += 1
    },
    allChecked(event) {
      this.cartList.map((element) => {
        console.log(event)
        element.isChecked = event
      })
      this.totalCount = this.cartList.length + 1
    },
  },
}
</script>

<style lang="less">
.myCart {
  .lijixiadan {
    width: 180px;
    height: 64px;
    background: #1c9ab1;

    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
    margin-left: 20px;
    cursor: pointer;
  }
  .wrapAllmoney {
    padding-left: 20px;
    border-left: 1px solid black;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .yingfuWord {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .yinguCount {
    font-size: 24px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #d03434;
    margin-left: 40px;
  }
  .wrapExpress {
    flex-direction: column;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;

    margin-left: 450px;
  }
  .yixuanCount {
    min-width: 70px;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .piliangDelete {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
    margin-left: 60px;
    cursor: pointer;
  }
  .totalCart {
    width: 1200px;
    height: 90px;
    background: #ffffff;
    border-radius: 2px;
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 82px;
  }
  margin-top: 50px;
  .cardHead {
    width: 1200px;
    height: 60px;
    background: #ffffff;
    border-radius: 2px;
    padding-left: 20px;
  }
  .wrapCount {
    width: 56px;
    height: 26px;
    border: 1px solid #dedede;
  }
  .jian {
    width: 26px;
    height: 26px;
    border: 1px solid #dedede;
    cursor: pointer;
  }
  .jia {
    border: 1px solid #dedede;
    width: 26px;
    height: 26px;
    cursor: pointer;
  }
  .cartItem {
    align-items: center;
    width: 1200px;
    height: 120px;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 2px;
    padding-left: 20px;
  }
  .commonWidth {
    width: 180px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .shopWidth {
    width: 300px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
}
</style>