<template>
  <div class="personalInfo">
    <div
      class="header-bg"
      :style="`background-color: ${userInfo.color || '#3DAED3'}`"
    ></div>
    <div class="headImg">
      <div class="contentWidth betweenFlex wrapPersonalhead">
        <div class="leftren commonFlex">
          <img
            :src="userInfo.photoUrl || require('@as/reading/test.png')"
            alt=""
            class="personImg"
          />
          <div style="margin-left: 40px">
            <div class="name">{{ userInfo.nick || '克里斯' }}</div>
            <div style="margin-top: 15px">
              <img
                :src="require('@as/personal/weirenzheng.png')"
                alt=""
                v-if="userInfo.isVerify === 1"
              />
              <img
                :src="require('@as/personal/yirenzheng.png')"
                alt=""
                v-else
              />
            </div>
          </div>
        </div>
        <div class="rightCount commonFlex">
          <div class="wrapGuanzhu">
            <div class="guanzhuCount">{{ userInfo.attention || 0 }}</div>
            <div class="guanzhuWord">关注</div>
          </div>
          <div class="wrapGuanzhu">
            <div class="guanzhuCount">{{ userInfo.fans || 0 }}</div>
            <div class="guanzhuWord">粉丝</div>
          </div>
          <div class="wrapGuanzhu">
            <div class="guanzhuCount">{{ userInfo.fans || 0 }}</div>
            <div class="guanzhuWord">总浏览量</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="contentWidth onlyFlex"
      style="margin-top: 40px; align-items: flex-start"
    >
      <div class="leftNav">
        <div v-for="(item, index) in navList" :key="index">
          <div class="startFlex wrapIconNav">
            <img :src="item.src" alt="" /><span class="navTitle">{{
              item.name
            }}</span>
          </div>
          <ul v-if="item.children.length > 0">
            <li
              v-for="(subItem, indexSub) in item.children"
              :key="indexSub"
              class="subTitle commonFlex"
              @click="activeIndex = index"
              :class="{ selectedSubItem: activeIndex == index }"
            >
              <router-link :to="subItem.path" class="linkSubtitle commonFlex">{{
                subItem.name
              }}</router-link>
            </li>
          </ul>
          <div class="lastFengeLine"></div>
        </div>
      </div>
      <div class="rightCatDetail">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      navList: [
        {
          name: '主页',
          src: require('@as/personal/shouye.png'),
          children: [],
        },
        {
          name: '阅读',
          src: require('@as/personal/read.png'),
          children: [
            { name: '专栏管理', path: '/personal/zhuanlanManage' },
            { name: '问答管理', path: '/personal/answerManage' },
            { name: '订阅管理', path: '/personal/dingyueManage' },
          ],
        },
        {
          name: '活动',
          src: require('@as/personal/activity.png'),
          children: [
            { name: '发布管理', path: '/personal/fabuManage' },
            { name: '参与管理', path: '/personal/canyuManage' },
          ],
        },
        {
          name: '招聘',
          src: require('@as/personal/zhaopin.png'),
          children: [
            { name: '我的简历', path: '/personal/myResumeManage' },
            { name: '职位管理', path: '/personal/JobManage' },
            { name: '投递收藏', path: '/personal/toudiManage' },
            { name: '简历管理', path: '/personal/resumeManage' },
            { name: '搜索简历', path: '/personal/searchResumeManage' },
          ],
        },
        {
          name: '黄页',
          src: require('@as/personal/huangye.png'),
          children: [],
        },
        {
          name: '管理',
          src: require('@as/personal/service.png'),
          children: [
            { name: '订单管理', path: '/personal/orderManage' },
            { name: '消息管理', path: '/personal/messageManage' },
            { name: '人脉管理', path: '/personal/socialManage' },
          ],
        },
        {
          name: '设置',
          src: require('@as/personal/shezhi.png'),
          children: [
            { name: '账号设置', path: '/personal/zhanghaoSet' },
            { name: '主页设置', path: '/personal/set' },
            { name: '专栏设置', path: '/1' },
            { name: '实名认证', path: '/personal/shimingRenzheng' },
          ],
        },
      ],
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    },
  },
}
</script>

<style lang="less">
.personalInfo {
  position: relative;
  .header-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 200px;
    opacity: 0.6;
  }
  .wrapGuanzhu {
    text-align: center;
    margin-left: 49px;
  }
  .guanzhuCount {
    font-size: 24px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .guanzhuWord {
    margin-top: 13px;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .name {
    font-size: 24px;
    font-family: PingFangSC;
    font-weight: 500;
    color: #ffffff;
  }
  .lastFengeLine {
    width: 100px;
    height: 1px;
    background: #eeeeee;
    border-radius: 1px;
    margin-left: 60px;
    margin-top: 10px;
  }
  .subTitle {
    // padding-left: 60px;
    height: 40px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
  }
  .subTitle:hover {
    background: rgba(8, 153, 202, 0.1);
  }
  .linkSubtitle {
    width: 100%;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
    margin-right: 26px;
  }
  .router-link-active {
    color: #4395cd !important;
    border-left: 3px solid #0899ca;
  }
  .navTitle {
    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
    margin-left: 20px;
  }
  .wrapIconNav {
    height: 70px;
    padding-left: 23px;
  }
  .leftNav {
    width: 200px;
    // height: 1124px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 100px;
  }
  .rightCatDetail {
    width: 980px;
    // height: 1470px;
    background: #ffffff;
    border-radius: 4px;
    margin-left: 20px;
    margin-bottom: 30px;
  }
  .headImg {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 200px;
  }
  .personImg {
    width: 120px;
    height: 120px;
    background: #9fded5;
    border: 2px solid #ffffff;
    border-radius: 50%;
  }
  .wrapPersonalhead {
    padding-top: 100px;
    margin-top: -20px;
  }
}
</style>