<template>
  <div class="dingyue contentWidth">
    <sidebar></sidebar>
    <div class="midContent">
      <img
        :src="require('@as/reading/guanchaMid.png')"
        alt=""
        style="width: 100%"
      />
      <articleList />
      <div class="emptyWrap commonFlex" v-if="articleList.length == 0">
        <img :src="require('@as//reading/dingyueEmpty.png')" alt="" />
        <div class="nodingyue">我暂时还没有订阅专栏</div>
        <div class="goDingyue commonFlex">去订阅专栏</div>
      </div>
      <div class="lookmore" v-if="articleList.length > 0">查看更多</div>
    </div>
    <div class="rightContent">
      <writeArticle />
      <div style="height: 20px"></div>
      <tuijianZhuanlan />
    </div>
  </div>
</template>

<script>
import sidebar from '@c/reading/sidebar'
import topMenu from '@c/reading/topMenu'
import writeArticle from '@c/reading/writeArticle'
import tuijianZhuanlan from '@c/reading/tuijianZhuanlan'
import articleList from '@c/reading/articleList'
export default {
  data() {
    return {
      articleList: [
        {
          title: '从黑石集团与Alnylam 20亿美元交易案例深度评析药',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title: '从黑石集团与Alnylam 20亿美元交易案例深度评析药',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title: '从黑石集团与Alnylam 20亿美元交易案例深度评析药',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title: '从黑石集团与Alnylam 20亿美元交易案例深度评析药',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title: '从黑石集团与Alnylam 20亿美元交易案例深度评析药品',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
        {
          title: '从黑石集团与Alnylam 20亿美元交易案例深度评析药',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },

        {
          title: '从黑石集团与Alnylam 20亿美元交易案例深度评析药',
          desp: '本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。',
          author: ['龚建华', '胡晓华', '张曦予'],
          company: '君合法律评论',
          sign: ['新证劵法', '诉讼'],
          time: '30分钟前',
        },
      ],
    }
  },
  components: {
    sidebar,
    topMenu,
    writeArticle,
    tuijianZhuanlan,
    articleList,
  },
  created() {
    console.log('首页')
  },
}
</script>
<style lang="less">
.dingyue {
  display: flex;
  justify-content: space-between;
  .midContent {
    width: 720px;
     .emptyWrap{
      width:720px;
      padding-top:110px;
      flex-direction: column;
      .nodingyue{
          
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #666666;
        margin-top:19px;
      }
      .goDingyue{
          margin-top:20px;
          width: 100px;
          height: 30px;
          background: #1C9AB1;

          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #FFFFFF;
      }
  }
    .lookmore {
      cursor: pointer;
      width: 718px;
      height: 41px;
      margin-bottom: 37px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 17px;
      background: #EBECEC;
    }
    .articleItem {
      // height: 136px;
      padding: 20px 0;
      border-bottom: 1px solid #dedede;
      display: flex;
      justify-content: space-between;
      &:last-child {
        border-bottom: none;
      }
      .articleRight {
        .title{
          cursor: pointer;
        }
        .articleRightBot {
          width:500px!important
        //   margin-top: 35px;
        //   display: flex;
        //   align-items: center;
        //   justify-content: space-between;
        //   div {
        //     display: flex;
        //     align-items: center;
        //   }
        //   span {
        //     font-size: 12px;
        //     font-family: PingFangSC;
        //     font-weight: 400;
        //     color: #afafaf;
        //     margin-right: 5px;
        //   }
        //   .signItem {
        //     padding: 2px 5px;

        //     font-size: 10px;
        //     font-family: PingFangSC;
        //     font-weight: 400;
        //     color: #afafaf;
        //     background: #E9ECED;
        //   }
        }
      }
    }
  }
  .rightContent {
    width: 330px;
  }
}
</style>