<template>
  <div class="zhuanlanIndex" style="margin-top: -20px">
    <div class="topInfo">
      <div class="topInfoMid" v-show="showEdit">
        <img :src="require('@as//reading/test.png')" alt="" class="left" />
        <div class="right">
          <div class="betweenFlex">
            <span class="title">{{ personInfo.title }}</span>
            <div class="wrapXiala commonFlex">
              <img
                :src="require('@as/reading/arrowDown.png')"
                alt=""
                class="canClick"
              />
            </div>
          </div>
          <div class="desp">
            {{ personInfo.desp }}
          </div>
          <div class="wrapCreateImg">
            <img
              :src="require('@as/reading/test.png')"
              alt=""
              class="createImg"
            />
            <span class="createName">{{ personInfo.creator }}</span>
          </div>
          <div class="onlyFlex">
            <div>
              共<span class="count">{{ personInfo.wenzhangCount }}</span
              >篇文章
            </div>
            <div style="margin-left: 77px">
              <span class="count">{{ personInfo.seeCount }}</span
              >人看过
            </div>
          </div>
          <div class="wrapDingyue betweenFlex">
            <div class="dingyue commonFlex">订阅专栏</div>
            <div class="wrapShare betweenFlex">
              <span>分享</span>
              <img :src="require('@as/reading/wechat.png')" alt="" />
              <img :src="require('@as/reading/weibo.png')" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="editZhuanlan" v-show="!showEdit">
        <div class="wrapPersonImg">
          <img
            :src="require('@as/reading/test.png')"
            alt=""
            class="editPersonImg"
          />
          <div class="banCircle canClick">修改图片</div>
        </div>
        <div class="rightEditZhualan">
          <input type="text" class="title" placeholder="请输入专栏名称" /><br />
          <textarea class="longText" placeholder="请输入专栏描述"></textarea>
          <div class="wrapChoice">
            <div class="queding commonFlex">确定</div>
            <div class="quit commonFlex">取消</div>
          </div>
        </div>
      </div>
    </div>
    <div class="zhuanlanContent contentWidth betweenFlex">
      <div class="left borderContent">
        <div class="topTab betweenFlex">
          <div
            v-for="(item, index) in tablist"
            :key="index"
            class="tabItem commonFlex canClick"
            :class="{ selectedTab: item.id == activeId }"
            @click="activeId = item.id"
          >
            <span class="tabName">{{ item.name }}</span>
            <span class="tabCount">{{ item.count }}</span>
          </div>
          <div>
            <img
              :src="require('@as/reading/xiala.png')"
              alt=""
              class="canClick"
            />
          </div>
        </div>

        <articleList />
        <div class="lookmore">加载更多</div>
      </div>
      <div class="right">
        <hotZhuanlan />
      </div>
    </div>
  </div>
</template>

<script>
import hotZhuanlan from '@c/reading/hotZhuanlan'
import articleList from '@c/reading/articleList'
export default {
  data() {
    return {
      showEdit: false,
      personInfo: {
        title: '建站达人秀 ',
        creator: '创建人名称',
        desp: '债券虚假陈述诉讼的纠纷解决需兼顾不同的上位法和交易规则',
        wenzhangCount: 10,
        seeCount: 1234,
      },
      activeId: 2,
      tablist: [
        { id: 2, name: '全部', count: 30 },
        { id: 1, name: '全部', count: 30 },
        { id: 11, name: '全部', count: 30 },
        { id: 12, name: '全部', count: 30 },
        { id: 13, name: '全部', count: 30 },

        { id: 14, name: '全部', count: 30 },

        { id: 15, name: '全部', count: 30 },

        { id: 16, name: '全部', count: 30 },
      ],
    }
  },
  components: {
    hotZhuanlan,
    articleList,
  },
}
</script>

<style lang="less">
.zhuanlanIndex {
  ::-webkit-input-placeholder {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .longText::placeholder {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .lookmore {
    width: 810px !important;
  }
  .zhuanlanContent {
    margin-top: 20px;
    align-items: flex-start !important;
    margin-bottom: 60px;
    .right {
      width: 330px;
    }
    .left {
      width: 850px;
      // height: 1060px;
      background: #ffffff;
      border: 1px solid #dedede;
      padding: 0 24px 0px 24px;
      .topTab {
        height: 73px;
        margin-bottom: -20px;
        .selectedTab {
          border: 1px solid #089acb !important;

          span {
            color: #089acb !important;
          }
        }
        .tabItem {
          width: 82px;
          height: 30px;
          border: 1px solid #cccccc;
          border-radius: 2px;

          .tabName {
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #333333;
            display: inline-block;
          }
          .tabCount {
            margin-left: 3px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }

  .editPersonImg {
    width: 100px;
    height: 100px;
    background: rgba(8, 154, 203, 0.5);
    border: 1px solid #ffffff;
    border-radius: 50%;
  }
  .wrapPersonImg {
    position: relative;
    .banCircle {
      width: 100px;
      height: 50px;
      border-radius: 0 0 50px 50px;
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(8, 154, 203, 0.5);
      z-index: 20000;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  // justify-content: space-between;
  .rightEditZhualan {
    margin-left: 48px;
    .wrapChoice {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
    .quit {
      cursor: pointer;
      width: 111px;
      height: 30px;
      border: 1px solid #1c99b0;
      background: #01363f;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      margin-left: 9px;
    }
    .queding {
      width: 111px;
      height: 30px;
      background: #089acb;
      cursor: pointer;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
    }
    .title {
      width: 920px;
      height: 44px;
      background: #ffffff;
      border: 1px solid #cccccc;
      padding: 10px;
      border: none;
      outline: none;
    }
    .longText {
      width: 920px;
      height: 84px;
      background: #ffffff;
      border: 1px solid #cccccc;
      padding: 10px;
      margin-top: 16px;
      border: none;
      outline: none;
      resize: none;
    }
  }
  .topInfo {
    width: 100%;
    // height: 280px;
    background: #01363f;
    padding-top: 50px;

    height: 280px;
    .editZhuanlan {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
    }
  }
  .topInfoMid {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .left {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .right {
      width: 1050px;
      .wrapDingyue {
        margin-top: 23px;
        .wrapShare {
          width: 75px;
          span {
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #a7a8a8;
            margin-right: 5px;
          }
        }
      }
      .dingyue {
        width: 111px;
        height: 30px;
        background: #089acb;

        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
      }
      .title {
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
      }
      .wrapXiala {
        width: 40px;
        height: 20px;
        background: #ffffff;
        opacity: 0.4;
        border-radius: 10px;
      }
      .desp {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
        margin-top: 13px;
      }
      .wrapCreateImg {
        margin-top: 13px;
        display: flex;
        align-items: center;
        .createImg {
          width: 37px;
          height: 37px;
          border-radius: 50%;
        }
        .createName {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
          margin-left: 17px;
        }
      }
      .onlyFlex {
        margin-top: 22px;
        div {
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #999999;
          line-height: 18px;
        }
        span {
          color: #089acb !important;
          font-size: 20px;
          font-family: PingFangSC-Medium;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
</style>