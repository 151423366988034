<template>
  <div class="mainZhaopin">
    <div
      v-for="(item, index) in zhaopinList"
      :key="index"
      class="zhaopinItem commonBorder"
    >
      <div class="betweenFlex">
        <div class="title">{{ item.title }}</div>
        <div class="commonFlex">
          <img :src="require('@as/personal/time.png')" alt="" />
          <span class="time">30分钟前</span>
          <img
            :src="require('@as/personal/baishou.png')"
            alt=""
            style="margin-left: 30px"
          />
          <span class="time">收藏</span>
        </div>
      </div>
      <div class="betweenFlex" style="margin-top: 18px">
        <div class="commonFlex">
          <span class="salary">10-15K</span>
          <span class="salaryBack"
            >/月<span class="divide">|</span>北京<span class="divide">|</span
            >法学硕士<span class="divide">|</span>1-2年</span
          >
        </div>
        <div class="commonFlex">
          <div class="signItem">还好还好</div>
          <div class="signItem">还好还好</div>
          <div class="signItem">还好还好</div>
        </div>
      </div>
    </div>
    <div class="wrapPage">
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zhaopinList: [
        { title: '瞧瞧瞧瞧瞧瞧瞧瞧' },
        { title: '瞧瞧瞧瞧瞧瞧瞧瞧' },
        { title: '瞧瞧瞧瞧瞧瞧瞧瞧' },
        { title: '瞧瞧瞧瞧瞧瞧瞧瞧' },
      ],
    }
  },
}
</script>

<style lang="less">
.mainZhaopin {
  padding: 0 20px;
  .wrapPage {
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .salaryBack {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    margin-left: 6px;
    margin-top: 2px;
  }
  .divide {
    margin: 0 10px;
  }
  .zhaopinItem {
    padding: 21px 0;
    .signItem {
      padding: 3px 7px;
      background: #1c9ab1;
      color: white;
      margin-left: 15px;
      font-size: 12px;
    }
    .salary {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c9bb2;
    }
    .title {
      font-size: 17px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #595757;
    }
    .time {
      margin-left: 5px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #afafaf;
    }
  }
}
</style>