<template>
  <div id="app" class="commonBack">
    <indexHeader v-if="hide == 1"></indexHeader>
    <router-view />
    <indexFoot v-if="footerShow == 1"></indexFoot>
  </div>
</template>

<script>
import indexFoot from '@c/indexFoot'
import indexHeader from '@c/indexHeader'
export default {
  name: 'App',
  components: {
    indexFoot,
    indexHeader,
  },
  data() {
    return {}
  },
  computed: {
    hide() {
      return this.$store.state.header.hide
    },
    footerShow() {
      return this.$store.state.footer.footerShow
    },
  },
  created() {},
  methods: {},
}
</script>

<style lang="less" >
// img {
//   display: block;
// }
</style>
