<template>
  <div class="RegisertWrap">
    <div class="midLoginWrap">
      <div class="leftLoginDetail">
        <div class="zhuceWord">注册</div>
        <div class="wrapInputPhone commonBorder commonFlex">
          <div class="leftPhone commonFlex">
            <el-select v-model="areaCode">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- <img
              src="../../assets/reading/arrowDown.png"
              alt=""
              class="select_icon"
            /> -->
          </div>
          <input
            type="number"
            placeholder="手机号码"
            class="phoneInput"
            v-model="mobile"
          />
        </div>
        <div class="wrapyanzhengCode betweenFlex commonBorder">
          <input
            type="text"
            placeholder="验证码"
            class="yanzhengCode"
            style="width: auto"
            v-model="imgCode"
          />
          <img :src="imgCodeUrl" alt="" @click="handlerChangeImgCode" />
        </div>
        <div class="wrapmsgCode" style="align-items: flex-end">
          <div class="wrapLeftInputCode commonBorder commonFlex">
            <input
              type="text"
              placeholder="短信码"
              class="msgCode"
              v-model="code"
            />
          </div>
          <div
            class="sendMsgCode commonFlex"
            :class="countdown > -1 ? 'disable' : ''"
            @click="handlerGetCode"
          >
            {{ countdown > -1 ? `${countdown}秒` : '发送验证码' }}
          </div>
        </div>
        <div class="wrapPassword commonBorder">
          <input
            type="password"
            placeholder="密码"
            class="password"
            v-model="password"
          />
        </div>
        <div class="wrapPassword commonBorder">
          <input
            type="password"
            placeholder="确认密码"
            class="password"
            v-model="passwordRe"
          />
        </div>
        <div class="wrapLstline">
          <el-checkbox v-model="checked"></el-checkbox>
          <span class="tongyi">点击注册代表您已阅读并同意 </span>
          <span class="protocal canClick">用户使用协议</span>
        </div>
        <div class="wrapTozhuce betweenFlex">
          <div class="register commonFlex" @click="handlerRegister">注册</div>
          <div>
            <span class="hasCode">已有账号?</span>
            <span class="toLogin" @click="toLogin">去登陆</span>
          </div>
        </div>
      </div>
      <rightBk />
    </div>
  </div>
</template>

<script>
import rightBk from '@c/login/rightBk'
import { User, Basic } from '@api/index'
export default {
  data() {
    return {
      checked: false,
      mobile: '',
      options: [
        {
          value: '+86',
          label: '+86',
        },
      ],
      areaCode: '+86',
      imgCodeUrl: `http://36.133.34.133:8088/common/code/verify`,
      imgCode: '',
      code: '',
      countdown: undefined,
      password: '',
      passwordRe: '',
    }
  },
  components: {
    rightBk,
  },
  created() {},
  methods: {
    toLogin() {
      this.$router.push('/login')
    },
    handlerGetCode() {
      if (!this.mobile) {
        this.$message.error({
          message: '请填写手机号',
        })
        return
      }
      if (this.countdown > -1) return
      Basic.getSmsCode({
        mobile: `${this.mobile}`,
        type: 1,
      }).then(() => {
        this.countdown = 60
        this.setCountdown()
      })
    },
    setCountdown() {
      setTimeout(() => {
        if (this.countdown === 0) this.countdown = undefined
        else {
          this.countdown -= 1
          this.setCountdown()
        }
      }, 1000)
    },
    handlerChangeImgCode() {
      this.imgCodeUrl = `http://36.133.34.133:8088/common/code/verify?${Date.now()}`
    },
    handlerRegister() {
      const {
        checked,
        mobile,
        areaCode,
        imgCode,
        code,
        password,
        passwordRe,
      } = this
      if (!mobile) {
        this.$message.error({
          message: '请填写手机号',
        })
        return
      }
      if (!imgCode) {
        this.$message.error({
          message: '请填写验证码',
        })
        return
      }
      if (!code) {
        this.$message.error({
          message: '请填写短信码',
        })
        return
      }
      if (!password || !password !== !passwordRe) {
        this.$message.error({
          message: '两次输入密码不一致',
        })
        return
      }
      if (!checked) {
        this.$message.error({
          message: '请勾选用户使用协议',
        })
        return
      }
      Basic.isImgCodeVaild({
        imgCode,
      })
        .then(() =>
          Basic.isSmsCodeVaild({
            code,
            mobile,
          })
        )
        .then(() =>
          User.register({
            code,
            mobile,
            password,
          })
        )
        .then(() => {
          this.$message.success({
            message: '注册成功，即将返回登录页',
          })
          this.toLogin()
        })
        .catch(() => {
          this.handlerChangeImgCode()
        })
    },
  },
}
</script>

<style lang="less">
.RegisertWrap {
  background: wheat;
  width: 100%;
  height: 100%;
  background: url('../../assets/login/loginBg.png') no-repeat;
  padding: 100px 0 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    width: 100%;
    border: none;
    outline: none;
  }
  ::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .midLoginWrap {
    width: 1150px;
    height: 560px;
    // margin: 0 auto;
    // padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loginTestImg {
    width: 384px;
    height: 560px;
  }
  .leftLoginDetail {
    width: 660px;
    height: 560px;
    background: white;
    padding-left: 70px;
    padding-top: 59px;
    .zhuceWord {
      font-size: 24px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
    .tongyi {
      margin: 0 10px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
    }
    .protocal {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      text-decoration: underline;
    }
    .wrapTozhuce {
      // height: 120px;
      margin-top: 18px;
      width: 520px;

      .hasCode {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
      .toLogin {
        color: #1c9ab1;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        cursor: pointer;
      }
      .register {
        width: 160px;
        height: 48px;
        background: #1c9ab1;
        cursor: pointer;
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .wrapLstline {
      display: flex;
      align-items: center;
      height: 79px;
      & /deep/ .el-checkbox__label {
        color: #999999;
      }
      & /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #dedede;
      }
      & /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #fff;
        border-color: #dedede;
      }
      & /deep/ .el-checkbox__inner::after {
        border-color: #999;
      }
    }
    .wrapyanzhengCode,
    .wrapmsgCode,
    .wrapPassword {
      width: 520px;
      height: 59px;
      display: flex;
      align-items: center;
    }
    .msgCode {
      width: 100%;
    }
    .wrapLeftInputCode {
      height: 59px;
      width: 360px;
    }
    .sendMsgCode {
      margin-left: 20px;
      width: 140px;
      height: 46px;
      border: 1px solid #1c9ab1;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;

      &.disable {
        border-color: #999;
        color: #999;
      }
    }
    .wrapInputPhone {
      width: 520px;
      height: 59px;
      .phoneInput {
        padding-left: 23px;
        width: 421px;
        font-size: 16px;
        border-left: 1px solid #dedede;
      }
      .leftPhone {
        position: relative;
        width: 80px;

        justify-content: flex-start !important;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
        /* .select_icon {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        } */
        & /deep/ .el-input__inner {
          padding-left: 0;
        }
        & /deep/ .el-input__suffix {
          right: 20px;
        }
      }
    }
  }
}
</style>