<template>
  <div class="pop_share">
    <div
      class="item"
      v-for="item in list"
      v-bind:key="item"
      @click="$emit('click-btn', item)"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
  },
}
</script>

<style lang="less" scoped>
.pop_share {
  position: absolute;
  top: 30px;
  // left: 100px;
  width: 100px;
  text-align: center;
  background: #fff;
  border-radius: 6px;
  &::after {
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 0;
    height: 0;
    content: '';
    transform: translateX(-50%);
    border: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }
  .item {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
  }
}
</style>