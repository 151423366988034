<template>
  <div class="myResumeManage">
    <div class="top betweenFlex commonBorder">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="64"
        :height="56"
        :size="16"
        :distance="44"
      />
      <div class="commonFlex">
        <div class="xiewenzhang commonFlex canClick" @click="showResume = true">
          创建简历
        </div>
      </div>
    </div>
    <ul v-show="activeIndex == 0">
      <li
        v-for="(item, index) in jianliList"
        :key="index"
        class="jianliitem betweenFlex commonBorder"
      >
        <div class="jianliTitle">{{ item.name }}</div>
        <div>
          <span v-if="item.ispass" class="passShenhe">审核通过</span>
          <span v-if="!item.ispass" class="noPass">审核未通过</span>
        </div>
        <div class="onlyFlex">
          <div class="wrapRefrensh commonFlex">
            <img :src="require('@as/personal/resume/refresh.png')" alt="" />
            <div class="refreshWord">刷新</div>
          </div>
          <div class="wrapRefrensh commonFlex" @click="toDetail()">
            <img :src="require('@as/personal/resume/view.png')" alt="" />
            <div class="refreshWord">预览</div>
          </div>
          <div class="wrapRefrensh commonFlex">
            <img :src="require('@as/personal/resume/edit.png')" alt="" />
            <div class="refreshWord">编辑</div>
          </div>
          <div class="wrapRefrensh commonFlex">
            <img :src="require('@as/personal/resume/download.png')" alt="" />
            <div class="refreshWord">下载</div>
          </div>
          <div class="wrapRefrensh commonFlex">
            <img :src="require('@as/personal/resume/删 除.png')" alt="" />
            <div class="refreshWord">删除</div>
          </div>
        </div>
      </li>
    </ul>

    <div class="maskResume commonFlex" v-show="showResume">
      <div class="maskContent">
        <div class="topCreate betweenFlex">
          <span class="createResume">创建简历</span>
          <span @click="showResume = false" class="canClick">x</span>
        </div>
        <div class="commonFlex" style="margin-top: 50px">
          <span class="jianliName">简历名称</span>
          <input type="text" class="jianliNameInput" v-model="resumeName" />
        </div>
        <div class="jianlibeizhu">简历名称只用于简历管理，不会对招聘者展示</div>
        <div class="onlyFlex wrapnextstep">
          <div class="quit commonFlex" @click="handlerCloseDialog">取消</div>
          <div class="nextstep commonFlex" @click="handlerGoNext">下一步</div>
        </div>
      </div>
    </div>
    <div class="yinsiSet" v-show="activeIndex == 1">
      <div class="tishiWord">
        <div class="startFlex">
          <img :src="require('@as/personal/info.png')" alt="" />
          <div class="remind">提示</div>
        </div>
        <div class="permittedResume">
          公开：允许所有企业查看您的简历。如不想被企业查看，请设置屏蔽企业。
          <br />
          保密：不允许企业查看您的简历，只有您应聘的企业才可查看
        </div>
      </div>
      <div class="myResumeState betweenFlex">
        <div class="resumeName">我的简历20202</div>
        <div class="shenheState">审核通过</div>
        <div>
          <el-radio v-model="radio" label="1">公开</el-radio>
          <el-radio v-model="radio" label="2">保密</el-radio>
        </div>
      </div>
      <div class="myResumeState betweenFlex">
        <div class="resumeName">我的简历20202</div>
        <div class="shenheState">审核通过</div>
        <div>
          <el-radio v-model="radio1" label="1">公开</el-radio>
          <el-radio v-model="radio1" label="2">保密</el-radio>
        </div>
      </div>
      <div class="myResumeState betweenFlex">
        <div class="resumeName">企业屏蔽</div>
        <div class="shenheState" style="margin-left: 30px">
          让所有企业都能搜索到我
        </div>
        <div class="shenheState">设置</div>
      </div>
      <div class="qiyePingbi onlyFlex">
        <div class="resumeName">企业屏蔽</div>
        <div class="wrapQiyePingbi">
          <el-radio v-model="radioCom" label="1" style="margin-bottom: 20px"
            >让所有企业都能搜索到我</el-radio
          ><br />
          <el-popover
            placement="bottom"
            width="315"
            trigger="hover"
            style="background: red"
          >
            <div class="startFlex">
              <span>律师事务所</span>
              <img
                :src="require('@as/personal/删 除.png')"
                alt=""
                style="margin-left: 30px; cursor: pointer"
              />
            </div>
            <input
              type="text"
              class="addCompany"
              placeholder="请填写企业名称"
              v-model="companeyName"
            />
            <div class="addCompanyWord commonFlex canClick">添加</div>
            <div slot="reference" class="showDialog">
              <el-radio v-model="radioCom" label="2"
                >我不希望某些企业搜索到我</el-radio
              >
            </div>
          </el-popover>
          <div class="commonFlex" style="margin-top: 150px">
            <div class="queding commonFlex canClick">确定</div>
            <div class="quxiao commonFlex canClick">取消</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerChoice from "../headerChoice";
export default {
  data() {
    return {
      yinsiSet: true,
      activeIndex: 0,
      showResume: false,
      radio1: 1,
      radio: 1,
      radioCom: 1,
      choiceList: ["我的简历", "隐私设置"],
      jianliList: [
        { name: "啊啊去去去", ispass: true },
        { name: "啊啊去去去", ispass: false },
        { name: "啊啊去去去", ispass: false },
        { name: "啊啊去去去", ispass: false },
      ],
      resumeName: "",
      companeyName: "",
    };
  },
  methods: {
    change(index) {
      this.activeIndex = index;
    },
    handlerCloseDialog() {
      this.showResume = false;
      this.resumeName = "";
    },
    handlerGoNext() {
      this.$router.push(`/createResume?name=${this.resumeName}`);
    },
    toDetail() {
      this.$router.push({ name: "resumeDetail" });
    },
  },
  components: {
    headerChoice,
  },
};
</script>

<style lang="less">
.addCompanyWord {
  width: 80px;
  height: 24px;
  background: #4395cd;
  border-radius: 3px;

  font-size: 12px;
  font-family: PingFangSC;
  font-weight: 400;
  color: #ffffff;
  background: #4395cd;
  margin-top: 5px;
}
.addCompany {
  width: 190px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-top: 5px;
  padding-left: 5px;
}
.myResumeManage {
  width: 980px;
  padding-bottom: 20px;
  // height: 1080px;
  background: #ffffff;
  border-radius: 4px;
  & /deep/ .el-dialog__header {
    padding: 16px 19px;
    border-bottom: 1px solid #f0f0f0;
    & /deep/ .el-dialog__title {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #333333;
    }
    & /deep/ .el-dialog__headerbtn .el-dialog__close {
      color: #4f4f4f;
      font-weight: bold;
    }
  }
  .el-popper {
    width: 315px !important;

    // height: 151px;
    background: #f5f7f8;
    border: 1px solid #dedede;
  }
  .selected {
    border-bottom: 2px solid #0899ca !important;
  }
  .queding {
    width: 60px;
    height: 30px;
    background: #4395cd;
    border-radius: 2px;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .quxiao {
    width: 60px;
    height: 30px;
    border: 1px solid #4395cd;
    border-radius: 2px;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #4395cd;
    margin-left: 10px;
  }
  .yinsiSet {
    padding: 20px;
    .wrapQiyePingbi {
      margin-left: 244px;
    }
    .qiyePingbi {
      width: 940px;
      height: 300px;
      border: 1px solid #dedede;
      border-radius: 1px;
      padding: 20px;
      margin-top: 20px;
    }
    .myResumeState {
      width: 940px;
      height: 50px;
      border: 1px solid #dedede;
      border-radius: 1px;
      margin-top: 15px;
      padding: 0 20px;
      .resumeName {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .shenheState {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #4395cd;
        cursor: pointer;
      }
    }
    .tishiWord {
      width: 940px;
      padding: 20px;
      padding-top: 15px;
      height: 100px;
      background: #fef8f1;
      border: 1px solid #f08d1e;
      // opacity: 0.2;
    }
    .permittedResume {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #f08d1e;
      margin-left: 22px;
      line-height: 20px;
      margin-top: 12px;
    }
    .remind {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #f08d1e;
      margin-left: 5px;
    }
  }
  .maskResume {
    // width: 895px;
    // height: 498px;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    z-index: 99999;
  }
  .maskContent {
    width: 640px;
    height: 280px;
    background: #ffffff;
    .wrapnextstep {
      justify-content: flex-end;
      margin-top: 59px;
      padding-right: 20px;
    }
    .quit {
      width: 80px;
      height: 30px;
      border: 1px solid #1c9ab1;
      border-radius: 2px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      cursor: pointer;
    }
    .nextstep {
      width: 80px;
      height: 30px;
      background: #1c9ab1;
      border-radius: 2px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      margin-left: 10px;
      cursor: pointer;
    }
    .jianlibeizhu {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
      margin-top: 12px;
      margin-left: 104px;
    }
    .jianliName {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
    .jianliNameInput {
      width: 515px;
      height: 40px;
      border: 1px solid #dedede;
      border-radius: 2px;
      margin-left: 14px;
    }
    .topCreate {
      height: 49px;
      padding: 0 20px;
      border: 1px solid #f0f0f0;
    }
    .createResume {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #333333;
    }
  }
  .passShenhe {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #4395cd;
  }
  .noPass {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #f08e1f;
    margin-left: 17px;
  }
  .jianliTitle {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .jianliitem {
    height: 89px;
    margin: 0 20px;
  }
  .jianliitem:hover {
    background: linear-gradient(90deg, #ffffff 0%, #ecfaff 50%, #ffffff 100%);
  }
  .wrapRefrensh {
    margin-right: 20px;
    text-align: center;
    flex-direction: column;
    cursor: pointer;
  }
  .refreshWord {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    margin-top: 5px;
  }
  .top {
    padding: 0 20px;
  }
  .xiewenzhang {
    width: 80px;
    height: 30px;
    background: #4395cd;
    border-radius: 2px;
    margin-left: 30px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>