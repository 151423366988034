<template>
  <div class="createResume">
    <div class="bkSky"></div>
    <div class="resumeContent">
      <div class="leftCat">
        <div
          v-for="(item, index) in category"
          :key="item"
          class="categoryItem canClick"
          :class="{ seletedCat: activeIndex == index }"
          @click="changeCat(item, index)"
        >
          {{ item }}
          <div
            class="shortborder"
            :style="{ width: activeIndex == index ? '17px' : '0' }"
          ></div>
        </div>
      </div>
      <div class="catDetail">
        <div class="detailItem commonBorder betweenFlex">
          <div class="startFlex">
            <img :src="require('@as/zhaopin/jibenxinxi.png')" alt="" />
            <span class="detailWord">基本信息</span>
            <span class="requireFlag">*</span>
          </div>
          <!-- <div class="commonFlex addPersonInfo canClick">
            <span>+</span>
            <span style="margin-left: 5px">添加基本信息</span>
          </div> -->
        </div>
        <basicInfo :id="id" />
        <div class="detailItem commonBorder betweenFlex">
          <div class="startFlex">
            <img :src="require('@as/zhaopin/yixiang.png')" alt="" />
            <span class="detailWord">职业意向</span>
            <span class="requireFlag">*</span>
          </div>
          <!-- <div class="commonFlex addPersonInfo">
            <span>+</span>
            <span style="margin-left: 5px">添加职业意向</span>
          </div> -->
        </div>
        <yixiang :id="id" />
        <div class="detailItem commonBorder betweenFlex">
          <div class="startFlex">
            <img :src="require('@as/zhaopin/jingli.png')" alt="" />
            <span class="detailWord">工作/实习经历</span>
            <span class="requireFlag">*</span>
          </div>
          <div class="commonFlex addPersonInfo" @click="handlerAdd('shixi')">
            <span>+</span>
            <span style="margin-left: 5px">添加工作经验</span>
          </div>
        </div>
        <shixijingli ref="shixi" :id="id" />
        <div class="detailItem commonBorder betweenFlex">
          <div class="startFlex">
            <img :src="require('@as/zhaopin/jiaoyu.png')" alt="" />
            <span class="detailWord">教育经历</span>
            <span class="requireFlag">*</span>
          </div>
          <div class="commonFlex addPersonInfo" @click="handlerAdd('edu')">
            <span>+</span>
            <span style="margin-left: 5px">添加教育经历</span>
          </div>
        </div>
        <educationExp ref="edu" :id="id" />
        <div class="detailItem commonBorder betweenFlex">
          <div class="startFlex">
            <img :src="require('@as/zhaopin/xiangmu.png')" alt="" />
            <span class="detailWord">项目经验</span>
          </div>
          <div class="commonFlex addPersonInfo" @click="handlerAdd('proj')">
            <span>+</span>
            <span style="margin-left: 5px">添加项目经验</span>
          </div>
        </div>
        <projectExp ref="proj" :id="id" />
        <div class="detailItem commonBorder betweenFlex">
          <div class="startFlex">
            <img :src="require('@as/zhaopin/yuyan.png')" alt="" />
            <span class="detailWord">语言能力</span>
          </div>
          <!-- <div class="commonFlex addPersonInfo">
            <span>+</span>
            <span style="margin-left: 5px">添加语言能力</span>
          </div> -->
        </div>
        <language :id="id" />
        <div class="detailItem commonBorder betweenFlex">
          <div class="startFlex">
            <img :src="require('@as/zhaopin/zige.png')" alt="" />
            <span class="detailWord">资格证书</span>
          </div>
          <div class="commonFlex addPersonInfo" @click="handlerAdd('book')">
            <span>+</span>
            <span style="margin-left: 5px">添加资格证书</span>
          </div>
        </div>
        <levelBook ref="book" :id="id" />
        <div class="detailItem commonBorder betweenFlex">
          <div class="startFlex">
            <img :src="require('@as/zhaopin/tianjia.png')" alt="" />
            <span class="detailWord">添加信息</span>
          </div>
          <!-- <div class="commonFlex addPersonInfo">
            <span>+</span>
            <span style="margin-left: 5px">添加信息</span>
          </div> -->
        </div>
        <addInfo :id="id" />
        <div class="detailItem commonBorder betweenFlex">
          <div class="startFlex">
            <img :src="require('@as/zhaopin/fujian.png')" alt="" />
            <span class="detailWord">添加附件</span>
          </div>
          <!-- <div class="commonFlex addPersonInfo">
            <span>+</span>
            <span style="margin-left: 5px">添加附件</span>
          </div> -->
        </div>
        <addFile :id="id" />
      </div>
    </div>
  </div>
</template>

<script>
import basicInfo from '@c/zhaopin/basicInfo'
import yixiang from '@c/zhaopin/yixiang'
import shixijingli from '@c/zhaopin/shixijingli'
import educationExp from '@c/zhaopin/educationExp'
import projectExp from '@c/zhaopin/projectExp'
import language from '@c/zhaopin/language'
import levelBook from '@c/zhaopin/levelBook'
import addInfo from '@c/zhaopin/addInfo'
import addFile from '@c/zhaopin/addFile'
export default {
  data() {
    return {
      category: [
        '基本信息',
        '求职意向',
        '工作经历',
        '教育经历',
        '项目经验',
        '语言能力',
        '资格证书',
        '自我评价',
        '附加信息',
        '添加附件',
      ],
      activeIndex: 0,
    }
  },
  components: {
    basicInfo,
    yixiang,
    shixijingli,
    educationExp,
    projectExp,
    language,
    levelBook,
    addInfo,
    addFile,
  },
  computed: {
    id() {
      return this.$route.query.id
    },
  },
  methods: {
    changeCat(item, index) {
      this.activeIndex = index
    },
    handlerAdd(name) {
      this.$refs[name].addItem()
    },
  },
}
</script>

<style lang="less" >
.createResume {
  .el-input__inner {
    // background: #ebf1f5;
    border-radius: 0 !important;
    height: 34px;
  }
  .catDetail {
    padding: 55px 73px 0 73px;
    width: 994px;
    .requireFlag {
      color: #e07b84;
      margin-left: 3px;
    }
    .detailWord {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      margin-left: 7px;
    }
    .detailItem {
      padding-bottom: 15px;
      // margin-bottom: 84px;
    }
    .addPersonInfo {
      width: 124px;
      height: 26px;
      border-radius: 20px;
      border: 1px solid #ededed;
      font-size: 13px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
      cursor: pointer;
      text-align: center;
    }
  }
  .categoryItem {
    font-size: 15px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    margin-bottom: 20px;
  }
  .seletedCat {
    color: #1c9ab1 !important;
  }
  .shortborder {
    height: 3px;
    background: #1c9ab1;
    margin-top: 11px;
  }
  .bkSky {
    width: 100%;
    height: 236px;
    background: lightblue;
    margin-top: -20px;
  }
  .resumeContent {
    padding-bottom: 30px;
    width: 1200px;
    // height: 1244px;
    margin: 0 auto;
    background: white;
    margin-top: -200px;
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 67px;
  }
  .leftCat {
    width: 205px;
    height: 100%;
    padding-top: 58px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #fafbfc;
  }
}
</style>