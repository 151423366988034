<template>
  <div class="hotjob">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <div class="line"></div>
        <img :src="require('@as/zhaopin/hotZhaopin.png')" alt="" />
        <span class="hotArticleWord">热门单位招聘</span>
      </div>
      <span class="moreWord">MORE</span>
    </div>
    <el-carousel height="113px" style="margin-top: 23px">
      <el-carousel-item v-for="(item, index) in cardList" :key="index">
        <el-image :src="item" alt="" style="height: 113px; width: 100%" />
      </el-carousel-item>
    </el-carousel>
    <div class="wrapChoiceArray">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="53"
        :height="45"
        :size="13"
        :distance="32"
      />
    </div>
    <ul style="margin: 10px -20px 0">
      <li
        v-for="(item, index) in list"
        :key="index"
        class="hotArticleItem commonBorder betweenFlex"
      >
        <div style="display: flex; align-items: center">
          <img :src="require('@as/reading/test.png')" alt="" class="test" />
          <div class="wrapTitleImg">
            <span class="title">{{ item.title }}</span>
            <img
              :src="require('@as/reading/personFlag1.png')"
              alt=""
              style="margin-left: 10px"
            />
          </div>
        </div>

        <div class="lookPosition commonFlex">查看职位</div>
      </li>
    </ul>
  </div>
</template>
<script>
import headerChoice from '@c/headerChoice'
export default {
  data() {
    return {
      activeName: 'second',
      activeIndex: 0,
      choiceList: ['知名律所', '知名公司', '知名媒体'],
      cardList: [
        require('@as/reading/listImg.png'),
        require('@as/reading/test.png'),
        require('@as/reading/listImg.png'),
      ],
      list: [
        {
          title: '中国进出口银',
          time: '2020.08.04',
          readCount: 3434,
        },
        {
          title: '中国进出口',
          time: '2020.08.04',
          readCount: 3434,
        },
        {
          title: '中国进出口',
          time: '2020.08.04',
          readCount: 3434,
        },
        {
          title: '中国进出口',
          time: '2020.08.04',
          readCount: 3434,
        },
        {
          title: '中国进出口银',
          time: '2020.08.04',
          readCount: 3434,
        },
      ],
    }
  },
  components: {
    headerChoice,
  },
  methods: {
    change(index) {
      this.activeIndex = index
    },
    toCompanyList() {
      this.$router.push({ name: 'jobGuide' })
    },
  },
}
</script>
<style lang="less">
.hotjob {
  padding: 20px;
  background: white;
  margin-top: 20px;
  .wrapChoiceArray {
    display: flex;
    justify-content: center;
    margin-left: 32px;
    & /deep/ .el-tabs--top {
      width: 100%;
    }
    & /deep/ .el-tabs__header {
      display: flex;
      justify-content: center;
    }
    & /deep/ .el-tabs__active-bar {
      background: #1c99b1;
    }
    & /deep/ .el-tabs__active-bar {
      padding: 0 16px;
    }
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
  .el-tabs__header {
    //   width:164px;
    margin: 0 auto;
  }
  .el-tabs--top {
    width: 136px;
    margin: 0 auto;
    margin-top: 10px;
  }
  .el-tabs__item.is-active {
    color: #1c99b1;
  }
  .el-tabs__item {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    padding: 0 10px;
  }
  .hotArticleItem {
    margin: 0 20px;
    display: flex;
    align-items: center;
    height: 63px;
    box-sizing: border-box;
    &:hover {
      background: #ecf2f6;
    }
    .wrapTitleImg {
      display: flex;
      align-items: flex-start;
    }
    .lookPosition {
      border: 1px solid #e7e7e7;
      width: 65px;
      height: 23px;
      margin-left: 70px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #babbbb;
      cursor: pointer;
    }
    .test {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .zhuantiBot {
      span {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #babbbb;
      }
    }
    .title {
      font-size: 13px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
      margin-left: 11px;
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    .moreWord {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a7a8a8;
    }
    .wraphotArticleWord {
      position: relative;
      padding-bottom: 3px;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .line {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 102px;
        height: 3px;
        background: #1c9ab1;
      }
      .hotArticleWord {
        margin-left: 9px;
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;

        color: #595757;
      }
    }
  }
}
</style>
