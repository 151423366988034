import Vue from "vue";
import App from "./App";
import router from "./router";
import Vuex from "vuex";
import store from "./store/index";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./css/reset.css";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Vuex);

// eslint-disable-next-line no-new
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});

router.beforeEach((to, from, next) => {
  var pattern = /\/serve+/;
  if (pattern.test(to.path)) {
    store.commit("header/change", { boole: 0 });
  } else if (to.name === "index") {
    store.commit("header/change", { boole: 1 });
  } else if (from.name === "serve") {
    store.commit("header/change", { boole: 1 });
  } else if (to.name === "login") {
    store.commit("footer/change", { boole: 0 });
  }
  next();
});
