<template>
  <setPop/>
</template>

<script> 
import setPop from './setPop'
export default {
  data() {
      return {
          
      }
  },
  components:{
      setPop
  }
}
</script>

<style>

</style>