<template>
  <div class="yixiangJob">
    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">期望职位</span>
        <span class="requireFlag">*</span>
      </div>
      <el-select
        v-model="expectPost"
        placeholder="请选择期望职位，如：销售经理"
        class="yxiangSelect"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">期望行业</span>
        <span class="requireFlag">*</span>
      </div>
      <el-select
        v-model="expectBusiness"
        placeholder="请选择期望行业，如：互联网/电子商务"
        class="yxiangSelect"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">工作城市</span>
        <span class="requireFlag">*</span>
      </div>
      <el-select
        v-model="workCity"
        placeholder="请选择工作城市，如：北京"
        class="yxiangSelect"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">薪资要求</span>
        <span class="requireFlag">*</span>
      </div>
      <el-select
        v-model="expectSalary"
        placeholder="请选择薪资要求，如：1万-2万"
        class="yxiangSelect"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="startFlex everyline">
      <div class="startFlex leftBasicInfo">
        <span class="detailWordInfo">工作性质</span>
        <span class="requireFlag">*</span>
      </div>
      <div>
        <el-radio v-model="workNature" label="1">全职</el-radio>
        <el-radio v-model="workNature" label="2">兼职</el-radio>
        <el-radio v-model="workNature" label="3">实习</el-radio>
      </div>
    </div>
    <botChoice @confirm="handlerConfirm" @cancel="handlerCancel" />
  </div>
</template>

<script>
import botChoice from './botChoice'
import { Resume } from '@api'
export default {
  props: ['id'],
  data() {
    return {
      expectBusiness: '',
      expectPost: '',
      expectSalary: '',
      workCity: '',
      workNature: '',
      options: [],
    }
  },
  components: {
    botChoice,
  },
  watch: {
    id: {
      handler(val) {
        if (!val) return
        Resume.getIntention({ userId: this.$store.state.uid }).then((res) => {
          Object.assign(this, { ...res })
        })
      },
      immediate: true,
    },
  },
  methods: {
    handlerConfirm() {
      const data = {
        expectPost: this.expectPost,
        expectBusiness: this.expectBusiness,
        workCity: this.workCity,
        expectSalary: this.expectSalary,
        workNature: this.workNature,
      }
      for (let i in data) {
        if (!this.isValid(i, data[i])) return
      }
      if (this.id) data.id = id
      data.userId = this.$store.state.uid
      Resume.changeIntention(data)
    },
    handlerCancel() {},
    isValid(keys, val) {
      const keysDir = {
        expectPost: '期望职位',
        expectBusiness: '期望行业',
        workCity: '工作城市',
        expectSalary: '薪资要求',
        workNature: '工作性质',
      }
      if (!val) {
        this.$message.error(`${keysDir[keys]}不能为空`)
        return false
      }
      return true
    },
  },
}
</script>

<style lang="less">
.yixiangJob {
  padding-left: 20px;
  padding-bottom: 40px;
  .cancel {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    margin-left: 50px;
  }
  .saveUpdate {
    width: 94px;
    height: 31px;
    border-radius: 32px;
    background: #1c9ab1;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .leftBasicInfo {
    width: 105px;
  }
  .yxiangSelect {
    width: 720px;
    height: 34px;
  }
  .yxiangSelect::-webkit-input-placeholder {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
  }
  .detailWordInfo {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .everyline {
    margin-top: 27px;
  }
}
</style>