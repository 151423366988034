<template>
  <div class="hotQuesChild">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <img :src="require('@as/reading/hotQues.png')" alt="" />
        <span class="hotArticleWord">热门问题</span>
      </div>
      <span class="moreWord">MORE</span>
    </div>
    <ul>
      <li
        v-for="(item, index) in list"
        :key="index"
        class="hotArticleItem commonBorder"
      >
        <div class="startFlex wrapDianWord">
          <span class="yuandian"></span>
          <div class="hotArticleItemTitle">{{ item.title }}</div>
        </div>
        <div class="betweenFlex botNews">
          <div>{{ item.huidaCount }}回答</div>
          <div>{{ item.yueduCount }}阅读</div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          title:
            '中国进出口银行湖南省分行五凌电力孟加拉风电法律服务项目-招标公告',
          huidaCount: 123,
          yueduCount: 811,
        },
        {
          title:
            '中国进出口银行湖南省分行五凌电力孟加拉风电法律服务项目-招标公告',
          huidaCount: 123,
          yueduCount: 811,
        },
        {
          title:
            '中国进出口银行湖南省分行五凌电力孟加拉风电法律服务项目-招标公告',
          huidaCount: 123,
          yueduCount: 811,
        },
        {
          title:
            '中国进出口银行湖南省分行五凌电力孟加拉风电法律服务项目-招标公告',
          huidaCount: 123,
          yueduCount: 811,
        },
        {
          title:
            '中国进出口银行湖南省分行五凌电力孟加拉风电法律服务项目-招标公告',
          huidaCount: 123,
          yueduCount: 811,
        },
        {
          title:
            '中国进出口银行湖南省分行五凌电力孟加拉风电法律服务项目-招标公告',
          huidaCount: 123,
          yueduCount: 811,
        },
      ],
    }
  },
  methods: {},
}
</script>
<style lang="less">
.hotQuesChild {
  padding: 20px;
  padding-bottom: 0px;
  background: white;
  .yuandian {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #089acb;
    display: inline-block;
    // margin-right: 5px;
    margin-top: 7px;
  }
  .wrapDianWord {
    align-items: flex-start;
    justify-content: space-between;
  }
  //   margin-top: 20px;
  width: 330px;
  .hasBack {
    background: lightgray;
  }
  .hotArticleItem {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    line-height: 18px;
    padding: 18px 0;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    .botNews {
      margin-top: 20px;
      padding-left: 15px;
      div {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #a6a7a7;
      }
    }
    .hasGuanzhu {
      width: 51px;
      height: 23px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      border: 1px solid #1c9ab1;
    }
    .noGuanzhu {
      width: 51px;
      height: 23px;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #babbbb;
      border: 1px solid #babbbb;
    }
    .follow {
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    .authorImg {
      width: 37px;
      height: 37px;
      border-radius: 50%;
    }
    &:last-child {
      border-bottom: none !important;
    }
    .hotArticleItemTitle {
      width: 275px;
      font-size: 13px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    .moreWord {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a7a8a8;
    }
    .wraphotArticleWord {
      border-bottom: 3px solid #1c9ab1;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .hotArticleWord {
        margin-left: 9px;

        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
      }
    }
  }
}
</style>