<template>
  <div class="contentWidth jobGuide">
    <div class="wrapTopFabu">
      <div class="firstGuide">
        无论您需要招聘一个还是一百个职位，您的下一位员工就在这里
      </div>
      <div class="secondGuide">海量专业法律人，随时随地，等你来聘</div>
      <div class="lijifabu commonFlex" @click="toFabu">立即发布职位</div>
      <div class="torenzheng">还没有认证hui律官方帐号? 免费去认证>></div>
    </div>
    <div>
      <div class="whyUseHui">为什么使用HUI律网招聘</div>
      <ul class="betweenFlex wrapLIstItem commonBorder">
        <li
          v-for="(item, index) in zhaopinEXcuse"
          :key="index"
          class="zhaopinItem commonFlex"
        >
          <div class="commonFlex wrapEXcuseImg">
            <img :src="item.imgUrl" alt="" />
          </div>

          <div class="excuse">{{ item.title }}</div>
          <div class="longExcuse">{{ item.desc }}</div>
        </li>
      </ul>
    </div>
    <div>
      <div class="whyUseHui">他们正在HUI律网招聘职位</div>
      <ul class="wrapCompanyItem commonBorder">
        <li
          v-for="(item, index) in companyList"
          :key="index"
          class="companyItem commonFlex"
          :class="{ noDistance: (index + 1) % 4 == 0 }"
        >
          <img :src="item.imgUrl" alt="" style="width: 130px; height: 46px" />
        </li>
      </ul>
    </div>
    <div>
      <div class="whyUseHui">轻松招聘，从今天开始</div>
      <ul class="betweenFlex wrapLIstItem commonBorder">
        <li
          v-for="(item, index) in zhaopinStep"
          :key="index"
          class="zhaopinItem commonFlex"
        >
          <div class="commonFlex wrapEXcuseImg">
            <img :src="item.imgUrl" alt="" />
          </div>
          <div class="lianjie" v-if="index !== 3"></div>
          <div class="excuse">{{ item.title }}</div>
          <div class="longExcuse" style="width: 130px">{{ item.desc }}</div>
          <div class="step commonFlex">STEP{{ index + 1 }}</div>
        </li>
      </ul>
    </div>

    <div class="commonFlex freeZhaopin">
      <img :src="require('@as/zhaopin/freeZhaopin.png')" alt="" />
      <div class="wrapRightFree">
        <div class="freeWord">免费委托发布招聘</div>
        <div class="freeContact">
          对于认证遇到困难，或者急需招聘的企业管理人员，在完成个人认证后，hui律将免费为您代发招聘需求，并及时将代收的简历发送给您。<br /><br />

          详情请联系baiyueliang@huilvnet.com
        </div>
      </div>
    </div>
    <div class="lookRencai commonFlex">
      <img :src="require('@as/zhaopin/botBack.png')" alt="" class="rencaiImg" />
      <div class="rencaiWord">寻找最合适的人才</div>
      <div class="fabujob commonFlex" @click="toFabu">立即发布职位</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zhaopinEXcuse: [
        {
          imgUrl: require('@as/zhaopin/youxuan.png'),
          title: '专业法律人，更优的人选',
          desc: '以最快速度 发现最合适人选',
        },
        {
          imgUrl: require('@as/zhaopin/zuanshi.png'),
          title: '专业门户，立体招聘',
          desc: '企业一站式信息展示体系 吸引更多优秀求职者',
        },
        {
          imgUrl: require('@as/zhaopin/yunjisuan.png'),
          title: '智能算法，优化系统',
          desc: '提高人才优选 节省HR时间',
        },
        {
          imgUrl: require('@as/zhaopin/feichangkaui.png'),
          title: '免费发布，仅需3分钟',
          desc: '节省支出 招聘事半功倍',
        },
      ],
      companyList: [
        { imgUrl: require('@as/reading/test.png') },
        { imgUrl: require('@as/reading/test.png') },
        { imgUrl: require('@as/reading/test.png') },
        { imgUrl: require('@as/reading/test.png') },
        { imgUrl: require('@as/reading/test.png') },
        { imgUrl: require('@as/reading/test.png') },
      ],
      zhaopinStep: [
        {
          imgUrl: require('@as/zhaopin/renzhengCom.png'),
          title: '认证企业账号',
          desc: '专属认证标志 求职者的信任',
        },
        {
          imgUrl: require('@as/zhaopin/shenhe.png'),
          title: '审核通过',
          desc: '快速响应 1个工作日完成',
        },
        {
          imgUrl: require('@as/zhaopin/createJob.png'),
          title: '创建职位',
          desc: '输入职位描述轻松发布',
        },
        {
          imgUrl: require('@as/zhaopin/rencai.png'),
          title: '找到最合适人才',
          desc: '收取简历，亦可从海量简历库中挑选',
        },
      ],
    }
  },
  methods: {
    toFabu() {
      this.$router.push('/publishJob')
    },
  },
}
</script>


<style lang="less" >
.jobGuide {
  background: white;
  .lookRencai {
    position: relative;
    margin-top: 58px;
    margin-bottom: 47px;
    height: 160px;
    flex-direction: column;
    .rencaiWord {
      font-size: 20px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      z-index: 20;
    }
    .fabujob {
      width: 120px;
      z-index: 20;
      height: 32px;
      border: 1px solid #ffffff;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #eeffff;
      margin-top: 26px;
    }
    .rencaiImg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .wrapRightFree {
    margin-left: 51px;
    .freeContact {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #a7a8a8;
      margin-top: 31px;
      width: 458px;
    }
    .freeWord {
      font-size: 26px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
  }
  .freeZhaopin {
    margin-top: 60px;
    margin-bottom: 58px;
  }
  .whyUseHui {
    text-align: center;
    margin-top: 60px;

    font-size: 26px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .lianjie {
    width: 123px;
    height: 1px;
    background: #dedede;
    position: absolute;
    left: 172px;
    top: 35px;
  }
  .wrapCompanyItem {
    display: flex;
    flex-wrap: wrap;
    padding: 54px;
    .companyItem {
      width: 250px;
      height: 90px;
      border: 1px solid #dedede;
      margin-right: 20px;
      margin-top: 20px;
    }
  }
  .wrapLIstItem {
    padding: 74px 72px;
    position: relative;
    .wrapEXcuseImg {
      width: 87px;
      height: 86px;
      border-radius: 50%;
      border: 1px solid #dddddd;
    }
    .zhaopinItem {
      width: 176px;
      flex-direction: column;
      position: relative;
      .excuse {
        margin-top: 27px;

        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      .step {
        width: 65px;
        height: 19px;
        border-radius: 10px;
        margin-top: 20px;
        font-size: 15px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
        background: #f08d1d;
      }
      .longExcuse {
        margin-top: 10px;
        max-width: 132px;
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #a7a8a8;
        line-height: 18px;
      }
    }
  }
  .wrapTopFabu {
    text-align: center;
    background: lightblue;
    height: 501px;
    .firstGuide {
      padding-top: 138px;
      font-size: 32px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
    }
    .secondGuide {
      font-size: 20px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      margin-top: 32px;
    }
    .lijifabu {
      margin: 60px auto 0;

      width: 178px;
      height: 46px;
      cursor: pointer;
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #eeffff;
      background: #f08d1d;
    }
    .torenzheng {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
</style>