<template>
  <div class="mainManagement">
    <img class="bg-img" :src="userInfo.homepageUrl" alt="" />
    <div
      class="bg"
      :style="`background: ${userInfo.color || '#3DAED3'};`"
    ></div>
    <div class="top commonFlex">
      <!-- <el-popover placement="bottom" width="400" trigger="click">
        <div class="shareOr">
          <div class="commonFlex content">分享</div>
          <div class="commonFlex content">举报</div>
        </div> -->
      <div class="showBelowGuide commonFlex" slot="reference">
        <img
          :src="require('@as/personal/topDown.png')"
          alt=""
          @click="showPop = !showPop"
          style="cursor: pointer"
        />
        <pop :list="list" @click-btn="handlerClickPopBtn" v-if="showPop" />
      </div>
      <!-- </el-popover> -->
      <img
        :src="userInfo.photoUrl || require('@as/reading/test.png')"
        alt=""
        class="personImg"
      />
      <div class="name">{{ userInfo.nick || '克里斯' }}</div>
      <div class="guanfang" v-if="userInfo.isVerify === 2">官方认证</div>
      <div style="margin-top: 15px" class="commonFlex">
        <div class="guanzhu commonFlex">关注</div>
        <div class="sixin commonFlex">私信</div>
      </div>
    </div>
    <div
      class="onlyFlex contentWidth wrapBothSide"
      style="margin-top: 20px; margin-bottom: 30px"
    >
      <div class="left">
        <topNav />
        <router-view></router-view>
      </div>
      <div class="right" style="margin-left: 20px">
        <mainData />
        <personalIntro />
        <achievement />
      </div>
    </div>
    <setPop :dialogVisible.sync="dialogVisible" @change="getUserInfo" />
  </div>
</template>

<script>
import mainData from '@c/mainManage/mainData'
import personalIntro from '@c/mainManage/personalIntro'
import achievement from '@c/mainManage/achievement'
import topNav from '@c/mainManage/topNav'
import pop from '@c/personal/pop'
import setPop from '@c/personal/setPop'
import { User } from '@api'
export default {
  data() {
    return {
      showPop: false,
      list: ['装饰主页', '分享'],
      dialogVisible: false,
    }
  },
  components: {
    mainData,
    personalIntro,
    achievement,
    topNav,
    pop,
    setPop,
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    },
  },
  methods: {
    handlerClickPopBtn(name) {
      const methodsDir = {
        装饰主页: () => (this.dialogVisible = true),
      }
      methodsDir[name]()
    },
    getUserInfo() {
      User.getUserInfo({
        userId: this.$store.state.uid,
      }).then((res) => {
        this.$store.commit('user/setUserInfo', res)
      })
    },
  },
}
</script>
<style lang="less" >
.mainManagement {
  position: relative;
  .shareOr {
    div {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
      line-height: 40px;
      margin-top: 15px;
    }
  }
  .content {
    height: 30px;
  }
  .el-popover {
    width: 246px !important;
    width: 100px;
    height: 80px;
    background: #ffffff;
    border-radius: 6px;
  }
  .showBelowGuide {
    width: 40px;
    height: 20px;
    background: #85c8e1;
    position: relative;
    border-radius: 10px;
    margin-left: 1095px;
    // position: absolute;
    // top: 45px;
    // right: 286px;
  }
  .wrapBothSide {
    align-items: flex-start;
  }
  .left {
    width: 850px;
    // height: 960px;
    background: #ffffff;
    margin-bottom: 50px;
  }
  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 300px;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 300px;
    opacity: 0.6;
  }
  .top {
    position: relative;
    width: 100%;
    height: 300px;
    margin-top: -20px;
    flex-direction: column;
    position: relative;
    .guanzhu {
      width: 84px;
      height: 30px;
      background: #ffffff;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #0899ca;
    }
    .sixin {
      cursor: pointer;
      width: 84px;
      height: 30px;
      border: 1px solid #ffffff;
      margin-left: 20px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
    }
    .personImg {
      width: 100px;
      height: 100px;
      background: #9fded5;
      border: 2px solid #ffffff;
      border-radius: 50%;
    }
    .name {
      font-size: 24px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      margin-top: 20px;
    }
    .guanfang {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      margin-top: 10px;
    }
  }
}
</style>