import req from "@/utils/request";
import { Message } from "element-ui";

// 验证码相关
const code = {
  // 注册发送手机验证码
  getSmsCode: (data) =>
    req.get("/common/code/sms", data).then(
      (res) => Promise.resolve(res),
      () => {
        Message.error("验证码发送失败");
        return Promise.reject();
      }
    ),

  // 验证注册手机验证码
  isSmsCodeVaild: (data) =>
    req.get("/common/code/sms/valid", data).then(
      (res) => Promise.resolve(res),
      () => {
        Message.error("验证码输入错误，请重试");
        return Promise.reject();
      }
    ),

  // 注册获取图形验证码
  getImgCode: () =>
    req.get("/common/code/verify").then(
      (res) => Promise.resolve(res),
      () => {
        Message.error("图形验证码获取失败");
        return Promise.reject();
      }
    ),

  // 注册验证图形验证码
  isImgCodeVaild: (data) =>
    req.get("/common/code/verify/valid", data).then(
      (res) => Promise.resolve(res),
      () => {
        Message.error("验证码输入错误，请重试");
        return Promise.reject();
      }
    ),
};

export default {
  ...code,

  // 获取省
  getProvince: () => req.get("/common/province"),

  // 获取市
  getCity: (data) => req.get("/common/city", data),

  // 获取区
  getArea: (data) => req.get("/common/area", data),

  // 图片上传
  uploadImg: (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    return req.put("/common/oss/upload", formData).then(
      (res) => Promise.resolve(res),
      () => {
        Message.error("文件上传失败");
        return Promise.reject();
      }
    );
  },
};
