<template>
  <div class="topNav onlyFlex commonBorder">
    <div v-for="(item, index) in navList" :key="index" class="">
      <router-link :to="item.path" class="navItem commonFlex">{{
        item.name
      }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        { name: "黄页", path: "/mainIndex" },
        { name: "专栏", path: "/mainIndex/mainZhuanlan" },
        { name: "活动", path: "/mainIndex/mainHuodong" },
        { name: "招聘", path: "/mainIndex/mainZhaopin" },
        { name: "问答", path: "/mainIndex/mainWenda" },
        { name: "关注", path: "/mainIndex/mainGuanzhu" },
      ],
    };
  },
};
</script>

<style lang="less">
.topNav {
  // width:550px;
  height: 49px;
  .router-link-exact-active {
    border-bottom: 2px solid #0899ca!important;
    color: #1c9ab1;
  }
  .navItem {
    width: 64px;
    height: 49px;
    margin-right: 40px;
    border-bottom: 2px solid transparent;
  }
}
</style>