<template>
  <div class="mainHuodong">
    <div
      v-for="(item, index) in huodongList"
      :key="index"
      class="huodongItem commonBorder"
    >
      <div class="betweenFlex">
        <div class="onlyFlex">
          <img :src="require('@as/personal/参与.png')" alt="" />
          <span class="canyu">我参与的</span>
        </div>
        <div class="time">2012-09-09</div>
      </div>
      <div class="onlyFlex" style="margin-top: 17px">
        <img :src="require('@as/reading/test.png')" alt="" class="huodongImg" />
        <div style="margin-left: 15px; padding-top: 8px">
          <div class="title">{{ item.title }}</div>
          <div class="betweenFlex" style="margin-top: 30px; width: 610px">
            <div class="commonFlex">
              <img :src="require('@as/personal/mainmap.png')" alt="" />
              <span class="time">地点：北京</span>
              <img
                :src="require('@as/personal/rilimain.png')"
                alt=""
                style="margin-left: 30px"
              />
              <span class="time">时间：2020.08.12</span>
            </div>
            <div class="kaishi commonFlex">即将开始</div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapPage">
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      huodongList: [
        {
          title: '瞧瞧瞧瞧瞧瞧瞧瞧',
        },
        {
          title: '瞧瞧瞧瞧瞧瞧瞧瞧',
        },
        {
          title: '瞧瞧瞧瞧瞧瞧瞧瞧',
        },
      ],
    }
  },
}
</script>

<style lang="less">
.mainHuodong {
  padding: 0 20px;
  .wrapPage {
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .kaishi {
    width: 79px;
    height: 26px;
    background: #0899ca;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .title {
    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .canyu {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
    margin-left: 4px;
  }
  .huodongItem {
    padding: 20px 0;
  }
  .huodongImg {
    width: 180px;
    height: 87px;
  }
  .time {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #808080;
    margin-left: 4px;
  }
}
</style>