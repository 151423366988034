<template>
  <div class="mainHunagye">
    <div class="wrapEdit">
      <div class="commonFlex canClick">
        <img :src="require('@as/personal/编辑.png')" alt="" />
        <span class="edit">编辑</span>
      </div>
    </div>
    <div style="margin-top: -20px">
      <div class="mainTitle">去悄悄去悄悄瞧瞧瞧瞧瞧瞧瞧瞧</div>
      <div class="content">
        君合律师事务所于1989年创立于北京，是中国最早的合伙制律师事务所之一。发展至今，君合已在海内外拥有十三个办公室和一支由超过240位合伙人和顾问、640多位受雇律师和法律翻译组成的逾880人的专业团队，是国际公认的、最优秀的中国大型综合律师事务所之一。
      </div>
    </div>
  </div>
</template>

<script>
import { User } from '@api/index'
export default {
  data() {
    return {}
  },
  mounted() {
    User.getYellowPages({
      userId: this.$store.state.uid,
    }).then((res) => {})
  },
}
</script>

<style lang="less">
.mainHunagye {
  padding: 0 20px;
  height: 300px;
  .mainTitle {
    font-size: 16px;
    color: #333333;
    margin: 20px 0;
  }
  .content {
    font-size: 16px;
    color: #666666;

    line-height: 24px;
  }
  .edit {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    margin-left: 3px;
  }
  .wrapEdit {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
