<template>
  <div class="indexHeader">
    <div class="indexHeaderMid">
      <img
        class="head_logo"
        :src="require('@as/index/logo.png')"
        @click="toHome()"
      />
      <el-image
        class="serve_badge"
        :src="require('@as/index/serve_badge.png')"
      ></el-image>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">频道</el-menu-item>
        <el-menu-item index="2">阅读</el-menu-item>
        <el-menu-item index="3">活动</el-menu-item>
        <el-menu-item index="4">招聘</el-menu-item>
        <el-menu-item index="5">黄页</el-menu-item>
        <el-menu-item index="6">服务</el-menu-item>
      </el-menu>
      <div class="input_container">
        <el-input
          placeholder="找文章/找活动/找招聘/找法律大牛"
          v-model="input4"
          @focus="toSearch()"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="input_float">
          <div class="title">搜索热词</div>
          <div class="float_main">
            <div class="item" v-for="item in 10" v-bind:key="item">
              法律咨询
            </div>
          </div>
        </div>
      </div>

      <div class="head_img" v-if="isLogin">
        <el-image
          class="img"
          :src="userInfo.photoUrl || require('@as/index/logo.png')"
        ></el-image>
        <div class="menu_float">
          <div class="title">{{ userInfo.nick || '用户名称' }}</div>
          <div class="row" @click="router('/mainIndex/')">
            <el-image
              class="row_img"
              :src="require('@as/index/icon_8.png')"
            ></el-image>
            <div>我的主页</div>
          </div>
          <div class="row" @click="router('/personal/zhuanlanManage')">
            <el-image
              class="row_img"
              :src="require('@as/index/icon_9.png')"
            ></el-image>
            <div>用户中心</div>
          </div>
          <div class="row" @click="router('/personal/zhuanlanManage')">
            <el-image
              class="row_img"
              :src="require('@as/index/icon_10.png')"
            ></el-image>
            <div>我的专栏</div>
          </div>
          <div class="row" @click="router('/personal/fabuManage')">
            <el-image
              class="row_img"
              :src="require('@as/index/icon_11.png')"
            ></el-image>
            <div>我的活动</div>
          </div>
          <div class="row" @click="router('/personal/myResumeManage')">
            <el-image
              class="row_img"
              :src="require('@as/index/icon_12.png')"
            ></el-image>
            <div>我的招聘</div>
          </div>
          <div class="row" @click="router('/personal/messageManage')">
            <el-image
              class="row_img"
              :src="require('@as/index/icon_13.png')"
            ></el-image>
            <div>我的消息</div>
          </div>
          <div class="row" @click="router('/personal/orderManage')">
            <el-image
              class="row_img"
              :src="require('@as/index/icon_14.png')"
            ></el-image>
            <div>我的订单</div>
          </div>
          <div class="row" @click="router('/personal/zhanghaoSet')">
            <el-image
              class="row_img"
              :src="require('@as/index/icon_15.png')"
            ></el-image>
            <div>账号设置</div>
          </div>
          <div class="row" @click="handlerQuit">
            <el-image
              class="row_img"
              :src="require('@as/index/icon_16.png')"
            ></el-image>
            <div>退出</div>
          </div>
        </div>
      </div>
      <div class="login" v-else>
        <div @click="() => this.$router.push({ name: 'login' })">登陆</div>
        <div>|</div>
        <div @click="() => this.$router.push({ name: 'register' })">注册</div>
      </div>
    </div>
  </div>
</template>
<script>
import { User } from '@api/index'
export default {
  data() {
    return {
      activeIndex: '1',
      input4: '',
      menuShow: false,
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.user.isLogin
    },
    userInfo() {
      return this.$store.state.user.userInfo
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      const pathDir = {
        1: 'index',
        2: 'reading',
        3: 'event',
        4: 'zhaopinMain',
        5: 'huangye',
        6: 'serve',
      }
      this.$router.push({
        name: pathDir[key],
      })
    },
    toHome() {
      this.$router.push({ name: 'index' })
    },
    toSearch() {
      this.$router.push({ name: 'search' })
    },
    toMainIndex() {
      this.$router.push({ name: 'mainIndex' })
    },
    router(str) {
      console.log(str)
      this.$router.push(str)
      //this.$router.push({name:str})
    },
    handlerQuit() {
      User.logout().then(() => {
        this.$store.commit('user/setUserInfo', {})
        this.$store.commit('user/setIsLogin', false)
        sessionStorage.removeItem('token')
        this.$router.replace('/')
      })
    },
  },
}
</script>
<style lang="less">
.indexHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  height: 70px;
  background: linear-gradient(90deg, #51999d, #4892b1);
  .indexHeaderMid {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .head_logo {
      flex-shrink: 0;
      width: 103px;
      height: 37px;
    }
    .serve_badge {
      position: absolute;
      top: 10px;
      left: 555px;
      width: 24px;
      height: 12px;
    }
    & /deep/ .el-menu {
      margin-left: 64px;
      flex-shrink: 0;
      background: transparent;
    }
    & /deep/ .el-menu-item {
      color: #fff;
    }
    & /deep/ .el-menu.el-menu--horizontal {
      border-color: transparent;
    }
    & /deep/ .el-menu--horizontal > .el-menu-item.is-active {
      border-color: transparent;
      background: transparent;
      color: white !important;
    }
    & /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
      background: transparent;
      color: white !important;
    }
    .input_container {
      margin-left: 230px;
      position: relative;
      .input_float {
        display: none;
        position: absolute;
        top: 40px;
        left: 0;
        padding: 18px 15px;
        width: 100%;
        background: #fff;
        border-radius: 0 0 3px 3px;
        z-index: 4;
        .title {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #999999;
        }
        .float_main {
          margin-top: 14px;
          display: flex;
          flex-wrap: wrap;
          .item {
            padding: 12px 22px;
            width: 50%;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #666666;
            &:hover {
              background: #eefcff;
            }
          }
        }
      }
      & /deep/ .el-input {
        width: 280px;
        height: 34px;
        color: #fff;

        box-sizing: border-box;
      }
      & /deep/ .el-input__inner {
        color: #fff;
        border-color: transparent;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 3px;
      }
      & /deep/ .el-input__icon {
        color: #dedede;
      }
      /* 修改input placeholder文字颜色 */
      & /deep/ .el-input__inner::-webkit-input-placeholder {
        color: #dedede;
      }
      & /deep/ .el-input__inner:-moz-placeholder {
        color: #dedede;
      }
      & /deep/ .el-input__inner:-ms-input-placeholder {
        color: #dedede;
      }
    }

    .login {
      flex-shrink: 0;
      margin-left: 26px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      div:first-child {
        margin-right: 6px;
      }
      div:last-child {
        margin-left: 6px;
      }
    }
    .head_img {
      position: relative;
      margin-left: 26px;
      padding: 13.5px 20px;
      background: #4189a8;
      &:hover {
        .menu_float {
          display: block;
        }
      }
      .img {
        width: 40px;
        height: 40px;
        background: #1c9ab1;
        border: 1px solid #ffffff;
        border-radius: 50%;
      }
      .menu_float {
        display: none;
        position: absolute;
        top: 70px;
        right: 0;
        width: 140px;
        background: #fff;
        box-shadow: 7px 7px 15px 0px rgba(1, 31, 37, 0.15);
        border-radius: 0px 0px 4px 4px;
        z-index: 4;
        .title {
          padding: 18px;
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 500;
          color: #333333;
          border-bottom: 1px solid #dedede;
        }
        .row {
          padding: 12px 15px;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
          &:last-child {
            padding: 18px 15px;
            border-top: 1px solid #dedede;
          }
          &:hover {
            background: #e8f5f7;
          }
          .row_img {
            margin-right: 12px;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
</style>
