<template>
  <div class="wrapEditor">
    <quill-editor
      style="height: 900px; width: 1140px"
      v-model="content"
      ref="myQuillEditor"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @change="onEditorChange($event)"
    >
    </quill-editor>
    <div class="botCreateBtn">
      <div class="commonFlex canClick">确认创建</div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
export default {
  data() {
    return {
      content: "",
      editorOption: {
        placeholder: "请在这里输入",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            ["blockquote", "code-block"], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ script: "sub" }, { script: "super" }], // 上下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], //对齐方式
            ["clean"], //清除字体样式
            ["image", "video"], //上传图片、上传视频
          ],
        },
      },
    };
  },
  components: {
    quillEditor,
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  methods: {
    onEditorReady(editor) {
      // 准备编辑器
    },
    onEditorBlur() {}, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    onEditorChange() {}, // 内容改变事件
  },
};
</script>

<style lang="less" >
.wrapEditor {
  background: white;
  margin: 0 auto;
  width: 1200px;
  height: 1010px;
  margin-top:-20px;
  padding:20px 30px;
  & /deep/ .ql-toolbar.ql-snow{
    border-left: none;
    border-right: none;
  }
  .ql-container.ql-snow {
    border: none;
  }
  .botCreateBtn {
    border-top: 1px solid #dedede;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    div {
      width: 420px;
      margin-top: 34px;
      height: 40px;
      background: #1c9ab1;

      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>