<template>
  <div class="projectExp">
    <div v-for="(item, index) in proList" :key="index">
      <div class="onlyFlex firstLine">
        <div class="name">项目名称</div>
        <div class="betweenFlex secondLine">
          <input
            type="text"
            class="projectName firstInput"
            v-model="item.projectName"
          />
          <div class="name" style="margin: 0 25px">项目角色</div>
          <input
            type="text"
            class="projectActor firstInput"
            v-model="item.projectRole"
          />
        </div>
      </div>
      <div class="onlyFlex firstLine">
        <div class="name">项目链接</div>
        <input
          type="text"
          class="projectName secondInput"
          v-model="item.projectLink"
        />
      </div>
      <div class="onlyFlex firstLine">
        <div class="name">项目开始时间</div>
        <div class="betweenFlex secondLine">
          <el-date-picker
            v-model="item.startTime"
            type="date"
            placeholder="开始时间"
            class="ruzhiTime"
            style="width: 280px"
          >
          </el-date-picker>
          <div class="name">至</div>
          <el-date-picker
            v-model="item.entTime"
            type="date"
            placeholder="结束时间"
            class="ruzhiTime"
            style="width: 280px"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="onlyFlex firstLine">
        <div class="name">项目描述</div>
        <textarea class="projectDesp" v-model="item.projectDesc"></textarea>
      </div>
      <div class="onlyFlex firstLine">
        <div class="name">项目业绩</div>
        <textarea class="projectDesp" v-model="item.projectResult"></textarea>
      </div>
      <div class="firstLine">
        <botChoice
          :isLast="index === proList.length - 1"
          @confirm="handlerConfirm"
          @cancel="handlerCancel(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import botChoice from './botChoice'
export default {
  props: ['id'],
  data() {
    return {
      proList: [
        {
          projectName: '',
          projectRole: '',
          projectLink: '',
          startTime: '',
          entTime: '',
          projectDesc: '',
          projectResult: '',
        },
      ],
    }
  },
  components: {
    botChoice,
  },
  watch: {
    id: {
      handler(val) {
        if (!val) return
        Resume.getProject({ userId: this.$store.state.uid }).then((res) => {
          this.proList = res
        })
      },
      immediate: true,
    },
  },
  methods: {
    handlerConfirm() {
      const data = this.proList.map((item) => {
        const obj = Object.assign({}, item)
        obj.userId = this.$store.state.uid
        if (this.id) obj.id = this.id
        return obj
      })
      for (let i in data) {
        for (let j in data[i]) {
          if (!this.isValid(j, data[i][j])) return
        }
      }
      Resume.changeProject(data)
    },
    handlerCancel(idx) {
      if (this.proList.length > 1) {
        this.proList.splice(idx, 1)
      }
    },
    isValid(keys, val) {
      const keysDir = {
        projectName: '项目名称',
        projectRole: '项目角色',
        projectLink: '项目链接',
        startTime: '项目开始时间',
        endTime: '项目结束时间',
        projectDesc: '项目描述',
        projectResult: '项目业绩',
      }
      if (keysDir[keys] && !val) {
        this.$message.error(`${keysDir[keys]}不能为空`)
        return false
      }
      return true
    },
    addItem() {
      this.proList.push({
        projectName: '',
        projectRole: '',
        projectLink: '',
        startTime: '',
        entTime: '',
        projectDesc: '',
        projectResult: '',
      })
    },
  },
}
</script>

<style lang="less">
.projectExp {
  padding-left: 20px;
  padding-bottom: 60px;
  .lastLine {
    margin-bottom: 30px;
  }
  .cancel {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    margin-left: 50px;
  }
  .saveUpdate {
    width: 94px;
    height: 31px;
    border-radius: 32px;
    background: #1c9ab1;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .leftBasicInfo {
    width: 115px;
  }
  .secondInput {
    width: 722px;
    height: 34px;
    border: 1px solid #eeeeee;
  }
  .projectDesp {
    width: 723px;
    height: 82px;
    border: 1px solid #eeeeee;
  }
  .firstLine {
    justify-content: space-between;
    margin-top: 25px;
  }
  .secondLine {
    width: 723px;
  }
  .firstInput {
    width: 280px;
    height: 34px;
    border: 1px solid #eeeeee;
  }
  .name {
    height: 34px;
    line-height: 34px;
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .ruzhiTime {
    width: 354px;
    height: 34px;
    .el-input__prefix {
      top: -3px;
    }
  }
}
</style>