<template>
  <div class="indexFoot">
    <div class="main">
      <div class="unit">
        <el-image
          class="unit_icon"
          :src="require('@as/index/footer_icon.png')"
        ></el-image>
        <div class="slogan">中国一站式专业法律人门户网站</div>
        <div class="text">慧律 · 会律 · 惠律 · 汇律</div>
      </div>
      <div class="section" @click="toAbout()">
        <div class="title">关于我们</div>
        <div class="item">关于我们</div>
        <div class="item">加入我们</div>
        <div class="item">商业合作</div>
        <div class="item">友情链接</div>
        <div class="item">隐私条款</div>
        <div class="item">用户协议</div>
        <div class="item">免责声明</div>
      </div>
      <div class="section">
        <div class="title">开通HUI律号</div>
        <div class="item">个人入驻</div>
        <div class="item">企业入驻</div>
        <div class="section">
          <div class="title">市场及媒体合作</div>
          <div class="item">白先生 15001223819</div>
          <div class="item">baiyueliang@huilvnet.com</div>
        </div>
      </div>
      <div class="section">
        <div class="title">HUI律网微信</div>
        <el-image
          class="unit_icon"
          :src="require('@as/index/footer_code.png')"
        ></el-image>
      </div>
    </div>
    <div class="copyright">
      COPYRIGHT HUI律网 京ICP备120134XX号-X 京公网安备 11010502037XXX号
      <a target="_blank" href="https://www.etycx.com/">网站建设：时代创信 </a>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    toAbout() {
      this.$router.push('about')
    },
  },
}
</script>
<style lang="less" scoped>
.indexFoot {
  background: #373d42;
  .main {
    margin: 0 auto;
    padding: 35px 0 45px;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    .unit {
      .unit_icon {
        width: 131px;
        height: 34px;
        display: block;
      }
      .slogan {
        margin-top: 20px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #808080;
      }
      .text {
        margin-top: 36px;
        font-size: 15px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .section {
      &:last-child {
        margin-right: 73px;
      }
      .section {
        margin-top: 35px;
      }
      .title {
        margin-bottom: 19px;
        font-size: 15px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
      }
      .item {
        margin-bottom: 10px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #808080;
        cursor: pointer;
        &:hover {
          color: #b1b1b1;
        }
      }
      .unit_icon {
        width: 82px;
        height: 82px;
      }
    }
  }
  .copyright {
    padding: 20px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #808080;
    text-align: center;
    background: #2d3235;
    a {
      color: #808080;
      cursor: pointer;
    }
  }
}
</style>