<template>
  <div class="startFlex everyline">
    <div class="startFlex leftBasicInfo"></div>
    <div class="startFlex">
      <div
        class="saveUpdate commonFlex"
        @click="() => this.$emit('confirm')"
        v-if="isLast"
      >
        保存并更新
      </div>
      <div class="cancel" @click="() => this.$emit('cancel')">取消</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLast: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style>
.cancel {
  font-size: 13px;
  font-family: PingFangSC;
  font-weight: 400;
  color: #595757;
  cursor: pointer;
}
.saveUpdate {
  width: 94px;
  height: 31px;
  margin-right: 50px;
  border-radius: 32px;
  background: #1c9ab1;
  cursor: pointer;
  font-size: 12px;
  font-family: PingFangSC;
  font-weight: 400;
  color: #ffffff;
}
</style>