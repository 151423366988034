<template>
  <div class="messageManage">
    <div class="wrapChoiceItem commonBorder">
      <div
        v-for="(item, index) in choice"
        :key="index"
        class="choiceItem commonFlex"
        :style="{
          width: width + 'px',
          height: height + 'px',
          fontSize: size + 'px',
          marginRight: distance + 'px',
        }"
        :class="{ selected: activeIndex == index }"
        @click="changeIndex(index)"
      >
        {{ item.name }}
        <span v-if="item.count > 0" class="msgCount">{{ item.count }}</span>
      </div>
    </div>
    <ul style="padding: 20px 0">
      <li v-for="item in msgList" :key="item" class="msgItem commonBorder">
        <div class="onlyFlex wrapLeftMsg">
          <img
            :src="require('@as/reading/test.png')"
            alt=""
            class="msgItemImg"
          />
          <div style="margin-left: 15px">
            <div class="betweenFlex wrapMsgItem">
              <span class="title"> {{ item.title }}</span>
              <div class="time">2020-1009</div>
            </div>
            <div class="betweenFlex wrapMsgItem">
              <span class="content">{{ item.content }}</span>
              <div v-if="item.isShanchu" class="shanchu">删除</div>
              <div v-if="!item.isShanchu" class="shanchu">回复</div>
            </div>
            <div v-if="!item.isShanchu" class="wenzhang">
              <span class="huifutishi">回复了你的评论: </span
              ><span class="huifuContent">{{ item.wenzhang }}</span>
            </div>
          </div>
        </div>
      </li>
      <div class="commonFlex messagePage" style="height: 150px">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      choice: [
        { name: '通知', count: 12 },
        { name: '私信', count: 2 },
        { name: '评论', count: 10 },
      ],
      width: 64,
      height: 56,
      activeIndex: 0,
      size: 16,
      distance: 46,
      msgList: [
        {
          title: '好哈哈哈哈',
          content: '瞧瞧瞧瞧瞧瞧',
          wenzhang: '等等等等等等等等等等等等等等等等等等等等等等',
          isShanchu: false,
        },
        {
          title: '好哈哈哈哈',
          content: '瞧瞧瞧瞧瞧瞧',
          wenzhang: '等等等等等等等等等等等等等等等等等等等等等等',
          isShanchu: true,
        },
        {
          title: '好哈哈哈哈',
          content: '瞧瞧瞧瞧瞧瞧',
          wenzhang: '等等等等等等等等等等等等等等等等等等等等等等',
          isShanchu: true,
        },
        {
          title: '好哈哈哈哈',
          content: '瞧瞧瞧瞧瞧瞧',
          wenzhang: '等等等等等等等等等等等等等等等等等等等等等等',
          isShanchu: true,
        },
      ],
    }
  },
  methods: {
    changeIndex(index) {
      this.activeIndex = index
    },
  },
}
</script>

<style lang="less">
.messageManage {
  .selected {
    border-bottom: 2px solid #0899ca !important;
  }
  .messagePage {
    align-items: flex-end;
    padding-bottom: 20px;
  }
  .huifutishi {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 300;
    color: #999999;
  }
  .huifuContent {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 300;
    color: #666666;
  }
  .selected {
    border-bottom: 2px solid #0899ca;
  }
  .msgItemImg {
    width: 40px;
    height: 40px;
    background: #4395cd;
    border-radius: 50%;
  }
  .title {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 300;
    color: #333333;
  }
  .content {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 300;
    color: #999999;
    margin-top: 10px;
  }
  .wenzhang {
    width: 885px;
    height: 40px;
    background: #f5f5f5;

    padding: 13px;

    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 300;
    color: #999999;
    margin-top: 20px;
  }
  .time {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 300;
    color: #666666;
  }
  .shanchu {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 300;
    color: #1c9ab1;
    margin-top: 10px;
    cursor: pointer;
  }
  .wrapMsgItem {
    width: 885px;
  }
  .wrapLeftMsg {
    align-items: flex-start;
  }
  .msgItem {
    padding: 20px;
    &:hover {
      background: #f0fdff;
      box-shadow: 2px 2px 6px 0 rgba(000, 000, 000, 0.1);
    }
  }
  .wrapChoiceItem {
    display: flex;
    align-items: center;
    padding-left: 20px;
    .msgCount {
      position: absolute;
      top: 11px;
      right: -10px;
      padding: 3px 5px;
      background: #e85050;
      border-radius: 7px;

      font-size: 10px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
    }
    .choiceItem {
      margin-right: 70px;
      position: relative;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>