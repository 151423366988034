<template>
  <div class="languageList">
    <el-popover
      placement="top"
      width="360"
      v-model="item.visible"
      v-for="(item, index) in langList"
      :key="item.name"
      class="lang-item"
    >
      <div class="popover-title">
        {{ item.name === '英语' ? '语言等级' : '熟练程度' }}
      </div>
      <div class="popover-content">
        <el-radio-group v-model="item.level">
          <el-radio :label="item" v-for="item in item.levelName" :key="item">{{
            item
          }}</el-radio>
        </el-radio-group>
      </div>
      <el-checkbox
        :label="item.name"
        slot="reference"
        @change="handlerChangeSelect(index)"
      ></el-checkbox>
    </el-popover>
    <div class="firstLine">
      <botChoice @confirm="handlerConfirm" @cancel="handlerCancel" />
    </div>
  </div>
</template>

<script>
import botChoice from './botChoice'
import { Resume } from '@api'
const enList = ['CET4', 'CET6', '专业八级', '专业六级']
const otherList = ['简单沟通', '读写精通', '商务洽谈', '同声传译']
export default {
  props: ['id'],
  data() {
    return {
      langList: [
        {
          name: '英语',
          select: false,
          visible: false,
          levelName: enList,
          level: '',
        },
        {
          name: '日语',
          select: false,
          visible: false,
          levelName: otherList,
          level: '',
        },
        {
          name: '韩语',
          select: false,
          visible: false,
          levelName: otherList,
          level: '',
        },
        {
          name: '德语',
          select: false,
          visible: false,
          levelName: otherList,
          level: '',
        },
        {
          name: '俄语',
          select: false,
          visible: false,
          levelName: otherList,
          level: '',
        },
        {
          name: '西班牙语',
          select: false,
          visible: false,
          levelName: otherList,
          level: '',
        },
      ],
    }
  },
  components: {
    botChoice,
  },
  watch: {
    id: {
      handler(val) {
        if (!val) return
        Resume.getOther({ userId: this.$store.state.uid }).then((res) => {
          const { langList } = this
          res.forEach((element) => {
            const idx = langList.findIndex(
              (item) => item.name === element.language
            )
            langList[idx].select = true
            langList[idx].level = element.level || element.skillLevel
          })
          this.langList = langList
        })
      },
      immediate: true,
    },
  },
  methods: {
    handlerConfirm() {
      const data = this.langList
        .filter((item) => item.select)
        .map((item) => {
          const obj = {
            language: item.name,
            userId: this.$store.state.uid,
          }
          if (item.name === '英语') obj.languageLevel = item.level
          else obj.skillLevel = item.level
          if (this.id) obj.id = this.id
          return obj
        })
      Resume.changeOther(data)
    },
    handlerCancel() {},
    handlerChangeSelect(index) {
      const { select } = this.langList[index]
      if (select) {
        this.langList[index].level = ''
        this.langList[index].visible = false
      } else this.langList[index].visible = true
      this.langList[index].select = !select
    },
  },
}
</script>

<style lang="less">
.languageList {
  padding-left: 20px;
  padding-bottom: 60px;
  .lang-item:not(:first-child) {
    margin-left: 52px;
  }
  .el-checkbox {
    margin-top: 20px;
  }
  .firstLine {
    align-items: flex-start !important;
    margin-top: 64px;
    padding-left: 140px;
  }
}
.popover-title {
  width: 48px;
  font-size: 12px;
  font-family: PingFangSC;
  font-weight: 400;
  color: #595757;
  line-height: 18px;
}
.popover-content {
  margin-top: 9px;

  & /deep/ .el-radio:nth-child(n + 4) {
    margin-top: 11px;
  }

  & /deep/ .el-radio__label {
    width: 48px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #afafaf;
    line-height: 18px;
  }
}
</style>