<template>
  <div class="hotZhuanti">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <img :src="require('@as/reading/write.png')" alt="" />
        <span class="hotArticleWord">热门专题</span>
      </div>
      <span class="moreWord">MORE</span>
    </div>
    <ul style="padding: 9px 0">
      <li v-for="(item, index) in list" :key="index" class="hotArticleItem">
        <img
          :src="require('@as/reading/listImg.png')"
          alt=""
          class="authorImg"
        />
        <div style="margin-left: 11px">
          <div class="title">{{ item.title }}</div>
          <div class="desp">{{ item.desp }}</div>
          <div class="lookzhuanti commonFlex">查看专题</div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          title: '李欣欣',
          desp: '债券虚假陈述诉讼的纠的地方的方法是否是否达到摄氏度',
        },
        {
          title: '李欣欣',
          desp: '债券虚假陈述诉讼的纠的地方的方法是否是否达到摄氏度',
        },
        {
          title: '李欣欣',
          desp: '债券虚假陈述诉讼的纠的地方的方法是否是否达到摄氏度',
        },
        {
          title: '李欣欣',
          desp: '债券虚假陈述诉讼的纠的地方的方法是否是否达到摄氏度',
        },
      ],
    }
  },
  methods: {},
}
</script>
<style lang="less">
.hotZhuanti {
  padding: 0 20px;
  background: white;
  width: 330px;
  box-sizing: border-box;
  padding-top: 10px;
  .hasBack {
    background: lightgray;
  }
  .hotArticleItem {
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
    line-height: 18px;
    padding: 9px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .lookzhuanti {
      width: 60px;
      height: 20px;
      border: 1px solid #40aabe;
      margin-top: 9px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c99b0;
    }
    .title {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
    .desp {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #a7a8a8;
      margin-top: 9px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      width: 143px;
    }
    .authorImg {
      width: 128px;
      height: 72px;
    }
    &:last-child {
      border-bottom: none !important;
    }
    .hotArticleItemTitle {
      font-size: 13px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #595757;
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    .moreWord {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a7a8a8;
    }
    .wraphotArticleWord {
      border-bottom: 3px solid #1c9ab1;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .hotArticleWord {
        margin-left: 9px;

        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
    }
  }
}
</style>