<template>
  <div class="wrapChoiceItem">
    <div
      v-for="(item, index) in choice"
      :key="index"
      class="choiceItem commonFlex"
      :style="{
        width: width + 'px',
        height: height + 'px',
        fontSize: size + 'px',
        marginRight: distance + 'px',
      }"
      :class="{ selected: activeIndex == index }"
      @click="changeIndex(index)"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    changeIndex(index) {
      this.$emit("changeIndex", index);
    },
  },
  props: {
    choice: Array,
    width: Number,
    height: Number,
    activeIndex: Number,
    size: Number,
    distance: Number,
  },
};
</script>

<style lang="less">
.wrapChoiceItem {
  display: flex;
  align-items: center;
  .choiceItem {
    margin-right: 70px;

    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
    border-bottom: 2px solid transparent;
  }
}
</style>