import Vue from "vue";
import Router from "vue-router";
import index from "@v/index.vue";
import reading from "@v/reading/index.vue";
import kuaixun from "@v/reading/kuaixun.vue";
import zhichang from "@v/reading/zhichang.vue";
import zhuanti from "@v/reading/zhuanti.vue";
import zhuantiDetail from "@v/reading/zhuantiDetail.vue";
import zhuanlan from "@v/reading/zhuanlan.vue";
import zhuanlanIndex from "@v/reading/zhuanlanIndex.vue";
import dingyue from "@v/reading/dingyue.vue";
import renqiAuthor from "@v/reading/renqiAuthor.vue";
// import publishArticle from "@v/reading/publishArticle.vue";
import wendaDetail from "@v/reading/wendaDetail.vue";
import wendaList from "@v/reading/wendaList.vue";
import biaoqianList from "@v/reading/biaoqianList.vue";
import biaoqianItem from "@v/reading/biaoqianItem.vue";
import contentDetail from "@v/reading/contentDetail.vue";
import editorContent from "@v/reading/editorContent.vue";
import login from "@v/login/login.vue";
import huangye from "@v/huangye";
import createHuangye from "@v/huangye/createHuangye";
import findPassword from "@v/login/findPassword";
import register from "@v/login/register";
import zhaopinDepart from "@v/zhaopin/zhaopinDepart";
import zhaopinMain from "@v/zhaopin/zhaopinMain";
import jobGuide from "@v/zhaopin/jobGuide";
import zhaopinDetail from "@v/zhaopin/zhaopinDetail";
import publishJob from "@v/zhaopin/publishJob";
import createResume from "@v/zhaopin/createResume";
import personal from "@v/personal/index";
import zhuanlanManage from "@c/personal/zhuanlanManage";
import answerManage from "@c/personal/wendaManage";
import dingyueManage from "@c/personal/dingyueManage";
import fabuManage from "@c/personal/fabuManage";
import canyuManage from "@c/personal/canyuManage";
import myResumeManage from "@c/personal/myResume";
import toudiManage from "@c/personal/toudiManage";
import orderManage from "@c/personal/orderManage";
import myCart from "@c/personal/myCart";
import messageManage from "@c/personal/messageManage";
import socialManage from "@c/personal/socialManage";
import JobManage from "@c/personal/JobManage";
import personalSet from "@c/personal/personalSet";
import resumeManage from "@c/personal/resumeManage";
import searchResumeManage from "@c/personal/searchResumeManage";
import zhanghaoSet from "@c/personal/zhanghaoSet";
import shimingRenzheng from "@c/personal/shimingRenzheng";
import mainIndex from "@v/mainIndex";
import mainHuangye from "@v/mainIndex/mainHuangye";
import mainZhuanlan from "@v/mainIndex/mainZhuanlan";
import mainHuodong from "@v/mainIndex/mainHuodong";
import mainZhaopin from "@v/mainIndex/mainZhaopin";
import mainWenda from "@v/mainIndex/mainWenda";
import mainGuanzhu from "@v/mainIndex/mainGuanzhu";
Vue.use(Router);
export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "index",
      component: index,
    },
    {
      path: "/myCart",
      name: "myCart",
      component: myCart,
    },
    {
      path: "/mainIndex",
      name: "mainIndex",
      component: mainIndex,
      children: [
        {
          path: "/",
          name: "mainHuangye",
          component: mainHuangye,
        },
        {
          path: "mainGuanzhu",
          name: "mainGuanzhu",
          component: mainGuanzhu,
        },
        {
          path: "mainWenda",
          name: "mainWenda",
          component: mainWenda,
        },
        {
          path: "mainZhaopin",
          name: "mainZhaopin",
          component: mainZhaopin,
        },
        {
          path: "mainHuodong",
          name: "mainHuodong",
          component: mainHuodong,
        },
        {
          path: "mainZhuanlan",
          name: "mainZhuanlan",
          component: mainZhuanlan,
        },
      ],
    },
    {
      path: "/personal",
      name: "personal",
      component: personal,
      children: [
        {
          path: "searchResumeManage",
          name: "searchResumeManage",
          component: searchResumeManage,
        },
        {
          path: "set",
          name: "personalSet",
          component: personalSet,
        },
        {
          path: "shimingRenzheng",
          name: "shimingRenzheng",
          component: shimingRenzheng,
        },
        {
          path: "zhuanlanManage",
          name: "zhuanlanManage",
          component: zhuanlanManage,
        },
        {
          path: "zhanghaoSet",
          name: "zhanghaoSet",
          component: zhanghaoSet,
        },
        {
          path: "JobManage",
          name: "JobManage",
          component: JobManage,
        },
        {
          path: "messageManage",
          name: "messageManage",
          component: messageManage,
        },
        {
          path: "socialManage",
          name: "socialManage",
          component: socialManage,
        },
        {
          path: "orderManage",
          name: "orderManage",
          component: orderManage,
        },
        {
          path: "toudiManage",
          name: "toudiManage",
          component: toudiManage,
        },
        {
          path: "myResumeManage",
          name: "myResumeManage",
          component: myResumeManage,
        },
        {
          path: "resumeManage",
          name: "resumeManage",
          component: resumeManage,
        },
        {
          path: "canyuManage",
          name: "canyuManage",
          component: canyuManage,
        },
        {
          path: "dingyueManage",
          name: "dingyueManage",
          component: dingyueManage,
        },
        {
          path: "answerManage",
          name: "answerManage",
          component: answerManage,
        },
        {
          path: "fabuManage",
          name: "fabuManage",
          component: fabuManage,
        },
      ],
    },
    {
      path: "/reading",
      name: "reading",
      component: reading,
      children: [
        {
          path: "editorContent",
          name: "editorContent",
          component: editorContent,
        },
        {
          path: "contentDetail",
          name: "contentDetail",
          component: contentDetail,
        },
        {
          path: "biaoqianList",
          name: "biaoqianList",
          component: biaoqianList,
        },
        {
          path: "biaoqianItem",
          name: "biaoqianItem",
          component: biaoqianItem,
        },
        {
          path: "wendaList",
          name: "wendaList",
          component: wendaList,
        },
        {
          path: "wendaDetail",
          name: "wendaDetail",
          component: wendaDetail,
        },
        // {
        //   path: "publishArticle",
        //   name: "publishArticle",
        //   component: publishArticle,
        // },
        {
          path: "renqiAuthor",
          name: "renqiAuthor",
          component: renqiAuthor,
        },
        {
          path: "zhichang",
          name: "zhichang",
          component: zhichang,
        },
        {
          path: "kuaixun",
          name: "kuaixun",
          component: kuaixun,
        },
        {
          path: "zhuanti",
          name: "zhuanti",
          component: zhuanti,
        },
        {
          path: "zhuantiDetail",
          name: "zhuantiDetail",
          component: zhuantiDetail,
        },
        {
          path: "zhuanlan",
          name: "zhuanlan",
          component: zhuanlan,
        },
        {
          path: "zhuanlanIndex",
          name: "zhuanlanIndex",
          component: zhuanlanIndex,
        },
        {
          path: "dingyue",
          name: "dingyue",
          component: dingyue,
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: login,
    },
    {
      path: "findPassword",
      name: "findPassword",
      component: findPassword,
    },
    {
      path: "/createResume",
      name: "createResume",
      component: createResume,
    },
    {
      path: "/zhaopinDetail",
      name: "zhaopinDetail",
      component: zhaopinDetail,
    },
    {
      path: "/publishJob",
      name: "publishJob",
      component: publishJob,
    },
    {
      path: "/zhaopinDepart",
      name: "zhaopinDepart",
      component: zhaopinDepart,
    },
    {
      path: "/jobGuide",
      name: "jobGuide",
      component: jobGuide,
    },
    {
      path: "/zhaopinMain",
      name: "zhaopinMain",
      component: zhaopinMain,
    },
    {
      path: "/register",
      name: "register",
      component: register,
    },

    {
      path: "/huangye",
      name: "huangye",
      component: huangye,
    },
    {
      path: "/huangye/createHuangye",
      name: "createHuangye",
      component: createHuangye,
    },

    {
      path: "/serve",
      component: () => import("@v/serve/index.vue"),
      children: [
        {
          path: "/",
          name: "serve",
          component: () => import("@v/serve/serve.vue"),
        },
        {
          path: "/serve/member",
          name: "member",
          component: () => import("@v/serve/member.vue"),
        },
        {
          path: "/serve/memberDetail",
          name: "memberDetail",
          component: () => import("@v/serve/memberDetail.vue"),
        },
        {
          path: "/serve/brand",
          name: "brand",
          component: () => import("@v/serve/brand.vue"),
        },
        {
          path: "/serve/brandDetail",
          name: "brandDetail",
          component: () => import("@v/serve/brandDetail.vue"),
        },
        {
          path: "/serve/knowledge",
          name: "knowledge",
          component: () => import("@v/serve/knowledge.vue"),
        },
        {
          path: "/serve/knowDetail",
          name: "knowDetail",
          component: () => import("@v/serve/knowDetail.vue"),
        },
        {
          path: "/serve/strict",
          name: "strict",
          component: () => import("@v/serve/strict.vue"),
        },
        {
          path: "/serve/strictDetail",
          name: "strictDetail",
          component: () => import("@v/serve/strictDetail.vue"),
        },
        {
          path: "/serve/buy",
          name: "buy",
          component: () => import("@v/serve/buy.vue"),
        },
        {
          path: "/serve/tender",
          name: "tender",
          component: () => import("@v/serve/tender.vue"),
        },
        {
          path: "/serve/tenderDetail",
          name: "tenderDetail",
          component: () => import("@v/serve/tenderDetail.vue"),
        },
      ],
    },
    {
      path: "/event",
      name: "event",
      component: () => import("@v/event/index.vue"),
    },
    {
      path: "/eventDetail",
      name: "eventDetail",
      component: () => import("@v/event/eventDetail.vue"),
    },
    {
      path: "/eventJoin",
      name: "eventJoin",
      component: () => import("@v/event/eventJoin.vue"),
    },
    {
      path: "/eventTake",
      name: "eventTake",
      component: () => import("@v/event/eventTake.vue"),
    },
    {
      path: "/eventCreate",
      name: "eventCreate",
      component: () => import("@v/event/eventCreate.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () => import("@v/about.vue"),
    },
    {
      path: "/search",
      name: "search",
      component: () => import("@v/search.vue"),
    },
    {
      path: "/resumeDetail",
      name: "resumeDetail",
      component: () => import("@v/personal/detail.vue")
    }
  ],
});
