<template>
  <div class="jobManage">
    <div class="top commonBorder betweenFlex" v-if="!showFankui">
      <headerChoice
        @changeIndex="change"
        :choice="choiceList"
        :activeIndex="activeIndex"
        :width="48"
        :height="56"
        size="16"
        :distance="54"
      />
      <div class="publishNew commonFlex canClick" v-show="!shouFankui">
        发布新职位
      </div>
    </div>
    <div class="top commonBorder startFlex" v-if="showFankui">
      <div class="wrapLinkImg">
        <div class="whiteLINK commonFlex" @click="showFankui = false">
          <img :src="require('@as/personal/go-to-link 拷贝.png')" alt="" />
        </div>
      </div>
      <div class="commonFlex topTitle">国际争端解决领域专业律师</div>
    </div>
    <ul style="padding: 0 20px" v-if="!showFankui">
      <li
        v-for="(item, index) in joblist"
        :key="index"
        class="articleItem commonBorder betweenFlex"
      >
        <div>
          <div class="title">{{ item.title }}</div>
          <div class="startFlex" style="margin-top: 10px">
            <img :src="require('@as/reading/time.png')" alt="" />
            <span class="jobtime"
              >2020.10.25～2020.12.31<span class="fengeLine">|</span>北京</span
            >
          </div>
        </div>
        <div>
          <div v-if="item.isLine" class="zaixian">在线中</div>
          <div v-if="!item.isLine" class="xiaxian">已下线</div>
        </div>
        <div class="wrapFankui commonFlex canClick" @click="showFankui = true">
          <span class="lookFankui">查看反馈</span>
          <span class="fankuiCount">35</span>
        </div>
        <div class="betweenFlex">
          <div class="wrapIcon">
            <div class="commonFlex whiteBorder">
              <img :src="require('@as/personal/refresh.png')" alt="" />
            </div>
            <span class="refresh">刷新</span>
          </div>
          <div class="wrapIcon">
            <div class="commonFlex whiteBorder">
              <img :src="require('@as/personal/edit.png')" alt="" />
            </div>
            <span class="refresh">修改</span>
          </div>
          <div class="wrapIcon">
            <div class="commonFlex whiteBorder">
              <img :src="require('@as/personal/xiaxian.png')" alt="" />
            </div>
            <span class="refresh">下线</span>
          </div>
        </div>
      </li>
      <div class="wrapTotalPage commonFlex">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
    <ul style="padding: 0 20px" v-if="showFankui">
      <li
        v-for="(item, index) in fankuiList"
        :key="index"
        class="articleItem commonBorder betweenFlex"
      >
        <div class="onlyFlex">
          <img
            :src="require('@as/reading/test.png')"
            alt=""
            class="fankuiIMG"
          />
          <div style="margin-left: 10px">
            <div class="title">{{ item.title }}</div>
            <div class="personDetail">
              26岁 <span class="fengeLine">|</span> 法学硕士<span
                class="fengeLine"
                >|</span
              >1-2年
            </div>
          </div>
        </div>
        <div class="jobtime">投递日期：2020.10.24</div>
        <div class="betweenFlex">
          <div class="wrapIcon">
            <div class="commonFlex whiteBorder">
              <img :src="require('@as/personal/partner.png')" alt="" />
            </div>
            <span class="refresh">邀面试</span>
          </div>
          <div class="wrapIcon">
            <div class="commonFlex whiteBorder">
              <img :src="require('@as/personal/forbidden.png')" alt="" />
            </div>
            <span class="refresh">不合适</span>
          </div>
          <div class="wrapIcon">
            <div class="commonFlex whiteBorder">
              <img :src="require('@as/personal/lanview.png')" alt="" />
            </div>
            <span class="refresh">查看</span>
          </div>
          <div class="wrapIcon">
            <div class="commonFlex whiteBorder">
              <img :src="require('@as/personal/xiazai.png')" alt="" />
            </div>
            <span class="refresh">下载</span>
          </div>
        </div>
      </li>
      <div class="wrapTotalPage commonFlex">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </ul>
  </div>
</template>

<script>
import headerChoice from '../headerChoice'
export default {
  data() {
    return {
      choiceList: ['在线中', '已下线', '审核中'],
      showFankui: false,
      activeIndex: 0,
      fankuiList: [
        { title: '去我的师傅的师傅说的' },
        { title: '去我的师傅的师傅说的' },
        { title: '去我的师傅的师傅说的' },
        { title: '去我的师傅的师傅说的' },
      ],
      joblist: [
        { title: '随哈哈哈哈哈哈哈哈', isLine: true },
        { title: '随哈哈哈哈哈哈哈哈', isLine: false },
        { title: '随哈哈哈哈哈哈哈哈', isLine: false },
        { title: '随哈哈哈哈哈哈哈哈', isLine: false },
      ],
    }
  },
  methods: {
    change(index) {
      this.activeIndex = index
    },
  },
  components: {
    headerChoice,
  },
}
</script>

<style lang="less">
.jobManage {
  .selectedXingzhi {
    border: 1px solid #c3e4eb !important;
    padding: 2px 7px;
    color: #1c9ab1 !important;
  }
  .fengeLine {
    margin: 0 10px;
  }
  .whiteBorder {
    width: 28px;
    height: 28px;
    background: #ffffff;
    border: 1px solid #a3c6cc;
    border-radius: 50%;
  }
  .topTitle {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
    margin-left: -25px;
  }
  .wrapLinkImg {
    width: 64px;
    border-bottom: 2px solid #0899ca;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 57px;
  }
  .whiteLINK {
    width: 24px;
    height: 24px;
    background: #f5f5f5;
    border: 1px solid #dedede;
    border-radius: 50%;
  }
  .personDetail {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    margin-top: 10px;
  }
  .fankuiIMG {
    width: 40px;
    height: 40px;
    background: #9fded5;
    border-radius: 50%;
  }
  .wrapTotalPage {
    margin: 0 auto;
    height: 100px;
    text-align: center;
  }
  .articleItem:hover {
    background: linear-gradient(90deg, #ffffff 0%, #ecfaff 50%, #ffffff 100%);
  }
  .articleItem {
    padding: 20px 0;
    .wrapIcon {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-right: 16px;
      cursor: pointer;
    }
    .refresh {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
      margin-top: 10px;
    }
    .wrapFankui {
      width: 90px;
      height: 26px;
      border: 1px solid #1c9ab1;
      border-radius: 2px;

      .fankuiCount {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
        margin-left: 5px;
      }
      .lookFankui {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #666666;
      }
    }
    .xiaxian {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
    }
    .zaixian {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    .jobtime {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #afafaf;
      margin-left: 10px;
    }
    .title {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
  }
  .top {
    padding-left: 20px;
  }
  .publishNew {
    width: 80px;
    height: 30px;
    background: #4395cd;
    border-radius: 2px;
    margin-right: 20px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>