<template>
  <div class="rightBk commonFlex">
    <img class="logo" :src="require('@as/login/logo.png')" alt="" />
    <div class="yizhanshi">专业法律人一站式信息门户</div>
    <div class="betweenFlex" style="margin-top: 65px">
      <div class="commonFlex direction">
        <img :src="require('@as/login/智慧.png')" alt="" />
        <div class="twoWord">慧 律</div>
        <div class="loginLongword">让专业法律人在一起前行</div>
      </div>
      <div class="commonFlex direction" style="margin-left: 42px">
        <img :src="require('@as/login/智慧社区.png')" alt="" />
        <div class="twoWord">会 律</div>
        <div class="loginLongword">让专业法律人在一起前行</div>
      </div>
    </div>
    <div class="betweenFlex" style="margin-top: 48px">
      <div class="commonFlex direction">
        <img :src="require('@as/login/智慧运维.png')" alt="" />
        <div class="twoWord">惠 律</div>
        <div class="loginLongword">让专业法律人在一起前行</div>
      </div>
      <div class="commonFlex direction" style="margin-left: 42px">
        <img :src="require('@as/login/智慧招商.png')" alt="" />
        <div class="twoWord">汇 律</div>
        <div class="loginLongword">让专业法律人在一起前行</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.rightBk {
  width: 410px;
  height: 560px;
  background: #1c9ab1;
  flex-direction: column;
  .logo {
    width: 199px;
    height: 50px;
  }
  .commonFlex.direction {
    img {
      width: 40px;
      height: 40px;
    }
  }
  .yizhanshi {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
    margin-top: 10px;
  }
  .twoWord {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 500;
    color: #ffffff;
    margin-top: 10px;
  }
  .loginLongword {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 500;
    color: #ffffff;
    margin-top: 4px;
  }
}
</style>