<template>
  <div class="personalIntro">
    <div class="hotArticleTop commonBorder">
      <div class="wraphotArticleWord">
        <img :src="require('@as/personal/简介.png')" alt="" />
        <span class="hotArticleWord">个人简介</span>
      </div>
      <div class="startFlex canClick">
        <img :src="require('@as/personal/编辑.png')" alt="" />
        <span class="moreWord">编辑</span>
      </div>
    </div>
    <div class="wrapAllinfo">
      <div class="startFlex eachitem">
        <img :src="require('@as/personal/图层 33.png')" alt="" />
        <div class="title" :class="{ default: intro.companyName }">
          {{ intro.companyName || '暂未填写公司和职称' }}
        </div>
      </div>
      <div class="startFlex eachitem">
        <img :src="require('@as/personal/图层 34.png')" alt="" />
        <div class="title wrapSign" v-if="intro.label">
          <div
            class="singITEM commonFlex"
            v-for="(item, index) in intro.label"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
        <div class="title" :class="{ default: intro.label }" v-else>
          暂未填写技能专长
        </div>
      </div>
      <div class="startFlex eachitem">
        <img :src="require('@as/personal/图层 35.png')" alt="" />
        <div class="title" :class="{ default: intro.introduce }">
          {{ intro.introduce || '暂未填写个人简介' }}
        </div>
      </div>
      <div class="startFlex eachitem">
        <img :src="require('@as/personal/图层 36.png')" alt="" />
        <div class="title" :class="{ default: intro.city }">
          {{ intro.city || '暂未填写所在城市' }}
        </div>
      </div>
      <div class="startFlex eachitem">
        <img :src="require('@as/personal/图层 37.png')" alt="" />
        <div class="title" :class="{ default: intro.linkUrl }">
          {{ intro.linkUrl || '暂未填写个人网址' }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { User } from '@api'
export default {
  data() {
    return {
      intro: {},
    }
  },
  created() {
    User.getUserIntro({
      userId: this.$store.state.uid,
    }).then((res) => {
      this.intro = res
    })
  },
}
</script>
<style lang="less">
.personalIntro {
  padding: 20px;
  background: white;
  margin-top: 20px;
  width: 330px;
  margin-top: 20px;
  padding-left: 25px;
  padding-top: 17px;
  padding-right: 24px;
  padding-bottom: 0px;
  .singITEM {
    width: 68px;
    height: 20px;
    border: 1px solid #dedede;
    border-radius: 2px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .eachitem {
    margin-bottom: 20px;
    align-items: flex-start;
    .title {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
      margin-left: 15px;

      &.default {
        color: #999999;
      }
    }
  }
  .wrapAllinfo {
    padding: 20px 0;
    padding-bottom: 1px;
  }
  .wrapSign {
    display: flex;
    flex-wrap: wrap;

    .selectedSign {
      width: 90px;
      height: 26px;
      background: rgba(28, 155, 178, 0.1);
      border: 1px solid #1c9bb2 !important;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9bb2 !important;
    }
    .signItem {
      margin-right: 10px;
      margin-top: 14px;
      width: 90px;
      height: 26px;
      border: 1px solid #dedede;

      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #afafaf;
    }
    .noDistance {
      margin-right: 0 !important;
    }
  }
  .hotArticleItem {
    display: flex;
    padding: 24px 0 15px 0;
    align-items: center;
    justify-content: space-between;
    .hotleft {
      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }

      .title {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #595757;
      }
      .leftBot {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
        margin-top: 12px;
      }
    }
    .right {
      width: 51px;
      height: 23px;
      border: 1px solid #1c9ab1;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    &:last-child {
      border-bottom: none !important;
    }
    .hotArticleItemTitle {
      margin-left: 12px;
    }
  }
  .hotArticleMid {
    padding: 0 63px;
    .wrapPaihang {
      display: flex;
      justify-content: space-between;
      margin: 23px 0;
      .selected {
        color: #1c9ab1;
        border-bottom: 1px solid #1c9ab1;
      }
      div {
        padding-bottom: 9px;
        cursor: pointer;

        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #585858;
      }
    }
  }
  .hotArticleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    .moreWord {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
      margin-left: 5px;
    }
    .wraphotArticleWord {
      border-bottom: 3px solid #1c9ab1;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .hotArticleWord {
        margin-left: 9px;

        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
