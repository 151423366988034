import req from "@/utils/request";
import { Message } from "element-ui";

const tagList = {
  业务领域: [
    "资本市场与证券",
    "保险",
    "银行与金融",
    "资产证券化与金融产品",
    "资产管理",
    "私募股权与投资基金",
    "收购兼并",
    "争议解决",
    "知识产权",
    "房地产",
    "建筑工程",
    "基础设施与项目融资",
    "外商投资",
    "国际贸易",
    "反垄断与竞争法",
    "一带一路与海外投资",
    "劳动法",
    "税务",
    "家事与财富规划",
    "破产重组与清算",
    "海事海商",
    "融资租赁",
    "海关与贸易",
    "刑事",
    "合规与风控",
    "日本业务",
    "网络安全与数据保护",
    "政府法律事务",
    "环境保护",
    "民法",
    "行政法与行政诉讼法",
    "公司法律业务",
  ],
  行业领域: [
    "科技、电信与互联网",
    "教育",
    "能源与自然资源",
    "文化、娱乐与传媒",
    "生命科学与医疗健康",
    "航空",
    "国防军工",
    "酒店/旅游开发与管理",
  ],
  其他分类: ["法律品牌建设与传播", "其他分类"],
};

export default {
  // 定制活动
  custom: (data) =>
    req.post("/activity/custom", data).then(
      () => {
        Message.success("活动定制成功，即将返回上一页");
        return Promise.resolve();
      },
      () => {
        Message.error("活动定制失败，请重试");
        return Promise.reject();
      }
    ),

  // 获取活动列表
  getList: (data) => req.get("/activity/deatil", data),

  // 新增活动
  add: (data) =>
    req.post("/activity/new", data).then(
      () => {
        Message.success("新增活动成功，即将返回上一页");
        return Promise.resolve();
      },
      () => {
        Message.error("新增活动失败，请重试");
        return Promise.reject();
      }
    ),

  // 新增活动--添加主办方、协办方、活动嘉宾
  addGuest: (data) =>
    req.post("/activity/new-guest", data).then(
      () => {
        Message.success("添加主办方、协办方、活动嘉宾成功");
        return Promise.resolve();
      },
      () => {
        Message.error("添加主办方、协办方、活动嘉宾失败，请重试");
        return Promise.reject();
      }
    ),

  // 新增活动--获取主办方、协办方、活动嘉宾列表
  getGuestList: (data) => req.get("/activity/new-guest-list", data),

  // 获取活动标签列表
  getTagList: () => Promise.resolve(tagList),
};
